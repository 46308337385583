/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { Redirect } from "react-router-dom";

import { SessionHandling } from "data/storage/";

import { usePPFPlans } from "data/network/hooks/";
import { INTERFACE_PATHS, SUB_INTERFACE_PATHS } from "data/constants";

function getUsersOwnPlanFromPlansList(plans: TytoData.PPF.Plans.Plan[]) {
  const loggedInUserID = SessionHandling.getUserIDOfActiveSession();

  if (!plans?.length || !loggedInUserID) {
    return undefined;
  }

  // * Multiple plans might match 'userID' as plans 'aboutID'
  const plansMatchingUserID = plans.filter(
    (plan) =>
      plan.aboutID === loggedInUserID && plan.activeStatus === "ocENABLED"
  );
  // debugger;

  if (!plansMatchingUserID?.length) {
    return;
  } else if (plansMatchingUserID.length === 1) {
    return plansMatchingUserID[0];
  }

  // * Sort newest to oldest;
  const sortedPlans = _.orderBy(plansMatchingUserID, ["startTime"], ["desc"]);

  const now = Date.now();

  // * Starting from newest, find first plan that has started but is not over
  const firstStartedButNotOverPlan = sortedPlans.find((plan) => {
    const startTimeMS = +new Date(plan.startTime);
    const endTimeMS = +new Date(plan.endTime);

    return startTimeMS < now && endTimeMS > now;
  });

  // * Return current plan, or newest is current is not decided within search parameters (above)
  return firstStartedButNotOverPlan ?? plansMatchingUserID[0];
}

interface Props {
  isMobile: boolean;
  showSearch: boolean;
  toggleShowSearch: (showSearch: boolean) => void;
}

const Home = ({ showSearch, toggleShowSearch, isMobile }: Props) => {
  const [redirectURL, updateRedirectURL] = React.useState<string | null>(null);

  const { data, storedValueQuery, isLoading } = usePPFPlans({
    onSuccess: (plansResp) => {
      const { gsPlans } = plansResp ?? {};

      if (gsPlans?.length) {
        const usersOwnPlan = getUsersOwnPlanFromPlansList(gsPlans);

        if (usersOwnPlan?.gsPlanID) {
          updateRedirectURL(
            `${INTERFACE_PATHS.PLAN}/${usersOwnPlan.gsPlanID}/${SUB_INTERFACE_PATHS.PLAN.GOALS}`
          );

          return;
        } else {
          updateRedirectURL(INTERFACE_PATHS.INTRODUCTION);
          return;
        }
      } else {
        // !!!!!! ???????
        // TODO What to do if no plans loaded at all that user can see?  Maybe go to Intro page?
        // !!!!!! ???????
        updateRedirectURL(INTERFACE_PATHS.INTRODUCTION);

        return;
      }
    },
  });

  if (redirectURL) {
    return <Redirect to={`${redirectURL}`} />;
  }

  return (
    <div className="home-inner-cont">
      <div style={{ width: "100%", display: "flex" }}>
        <h1
          className="font-size-title title-font"
          style={{ margin: "80px auto" }}
        >
          {isLoading ? "Loading..." : "Home"}
        </h1>
      </div>
    </div>
  );
};

export default Home;
