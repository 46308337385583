/*
 * Component Description
 */
import * as React from "react";

import { usePPFPlanDashboardSummary } from "data/network/hooks/";
import { SessionHandling } from "data/storage/";
import _ from "lodash";

import { StandardNav } from "components/meta/";
import "./style.scss";
import StatisticsSelection from "./subcomponents/StatisticsSelection";
import { Icon } from "components/common/";
import StatisticsDisplay from "./subcomponents/StatisticsDisplay";
import Nav from "components/meta/sub-navs/generic-nav";

interface Props extends SITE.GeneralStoreInjectedProps {}

const Statistics = (props: Props) => {
  const [teamID, updateTeamID] = React.useState(() => {
    return SessionHandling.getActiveSession()?.domainID ?? 0;
  });
  const [isCascade, updateIsCascade] = React.useState(false);
  const [endDate, updateEndDate] = React.useState(new Date());
  const [startDate, updateStartDate] = React.useState(() => {
    const startDate = new Date(endDate);

    startDate.setDate(startDate.getDate() - 30);

    return startDate;
  });

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return width;
  }
  const [windowDimensions, updateWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    function handleResize() {
      updateWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  var dashboardSummaryQuery = usePPFPlanDashboardSummary({
    afterDate: startDate,
    beforeDate: endDate,
    isCascade,
    teamID,
    interval: "DD",
  });

  var summary = {
    countDistinctAboutGoalsCreated:
      dashboardSummaryQuery.data?.countDistinctAboutGoalsCreated ?? 0,
    countDistinctAboutGoalsUpdated:
      dashboardSummaryQuery.data?.countDistinctAboutGoalsUpdated ?? 0,
    countGoalsCreated: dashboardSummaryQuery.data?.countGoalsCreated ?? 0,
    countGoalsUpdated: dashboardSummaryQuery.data?.countGoalsUpdated ?? 0,
    countPersons: dashboardSummaryQuery.data?.countPersons ?? 0,
    dateSeries: dashboardSummaryQuery.data?.dateSeries,
  };

  return (
    <main className="interface-stats centered-content-wrapper">
      {!_.get(props, "GeneralStore.state.isMobile", false) && (
        <StandardNav className="statistics-main-nav" />
      )}
      {_.get(props, "GeneralStore.state.isMobile", false) && (
        <Nav
          className="statistics-nav"
          children={<h2 className="interface-stats-title">Statistics</h2>}
        />
      )}
      {windowDimensions > 800 && (
        <h1 className="stats-heading-desktop">Statistics</h1>
      )}
      <StatisticsSelection
        passDates={(startDate, endDate) => {
          updateStartDate(startDate);
          updateEndDate(endDate);
        }}
        passTeamID={(teamID) => {
          updateTeamID(teamID);
        }}
        passCascade={(cascade) => {
          updateIsCascade(cascade);
        }}
      />
      {dashboardSummaryQuery.isLoading && (
        <div className="interface-stats-loading-data">
          <Icon
            className="interface-stats-loading-data-icon"
            icon="cog"
            size={46}
          />
        </div>
      )}
      {!dashboardSummaryQuery.isLoading && (
        <StatisticsDisplay
          summaryData={summary}
          startDate={startDate}
          endDate={endDate}
          screenWidth={windowDimensions}
        />
      )}
    </main>
  );
};

export default Statistics;
