import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Link, Timestamp } from "components/common/";
import { usePPFTemplateImageURL } from "data/helpers/Hooks";
import {
  getChildGoalURLFromGoal,
  getGoalImageURL,
  getFriendlyStatus,
} from "../../utils/";

import "./ChildGoalCard.scss";

const TWO_DAYS_IN_MS = 1000 * 60 * 60 * 24 * 2;

const BG_STYLE: React.CSSProperties = {
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
};

interface Props {
  childGoal: TytoData.PPF.Plan.Goals.ChildGoal;
  locationData: any;
  parentByTheme: GoalStories.KeyedData["parentByTheme"];
}

const ChildGoalCard = ({ childGoal, locationData, parentByTheme }: Props) => {
  const [toPath, updateToPath] = React.useState(() => {
    // TODO
    return `${getChildGoalURLFromGoal(childGoal)}${locationData.search ?? ""}`;
  });
  const [goalImageURL, updateGoalImageURL] = React.useState(() => {
    return getGoalImageURL(childGoal);
  });
  const [relModifiedMsg] = React.useState(() => {
    return getTimestampRelText(childGoal.modifiedDate);
  });

  const [completedPercentage] = React.useState(() => {
    const result = childGoal.gsGoalItems.reduce((count, step) => {
      if (step.status === "ocDISABLED" || step.status !== "ocCOMPLETE") {
        return count;
      }

      return count + Math.min(Math.max(step.itemWeight, 0), 100);
    }, 0);

    return Math.min(Math.max(result, 0), 100);
  });

  React.useEffect(() => {
    updateGoalImageURL(getGoalImageURL(childGoal));
  }, [childGoal.profileImageAsset]);

  React.useEffect(() => {
    updateToPath(
      `${getChildGoalURLFromGoal(childGoal)}${locationData.search ?? ""}`
    );
  }, [locationData?.search]);

  const { templateSquareImageURL } = usePPFTemplateImageURL(
    childGoal.profileImageKey
  );

  const bgSrcURL = templateSquareImageURL || goalImageURL;

  const parentType = parentByTheme[childGoal.gsParentGoalID];

  return (
    <Link
      className={cx("cg-goalstories-goalsgrid-card", !bgSrcURL && "no-image")}
      style={
        bgSrcURL
          ? {
              background: `linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent 75%), url(${bgSrcURL})`,
              ...BG_STYLE,
            }
          : undefined
      }
      href={toPath}
      value={`Go to ${parentType} Goal`}
    >
      <div className="cg-goalstories-goalsgrid-card-timestamp-cont">
        <span
          className={cx(
            "cg-goalstories-goalsgrid-card-timestamp-wrapper",
            relModifiedMsg && "recent-timestamp"
          )}
        >
          {relModifiedMsg ? (
            <>Updated {relModifiedMsg}!</>
          ) : (
            <>
              Last updated{" "}
              <Timestamp
                className="cg-goalstories-goalsgrid-card-timestamp"
                date={childGoal.modifiedDate}
              />{" "}
              ago
            </>
          )}
        </span>
      </div>

      <h2 className="cg-goalstories-goalsgrid-card-title">{childGoal.name}</h2>

      <div className="cg-goalstories-goalsgrid-card-status-cont">
        <div className="cg-goalstories-goalsgrid-card-status-left-cont">
          <p className="cg-goalstories-goalsgrid-card-percent">
            {completedPercentage}%
          </p>
          <p className="cg-goalstories-goalsgrid-card-targetstatus">
            {getFriendlyStatus(childGoal.targetStatus)}
          </p>
        </div>

        <div className="cg-goalstories-goalsgrid-card-status-right-cont">
          <p
            className="cg-goalstories-goalsgrid-card-duration"
            data-theme={parentType}
            data-durationyears={childGoal.durationYears}
          >
            {childGoal.durationYears} year
            {childGoal.durationYears === 1 ? "" : "s"}
          </p>
          {parentType && (
            <p className="cg-goalstories-goalsgrid-parent-type">{parentType}</p>
          )}
        </div>
      </div>
    </Link>
  );
};

function getTimestampRelText(modifiedDate: string) {
  if (!modifiedDate) {
    return undefined;
  }

  const now = new Date();
  const asDate = new Date(modifiedDate);

  if (
    now.getFullYear() !== asDate.getFullYear() ||
    +now - +asDate > TWO_DAYS_IN_MS
  ) {
    return undefined;
  }

  if (now.getDate() === asDate.getDate()) {
    return "today";
  }

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  if (yesterday.getDate() === asDate.getDate()) {
    return "yesterday";
  }

  return undefined;
}

export default ChildGoalCard;
