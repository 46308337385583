/*
 * Component Description - Common Component that is the card itself that the wrapper ToastMessages.
 */
import * as React from "react";
import { useState, useEffect } from "react";
import Icon from "../icon";

import "./style.scss";
interface Props {
  className?: string;
  toastList: any;
  position: string;
  autoDelete: boolean;
  autoDeleteTime: number;
}

const ToastMessage = (props: Props) => {
  const { toastList, position, autoDelete, autoDeleteTime } = props;
  const [list, setList] = useState(toastList);

  useEffect(() => {
    setList([...toastList]);
  }, [toastList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length && list.length) {
        deleteToast(toastList[0].id);
      }
    }, autoDeleteTime);

    return () => {
      clearInterval(interval);
    };
  }, [toastList, autoDelete, autoDeleteTime, list]);

  const deleteToast = (id: number) => {
    const listItemIndex = list.findIndex((e: any) => e.id === id);
    const toastListItem = toastList.findIndex((e: any) => e.id === id);
    list.splice(listItemIndex, 1);
    toastList.splice(toastListItem, 1);
    setList([...list]);
  };

  return (
    <>
      <div className={`toast-notification-container ${position}`}>
        {list.map((toast: any, i: any) => (
          <div
            key={i}
            className={`toast-notification toast ${position} opaque-bg`}
            style={{ color: toast.color }}
          >
            <button
              className="toast-toast-delete-x"
              onClick={() => deleteToast(toast.id)}
            >
              X
            </button>
            <div className="toast-notification-image">
              {/* //Version with new icons */}
              <img src={toast.icon} alt="" />
              {/* //TODO works with Icon - Although it's missing exclamation and warning triangle */}
              {/* <Icon
                icon={
                  toast.title === "Success"
                    ? "check-circle" // check-circle
                    : toast.title === "Error"
                    ? "comment-alt-exclamation" //comment-alt-exclamation
                    : toast.title === "Warning"
                    ? "cog" //cog
                    : toast.title === "Info"
                    ? "info-circle" //info-circle
                    : toast.title
                }
                size={30}
              /> */}
            </div>
            <div>
              <p className="toast-notification-title">{toast.title}</p>
              <p className="toast-notification-message">{toast.description}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ToastMessage;
