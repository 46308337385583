/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { useObjectURL } from "data/helpers/Hooks";

import { getEncoding, makePathURLQualified } from "data/helpers/";
import { usePPFTemplateImageURL } from "data/helpers/Hooks";
import { Icon } from "components/common/";
import { ImagePickerModal } from "components/modals/";

import "./GoalImage.scss";

function getGoalImageURL(profileImageAsset?: Data.Asset) {
  if (!profileImageAsset?.encodings?.length) {
    return "";
  }

  const encoding = getEncoding(profileImageAsset.encodings, "ocDEFAULT");

  if (!encoding) {
    return "";
  }

  return makePathURLQualified(encoding.pathURL);
}

function isAcceptableFileType(type?: string) {
  return /image\//.test(type ?? "");
}

interface Props {
  className?: string;
  newGoalImage?: Blob | File | null;
  newGoalTemplateImage: Data.PPFTemplateImage | null;
  updating?: boolean;
  updateNewGoalImage: (newGoalImage: Blob | File | null) => void;
  updateNewGoalTemplateImage: (
    newGoalTemplateImage: Data.PPFTemplateImage | null
  ) => void;
  storedGoalTemplateImageKey?: string;
  profileImage?: Data.Asset;
  theme: keyof typeof TytoData.PPFParentGoalName;
  updateRemoveExistingImage?: (remove: boolean) => void;
}

const GoalImage = (props: Props) => {
  const [showModal, updateShowModal] = React.useState(false);
  const [goalImageURL, updateGoalImageURL] = React.useState(() => {
    return getGoalImageURL(props.profileImage);
  });
  const [acceptableDropType, updateAcceptableDropType] = React.useState<
    boolean | null
  >(null);
  const [droppedFile, updateDroppedFile] = React.useState<File | null>(null);

  const { templateSquareImageURL } = usePPFTemplateImageURL(
    props.newGoalTemplateImage?.imageKey ?? props.storedGoalTemplateImageKey
  );

  React.useEffect(() => {
    updateGoalImageURL(getGoalImageURL(props.profileImage));
  }, [props.profileImage]);

  const imageURL = useObjectURL(props.newGoalImage);

  const displayingImageURL = imageURL || templateSquareImageURL || goalImageURL;

  return (
    <>
      <section
        className={cx(
          "cg-editgoal-img-cont",
          displayingImageURL && "has-image",
          acceptableDropType !== null &&
            acceptableDropType &&
            "acceptable-file-type",
          acceptableDropType !== null &&
            !acceptableDropType &&
            "unacceptable-file-type",
          props.className
        )}
        style={{
          backgroundImage: displayingImageURL
            ? `url(${displayingImageURL})`
            : undefined,
        }}
        onDrop={(e) => {
          e.preventDefault?.();

          console.log("DROP: ", e);

          const isImage = isAcceptableFileType(
            e.dataTransfer?.files?.[0]?.type ?? ""
          );

          if (isImage) {
            updateDroppedFile(e.dataTransfer?.files?.[0]);
            updateShowModal(true);
          } else {
            updateAcceptableDropType(isImage);
          }
        }}
        onDragOver={(e) => {
          // Prevent default behavior --> Prevent file from being opened in new Tab
          e.preventDefault();
        }}
      >
        <Icon
          buttonProps={{
            className: "cg-editgoal-img-change-btn",
            type: "button",
          }}
          onClick={() => updateShowModal(true)}
          isButton={true}
          icon="camera"
          size={48}
        />

        {!!displayingImageURL && (
          <Icon
            className="cg-editgoal-img-remove-icon"
            size={20}
            isButton={true}
            buttonProps={{
              className: "cg-editgoal-img-remove-icon-btn",
              type: "button",
            }}
            onClick={() => {
              if (!props.newGoalImage && !props.newGoalTemplateImage) {
                props.updateRemoveExistingImage?.(true);
              }

              props.updateNewGoalImage(null);
              props.updateNewGoalTemplateImage(null);
            }}
            icon="trash-alt"
          />
        )}
      </section>

      <ImagePickerModal
        initialFile={droppedFile}
        onDismiss={() => updateShowModal(false)}
        onImageSelect={(imgBlobOrFile) => {
          props.updateNewGoalImage(imgBlobOrFile ?? null);

          updateShowModal(false);
        }}
        onTemplateImageSelect={(newTemplateImage) => {
          props.updateNewGoalTemplateImage(newTemplateImage);

          updateShowModal(false);
        }}
        isOpen={showModal}
        theme={props.theme}
      />
    </>
  );
};

export default GoalImage;
