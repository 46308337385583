/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { IMAGE_BASE_URL } from "../../../data/constants/";

import "./style.scss";

interface Props {
  alt?: string;
  onClick?: () => void;
  className?: string;
  isRelPath?: boolean;
  loading?: React.ImgHTMLAttributes<HTMLImageElement>["loading"];
  style?: React.CSSProperties;
  src?: string;
  innerRef?: any;
}

function getAbsolutePath(relPath?: string) {
  if (!relPath) {
    return "";
  }

  return `${IMAGE_BASE_URL}${relPath}`;
}

const Image = (props: Props) => {
  const [src, updateSrc] = React.useState(
    props.isRelPath ? getAbsolutePath(props.src) : props.src
  );

  React.useEffect(() => {
    updateSrc(props.isRelPath ? getAbsolutePath(props.src) : props.src);
  }, [props.src, props.isRelPath]);

  return (
    <img
      alt={props.alt || ""}
      className={cx("cc-img", props.className)}
      loading={props.loading ?? "lazy"}
      onClick={props.onClick}
      src={src}
      ref={props.innerRef}
      style={props.style}
    />
  );
};

export default Image;
