/*
 * Component Description
 */
import * as React from "react";

import { ImageData } from "./ImagesWrapper";

interface Props extends ImageData {
  imageRatio?: string;
  pageNum: number;
  totalPagesCount: number;
}

const DocumentViewerImage = ({
  height,
  imageRatio: aspectRatio,
  pageNum,
  src,
  totalPagesCount,
}: Props) => {
  const [minHeight] = React.useState(
    window.innerWidth < 800
      ? "100px"
      : `${Math.min(window.innerHeight * 0.9, height)}px`
  );

  if (!src) {
    return null;
  }

  return (
    <figure
      className="document-viewer-image-cont"
      style={{ minHeight: minHeight, minWidth: `50px` }}
    >
      <figcaption className="document-viewer-image-label">
        Page {pageNum} of {totalPagesCount}
      </figcaption>
      <img
        className="document-viewer-image"
        src={src}
        style={{ aspectRatio }}
        loading="lazy"
      />
    </figure>
  );
};

export default DocumentViewerImage;
