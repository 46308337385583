/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { INTERFACE_PATHS, SUB_INTERFACE_PATHS } from "data/constants/";
import { TextButton } from "components/common/";
import { StandardNav } from "components/meta/";

import UserImage from "./UserImage";

import "./ProfileHeader.scss";

interface Props {
  personData: TytoData.Person;
  AppStore: StoreAPI.AppStoreProps;
  GeneralStore: StoreAPI.GeneralStoreProps;
  subInterface?: ProfilePage.Subinterface;
}

const ProfileHeader = (props: Props) => {
  const [userName, updateUserName] = React.useState(
    `${props.personData.givenName} ${props.personData.familyName}`
  );

  React.useEffect(() => {
    updateUserName(
      `${props.personData.givenName} ${props.personData.familyName}`
    );
  }, [props.personData]);

  const isMobile = !!props.GeneralStore.state?.isMobile;

  return (
    <section className="profile-header">
      <div
        className="centered-content-wrapper"
        style={{ paddingBottom: "20px" }}
      >
        <StandardNav />
      </div>

      <div className="profile-header-top">
        <div className="profile-header-userthumb-cont one-third ">
          {/* <UserThumb
            className="profile-header-userthumb"
            userName={userName}
            asset={profileImageAsset}
            size={isMobile ? 140 : 225}
          /> */}
          <UserImage
            isMobile={isMobile}
            personData={props.personData}
            userName={userName}
            uploadURL={props.GeneralStore.state?.uploadURL}
            AppStore={props.AppStore}
          />
        </div>

        <h2 className="profile-header-username title-font one-third">
          {userName}
        </h2>
      </div>

      <div className="profile-header-tab-btns">
        <div className="profile-header-tab-btns-inner-cont one-third ">
          <div
            className={cx(
              "profile-header-tab-btn-wrapper",
              (props.subInterface === SUB_INTERFACE_PATHS.PROFILE.INFORMATION ||
                !props.subInterface) &&
                "active-tab"
            )}
          >
            <TextButton
              className="profile-header-tab-btn"
              href={`${INTERFACE_PATHS.PROFILE}/${SUB_INTERFACE_PATHS.PROFILE.INFORMATION}`}
              value="Information"
            />
          </div>

          {/* <div
            className={cx(
              "profile-header-tab-btn-wrapper",
              props.subInterface ===
                SUB_INTERFACE_PATHS.PROFILE.MY_CERTIFICATIONS && "active-tab"
            )}
          >
            <TextButton
              className="profile-header-tab-btn"
              href={`${INTERFACE_PATHS.PROFILE}/${SUB_INTERFACE_PATHS.PROFILE.MY_CERTIFICATIONS}`}
              value="My Certifications"
            />
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default ProfileHeader;
