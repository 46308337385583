/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import cx from "classnames";
import { Redirect } from "react-router-dom";

import { StandardNav } from "components/meta/";
import { Button, Video, Image, Icon, Link } from "components/common/";
import { hostname } from "os";
import DocumentViewer from "../../components/modals/document-viewer/DocumentViewer";

interface TrainingSection {
  _uid?: string;
  type?: string;
  title?: string;
  description?: string;
  videoURL: string;
  videoPosterURL: string;
  pdfTitle: string;
  pdfFileURL: string;
  pdfPreviewImages: string[];
  pdfEncoding: string;
}

type ActiveTab = "manager" | "employee";

interface Props {
  config?: SITE.Library.Config; //TrainingConfig
  isMobile: boolean;
  showSearch: boolean;
  toggleShowSearch: (showSearch: boolean) => void;
  hasManagerAccess: boolean;
  viewDocument: boolean;
}

const Library = ({
  showSearch,
  toggleShowSearch,
  isMobile,
  hasManagerAccess,
  config,
}: Props) => {
  const [activeTab, updateActiveTab] = React.useState<ActiveTab>(
    hasManagerAccess ? "manager" : "employee"
  );
  // console.log("=== PPF library ", config);
  if (!config) {
    return null;
  }

  const activeTabData =
    activeTab === "manager" ? config.manager : config.employee;

  return (
    <main className="interface-ppfplan centered-content-wrapper">
      <StandardNav className="ppfhelp-main-nav" />

      <div className="library-inner-cont">
        <div className="library-inner-items">
          <h1 className="font-size-title library-title">TRAINING LIBRARY</h1>
          <article>
            {hasManagerAccess && (
              <aside className="library-toggle-cont">
                <Button
                  className={cx(
                    "library-btn",
                    activeTab === "employee" && "btn-glow"
                  )}
                  onClick={() => updateActiveTab("employee")}
                  value="Employee"
                  theme={activeTab === "employee" ? "action" : "dark-grey"}
                />

                <Button
                  className={cx(
                    "library-btn",
                    activeTab === "manager" && "btn-glow"
                  )}
                  onClick={() => updateActiveTab("manager")}
                  value="Manager"
                  theme={activeTab === "manager" ? "action" : "dark-grey"}
                />
              </aside>
            )}

            <LibraryContent data={activeTabData} />
          </article>
        </div>
      </div>
    </main>
  );
};
interface LibraryContentProps {
  data: TrainingSection[];
}

const LibraryContent = ({ data }: LibraryContentProps) => {
  const [documentViewer, updatedocumentViewer] = React.useState(false);
  function produceFakeEncoding(pdfEncoding: string) {
    //* FakeAsset is an empty object that contains a fake encoding to the pdfEncoding path that lives in the domains folder instead of the libary
    const fakeAsset = {
      assetDesc: "",
      assetID: 1,
      assetName: "",
      assetType: "",
      courseItemID: 1,
      createdByID: 1,
      createdByName: "",
      createdDate: "",
      domainID: 1,
      modifiedByID: 1,
      modifiedDate: "",
      orientation: "",
      originalMD5: "",
      sequence: 1,
      softwareRequirements: "",
      encodings: [
        {
          activeStatus: "ocENABLED",
          encodingType: "ocPDFIMAGES/imgtmp",
          height: 1,
          length: 1,
          mimeType: "image/jpeg",
          sizeBytes: 1,
          modifiedDate: "2022-09-23T18:21:51.98",
          pathURL: pdfEncoding,
          techNote: "",
          width: 1,
        },
      ],
    };
    return fakeAsset;
  }

  if (!data.length) {
    return (
      <div className="library-no-training-message ">No Training Available.</div>
    );
  }

  return (
    <>
      <div className="library-video-wrapper">
        {data.map((section) => (
          <section key={section._uid}>
            {/* //* DETAILS */}
            <h1 className="library-title-text">
              <span className="libary-type-text">
                {section.type && section.type + ": "}
              </span>
              {section.title}
            </h1>
            {section.description ? (
              <p className="library-description-text">{section.description}</p>
            ) : null}
            {/* //* VIDEO */}
            {section.videoURL ? (
              <>
                <Video
                  className="library-video"
                  poster={section.videoPosterURL}
                  src={section.videoURL}
                  type="video/mp4"
                  controls={true}
                  key={section.videoURL}
                />
                {!section.videoPosterURL && (
                  <div>No videoPosterURL supplied</div>
                )}
                <div className="library-spacer"></div>
              </>
            ) : (
              <div className="library-missing-text">No videoURL supplied</div>
            )}

            {/* //* PDF DOCUMENT */}
            {section.pdfFileURL && (
              <>
                <div className="library-doc-wrapper">
                  <h1 className="library-title-text">
                    <span className="libary-type-text">
                      {!!section.pdfTitle && "DOCUMENT: "}
                    </span>
                    {section.pdfTitle}
                  </h1>

                  {!documentViewer ? (
                    <>
                      {section.pdfPreviewImages &&
                      section.pdfPreviewImages[0] != "" ? (
                        <div className="library-preview-images-cont">
                          <Image
                            className={"library-pdf-single_1"}
                            src={section.pdfPreviewImages[0]}
                            onClick={() => {
                              {
                                section.pdfEncoding &&
                                  updatedocumentViewer(!documentViewer);
                              }
                            }}
                          />
                          <Image
                            className={"library-pdf-single_2"}
                            src={section.pdfPreviewImages[1]}
                          />
                          <Image
                            className={"library-pdf-single_3"}
                            src={section.pdfPreviewImages[2]}
                          />
                        </div>
                      ) : (
                        <div className="library-preview-images-cont-placeholder">
                          <Icon
                            className="cc-btn-waiting-icon"
                            icon="file-alt"
                            size={140}
                          />
                          {/* <div className="library-preview-images-cont-placeholder-text">
                            No preview Image
                          </div> */}
                        </div>
                      )}
                    </>
                  ) : (
                    <Button
                      className="library-doc-close"
                      value="X"
                      theme="action"
                      onClick={() => {
                        updatedocumentViewer(!documentViewer);
                      }}
                    />
                  )}

                  {documentViewer && (
                    <DocumentViewer
                      asset={produceFakeEncoding(section.pdfEncoding)}
                    />
                  )}
                  {/* //* Download Document Button */}
                  <Link
                    className="library-doc-download"
                    value=""
                    ariaTitle=""
                    onClick={() => {
                      window.open(section.pdfFileURL);
                    }}
                  >
                    <span className="library-doc-download-text">Download</span>
                    <Icon
                      className="library-doc-download-icon"
                      icon="download-2"
                      size={12}
                    />
                  </Link>
                </div>
                <div className="library-spacer"></div>
              </>
            )}
          </section>
        ))}
      </div>
    </>
  );
};

export default Library;
