import * as _ from "lodash";

import {
  INTERFACE_PATHS,
  SUB_INTERFACE_PATHS,
  INTERFACE_SEARCH_PARAMS,
} from "data/constants/";
import {
  detemerminePPFParentGoal,
  getEncoding,
  makePathURLQualified,
} from "data/helpers/";

export const DEFAULT_PARENT_CHILD_INDEX_VALUES: GoalStories.ParentChildIndexes =
  {
    start: -1,
    end: -1,
    1: -1,
    3: -1,
    5: -1,
    count: 0,
  };

export function getKeyedChildGoals(
  parentGoal: TytoData.PPF.Plan.Goals.ParentGoal
) {
  return _.keyBy(parentGoal.childGoals ?? [], "gsGoalID");
}

export function sortGoals(childGoals: TytoData.PPF.Plan.Goals.ChildGoal[]) {
  return _.sortBy(
    childGoals,
    ["durationYears", "createdDate", "targetDate"],
    ["asc", "asc", "asc"]
  );
}

export function generateDefaultChildIndexObj() {
  return {
    personal: { ...DEFAULT_PARENT_CHILD_INDEX_VALUES },
    professional: { ...DEFAULT_PARENT_CHILD_INDEX_VALUES },
    financial: { ...DEFAULT_PARENT_CHILD_INDEX_VALUES },
  };
}

/**
 *
 * @param parentGoals - List of all 3 Parent Goals for Plan
 * @returns `KeyedData` - All the Parent and Childs Goals sorted and parsed for consumption in this Component and its Children Components
 */
export function getKeyedData(
  parentGoals: TytoData.PPF.Plan.Goals.ParentGoal[]
): GoalStories.KeyedData {
  /**
   * Desired Output:
   * 1. Child Goals Keyed by gsGoalID
   * 2. Child Goals flattened and sorted
   * 3. Parent Goals Keyed by gsGoalID
   * 4. Parent Goals Keyed by 'Theme' name ('personal', 'financial', 'professional')
   * 5. Parent->Child durationYears Indexed
   */
  return (parentGoals ?? []).reduce(
    (
      {
        allChildGoals,
        incompleteChildGoals,
        completedChildGoals,
        keyedChildren,
        keyedParents,
        parentByTheme,
        childIndexes,
      }: GoalStories.KeyedData,
      parentGoal
    ) => {
      // * Accomplishes [1.]
      keyedChildren = {
        ...keyedChildren,
        ...getKeyedChildGoals(parentGoal),
      };

      const sorted = sortGoals(parentGoal.childGoals ?? []);
      const startingIndex = Number(allChildGoals.length);
      const incompleteStartingIndex = Number(incompleteChildGoals.length);
      const completedStartingIndex = Number(completedChildGoals.length);

      // * Accomplishes [2.]
      allChildGoals = [...allChildGoals, ...sorted];

      // * Accomplishes [3.]
      keyedParents = {
        ...keyedParents,
        [parentGoal.gsGoalID]: parentGoal,
      };

      const theme = detemerminePPFParentGoal(parentGoal);

      if (theme) {
        // * Accomplishes [4.]
        parentByTheme[theme] = parentGoal.gsGoalID;
        parentByTheme[parentGoal.gsGoalID] = theme;

        const splitOutChildGoals = getIndexes({
          childGoals: sorted,
          allStartingIdx: startingIndex,
          completedStartingIdx: completedStartingIndex,
          incompleteStartingIdx: incompleteStartingIndex,
        });

        // * Accomplishes [5.] - Set indexes for this theme
        if (childIndexes["all"][theme]) {
          const { items, ...indexes } = splitOutChildGoals.all;

          childIndexes["all"][theme] = {
            ...indexes,
            end: allChildGoals.length - 1,
          };
        }

        if (childIndexes.completed[theme]) {
          const { items, ...indexes } = splitOutChildGoals.completed;

          completedChildGoals = completedChildGoals.concat(items);

          childIndexes.completed[theme] = {
            ...indexes,
          };
        }

        if (childIndexes.incomplete[theme]) {
          const { items, ...indexes } = splitOutChildGoals.incomplete;

          incompleteChildGoals = incompleteChildGoals.concat(items);

          childIndexes.incomplete[theme] = {
            ...indexes,
          };
        }
      }

      return {
        allChildGoals,
        incompleteChildGoals,
        completedChildGoals,
        childIndexes,
        keyedChildren,
        keyedParents,
        parentByTheme,
      };
    },
    {
      allChildGoals: [],
      incompleteChildGoals: [],
      completedChildGoals: [],
      keyedParents: {},
      keyedChildren: {},
      parentByTheme: {},
      childIndexes: {
        all: generateDefaultChildIndexObj(),
        incomplete: generateDefaultChildIndexObj(),
        completed: generateDefaultChildIndexObj(),
      },
    }
  );
}

export interface GetIndexReduceItem extends GoalStories.ParentChildIndexes {
  items: TytoData.PPF.Plan.Goals.ChildGoal[];
}

/**
 *
 * @param childGoals - All childGoals for this Parent Goal
 * @param allStartingIdx - index of the first childGoal relative to all chldGoals so far across all iterated parentGoals
 * @param completedStartingIdx - Same as above but for Completed Goals
 * @param incompleteStartingIdx - Same as above but for Incomplete Goals
 * @returns `ParentChildIndexes` for all, incomplete, and completed of the supplied Parent Goal's children
 */
export function getIndexes({
  childGoals,
  allStartingIdx,
  completedStartingIdx,
  incompleteStartingIdx,
}: {
  childGoals: TytoData.PPF.Plan.Goals.ChildGoal[];
  allStartingIdx: number;
  completedStartingIdx: number;
  incompleteStartingIdx: number;
}) {
  return childGoals.reduce(
    (
      accum: {
        all: GetIndexReduceItem;
        completed: GetIndexReduceItem;
        incomplete: GetIndexReduceItem;
      },
      curGoal,
      curIdx
    ) => {
      switch (curGoal.durationYears) {
        case -1:
        case 1:
        case 3:
        case 5:
          let durationKey = curGoal.durationYears;

          // * Special case for existing Plans that have goals with no durationYears set (defauly value is -1)
          if (durationKey === -1) {
            durationKey = 1;
          }

          const statusKey =
            curGoal.targetStatus === "ocCOMPLETE" ? "completed" : "incomplete";
          // * This is used so that the duration indexes are relative to the whole Array, not just this paren't childGoals Array
          let baseIndex =
            curGoal.targetStatus === "ocCOMPLETE"
              ? completedStartingIdx
              : incompleteStartingIdx;

          // * Always additionally update the 'all' bucket
          accum.all[durationKey] =
            accum.all[durationKey] < 0
              ? allStartingIdx + curIdx
              : accum.all[durationKey];

          accum.all.count = accum.all.count + 1;

          // * Update specific status -> duration bucket
          // * NOTE: using the length *before* pushing to Array since the current length will be the index position of the new item
          // * I.E., index position starts at 0, but length is the quantity (if current length is 0, this item will be in the 0th position, once added)
          accum[statusKey][durationKey] =
            accum[statusKey][durationKey] < 0
              ? baseIndex + accum[statusKey].items.length
              : accum[statusKey][durationKey];

          accum[statusKey].items.push(curGoal);
          accum[statusKey].count = accum[statusKey].count + 1;
          accum[statusKey].end = accum[statusKey].end + 1;
          break;
        default:
          break;
      }

      return accum;
    },
    {
      all: {
        items: [],
        ...DEFAULT_PARENT_CHILD_INDEX_VALUES,
        start: allStartingIdx,
        end: allStartingIdx,
      },
      completed: {
        items: [],
        ...DEFAULT_PARENT_CHILD_INDEX_VALUES,
        start: completedStartingIdx,
        end: completedStartingIdx,
      },
      incomplete: {
        items: [],
        ...DEFAULT_PARENT_CHILD_INDEX_VALUES,
        start: incompleteStartingIdx,
        end: incompleteStartingIdx,
      },
    }
  );
}

export function getChildGoalURL({
  childGoalID,
  keyedChildren,
  filter,
}: {
  filter?: GoalStories.StatusFilter;
  childGoalID: number;
  keyedChildren: GoalStories.KeyedData["keyedChildren"];
}) {
  if (!childGoalID || !keyedChildren) {
    return;
  }

  const childGoal = keyedChildren?.[childGoalID];

  if (!childGoal) {
    return;
  }

  const { gsPlanID, gsGoalID } = childGoal;

  let path = `${INTERFACE_PATHS.PLAN}/${gsPlanID ?? 0}/${
    SUB_INTERFACE_PATHS.PLAN.GOALS
  }/${gsGoalID}`;

  if (filter === "completed" || filter === "all") {
    path = `${path}?${INTERFACE_SEARCH_PARAMS.PLAN.GOAL_STATUS_FILTER}=${filter}`;
  }

  return path;
}

/**
 * Given a childGoal object, will return a relative path to view the childGoal.
 * @param childGoal - A childsGoal object
 * @returns `String` - Which is a relative path to the childGoal
 */
export function getChildGoalURLFromGoal(
  childGoal: TytoData.PPF.Plan.Goals.ChildGoal
) {
  if (!childGoal) return;

  const { gsGoalID, gsPlanID, targetStatus } = childGoal;

  const status = getNormalizedStatusFilter(targetStatus);

  let path = `${INTERFACE_PATHS.PLAN}/${gsPlanID ?? 0}/${
    SUB_INTERFACE_PATHS.PLAN.GOALS
  }/${gsGoalID}`;

  if (status === "completed") {
    path = `${path}?${INTERFACE_SEARCH_PARAMS.PLAN.GOAL_STATUS_FILTER}=${status}`;
  }

  return path;
}

export const findChildGoal = ({
  childGoals,
  curGoalID,
  dir,
  keyedChildren,
  targetIdx,
}: {
  childGoals: TytoData.PPF.Plan.Goals.ChildGoal[];
  curGoalID: number;
  dir?: "left" | "right";
  keyedChildren: GoalStories.KeyedData["keyedChildren"];
  targetIdx?: number;
}) => {
  // * [1] - If no chldren return;
  if (!childGoals?.length) {
    return undefined;
  }

  // * [2] - Find child based off whether going left or right (and account fdor current being first or last item)

  if (typeof targetIdx === "number") {
    const targetItem = childGoals[targetIdx];
    // const targetItem = sortedChildGoals[targetIdx];

    if (targetItem?.gsGoalID) {
      return targetItem;
    }
  }

  const childGoalsArr = childGoals;
  // const childGoalsArr = sortedChildGoals;

  let newGoal = keyedChildren[curGoalID];
  let newGoalIdx = childGoalsArr.findIndex(
    (childGoal) => childGoal.gsGoalID === curGoalID
  );

  if (newGoalIdx < 0) {
    // * Error black screen when there are less than 0 completed goals - now it gracefully fails.
    newGoal = childGoalsArr[0];
  } else {
    if (dir === "left") {
      newGoalIdx = newGoalIdx < 1 ? childGoalsArr.length - 1 : newGoalIdx - 1;
    } else {
      newGoalIdx = newGoalIdx >= childGoalsArr.length - 1 ? 0 : newGoalIdx + 1;
    }

    newGoal = childGoalsArr[newGoalIdx] ?? childGoalsArr[0];
  }
  // * [3] - Return ID of new targetGoal
  return newGoal;
};

export function getNormalizedStatusFilter(
  status?:
    | "completed"
    | "all"
    | TytoData.PPF.Plan.Goals.ChildGoal["targetStatus"]
): GoalStories.StatusFilter {
  switch (status) {
    case "ocCOMPLETE":
    case "completed":
      return "completed";
    case "all":
      return "all";
    default:
      return "incomplete";
  }
}

export function getGoalMatchesStatusFilter({
  childGoal,
  statusFilter,
}: {
  childGoal?: TytoData.PPF.Plan.Goals.ChildGoal;
  statusFilter: GoalStories.StatusFilter;
}) {
  if (!childGoal) {
    return false;
  }

  switch (statusFilter) {
    case "all":
      return true;
    case "completed":
      return childGoal?.targetStatus === "ocCOMPLETE";
    case "incomplete":
    default:
      return childGoal?.targetStatus !== "ocCOMPLETE";
  }
}

export function getTargetGoalsList({
  allChildGoals,
  completedChildGoals,
  incompleteChildGoals,
  statusFilter,
}: {
  allChildGoals: TytoData.PPF.Plan.Goals.ChildGoal[];
  completedChildGoals: TytoData.PPF.Plan.Goals.ChildGoal[];
  incompleteChildGoals: TytoData.PPF.Plan.Goals.ChildGoal[];
  statusFilter: GoalStories.StatusFilter;
}) {
  switch (statusFilter) {
    case "all":
      return allChildGoals;
    case "completed":
      return completedChildGoals;
    case "incomplete":
    default:
      return incompleteChildGoals;
  }
}

export function getGoalImageURL(childGoal: TytoData.PPF.Plan.Goals.ChildGoal) {
  if (!childGoal.profileImageAsset?.encodings?.length) {
    return "";
  }

  const encoding = getEncoding(
    childGoal.profileImageAsset.encodings,
    "ocDEFAULT"
  );

  if (!encoding) {
    return "";
  }

  return makePathURLQualified(encoding.pathURL);
}

export function getStatusOptions() {
  return [
    getFriendlyStatus("ocATRISK"),
    getFriendlyStatus("ocNOTSTARTED"),
    getFriendlyStatus("ocCOMPLETE"),
  ];
}

export function getFriendlyStatus(
  goalStatus: TytoData.PPF.Plan.Plan["targetStatus"]
) {
  switch (goalStatus) {
    case "ocATRISK":
    case "ocSTALLED":
      return "😰 At Risk";
    case "ocCOMPLETE":
      return "✅ Completed";
    case "ocINPROGRESS":
    case "ocNOTSTARTED":
    default:
      return "👍 On Track";
  }
}

export function getPlaceholderStatusText(opt: string) {
  switch (getNormalizedStatusFilter(opt as any)) {
    case "all":
      return "All Goals";
    case "completed":
      return "Completed Goals";
    case "incomplete":
      return "In Progress Goals";
  }
}

export function getFilterStatusOptions() {
  return [
    getNormalizedStatusFilter("all"),
    getNormalizedStatusFilter("ocCOMPLETE"),
    getNormalizedStatusFilter("ocINPROGRESS"),
  ];
}

export function getStatusFilter(opt: string) {
  switch (opt) {
    case "all":
      return getNormalizedStatusFilter("all");
    case "completed":
    case "ocCOMPLETE":
      return getNormalizedStatusFilter("ocCOMPLETE");
    case "ocINPROGRESS":
    default:
      return getNormalizedStatusFilter("ocINPROGRESS");
  }
}
