/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import cx from "classnames";
import { Redirect, useLocation } from "react-router-dom";

import { Icon, Link } from "components/common/";
import { IconProps } from "components/common/icon/";
import {
  INTERFACE_PATHS,
  SUB_INTERFACE_PATHS,
  INTERFACE_SEARCH_PARAMS,
} from "data/constants/";
import { usePPFPlans, usePPFPlanByID } from "data/network/hooks/";
import { createPPFPlan } from "data/network/special-calls/";
import { SessionHandling } from "data/storage";

// import NavLink from "../training-menu/NavLink";
// import { MenuWrapperProps } from "../../";
// import GenericMenuContent from "../../GenericMenuContent";

import "../default/style.scss";
import "./style.scss";

interface LinkItem {
  searchParams?: string;
  checkSearchParams?: boolean;
  disabled?: boolean;
  icon?: IconProps["icon"];
  iconRight?: IconProps["icon"];
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  title: string;
  type?: "link" | "button";
  to?: string;
  subItems?: LinkItem[];
}

interface Props extends CMMenu.SubMenu {
  hasContentAbove?: boolean;
  hasParentSite?: boolean;
  planID?: number;
}

const PlanMenu = ({
  hasContentAbove,
  hasParentSite,
  planID,
  updateIsSearching,
  updateSearchType,
}: Props) => {
  const [creatingOwnPlan, updateCreatingOwnPlan] = React.useState(false);
  const [errorMsg, updateErrorMsg] = React.useState("");
  const [redirectURL, updateRedirectURL] = React.useState("");
  const [loggedInUserID] = React.useState(() => {
    return SessionHandling.getUserIDOfActiveSession();
  });
  const [targetPlan, updateTargetPlan] =
    React.useState<TytoData.PPF.Plans.Plan | null>(null);
  const [loggedInUsersPlan, updateLoggedInUsersPlan] =
    React.useState<TytoData.PPF.Plans.Plan | null>(null);

  const userPlanData = usePPFPlans({
    onSuccess: (plansResp) => {
      const { gsPlans } = plansResp ?? {};

      if (gsPlans?.length) {
        const newLoggedInUsersPlan = gsPlans.find(
          (plan) => plan.aboutID === loggedInUserID
        );
        const newTargetPlan = planID
          ? gsPlans.find((plan) => plan.gsPlanID === planID)
          : undefined;

        updateTargetPlan(newTargetPlan ?? null);
        updateLoggedInUsersPlan(newLoggedInUsersPlan ?? null);
      } else {
      }
    },
  });

  React.useEffect(() => {
    if (userPlanData.eagerData) {
      const { gsPlans } = userPlanData.eagerData;

      if (gsPlans?.length) {
        // const planOfPassed = gsPlans.filter((plan) => plan.gsPlanID === planID);
        // if (
        //   planOfPassed[0].aboutID &&
        //   planOfPassed[0].gsPlanID !== usersPlan &&
        //   usersPlan
        // ) {
        //   updateViewingUsersPlan(false);
        //   updatePlanOwnerName(planOfPassed[0].about.name.split(" ")[0]);
        // }
        const loggedInUserID = SessionHandling.getUserIDOfActiveSession();

        const newLoggedInUsersPlan = gsPlans.find(
          (plan) => plan.aboutID === loggedInUserID
        );
        const newTargetPlan = planID
          ? gsPlans.find((plan) => plan.gsPlanID === planID)
          : undefined;

        updateTargetPlan(newTargetPlan ?? null);
        updateLoggedInUsersPlan(newLoggedInUsersPlan ?? null);
      }
    } else {
      updateTargetPlan(null);
    }
  }, [planID, userPlanData.data]);

  React.useEffect(() => {
    if (redirectURL) {
      window.location.href = `${redirectURL}`;
    }
  }, [redirectURL]);

  const disabledMenuItems =
    !targetPlan && (userPlanData.isLoading || !userPlanData.isFetched);
  const viewingOtherUsersPlan =
    !!targetPlan && loggedInUserID !== targetPlan?.aboutID;
  const planOwnerName = targetPlan?.about?.elementName?.split?.(" ")?.[0] ?? "";
  const loggedInUsersPlanID = loggedInUsersPlan?.gsPlanID ?? 0;

  // if (redirectURL) {
  //   return <Redirect to={redirectURL} />;
  // }

  if (hasParentSite) {
    // * If there needs to be a different menu render when iframed inside Tryyb
  }

  function createPlanForUserAndReroute(
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) {
    updateCreatingOwnPlan(true);
    updateErrorMsg("");

    const { userID, userName, teamRootID } =
      SessionHandling.getActiveSession() ?? {};

    if (!userID || !userName || !teamRootID) {
      updateCreatingOwnPlan(false);
      updateErrorMsg("Missing Data, cannot create plan.");
      return;
    }

    createPPFPlan({
      personID: userID,
      personName: userName,
      startDate: new Date(),
      teamRootID,
      onError: (newErrorMsg) => {
        updateErrorMsg(`${newErrorMsg}`);
        updateCreatingOwnPlan(false);
      },
      onSuccess: (planID, redirectPath) => {
        updateRedirectURL(redirectPath);
        updateCreatingOwnPlan(false);
      },
    });
    e.preventDefault?.();
  }

  let loc = useLocation();
  let location = loc as any;

  return (
    <>
      {hasContentAbove && (
        <hr className="cm-menu-default-menu-hr border-color" />
      )}
      <div className="cm-menu-default-menu">
        <ul className="cm-menu-user-plan-link-list">
          {!disabledMenuItems && !loggedInUsersPlanID ? (
            <MenuLinkItem
              disabled={disabledMenuItems || creatingOwnPlan}
              fillBG={true}
              icon="plus"
              location={location}
              iconRight="plus"
              title={
                creatingOwnPlan ? "Configuring Plan..." : "Create New Goal"
              }
              type={"button"}
              onClick={
                !loggedInUsersPlanID ? createPlanForUserAndReroute : undefined
              }
              to={``}
            />
          ) : (
            <MenuLinkItem
              disabled={disabledMenuItems || creatingOwnPlan}
              fillBG={true}
              icon="calendar"
              location={location}
              iconRight="plus"
              title="Create New Goal"
              pathMatches={
                location?.pathname ===
                `${INTERFACE_PATHS.PLAN}/${loggedInUsersPlanID}/${SUB_INTERFACE_PATHS.PLAN.NEW_GOAL}`
              }
              to={`${INTERFACE_PATHS.PLAN}/${loggedInUsersPlanID}/${SUB_INTERFACE_PATHS.PLAN.NEW_GOAL}`}
            />
          )}

          {(!disabledMenuItems || !!loggedInUsersPlanID) && (
            <>
              <MenuLinkItem
                disabled={disabledMenuItems || creatingOwnPlan}
                checkSearchParams={true}
                onClick={
                  !loggedInUsersPlanID ? createPlanForUserAndReroute : undefined
                }
                icon="award"
                location={location}
                title={creatingOwnPlan ? "Configuring Plan..." : "My PPF Goals"}
                to={`${INTERFACE_PATHS.PLAN}/${loggedInUsersPlanID}/${SUB_INTERFACE_PATHS.PLAN.GOALS}`}
                pathMatches={
                  location?.pathname ===
                  `${INTERFACE_PATHS.PLAN}/${loggedInUsersPlanID}/${SUB_INTERFACE_PATHS.PLAN.GOALS}`
                }
                searchParams=""
              />
              <MenuLinkItem
                disabled={disabledMenuItems || creatingOwnPlan}
                checkSearchParams={true}
                onClick={
                  !loggedInUsersPlanID ? createPlanForUserAndReroute : undefined
                }
                icon="checkbox"
                location={location}
                title={
                  creatingOwnPlan ? "Configuring Plan..." : "View Completed"
                }
                pathMatches={
                  location?.pathname ===
                  `${INTERFACE_PATHS.PLAN}/${loggedInUsersPlanID}/${SUB_INTERFACE_PATHS.PLAN.GOALS}`
                }
                to={`${INTERFACE_PATHS.PLAN}/${loggedInUsersPlanID}/${SUB_INTERFACE_PATHS.PLAN.GOALS}`}
                searchParams="completed"
              />
              <MenuLinkItem
                disabled={disabledMenuItems || creatingOwnPlan}
                onClick={
                  !loggedInUsersPlanID ? createPlanForUserAndReroute : undefined
                }
                icon="vault"
                location={location}
                pathMatches={
                  location?.pathname ===
                  `${INTERFACE_PATHS.PLAN}/${loggedInUsersPlanID}/${SUB_INTERFACE_PATHS.PLAN.PERMISSIONS}`
                }
                title={
                  creatingOwnPlan ? "Configuring Plan..." : "My Permissions"
                }
                to={`${INTERFACE_PATHS.PLAN}/${loggedInUsersPlanID}/${SUB_INTERFACE_PATHS.PLAN.PERMISSIONS}`}
              />
            </>
          )}
        </ul>
        {viewingOtherUsersPlan && (
          <ul className="cm-menu-viewing-plan-link-list">
            <MenuLinkItem
              disabled={!planID || disabledMenuItems}
              fillBG={true}
              icon="calendar"
              location={location}
              iconRight="plus"
              title={"Create New Goal for " + planOwnerName}
              to={`${INTERFACE_PATHS.PLAN}/${planID}/${SUB_INTERFACE_PATHS.PLAN.NEW_GOAL}`}
            />

            <MenuLinkItem
              disabled={!planID || disabledMenuItems}
              checkSearchParams={true}
              icon="award"
              location={location}
              title={"View " + planOwnerName + "'s PPF's"}
              to={`${INTERFACE_PATHS.PLAN}/${planID}/${SUB_INTERFACE_PATHS.PLAN.GOALS}`}
              searchParams={``}
            />

            <MenuLinkItem
              disabled={!planID || disabledMenuItems}
              checkSearchParams={true}
              icon="checkbox"
              location={location}
              title={"View " + planOwnerName + "'s Completed"}
              to={`${INTERFACE_PATHS.PLAN}/${planID}/${SUB_INTERFACE_PATHS.PLAN.GOALS}`}
              searchParams={`completed`}
            />

            <MenuLinkItem
              disabled={!planID || disabledMenuItems}
              icon="vault"
              location={location}
              omitPathCheck={true}
              title={planOwnerName + "'s Permissions"}
              to={`${INTERFACE_PATHS.PLAN}/${planID}/${SUB_INTERFACE_PATHS.PLAN.PERMISSIONS}`}
            />
          </ul>
        )}

        <ul className="cm-menu-genral-link-list">
          <MenuLinkItem
            icon="people"
            location={location}
            title="My People"
            omitPathCheck={true}
            onClick={() => {
              updateSearchType("my-people");
              updateIsSearching(true);
            }}
            // to={`${INTERFACE_PATHS.PLAN}/${planID ?? 0}/${ //TODO need my people page
            //   SUB_INTERFACE_PATHS.PLAN.PERMISSIONS
            // }`}
          />
          <MenuLinkItem
            omitPathCheck={true}
            onClick={() => {
              updateSearchType("all-people");
              updateIsSearching(true);
            }}
            icon="eyeglass"
            location={location}
            title="Search Users"
            type="button"
            // to={`${INTERFACE_PATHS.USER_SEARCH}`}
          />
          <MenuLinkItem
            icon="stats"
            location={location}
            title="Statistics"
            type="button"
            to={`${INTERFACE_PATHS.STATISTICS}`}
          />
          <MenuLinkItem
            icon="video"
            location={location}
            title="PPF Video Library"
            type="button"
            to={`${INTERFACE_PATHS.HELP}`}
          />
        </ul>

        {/* <ul className="cm-menu-primary-links-list">
          <MenuLinkItem
            disabled={!planID}
            fillBG={true}
            icon="plus"
            iconRight="plus"
            title="Create New Goal"
            to={`${INTERFACE_PATHS.PLAN}/${planID ?? 0}/${
              SUB_INTERFACE_PATHS.PLAN.NEW_GOAL
            }`}
          />

          <MenuLinkItem
            onClick={() => updateIsSearching(true)}
            icon="search"
            title="Search Users"
            type="button"
            // to={`${INTERFACE_PATHS.USER_SEARCH}`}
          />

          <MenuLinkItem
            checkSearchParams={true}
            icon="check"
            title="My PPFs"
            to={`${INTERFACE_PATHS.PLAN}/${planID ?? 0}/${
              SUB_INTERFACE_PATHS.PLAN.GOALS
            }`}
          />

          <MenuLinkItem
            checkSearchParams={true}
            icon="check-circle-2"
            title="View Completed"
            to={`${INTERFACE_PATHS.PLAN}/${planID ?? 0}/${
              SUB_INTERFACE_PATHS.PLAN.GOALS
            }?${INTERFACE_SEARCH_PARAMS.PLAN.GOAL_STATUS_FILTER}=completed`}
          />

          <MenuLinkItem
            disabled={true}
            icon="users"
            title="My People"
            to={`${INTERFACE_PATHS.PLAN}/${planID ?? 0}/${
              SUB_INTERFACE_PATHS.PLAN.PERMISSIONS
            }`}
          />

          <MenuLinkItem
            icon="eye"
            title="Permissions"
            to={`${INTERFACE_PATHS.PLAN}/${planID ?? 0}/${
              SUB_INTERFACE_PATHS.PLAN.PERMISSIONS
            }`}
          />
        </ul> */}
      </div>
    </>
  );
};

interface MenuLinkItemProps extends LinkItem {
  fillBG?: boolean;
  location?: Location;
  omitPathCheck?: boolean;
  pathMatches?: boolean;
  //   linkItem: LinkItem;
}

const MenuLinkItem = ({
  checkSearchParams,
  disabled,
  fillBG,
  icon,
  iconRight,
  location,
  onClick,
  pathMatches,
  searchParams,
  subItems,
  title,
  type,
  to,
  omitPathCheck,
}: MenuLinkItemProps) => {
  const [toPath, updateToPath] = React.useState(
    `${to ?? ""}${searchParams ? `?${searchParams}` : ""}`
  );
  const [toPathMatches, updateToPathMatches] = React.useState(() => {
    const regExp = new RegExp(`^${_.escapeRegExp(to)}`, "i");

    return !!to && regExp.test(location?.pathname ?? "");
  });
  const [searchParamMatch, updateSearchParamMatch] = React.useState(() => {
    return !checkSearchParams
      ? true
      : searchParamMatches({ targetStatusFilter: searchParams, location });
  });

  React.useEffect(() => {
    updateToPath(
      `${to ?? ""}${
        searchParams
          ? `?${INTERFACE_SEARCH_PARAMS.PLAN.GOAL_STATUS_FILTER}=${searchParams}`
          : ""
      }`
    );
  }, [to, searchParams]);

  React.useEffect(() => {
    const matches = searchParamMatches({
      targetStatusFilter: searchParams,
      location,
    });
    const regExp = new RegExp(`^${_.escapeRegExp(to)}`, "i");

    updateSearchParamMatch(matches);
    updateToPathMatches(!!to && regExp.test(location?.pathname ?? ""));
  }, [
    omitPathCheck,
    checkSearchParams,
    searchParams,
    location?.search,
    location?.pathname,
  ]);

  const isCurrentLocation =
    (!!checkSearchParams && searchParamMatch && toPathMatches) ||
    (!checkSearchParams && (toPathMatches || pathMatches));

  return (
    <>
      <li
        className={cx(
          "cm-menu-default-menu-list-item",
          disabled && "is-disabled",
          isCurrentLocation && "is-current-location",
          fillBG && "opaque-bg"
        )}
        key={title}
      >
        <Link
          className={cx(
            "cm-menu-default-menu-link text-color",
            disabled && "fade"
          )}
          disabled={disabled || isCurrentLocation}
          href={toPath}
          onClick={onClick}
          type={onClick && type}
          value={title}
        >
          {icon && (
            <Icon
              className="cm-menu-default-menu-link-icon"
              size={16}
              icon={icon}
            />
          )}
          <span style={{ paddingTop: "2px" }}>{title}</span>
          {iconRight && (
            <div className="cm-menu-default-menu-link-icon-right-cont">
              <Icon
                className="cm-menu-default-menu-link-icon-right"
                size={10}
                icon={iconRight}
              />
            </div>
          )}
        </Link>

        {disabled && (
          <span className="cm-menu-default-menu-link-disabled-msg font-color">
            Disabled
          </span>
        )}
      </li>

      {subItems && !!subItems.length && (
        <ul className="cm-menu-primary-links-list sub-list">
          {subItems.map((subLinkItem) => (
            <MenuLinkItem
              key={subLinkItem.title}
              location={location}
              {...subLinkItem}
            />
          ))}
        </ul>
      )}
    </>
  );
};

function searchParamMatches({
  targetStatusFilter,
  location,
}: {
  location?: Location;
  targetStatusFilter?: string;
}) {
  const params = new URLSearchParams(
    (location ?? window.location).search ?? ""
  );

  const statusFilter = params.get("goalStatusFilter");

  if (typeof targetStatusFilter === "string" && !targetStatusFilter) {
    const val =
      !statusFilter &&
      /(\/\d+\/goals(\/\d+)?)$/i.test(location?.pathname ?? " ");

    return val;
  }

  return statusFilter === targetStatusFilter;
}

export default PlanMenu;
