import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Input } from "components/common/";
import { TextEdit as TextEditModal } from "components/modals/";

import "./style.scss";

interface Props {
  className?: string;
  containerClassName?: string;
  placeholder?: string;
  name: string;
  creating?: boolean;
  isMobile?: boolean;
  value: string;
  updateValue: (newVal: string) => void;
}

const DetailTextField = (props: Props) => {
  const [showModal, updateShowModal] = React.useState(false);

  if (!props.isMobile) {
    return (
      <Input
        className={props.className}
        containerClassName={props.containerClassName}
        disabled={props.creating}
        name={props.name}
        placeholder={props.placeholder}
        onChange={(newVal) => {
          // updateGoalDesc(newVal);
          props.updateValue?.(newVal);
        }}
        value={props.value}
        type="textarea"
      />
    );
  }

  return (
    <>
      <button
        className={cx(props.className, "detail-fake-input")}
        onClick={() => {
          updateShowModal(true);
        }}
      >
        {props.value}
      </button>

      <TextEditModal
        label={props.name}
        onConfirm={(newVal) => {
          props.updateValue(newVal);
          updateShowModal(false);
        }}
        onDismiss={() => {
          updateShowModal(false);
        }}
        isOpen={showModal}
        startingText={props.value}
      />
    </>
  );
};

export default DetailTextField;
