/*
 * Component Description
 */
import * as React from "react";

import { IconProps } from "../typings";

const Report = ({ className, color, size, style }: IconProps) => {
  return (
    <svg
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      height={size}
      width={size}
      fill={color}
    >
        <g clipPath="url(#clip0_1707_587)">
            <path d="M8.06426 10.2643C7.86916 10.2643 7.68205 10.1868 7.54409 10.0488C7.40614 9.91084 7.32863 9.72373 7.32863 9.52863V7.32863C7.31873 7.22652 7.33029 7.12346 7.36258 7.02608C7.39487 6.9287 7.44717 6.83915 7.51612 6.76318C7.58507 6.68722 7.66915 6.62651 7.76296 6.58497C7.85677 6.54343 7.95823 6.52197 8.06082 6.52197C8.16341 6.52197 8.26487 6.54343 8.35868 6.58497C8.45249 6.62651 8.53657 6.68722 8.60552 6.76318C8.67447 6.83915 8.72677 6.9287 8.75906 7.02608C8.79135 7.12346 8.80291 7.22652 8.79301 7.32863V9.52863C8.79302 9.72255 8.71646 9.90862 8.57998 10.0464C8.44351 10.1841 8.25816 10.2624 8.06426 10.2643V10.2643Z" />
            <path d="M11.0003 10.2643C10.8052 10.2643 10.6181 10.1868 10.4801 10.0488C10.3422 9.91085 10.2646 9.72375 10.2646 9.52865V6.5999C10.2823 6.41795 10.3671 6.24909 10.5024 6.12623C10.6378 6.00337 10.814 5.9353 10.9968 5.9353C11.1796 5.9353 11.3559 6.00337 11.4912 6.12623C11.6266 6.24909 11.7114 6.41795 11.729 6.5999V9.53552C11.7272 9.72824 11.6499 9.91256 11.5136 10.0488C11.3773 10.1851 11.193 10.2625 11.0003 10.2643V10.2643Z" />
            <path d="M13.9354 10.2643C13.7403 10.2643 13.5531 10.1868 13.4152 10.0488C13.2772 9.91085 13.1997 9.72374 13.1997 9.52864V5.13551C13.1898 5.0334 13.2014 4.93034 13.2337 4.83296C13.266 4.73558 13.3183 4.64603 13.3872 4.57007C13.4562 4.4941 13.5402 4.4334 13.6341 4.39186C13.7279 4.35032 13.8293 4.32886 13.9319 4.32886C14.0345 4.32886 14.136 4.35032 14.2298 4.39186C14.3236 4.4334 14.4077 4.4941 14.4766 4.57007C14.5456 4.64603 14.5979 4.73558 14.6302 4.83296C14.6624 4.93034 14.674 5.0334 14.6641 5.13551V9.53551C14.6623 9.72824 14.585 9.91256 14.4487 10.0488C14.3124 10.1851 14.1281 10.2625 13.9354 10.2643V10.2643Z" />
            <path d="M16.8646 22H5.13582C4.35725 22 3.61056 21.6907 3.06002 21.1402C2.50949 20.5896 2.2002 19.843 2.2002 19.0644V5.775C2.19937 5.237 2.31723 4.70543 2.5454 4.21821C2.77356 3.73098 3.10641 3.30009 3.5202 2.95625L6.0502 0.8525C6.70849 0.30392 7.53767 0.00240132 8.39457 0L16.8646 0C17.6432 0 18.3898 0.309288 18.9404 0.859825C19.4909 1.41036 19.8002 2.15705 19.8002 2.93562V19.0644C19.8002 19.843 19.4909 20.5896 18.9404 21.1402C18.3898 21.6907 17.6432 22 16.8646 22V22ZM8.39457 1.46438C7.89166 1.47231 7.40654 1.65181 7.01958 1.97312L4.4552 4.08375C4.2071 4.29012 4.0076 4.5487 3.87094 4.84105C3.73428 5.13339 3.66381 5.45229 3.66457 5.775V19.0644C3.66638 19.4522 3.82125 19.8236 4.09548 20.0978C4.36971 20.3721 4.74113 20.5269 5.12895 20.5288H16.8646C17.2524 20.5269 17.6238 20.3721 17.898 20.0978C18.1723 19.8236 18.3271 19.4522 18.329 19.0644V2.93562C18.3271 2.54781 18.1723 2.17639 17.898 1.90215C17.6238 1.62792 17.2524 1.47306 16.8646 1.47125L8.39457 1.46438Z" />
            <path d="M15.3996 17.5999H6.59963C6.49751 17.6098 6.39445 17.5982 6.29707 17.5659C6.19969 17.5337 6.11015 17.4814 6.03418 17.4124C5.95821 17.3435 5.89751 17.2594 5.85597 17.1656C5.81443 17.0718 5.79297 16.9703 5.79297 16.8677C5.79297 16.7651 5.81443 16.6637 5.85597 16.5698C5.89751 16.476 5.95821 16.392 6.03418 16.323C6.11015 16.2541 6.19969 16.2018 6.29707 16.1695C6.39445 16.1372 6.49751 16.1256 6.59963 16.1355H15.3996C15.5017 16.1256 15.6048 16.1372 15.7022 16.1695C15.7996 16.2018 15.8891 16.2541 15.9651 16.323C16.041 16.392 16.1017 16.476 16.1433 16.5698C16.1848 16.6637 16.2063 16.7651 16.2063 16.8677C16.2063 16.9703 16.1848 17.0718 16.1433 17.1656C16.1017 17.2594 16.041 17.3435 15.9651 17.4124C15.8891 17.4814 15.7996 17.5337 15.7022 17.5659C15.6048 17.5982 15.5017 17.6098 15.3996 17.5999V17.5999Z" />
            <path d="M15.4001 13.9356H6.60014C6.41819 13.9179 6.24933 13.8332 6.12647 13.6978C6.00361 13.5625 5.93555 13.3862 5.93555 13.2034C5.93555 13.0206 6.00361 12.8443 6.12647 12.709C6.24933 12.5736 6.41819 12.4889 6.60014 12.4712H15.4001C15.5023 12.4613 15.6053 12.4729 15.7027 12.5052C15.8001 12.5374 15.8896 12.5897 15.9656 12.6587C16.0415 12.7277 16.1023 12.8117 16.1438 12.9055C16.1853 12.9993 16.2068 13.1008 16.2068 13.2034C16.2068 13.306 16.1853 13.4075 16.1438 13.5013C16.1023 13.5951 16.0415 13.6791 15.9656 13.7481C15.8896 13.8171 15.8001 13.8694 15.7027 13.9016C15.6053 13.9339 15.5023 13.9455 15.4001 13.9356V13.9356Z" />
        </g>
        <defs>
        <clipPath id="clip0_1707_587">
        <rect width={size} height={size} fill={color} />
        </clipPath>
        </defs>
    </svg>
  );
};

export default Report;
