import { createBrowserHistory } from "history";
// import { SES_STOR } from "../storage/";

// * NOTE: Importing from Storage forces webpack to read all the other files imported->exported in that file
// * ***** which causes a circular dependency loop where it is trying to initialize a file
// * ***** which reads a file that is initializing by trying to read that first file.
import { SES_STOR } from "data/storage/browser-storage";

import PkgJson from "../../../package.json";

export * from "./system";

const API_HOSTNAME = "api-hostname";

function determineAPIHostFromParent() {
  if (window.parent.location !== window.location && document.referrer) {
    const referrerLocation = new URL(document.referrer);

    // ! SPOOKY. What if it was iframed in a malicious website?
    // TODO: Ensure Azure CDN has whitelist of sites this can be iframed in
    if (
      referrerLocation?.hostname &&
      referrerLocation.hostname !== window.location.hostname
    ) {
      // * If referrer is not the same as host, set that to SessionStorage for continued use
      // * even after page refreshes.
      // ! Side Effect; meh...
      try {
        SES_STOR.set(API_HOSTNAME, referrerLocation.hostname);
      } catch (err) {
        //
      }

      return referrerLocation.hostname;
    }

    const storedHostname = SES_STOR.get(API_HOSTNAME);

    // * If rerrer is same as current host but there is an hostname in SessionStorage that is different, use such.
    if (storedHostname && storedHostname !== window.location.hostname) {
      return storedHostname;
    }
  }

  return undefined;
}

// * URLs
export const URL_BASE =
  determineAPIHostFromParent() ??
  process.env.REACT_APP_API_HOSTNAME ??
  "cherry.mocaworks.com";
export const API_URL = `https://${URL_BASE}/tyto/api`;
export const URL_ORIGIN = `https://${URL_BASE}`;
export const DOMAINS_FOLDER_ORIGIN = `https://${
  process.env.REACT_APP_DOMAINS_FOLDER_ORIGIN ?? "ppfapi.mocaworks.com"
}`;
export const IMAGE_BASE_URL = URL_ORIGIN;
export const START_CONFIG_PATH = `/v2/domains/:domainid/start/mastery_start_config.json`;
export const PPF_LIBRARY_CONFIG_PATH = `/v2/domains/:domainid/start/ppf_library_config.json`; //We are storing files in one place should we have 1 config at Top Level CV 1825957?
export const WS_SERVER_URL = `wss://${
  process.env.REACT_APP_COMMAND_CHANNEL_HOSTNAME || "app.teamtools.com:8080"
}/`;
export const IS_LOCAL_HOST = /^(localhost|192\.168\.(\d)+\.(\d)+)/i.test(
  window.location.host
);

// * Local Image Names
export const PPF_LOGO = "PPF_logo.png";
// export const PPF_LOGO_FULL = "ppf_logo_refresh.png";
export const PPF_LOGO_FULL = "PPF_full_logo.png";
export const PPF_PERSONAL_IMAGE = "images/person-lake-mountains.png";
export const PPF_PROFESSIONAL_IMAGE = "images/business-people.png";
export const PPF_FINANCIAL_IMAGE = "images/investing.png";

// * Variables
export const MOBILE_WIDTH_BREAKPOINT = 800;
export const DEFAULT_SESSION_TIMEOUT_LENGTH = 60 * 24 * 90; // 60 minutes in an hour * 24 hours in a day * 90 Days = 90 days in minutes
export const DEFAULT_APP_BRAND = "PPF";
export const DEFAULT_APP_VERSION = `${PkgJson.version}`;
export const DEFAULT_COMPUTER_ID = `${window.navigator.userAgent}`;
export const AUTO_SCROLL_DELAY_IN_MS = 400;
export const NEXT_QUESTION_SCROLL_DELAY_IN_MS = 1600;
export const BROWSER_META_DETA = {
  SUPPORTS: {
    ASPECT_RATIO: "aspectRatio" in document.documentElement.style,
    CONTAINER_QUERIES: "container" in document.documentElement.style,
  },
};
export const BANNER_CAROUSEL_TOP_COUNT = 5;
export const HAS_LOCAL_STORAGE = !!localStorage && !!sessionStorage;
export const HAS_VIBRATION = "vibrate" in navigator;

// * Endpoints
export const ENDPOINT_ASSET_ENCODING = `${API_URL}/Asset/Encoding`;
export const ENDPOINT_BLOCK = `${API_URL}/Block`;
export const ENDPOINT_BLOCK_ENROLLMENT = `${API_URL}/BlockEnrollment`;
export const ENDPOINT_BLOCK_PREREQUISITES = `${API_URL}/Block/Prerequisites`;
export const ENDPOINT_CATALOG_CURRICULUM_PUBLICATION = `${API_URL}/CatalogCurriculumPublication`;
export const ENDPOINT_CATALOG_CURRICULUM_PUBLICATION_SEARCH_LESSON_CONTENT = `${API_URL}/CatalogCurriculumPublication/SearchLessonContent`;
export const ENDPOINT_CONFIGURATION_CLIENT = `${API_URL}/Configuration/Client`;
export const ENDPOINT_CURRICULUM_PUB_CATALOG_ITEMS_TRENDING = `${API_URL}/CurriculumPubCatalogItemsTrending`;
export const ENDPOINT_EXAM_TAKE_ANSWER = `${API_URL}/Exam/Take/Answer`;
export const ENDPOINT_EXAM_TAKE_COMPLETE = `${API_URL}/Exam/Take/Complete`;
export const ENDPOINT_EXAM_TAKE_EVALUATE_RESPONSE = `${API_URL}/Exam/Take/Evaluate/Response`;
export const ENDPOINT_EXAM_TAKE_QUESTION = `${API_URL}/Exam/Take/Question`;
export const ENDPOINT_EXAM_TAKE_TRAINING_INVITE = `${API_URL}/Exam/Take/TrainingInvite`;
export const ENDPOINT_EXAM_TAKE_TRAINING_INVITES = `${API_URL}/Exam/Take/TrainingInvites`;
export const ENDPOINT_DEV_PLAN_ENROLLMENT = `${API_URL}/DevPlanEnrollment`;
export const ENDPOINT_DISC_PROFILES = `${API_URL}/DiscProfiles`;
export const ENDPOINT_DISC_PROFILE_INTERACTIVE = `${API_URL}/DiscProfile/Interactive`;
export const ENDPOINT_DISC_PROFILE_INTERACTIVE_MOCKUP = `${API_URL}/DiscProfile/Interactive/Mockup`;
export const ENDPOINT_DISC_PROFILE_TEAM = `${API_URL}/DiscProfile/Team`;
export const ENDPOINT_DISC_PROFILE_MINI = `${API_URL}/DiscProfile/Mini`;
export const ENDPOINT_DISC_PROFILES_MINI = `${API_URL}/DiscProfiles/Mini`;
export const ENDPOINT_DISC_PROFILES_EMAIL_VIEW_READY = `${API_URL}/DiscProfiles/EmailViewReady`;
export const ENDPOINT_DOMAIN = `${API_URL}/Domain`;
export const ENDPOINT_DOMAIN_IMAGE = `${API_URL}/Domain/Image`;
export const ENDPOINT_DOMAIN_INVITATION_EMAIL = `${API_URL}/DomainInvitationEmail`;
export const ENDPOINT_DOMAIN_INVITATION_EMAIL_TEMPLATE = `${API_URL}/DomainInvitationEmail/Template`;
export const ENDPOINT_DOMAIN_BILLING = `${API_URL}/DomainBilling`;
export const ENDPOINT_DOMAIN_BILLINGS = `${API_URL}/DomainBillings`;
export const ENDPOINT_DOMAIN_MESSAGES = `${API_URL}/Domain/Messages`;
export const ENDPOINT_DOMAIN_MESSAGE = `${API_URL}/Domain/Message`;
export const ENDPOINT_ENROLLMENT_VERIFICATION_REQUEST = `${API_URL}/Enrollment/VerificationRequest`;
export const ENDPOINT_EMAIL_QUEUE_STATUS = `${API_URL}/EmailQueueStatus`;
export const ENDPOINT_EMAIL_QUEUE_STATUS_USER = `${API_URL}/EmailQueueStatus/User`;
export const ENDPOINT_GS_GOALS = `${API_URL}/gsGoals`;
export const ENDPOINT_GS_GOAL = `${API_URL}/gsGoal`;
export const ENDPOINT_GS_GOAL_ITEM = `${API_URL}/gsGoal/Item`;
export const ENDPOINT_GS_GOAL_PROFILE_IMAGE = `${API_URL}/gsGoal/ProfileImage`;
export const ENDPOINT_GS_MEASURE_LOG = `${API_URL}/gsmeasure/log`;
export const ENDPOINT_GS_MEMBER = `${API_URL}/gsMember`;
export const ENDPOINT_GS_MEMBER_CLONE = `${API_URL}/gsMember/Clone`;
export const ENDPOINT_GS_MEMBERS = `${API_URL}/gsMembers`;
export const ENDPOINT_GS_PPF_DASHBOARD = `${API_URL}/GSPPFDashboard`;
export const ENDPOINT_GS_PPF_DASHBOARD_SUMMARY = `${API_URL}/GSPPFDashboard/summary`;
export const ENDPOINT_GS_PLANS = `${API_URL}/gsPlans`;
export const ENDPOINT_GS_PLAN = `${API_URL}/gsPlan`;
export const ENDPOINT_GS_PLAN_NOTICES = `${API_URL}/gsPlan/noticeboard`;
export const ENDPOINT_GS_PLAN_NOTICE = `${API_URL}/gsPlan/Notice`;
export const ENDPOINT_GS_PLAN_NOTICE_LIKE = `${API_URL}/gsPlan/Notice/Like`;
export const ENDPOINT_GS_PLAN_NOTICE_UNLIKE = `${API_URL}/gsPlan/Notice/Unlike`;
export const ENDPOINT_GS_PLAN_NOTICE_COMMENT = `${API_URL}/gsPlan/Notice/Comment`;
export const ENDPOINT_GS_PLAN_NOTICE_COMMENT_LIKE = `${API_URL}/gsPlan/Notice/Comment/Like`;
export const ENDPOINT_GS_PLAN_NOTICE_COMMENT_UNLIKE = `${API_URL}/gsPlan/Notice/Comment/Unlike`;
export const ENDPOINT_GS_PLAN_NOTICE_MEMBER = `${API_URL}/gsPlan/Notice/Member`;
export const ENDPOINT_GS_TEMPLATE_IMAGES = `${URL_ORIGIN}/tyto/Content/images/gsPPFimages.json`;
export const ENDPOINT_LAUNCH_ENROLLMENT = `${API_URL}/Launch/Enrollment`;
export const ENDPOINT_LESSON = `${API_URL}/Lesson`;
export const ENDPOINT_LOGIN_URL = `${API_URL}/Login/Authenticate`;
export const ENDPOINT_LOGIN4_URL = `${API_URL}/Login/Authenticate4`;
export const ENDPOINT_LOGIN_RECOVER_URL = `${API_URL}/Login/Recover`;
export const ENDPOINT_LOGIN_RECOVER_TEAMTOOLS_URL = `${API_URL}/Login/Recover/TeamTools`;
export const ENDPOINT_LOGIN_RESET_PASSWORD_URL = `${API_URL}/Login/resetpassword`;
export const ENDPOINT_LOGIN_RESET_PASSWORD_VALIDATE_URL = `${API_URL}/Login/resetpassword/validate`;
export const ENDPOINT_LOGOUT_URL = `${API_URL}/Session/Logout`;
export const ENDPOINT_PREREQUISITE_ENROLLMENTS = `${API_URL}/PrerequisiteEnrollments`;
export const ENDPOINT_SESSION_CHECK = `${API_URL}/AccountSession`;
export const ENDPOINT_TEAM_INFO = `${API_URL}/TeamsByFunction`;
export const ENDPOINT_PERSON_GET = `${API_URL}/Person`;
export const ENDPOINT_PERSON_MY_PASSWORD = `${API_URL}/Person/MyPassword`;
export const ENDPOINT_PERSON_NOTICES = `${API_URL}/Person/Notices`;
export const ENDPOINT_PERSON_PASSWORD = `${API_URL}/Person/Password`;
export const ENDPOINT_PERSON_PROFILE_PHOTO = `${API_URL}/Person/profilephoto`;
export const ENDPOINT_PLAN_ENROLL = `${API_URL}/DevPlan/Enrollment`;
export const ENDPOINT_SAVEFORLESSON = `${API_URL}/Asset/Saveforlesson`;
export const ENDPOINT_TASKS = `${API_URL}/Tasks`;
export const ENDPOINT_TASK_VERIFICATION_REQUEST = `${API_URL}/Task/VerificationRequest`;
export const ENDPOINT_TASK_STRUCTURE = `${API_URL}/Task/Structure`;
export const ENDPOINT_TEAM = `${API_URL}/Team`;
export const ENDPOINT_TEAMBOARD = `${API_URL}/Teamboard`;
export const ENDPOINT_TEAM_TOOLS_CONFIG = `${API_URL}/TeamTools/Config`;
export const ENDPOINT_TEAM_TOOLS_EMAIL_LOGIN = `${API_URL}/TeamTools/EmailLogin`;
export const ENDPOINT_TEAM_TOOLS_INVITE = `${API_URL}/TeamToolsInvite/`;
export const ENDPOINT_TEAM_TOOLS_INVITE_EMAIL = `${API_URL}/TeamToolsInvite/Email`;
export const ENDPOINT_TEAM_TOOLS_INVITE_TEMPSESSION = `${API_URL}/TeamToolsInvite/tempSession`;
export const ENDPOINT_TEAM_MEMBERSHIP_PERSON = `${API_URL}/TeamMembership/Person`;
export const ENDPOINT_TRAINING = `${API_URL}/Training`;
export const ENDPOINT_TIMEZONES = `${API_URL}/TimeZone`;
export const ENDPOINT_PERSON_GET_ADVANCED = `${API_URL}/People/AdvancedSearch`;
export const ENDPOINT_MENU_GET = `${API_URL}/Menu`;
export const ENDPOINT_FUNCTION_OPS = `${API_URL}/Function/Ops`;
export const ENDPOINT_GS_CHANGELOG = `${API_URL}/gsChangelog`;
export const ENDPOINT_USER_ALERT_COUNTS = `${API_URL}/UserAlertCounts`;
// * Site Theme Options
export const SITE_THEMES = {
  dark: "dark" as SITE.SiteTheme.dark,
  light: "light" as SITE.SiteTheme.light,
  system: "system" as SITE.SiteTheme.system,
};

// * SET_STATUS SEMANTIC ALIASES
export const SET_STATUSES = {
  NOT_SPECIFIED: 0,
  SYSTEM: 1,
  AUTOMATIC: 1,
  MANUALLY_SET: 2,
  COMPLETE_AT_LAUNCH: 3,
  VERFIED_BY_3RD_PARTY: 4,
  COMPLETE_ON_UPLOAD: 5,
  COMPLETE_AFTER_UPLOAD_IS_VERIFIED: 6,
  AT_END_OF_VIDEO: 7,
  AT_END_OF_CONTENT: 7,
  VERIFY_LIST: 8,
  UPLOAD_VERIFIED_LIST: 9,
  VERIFY_CHOICE: 10,
  VERIFY_CHOICE_UPLOAD: 11,
};

// * Interface Paths
export const INTERFACE_PATHS = {
  // COURSE: "/course", // * Not actually used
  // COURSES: "/courses", // * Not actually used
  CREATE_PLAN: "/create-plan",
  DEFAULT_ROUTE: "/",
  HELP: "/help",
  HOME: "/",
  INTRODUCTION: "/introduction",
  LOGIN: "/login",
  LOGIN_WITH_KEY: "/session-check", // * A Special route for logging in from Tryyb (main application)
  NO_STORAGE: "/no-storage", // * A special route if the user's browser doesn't support localStorage
  PLANS: "/plans",
  PLAN: "/plan",
  PROFILE: "/profile",
  STATISTICS: "/statistics",
  TEAMBOARD: "/teamboard", // * Not actually used
  USER_SEARCH: "/search-users",
};

// * Set Actual DEFAULT_ROUTE
const DEFAULT_ROUTE = INTERFACE_PATHS.PLANS;

INTERFACE_PATHS.DEFAULT_ROUTE = DEFAULT_ROUTE;

export const SUB_INTERFACE_PATHS = {
  PLAN: {
    COMPLETED_GOALS: "completed-goals",
    EDIT_GOAL: "edit-goal",
    PERSONAL: "personal",
    PROFESSIONAL: "professional",
    FINANCIAL: "financial",
    NEW_GOAL: "new-goal",
    GOALS: "goals",
    OVERVIEW: "overview",
    PERMISSIONS: "permissions",
  },
  PROFILE: {
    INFORMATION: "information",
    MY_CERTIFICATIONS: "my-certifications",
  },
};

// * Misc
export const INTERFACE_SEARCH_PARAMS = {
  // COURSE: {
  //   TARGET_PREREQUISITE_ID: "targetPrereqID",
  // },
  PLAN: {
    GOAL_STATUS_FILTER: "goalStatusFilter",
  },
};

// * Unique Keys for React-Query (referenced in the first argument of 'useQuery' hooks)
export const REACT_QUERY_KEYS = {
  ADVANCED_PERSON_SEARCH: "adv-person-search",
  ASSET_ENCODING: "asset-encoding",
  CLIENT_CONFIG: "client-config",
  EXAM_INVITES: "exam-invites",
  EXAM_QUESTION: "exam-question",
  EXAM_TRAINING_INVITES: "exam-training-invites",
  TASK_STRUCTURE: "task-structure",
  TRAINING: "training",
  TRENDING: "trending",
  CATALOG: "catalog",
  BLOCK: "block",
  BLOCK_PREREQUISITES: "block-prerequisites",
  LAUNCH_ENROLLMENT: "launch-enrollment",
  LESSON: "lesson",
  PERSON: "person",
  PREREQUISITE_ENROLLMENTS: "block-prerequisite-enrollments",
  TIMEZONES: "timezones",
  PERSON_PROFILE_PHOTO: "person-profile-photo",
  PERSON_NOTICES: "person-notices",
  PPF_TEMPLATE_IMAGES: "ppf-template-images",
  PLAN: "plan",
  PLANS: "plans",
  PLAN_CHANGE_LOG: "plab-change-log",
  PLAN_GOALS: "plan-goals",
  PLAN_MEMBERS: "plan-members",
  PLAN_DASHBOARD: "plan-dashboard",
  PLAN_DASHBOARD_SUMMARY: "plan-dashboard-summary",
  PLAN_NOTICEBOARD: "plan-noticeboard",
  PLAN_NOTICE: "plan-notice",
  PLAN_NOTICE_COMMENT: "plan-notice-comment",
  PPF_LIBRARY_CONFIG: "ppf-library-config",
  PPF_LIBRARY_CONFIG_FALLBACK: "ppf-library-config-fallback",
  TEAMS_BY_FUNCTION: "teams-by-function",
  FUNCTION_OPS: "function-ops",
  USER_ALERT_COUNTS: "user-alert-counts",
};

// * Just a few important Domain IDs within the system
export const DOMAINS_MAP = {
  AMI: 1752244,
  AUDIGY: 15572,
  BELTONE: 1389407,
  BUILDING_AN_EMPIRE: 1964196,
  CARDONE_VENTURES: 1698652,
  CV: 1825957,
  HEALTH_PLAN_10X: 2001650,
};

// * This should be irrelevant to the PPF project
// export const EXAM_DISPLAY_RESULTS_DURING = {
//   ocRESULTANDFB: "ocRESULTANDFB",
//   ocRESULT: "ocRESULT",
//   ocRESULTCORRECT: "ocRESULTCORRECT",
//   ocRESCORANDFB: "ocRESCORANDFB",
//   ocDISABLED: "ocDISABLED",
//   ocVACANT: "ocVACANT",
// };

// * This should be a list of specific Reusable Event Types that get defined here and refernced wherever relevant in the code
export const TRACKING_EVENT_TYPES: {
  [friendlyName: string]: Tracking.InteractionEventType;
} = {
  MENU_BUTTON_TOGGLED: "Menu Button Toggled",
  // MENU_INTERFACE_LINK_CLICKED: "Menu Interface-Link Clicked",
  // MENU_COURSE_STEP_CLICKED: "Menu Course Step Clicked",
  // MENU_COURSE_SECTION_TOGGLED: "Menu Course Section Toggled",
  // NAV_LINK_CLICKED: "Nav Link Clicked",
  // NAV_HOME_IMAGE_CLICKED: "",
  // NAV_USER_THUMB_CLICKED: "",
  // NAV_USER_SEARCH_OPENED: "",
  // TRAINING_TILE_CLICKED: "",
  // COURSE_ASSET_TILE_CLICKED: "",
  // TRAINING_TILES_SCROLL_BUTTON_CLICKED: "",
};

export const PPF_GOAL_STATUSES: {
  AT_RISK: "ocATRISK";
  COMPLETE: "ocCOMPLETE";
  IN_PROGRESS: "ocINPROGRESS";
  NOT_STARTED: "ocNOTSTARTED";
  STALLED: "ocSTALLED";
  VACANT: "ocVACANT";
} = {
  AT_RISK: "ocATRISK",
  COMPLETE: "ocCOMPLETE",
  IN_PROGRESS: "ocINPROGRESS",
  NOT_STARTED: "ocNOTSTARTED",
  STALLED: "ocSTALLED",
  VACANT: "ocVACANT", // * Basically, not yet set; void; null.
};

export const PPF_MEMBER_FALSE_DATA = {
  goalView: false, // ! FALSE
  memberView: false, // ! FALSE
  planView: true, // * TRUE
  goalAdd: false, // ! FALSE
  goalChange: false, // ! FALSE
  goalDelete: false, // ! FALSE
  measureLogAdd: false, // ! FALSE
  measureLogChange: false, // ! FALSE
  measureLogDelete: false, // ! FALSE
  measureLogView: false, // ! FALSE
  memberAdd: false, // ! FALSE
  memberChange: false, // ! FALSE
  memberDelete: false, // ! FALSE
  noticeStandardAdd: false, // ! FALSE
  noticeStandardChange: false, // ! FALSE
  noticeStandardDelete: false, // ! FALSE
  noticeStandardView: false, // ! FALSE
  planChange: false, // ! FALSE
  planDelete: false, // ! FALSE
};

export const PPF_MEMBER_ADD_DATA_FULL_PERMISSIONS = {
  goalView: true, // * TRUE
  memberView: true, // * TRUE
  planView: true, // * TRUE
  goalAdd: true, // * TRUE
  goalChange: true, // * TRUE
  goalDelete: true, // * TRUE
  measureLogAdd: true, // * TRUE
  measureLogChange: true, // * TRUE
  measureLogDelete: true, // * TRUE
  measureLogView: true, // * TRUE
  memberAdd: true, // * TRUE
  memberChange: true, // * TRUE
  memberDelete: true, // * TRUE
  noticeStandardAdd: true, // * TRUE
  noticeStandardChange: true, // * TRUE
  noticeStandardDelete: true, // * TRUE
  noticeStandardView: true, // * TRUE
  planChange: true, // * TRUE
  planDelete: true, // * TRUE
};

export const DEFAULT_PPF_MEMBER_ADD_DATA = {
  ...PPF_MEMBER_ADD_DATA_FULL_PERMISSIONS,
  goalAdd: false, // ! FALSE
  goalChange: false, // ! FALSE
  goalDelete: false, // ! FALSE
  noticeStandardAdd: false, // ! FALSE
  noticeStandardChange: false, // ! FALSE
  noticeStandardDelete: false, // ! FALSE
  noticeStandardView: false, // ! FALSE
  planChange: false, // ! FALSE
  planDelete: false, // ! FALSE
};

export const PPF_MEMBER_ADD_DATA_EVERYTHING_BUT_PLAN_CHANGE = {
  ...PPF_MEMBER_ADD_DATA_FULL_PERMISSIONS,
  planDelete: false, // ! FALSE
  planChange: false, // ! FALSE
};

export const PPF_ROLES_BY_NAME = {
  MANAGER: {
    title: "",
    permissions: {
      ...PPF_MEMBER_ADD_DATA_FULL_PERMISSIONS,
    },
  },
  CONTRIBUTOR: {
    title: "",
    permissions: {
      ...PPF_MEMBER_ADD_DATA_FULL_PERMISSIONS,
      goalDelete: false, // ! FALSE
      memberAdd: false, // ! FALSE
      memberChange: false, // ! FALSE
      memberDelete: false, // ! FALSE
      noticeStandardChange: false, // ! FALSE
      noticeStandardDelete: false, // ! FALSE
      planChange: false, // ! FALSE
      planDelete: false, // ! FALSE
    },
  },
  READER: {
    title: "",
    permissions: {
      ...PPF_MEMBER_FALSE_DATA,
      planView: true, // * TRUE
      memberView: true, // * TRUE
      goalView: true, // * TRUE
    },
  },
};

// * GOAL IMAGE RATIOS
export const GOAL_IMAGE_RATIOS = {
  SQUARE: {
    height: 1000,
    width: 1000,
    aspectRatio: 1,
  },
  PORTRAIT: {
    width: 736,
    height: 1050,
    aspectRatio: 368 / 525,
  },
  LANDSCAPE: {
    width: 1104,
    height: 357,
    aspectRatio: 368 / 119,
  },
};

export const SPECIAL_USER_IDS = new Set([
  2, // Oca
  4792, // Pete
  1503800, // Austin
  1960713, // Joshua
  2043811, // Johann
  1625045, // Ryann
]);

export const PLAN_CHANGE_LOG_SRC_TYPES = {
  GOAL_CHANGE: "usp_persistGSGoal",
  GOAL_ITEM_CHANGE: "usp_persistGSGoalItem",
  PLAN_MEMBER_CHANGE: "usp_persistGSMember",
};

export const BROWSER_HISTORY = createBrowserHistory();
