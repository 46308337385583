/*
 * Component Description
 */
import * as React from "react";
import { useDrag } from "@use-gesture/react";
import { a, useSpring, config } from "@react-spring/web";
import cx from "classnames";

import { StoreContext as GeneralStoreContext } from "data/stores/GeneralStore";
import { useGoalNotices, useStoredInputValue } from "data/helpers/Hooks";
import * as Storage from "data/storage/";

import { Icon } from "components/common/";

import NoticeInput from "./NoticeInput";
import NoticeThreads from "../notice-threads/";

const MAX_HEIGHT_GAP_FROM_TOP = 10;
const STATIC_MAX_HEIGHT = Math.max(
  window.innerHeight - MAX_HEIGHT_GAP_FROM_TOP,
  0
);
const MAX_HEIGHT = 0;
const VELOCITIES = {
  FULL: 0.8,
  FULL_DOWNWARDS: 1.3,
  MIDDLE: 0.35,
};

interface NoticeThreadsMobileProps extends Props, GenericWrapperProps {
  isCVDomain?: boolean;
}

const NoticeThreadsMobileWrapper = (props: NoticeThreadsMobileProps) => {
  const contRef = React.useRef<HTMLElement | null>(null);
  const noticeContRef = React.useRef<HTMLElement | null>(null);
  const noticeListContRef = React.useRef<HTMLUListElement | null>(null);
  const curComputedMaxHeight = React.useRef<number | null>(null);
  const startingHeight = React.useRef<number | null>(null);
  const [scrolledForID, updateScrolledForID] = React.useState(0);
  const [messagesCount, updateMessagesCount] = React.useState(() => {
    return getMessagesCount(props.goalID ?? 0, props.planNotices);
  });

  const [isPending, startTransition] = React.useTransition();

  const [style, api] = useSpring(() => ({ maxHeight: MAX_HEIGHT }));

  // * Attempt to scroll to end of list on mount
  React.useEffect(() => {
    if (!props.goalNotices.length) {
      return;
    }

    if (noticeContRef.current && noticeListContRef.current) {
      scrollToEndOfList({
        noticeContRef,
        noticeListContRef,
      });

      updateScrolledForID(props.goalID);
    } else {
      // * Uh oh...
      debugger;
    }
  }, []);

  // * If goalID or goalNotices list changes...
  React.useEffect(() => {
    // * Is current goalID has not been scrolled
    if (
      props.goalID !== scrolledForID ||
      (!scrolledForID && props.goalNotices?.length)
    ) {
      updateScrolledForID(props.goalID);

      console.log("Ran for: ", props.goalID);
    }
  }, [props.goalID, props.goalNotices]);

  // * On goalID change
  React.useEffect(() => {
    // TODO
    if (props.goalID !== scrolledForID) {
      //   debugger;
      if (noticeListContRef.current) {
        const height = getHeightOfMostRecentMessage(noticeListContRef);

        open({
          canceled: false,
          immediate: !messagesCount,
          targetHeight: height,
        });
      }
    }

    setTimeout(() => {
      startTransition(() => {
        if (noticeContRef.current && noticeListContRef.current) {
          scrollToEndOfList({
            noticeContRef,
            noticeListContRef,
          });
        }
      });
    }, 0);

    if (props.goalNotices?.length) {
      updateScrolledForID(props.goalID);
    }
  }, [props.goalID]);

  // * On GoalNotices Change
  React.useEffect(() => {
    // TODO
    if (!props.planNotices) {
      return;
    }

    // * was passing planNotices instead of GoalNotices, but should no longer be necessary
    // * Since goalNotices is now a memoized value and not useState update, delayed until next render
    const newCount = getMessagesCount(props.goalID ?? 0, props.goalNotices);

    let wasScrolled = false;

    // debugger;
    // * Essentially, goalMessages weren't previously loaded, but now are for the current goalID
    if (messagesCount !== null || props.goalID !== scrolledForID) {
      if (
        (newCount ?? 0) > (messagesCount ?? 0) ||
        props.goalID !== scrolledForID
      ) {
        updateMessagesCount(newCount ?? 0);

        let targetHeight = undefined;

        if (props.goalID === scrolledForID) {
          targetHeight = curComputedMaxHeight.current ?? undefined;
        } else {
          targetHeight = getHeightOfMostRecentMessage(noticeListContRef);
        }

        open({
          canceled: false,
          targetHeight,
        });

        startTransition(() => {
          if (noticeContRef.current && noticeListContRef.current) {
            scrollToEndOfList({
              noticeContRef,
              noticeListContRef,
            });
          }
        });

        wasScrolled = true;
      }
    } else if (newCount !== null) {
      const height = getHeightOfMostRecentMessage(noticeListContRef);

      open({ canceled: false, targetHeight: height });

      // debugger;

      if (props.goalID === scrolledForID && scrolledForID) {
        scrollToEndOfList({
          noticeContRef,
          noticeListContRef,
        });
      }

      updateMessagesCount(newCount ?? 0);
    }

    if (wasScrolled && props.goalID !== scrolledForID) {
      updateScrolledForID(props.goalID);
    }
  }, [props.goalNotices]);

  // * Open callback
  const open = ({
    canceled,
    targetHeight,
    immediate = false,
  }: {
    canceled: boolean;
    immediate?: boolean;
    targetHeight?: number;
  }) => {
    // when cancel is true, it means that the user passed the upwards threshold
    // so we change the spring config to create a nice wobbly effect
    api.start({
      maxHeight:
        targetHeight ?? curComputedMaxHeight?.current ?? STATIC_MAX_HEIGHT,
      immediate,
      config: canceled && false ? config.wobbly : config.stiff,
    });
  };

  // * Close callback
  const close = (velocity = 0) => {
    api.start({
      maxHeight: 0,
      immediate: false,
      config: { ...config.stiff, velocity },
    });
  };

  return (
    <section
      className="cg-goalstories-currentgoal-noticethreads-cont"
      ref={contRef}
      onTouchStartCapture={(e) => {
        // e.stopPropagation();
        // e.preventDefault();
        const body = document.querySelector("body");
        const contentWrapper = document.querySelector(".interface-main-cont");
        const interfaceWrapper = document.querySelector(".interface-wrapper");

        body?.classList.add("no-scroll");
        contentWrapper?.classList.add("no-scroll");
        interfaceWrapper?.classList.add("no-scroll");
      }}
      onTouchEndCapture={() => {
        const body = document.querySelector("body");
        const contentWrapper = document.querySelector(".interface-main-cont");
        const interfaceWrapper = document.querySelector(".interface-wrapper");

        body?.classList.remove("no-scroll");
        contentWrapper?.classList.remove("no-scroll");
        interfaceWrapper?.classList.remove("no-scroll");
      }}
      onTouchCancelCapture={() => {
        const body = document.querySelector("body");
        const contentWrapper = document.querySelector(".interface-main-cont");
        const interfaceWrapper = document.querySelector(".interface-wrapper");

        body?.classList.remove("no-scroll");
        contentWrapper?.classList.remove("no-scroll");
        interfaceWrapper?.classList.remove("no-scroll");
      }}
    >
      {props.isMobile && !!props.goalNotices?.length && (
        <div
          className="cg-goalstories-currentgoal-noticethreads-icon-wrapper"
          // {...getDragBindEvents()}
          onTouchStartCapture={(e) => {
            if (!noticeContRef.current?.getBoundingClientRect) {
              startingHeight.current = null;
            }

            const rectData = noticeContRef.current?.getBoundingClientRect();
            const newComputedMaxHeight = determineHeightData({
              contRef,
              noticeContRef,
              noticeListContRef,
            });

            console.log(
              "Touch Start! Height set to: ",
              rectData?.height,
              " newComputedMaxHeight: ",
              newComputedMaxHeight
            );
            startingHeight.current = rectData?.height ?? null;
            curComputedMaxHeight.current = newComputedMaxHeight;
          }}
        >
          <div className="cg-goalstories-currentgoal-noticethreads-icon-wrapper-inner-cont">
            <p className="cg-goalstories-currentgoal-noticethreads-messages-count">
              {messagesCount ?? 0} Message{messagesCount === 1 ? "" : "s"}
            </p>
          </div>

          <div className="cg-goalstories-currentgoal-noticethreads-icon-wrapper-inner-cont">
            <Icon
              className="cg-goalstories-currentgoal-noticethreads-icon"
              icon="line-horizontal"
              size={28}
            />
          </div>

          <div className="cg-goalstories-currentgoal-noticethreads-icon-wrapper-inner-cont" />
        </div>
      )}

      <a.section
        className="currentgoal-notices-list-wrapper"
        ref={noticeContRef}
        style={style}
      >
        <NoticeThreads
          goalID={props.childGoal?.gsGoalID}
          goalNotices={props.goalNotices}
          changelog={props.goalChangelog}
          permissions={props.permissions}
          notices={props.planNotices ?? []}
          innerRef={noticeListContRef}
          replyNoticeID={props.replyNoticeID}
          updateReplyID={props.updateReplyNoticeID}
          isCVDomain={props.isCVDomain}
        />
      </a.section>

      {!props.isCVDomain && (
        <NoticeInput
          onPost={() => {
            props.clearStoredValue?.();
          }}
          goalNotices={props.goalNotices}
          isMemberOfPlan={props.isMemberOfPlan}
          isMobile={props.isMobile}
          messageText={props.newMessageText}
          newMessageInputRef={props.newMessageInputRef}
          permissions={props.permissions}
          plan={props.plan}
          childGoal={props.childGoal}
          replyNoticeID={props.replyNoticeID}
          updateMessageText={props.updateNewMessageText}
          updateReplyNoticeID={props.updateReplyNoticeID}
        />
      )}
    </section>
  );
};

function determineHeightData({
  contRef,
  noticeContRef,
  noticeListContRef,
}: {
  contRef: React.MutableRefObject<HTMLElement | null>;
  noticeContRef: React.MutableRefObject<HTMLElement | null>;
  noticeListContRef: React.MutableRefObject<HTMLUListElement | null>;
}) {
  const maxWindowHeight = window.innerHeight - MAX_HEIGHT_GAP_FROM_TOP;

  if (
    !!contRef.current &&
    !!noticeContRef.current &&
    !!noticeListContRef.current
  ) {
    const wrapperData = contRef.current.getBoundingClientRect();
    const noticeContData = noticeContRef.current.getBoundingClientRect();
    const listContData = noticeListContRef.current.getBoundingClientRect();

    const heightWithoutList = wrapperData.height - noticeContData.height;

    // * Choose smallest: Either height of all notices, or Height of container minus scrollable container height
    const computedMaxListHeight = Math.min(
      maxWindowHeight - heightWithoutList,
      listContData.height
    );

    return computedMaxListHeight;
  }

  return null;
}

function getMessagesCount(
  goalID: number,
  planNotices?: GenericWrapperProps["goalNotices"]
) {
  if (!planNotices) {
    return null;
  }

  const goalNotices = planNotices.filter(
    (planNotice) => planNotice.aboutID === goalID
  );

  if (!goalNotices?.length) {
    return 0;
  }

  const noticesAndCommentsCount = goalNotices.reduce((accum, notice) => {
    // * '+ 1' since the notice itself is not counted in it's own comments list
    const noticeMessagesCount = (notice.comments?.length ?? 0) + 1;

    return accum + noticeMessagesCount;
  }, 0);

  return noticesAndCommentsCount;
}

function getHeightOfMostRecentMessage(
  noticeListContRef: React.MutableRefObject<HTMLUListElement | null>
) {
  if (!noticeListContRef.current) {
    return 0;
  }

  const messagesAsArray = Array.from(
    noticeListContRef.current.querySelectorAll("[data-msg-container]")
  );

  const lastMessageElement = messagesAsArray.slice(-1)[0];

  let height = 0;

  if (messagesAsArray.length === 1) {
    height = noticeListContRef.current?.getBoundingClientRect?.()?.height ?? 0;
  } else if (lastMessageElement) {
    const extraHeightToSeeMessageAbove = 43;
    // * Where '30' is an abritrary height to help you see there is a message above
    // * this most recent one (that you can scroll up to see more)
    height =
      (lastMessageElement?.getBoundingClientRect?.()?.height ?? 0) +
      extraHeightToSeeMessageAbove;
  }

  return height;
}

function scrollToEndOfList({
  noticeContRef,
  noticeListContRef,
}: {
  noticeContRef: React.MutableRefObject<HTMLElement | null>;
  noticeListContRef: React.MutableRefObject<HTMLUListElement | null>;
}) {
  noticeContRef.current?.scrollTo?.({
    top: noticeListContRef.current?.getBoundingClientRect?.()?.height ?? 1000,
    behavior: undefined,
  });
}

interface NoticeThreadsDesktopProps extends Props, GenericWrapperProps {
  isCVDomain?: boolean;
  maxHeight?: number;
  passbackOpenStatus?: (open: boolean) => void;
}

const NoticeThreadsDesktop = (props: NoticeThreadsDesktopProps) => {
  const [selectedTab, updateSelectedTab] = React.useState("comments");
  return (
    <section className="cg-goalstories-currentgoal-noticethreads-cont">
      <p
        className="cg-goalstories-currentgoal-noticethreads-x-button"
        onClick={() => {
          if (props.passbackOpenStatus) props.passbackOpenStatus(false);
        }}
      >
        &times;
      </p>
      <div className="currentgoal-notices-selection-options">
        <h1
          onClick={() => {
            updateSelectedTab("comments");
          }}
          className={cx(
            "currentgoal-notices-selection-option",
            selectedTab === "comments" ? "currently-selected-section" : ""
          )}
        >
          Comments
        </h1>
        <h1
          onClick={() => {
            updateSelectedTab("history");
          }}
          className={cx(
            "currentgoal-notices-selection-option",
            selectedTab === "history" ? "currently-selected-section" : ""
          )}
        >
          History
        </h1>
      </div>
      {selectedTab === "comments" && (
        <>
          <section
            className="currentgoal-notices-list-wrapper"
            style={{ maxHeight: `${props.maxHeight}px` }}
          >
            <NoticeThreads
              goalID={props.childGoal?.gsGoalID}
              goalNotices={props.goalNotices}
              permissions={props.permissions}
              notices={props.planNotices}
              replyNoticeID={props.replyNoticeID}
              updateReplyID={props.updateReplyNoticeID}
              isCVDomain={props.isCVDomain}
              isComments={true}
            />
          </section>

          {!props.isCVDomain && (
            <NoticeInput
              onPost={() => {
                props.clearStoredValue?.();
              }}
              goalNotices={props.goalNotices}
              isMemberOfPlan={props.isMemberOfPlan}
              isMobile={props.isMobile}
              messageText={props.newMessageText}
              newMessageInputRef={props.newMessageInputRef}
              permissions={props.permissions}
              plan={props.plan}
              childGoal={props.childGoal}
              replyNoticeID={props.replyNoticeID}
              updateMessageText={props.updateNewMessageText}
              updateReplyNoticeID={props.updateReplyNoticeID}
            />
          )}
        </>
      )}
      {selectedTab === "history" && (
        <section
          className="currentgoal-notices-list-wrapper"
          style={{ maxHeight: `${props.maxHeight}px` }}
        >
          <NoticeThreads
            goalID={props.childGoal?.gsGoalID}
            goalNotices={props.goalNotices}
            changelog={props.goalChangelog}
            permissions={props.permissions}
            replyNoticeID={props.replyNoticeID}
            updateReplyID={props.updateReplyNoticeID}
            isCVDomain={props.isCVDomain}
            isComments={false}
          />
        </section>
      )}
    </section>
  );
};

export const DEFAULT_NOTICES_ARR: TytoData.Notices.Notice[] = [];

interface GenericWrapperProps {
  clearStoredValue: () => void;
  newMessageText: string;
  goalNotices: TytoData.Notices.Notice[];
  goalChangelog: Array<TytoData.GoalChangeLogItem>;
  goalID: number;
  isMobile: boolean;
  replyNoticeID: number;
  updateNewMessageText: (newValue: string) => void;
  updateReplyNoticeID: React.Dispatch<React.SetStateAction<number>>;
}

interface Props {
  childGoal: TytoData.PPF.Plan.Goals.ChildGoal;
  isMobile: boolean;
  newMessageInputRef: React.MutableRefObject<HTMLInputElement | null>;
  permissions: TytoData.PPF.Plan.MemberPermissionData;
  planNotices?: TytoData.Notices.Notice[];
  planChangelog?: Array<TytoData.GoalChangeLogItem>;
  plan: TytoData.PPF.Plan.Plan;
  isMemberOfPlan: boolean;
  maxHeight?: number;
  passbackOpenStatus?: (open: boolean) => void;
}

const NoticeThreadSizeRouter = (props: Props) => {
  const GeneralStore = React.useContext(GeneralStoreContext);

  const domainID =
    Storage.SessionHandling.getPropertyFromActiveSession("domainID");
  const isCVDomain = domainID === 1698652 ? true : false;

  const isMobile = !!GeneralStore.state?.isMobile;

  const [replyNoticeID, updateReplyNoticeID] = React.useState(0);
  const [newMessageText, updateNewMessageText, clearStoredValue] =
    useStoredInputValue({
      subKey: `goal-${props.childGoal.gsGoalID}`,
    });

  const goalNotices = useGoalNotices({
    noticeBoard: props.planNotices ?? DEFAULT_NOTICES_ARR,
    goalID: props.childGoal.gsGoalID,
  });

  return (
    <section className="cg-goalstories-currentgoal-noticethreads-cont">
      {props.isMobile && (
        <Icon
          className="cg-goalstories-currentgoal-noticethreads-icon"
          icon="line-horizontal"
          size={28}
        />
      )}

      {/* <NoticeThreads
        goalID={props.childGoal?.gsGoalID}
        permissions={props.permissions}
        notices={props.planNotices}
        replyNoticeID={replyNoticeID}
        updateReplyID={updateReplyNoticeID}
        changelog={props.planChangelog}
      /> */}

      {/* {isMobile && (
        <NoticeThreadsMobileWrapper
          {...props}
          clearStoredValue={clearStoredValue}
          isMemberOfPlan={props.isMemberOfPlan}
          isMobile={isMobile}
          newMessageInputRef={props.newMessageInputRef}
          goalNotices={goalNotices}
          goalChangelog={props.planChangelog ?? []}
          goalID={props.childGoal.gsGoalID}
          newMessageText={newMessageText}
          replyNoticeID={replyNoticeID}
          updateNewMessageText={updateNewMessageText}
          updateReplyNoticeID={updateReplyNoticeID}
          // isCVDomain={isCVDomain}
        />
      )} */}
      <NoticeThreadsDesktop
        {...props}
        clearStoredValue={clearStoredValue}
        isMemberOfPlan={props.isMemberOfPlan}
        isMobile={isMobile}
        newMessageInputRef={props.newMessageInputRef}
        goalNotices={goalNotices}
        goalChangelog={props.planChangelog ?? []}
        goalID={props.childGoal.gsGoalID}
        newMessageText={newMessageText}
        replyNoticeID={replyNoticeID}
        updateNewMessageText={updateNewMessageText}
        updateReplyNoticeID={updateReplyNoticeID}
        maxHeight={props.maxHeight}
        passbackOpenStatus={(open) => {
          if (props.passbackOpenStatus) props.passbackOpenStatus(open);
        }}
        // isCVDomain={isCVDomain}
      />
    </section>
  );
};

export default NoticeThreadSizeRouter;
