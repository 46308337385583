import { ENDPOINT_DISC_PROFILE_INTERACTIVE } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DISC_PROFILE_INTERACTIVE;

export const DISCProfileInteractive = {
  get(
    params: Endpoints.Tyto.DISCProfile.Interactive.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, params || {}, callOpts) as Promise<{
      discProfile: TytoData.DISCCompareProfile;
      session: Data.SessionData;
    }>;
  },
};
