import { ENDPOINT_USER_ALERT_COUNTS } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_USER_ALERT_COUNTS;

export const UserAlertCounts = {
  get(
    params: Endpoints.Tyto.UserAlertCounts.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params,
      callOpts
    ) as Promise<Endpoints.Responses.UserAlertCounts.Get>;
  },
};
