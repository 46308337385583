import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";

// import { Props as CurrentGoalProps } from "../current-goal/";
import ChildGoalCard from "./ChildGoalCard";

import "./style.scss";

interface Props {
  childGoals: TytoData.PPF.Plan.Goals.ChildGoal[];
  parentByTheme: GoalStories.KeyedData["parentByTheme"];
}

const GoalsGrid = ({ childGoals, parentByTheme }: Props) => {
  const locationData = useLocation();

  return (
    <div className="cg-goalstories-goalsgrid">
      {!!childGoals?.length
        ? childGoals.map((childGoal) => (
            <ChildGoalCard
              key={childGoal.gsGoalID}
              childGoal={childGoal}
              parentByTheme={parentByTheme}
              locationData={locationData}
            />
          ))
        : null}
    </div>
  );
};

export default GoalsGrid;
