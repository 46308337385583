/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { StoreContext as AppStoreContext } from "data/stores/AppStore";
import { usePerson } from "data/network/hooks/";
import { Message } from "components/common";
import * as Storage from "data/storage/";

import ProfileTabs from "./subcomponents/ProfileTabs";
import ProfileHeader from "./subcomponents/ProfileHeader";

export interface Props {
  isMobile: boolean;
  GeneralStore: StoreAPI.GeneralStoreProps;
  subInterface?: ProfilePage.Subinterface;
}

const Profile = (props: Props) => {
  let AppStore = React.useContext(AppStoreContext);

  const [userID] = React.useState(
    Storage.SessionHandling.getUserIDOfActiveSession()
  );

  const personDataQuery = usePerson({ userID });

  const personData =
    personDataQuery?.data?.person ??
    personDataQuery?.storedValueQuery?.data?.person;

  return (
    <main className="interface-profile-main">
      {personData ? (
        <ProfileHeader {...props} AppStore={AppStore} personData={personData} />
      ) : (
        <section>
          <Message
            className="profile-main-loading-msg"
            text="Loading Profile Information..."
          />
        </section>
      )}

      {personData ? (
        <ProfileTabs {...props} AppStore={AppStore} personData={personData} />
      ) : null}
    </main>
  );
};

export default Profile;
