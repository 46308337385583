/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { createPseudoRandomNumber } from "data/helpers/";
import API from "data/network/tyto/";
import { getUploadURL } from "data/network/special-calls/";
// import {  } from "data/network/hooks/";
import {
  Button,
  Timestamp,
  Icon,
  ReactSelect,
  TextButton,
  ToggleSwitch,
  UncontrolledDatePicker,
} from "components/common/";
import ParentTypeBadge from "../parent-type-badge";
import GoalImage from "./GoalImage";
import GoalSteps from "./GoalSteps";
import GoalWeight from "./GoalWeight";
import DetailTextEdit from "./DetailTextField";
import { StoreContext as GeneralStoreContext } from "data/stores/GeneralStore";

import { SMARTGoals } from "components/modals";

import "./style.scss";

const MIN_STEPS_COUNT = 3;
const LEGACY_STEPS_ID = "legacy-steps-text";

function goalLengthToDate({
  planStartDate,
  goalLengthInYears = 1,
  customDate,
}: {
  customDate?: Date;
  goalLengthInYears?: number;
  planStartDate: string;
}) {
  if (customDate) {
    return customDate;
  }

  let startDateAsDate = new Date(planStartDate);

  if (!startDateAsDate.getFullYear?.() || +startDateAsDate < 0) {
    startDateAsDate = new Date();
  }

  startDateAsDate.setFullYear(
    startDateAsDate.getFullYear() + goalLengthInYears
  );

  return startDateAsDate;
}

export interface SaveData {
  goalTitle: string;
  goalDesc: string;
  newGoalSteps?: TytoData.PPF.Plan.Goals.GoalItem[];
  newTemplateImageKey?: Data.PPFTemplateImage["imageKey"];
  profileImageID?: number;
  updatedExistingGoalSteps?: TytoData.PPF.Plan.Goals.GoalItem[];
  uploadGUID?: string;
  targetDateFinal?: string;
  targetStatus?: keyof typeof TytoData.GoalStatus;
  goalDuration?: number;
  gsParentGoalID?: number;
  items?: string;
  // profileImage: Data.Asset;
  // steps: string;
}

interface EditGoalProps {
  className?: string;
  creating: boolean;
  errorMsg?: string;
  formType?: "create" | "edit";
  goalEndDate?: string;
  goalTitle?: string;
  goalDesc?: string;
  goalLength?: number;
  onSave: (data: SaveData) => void;
  plan: TytoData.PPF.Plan.Plan;
  profileImage?: Data.Asset;
  legacySteps?: string;
  steps?: TytoData.PPF.Plan.Goals.GoalItem[];
  templateImageKey?: string;
  parentGoalType: keyof typeof TytoData.PPFParentGoalName;
  updateCreating?: (isCreating: boolean) => void;
  updateGoalTitle?: (newGoalLength: string) => void;
  updateGoalDesc?: (newGoalLength: string) => void;
  goalTargetStatus?: keyof typeof TytoData.GoalStatus;
  parentGoal?: TytoData.PPF.Plan.Goals.ParentGoal;
  onCancel: () => void;
  goalDuration?: number;
  goalCreatedDate?: string;
  parentGoals?: TytoData.PPF.Plan.Goals.ParentGoal[];
}

function createTempStep(
  idxOffset: number,
  idx: number,
  data?: Partial<Pick<TytoData.PPF.Plan.Goals.GoalItem, "description" | "seq" | "itemWeight" | "status">>
): TytoData.PPF.Plan.Goals.GoalItem {
  return {
    description: "",
    seq: idxOffset + idx + 1,
    itemWeight: 0,
    status: "ocNOTSTARTED",
    ...(data ?? {}),
    gsGoalItemKey: createPseudoRandomNumber(10),
  };
}

function getUpdatedExistingGoalSteps({
  savedSteps,
  stepsFromState,
}: {
  savedSteps?: TytoData.PPF.Plan.Goals.GoalItem[];
  stepsFromState?: TytoData.PPF.Plan.Goals.GoalItem[];
}) {
  if (!savedSteps?.length || !stepsFromState?.length) {
    return [];
  }

  const keyedSavedSteps = _.keyBy(savedSteps, "gsGoalItemKey");

  return stepsFromState.filter((step) => {
    const keyedRef = keyedSavedSteps[step.gsGoalItemKey];
    return (
      keyedRef.status !== step.status ||
      keyedRef.description !== step.description ||
      keyedRef.seq !== step.seq
    );
  });
}

async function uploadThenSave({
  imageBlobOrFile,
  onSave,
  saveData,
}: {
  imageBlobOrFile: Blob | File | null;
  onSave: EditGoalProps["onSave"];
  saveData: SaveData;
}) {
  try {
    let uploadGUID = undefined;

    if (imageBlobOrFile) {
      const uploadURL = await getUploadURL();

      if (uploadURL) {
        const uploadResp = await API.Upload.post({
          endpointURL: uploadURL,
          files: [imageBlobOrFile],
        });

        uploadGUID = uploadResp.uploadFiles[0].fileUploadKey;
      }
    }

    onSave(uploadGUID ? { ...saveData, uploadGUID } : saveData);
  } catch (err) {
    onSave(saveData);
  }
}

const EditGoal = (props: EditGoalProps) => {
  let GeneralStore = React.useContext(GeneralStoreContext);
  const [hasAutoGenerated, updateHasAutoGenerated] = React.useState(false);
  const isMobile = _.get(GeneralStore, "state.isMobile", false);
  const [goalTitle, updateGoalTitle] = React.useState(props.goalTitle ?? "");
  const [goalDesc, updateGoalDesc] = React.useState(props.goalDesc ?? "");
  const [legacySteps, updateLegacySteps] = React.useState(props.legacySteps ?? "");
  const [removeExistingImage, updateRemoveExistingImage] =
    React.useState(false);
  const [newGoalImage, updateNewGoalImage] = React.useState<Blob | File | null>(
    null
  );
  const [listShowing, updateListShowing] = React.useState<
    "type" | "status" | "year" | null
  >(null);
  const [targetStatus, updateTargetStatus] = React.useState(
    props.goalTargetStatus
  );
  const [goalType, updateGoalType] = React.useState(props.parentGoalType);
  const [parentGoalID, updateParentGoalID] = React.useState(
    props.parentGoal?.gsParentGoalID
  );
  const [goalDuration, updateGoalDuration] = React.useState(() => {
    if (props.goalDuration && props.goalDuration > 0) {
      return `${props.goalDuration} year${props.goalDuration > 1 ? "s" : ""}`;
    }
  });
  const [checkToggle, updateCheckToggle] = React.useState(() => {
    if (
      props.goalEndDate &&
      props.goalCreatedDate &&
      props.goalDuration !== -1 &&
      props.goalDuration
    ) {
      var orgDate = new Date(props.goalEndDate);
      var dateFromCreation = new Date(props.goalCreatedDate);
      var yearDifference = dateFromCreation.getFullYear() + props.goalDuration;
      dateFromCreation.setFullYear(yearDifference);
      if (
        orgDate.getFullYear() === dateFromCreation.getFullYear() &&
        orgDate.getMonth() === dateFromCreation.getMonth() &&
        orgDate.getDate() === dateFromCreation.getDate()
      ) {
        return false;
      }
    }
    return true;
  });
  const [newGoalTemplateImage, updateNewGoalTemplateImage] =
    React.useState<Data.PPFTemplateImage | null>(null);
  const [existingSteps, updateExistingSteps] = React.useState(() => {
    return _.sortBy(props.steps ?? [], ["seq"], ["asc"]);
  });
  const [newSteps, updateNewSteps] = React.useState(() => {
    if (existingSteps.length && existingSteps.length >= 3) {
      return [];
      // return existingSteps;
    }

    const neededAdditionalStepsCount =
      MIN_STEPS_COUNT - (existingSteps?.length ?? 0);
    const supplementarySteps = Array.from({
      length: neededAdditionalStepsCount,
    }).map((__, curIdx) => createTempStep(existingSteps?.length ?? 0, curIdx));

    return [...(supplementarySteps ?? [])];
  });

  const [goalStepLength, updateGoalStepLength] = React.useState(
    newSteps.filter((step) => step.status !== "ocDISABLED").length +
      existingSteps.filter((step) => step.status !== "ocDISABLED").length
  );

  const [goalLengthAsDate, updateGoalLengthAsDate] = React.useState(() => {
    return props.goalEndDate ? new Date(props.goalEndDate) : new Date();
  });

  const canSave = !props.creating && goalTitle && goalDesc;

  React.useEffect(() => {
    var newParentGoalID = props.parentGoals?.filter(
      (goal) => goal.name.toLowerCase() === goalType
    );
    if (newParentGoalID) {
      updateParentGoalID(newParentGoalID[0].gsGoalID);
    }
  }, [goalType]);

  React.useEffect(() => {
    updateGoalStepLength(
      newSteps.filter((step) => step.status !== "ocDISABLED").length +
        existingSteps.filter((step) => step.status !== "ocDISABLED").length
    );
  }, [existingSteps, newSteps]);

  let btnText = props.formType === "edit" ? "UPDATE" : "FINISH";

  if (props.creating) {
    btnText = props.formType === "edit" ? "UPDATING..." : "CREATING...";
  }
  return (
    <div
      className={cx("cg-editgoal-cont", props.className)}
      onSubmit={(e) => {
        e.preventDefault?.();
      }}
    >
      <div className="plan-editgoal-top-section">
        <div className="plan-editgoal-image-wrapper">
          <GoalImage
            className="plan-editgoal-image"
            updating={props.creating}
            newGoalImage={newGoalImage}
            newGoalTemplateImage={newGoalTemplateImage}
            profileImage={removeExistingImage ? undefined : props.profileImage}
            storedGoalTemplateImageKey={
              removeExistingImage ? undefined : props.templateImageKey
            }
            theme={props.parentGoalType}
            updateNewGoalImage={updateNewGoalImage}
            updateNewGoalTemplateImage={updateNewGoalTemplateImage}
            updateRemoveExistingImage={updateRemoveExistingImage}
          />
        </div>
        <div className="plan-editgoal-inputs">
          <EditGoalSection title="Title" required="*">
            <DetailTextEdit
              className="plan-editgoal-section-input plan-editgoal-section-title-input title-font3"
              containerClassName="plan-editgoal-section-title-input"
              creating={props.creating}
              isMobile={isMobile}
              name="Goal Title"
              placeholder={"Give your goal a title"}
              updateValue={(newVal) => {
                updateGoalTitle(newVal);
                props.updateGoalTitle?.(newVal);
              }}
              value={goalTitle}
            />
          </EditGoalSection>

          <EditGoalSection
            title="Description"
            hasSMARTGoalIcon={true}
            isMobile={isMobile}
            required="*"
            className="plan-editgoal-section-desc"
          >
            <DetailTextEdit
              className="plan-editgoal-section-input-desc"
              containerClassName="plan-editgoal-section-desc-input title-font2"
              creating={props.creating}
              isMobile={isMobile}
              name="Goal Description"
              placeholder="Say more about this goal"
              updateValue={(newVal) => {
                updateGoalDesc(newVal);
                props.updateGoalDesc?.(newVal);
              }}
              value={goalDesc}
            />
          </EditGoalSection>
          <div className="plan-editgoal-inputs-dropdowns">
            <EditGoalSection
              title="Goal Type"
              className="plan-editgoal-inputs-dropdowns-connected"
            >
              <ReactSelect
                className="plan-editgoal-inputs-dropdown title-font3"
                classNamePrefix="plan-editgoal-inputs-dropdown"
                labels={["Personal", "Professional", "Financial"]}
                values={["personal", "professional", "financial"]}
                placeHolder={goalType}
                passBack={(value) => {
                  updateGoalType(value.value);
                }}
                onMenuOpen={() => {
                  updateListShowing("type");
                }}
                onMenuClose={() => {
                  updateListShowing(null);
                }}
              />
            </EditGoalSection>
            <EditGoalSection
              title="Goal Duration"
              className="plan-editgoal-inputs-dropdowns-connected"
            >
              <ReactSelect
                className="plan-editgoal-inputs-dropdown title-font3"
                classNamePrefix="plan-editgoal-inputs-dropdown"
                labels={["1 Year", "3 Years", "5 Years"]}
                values={["1 Year", "3 Years", "5 Years"]}
                passBack={(value) => {
                  updateGoalDuration(value.value);
                }}
                placeHolder={goalDuration}
                onMenuOpen={() => {
                  updateListShowing("year");
                }}
                onMenuClose={() => {
                  updateListShowing(null);
                }}
              />
            </EditGoalSection>
            <EditGoalSection
              title="This goal is"
              className="plan-editgoal-inputs-dropdowns-connected"
            >
              <ReactSelect
                className="plan-editgoal-inputs-dropdown title-font3"
                classNamePrefix="plan-editgoal-inputs-dropdown"
                labels={["😰 At Risk", "👍 On Track", "✅ Completed"]}
                placeHolder={
                  targetStatus ? getFriendlyStatus(targetStatus) : ""
                }
                values={["ocATRISK", "ocNOTSTARTED", "ocCOMPLETE"]}
                passBack={(value) => {
                  updateTargetStatus(value.value);
                }}
                onMenuOpen={() => {
                  updateListShowing("status");
                }}
                onMenuClose={() => {
                  updateListShowing(null);
                }}
              />
            </EditGoalSection>
          </div>
          <div className="plan-editgoal-inputs-dueDate">
            <h3 className="plan-editgoal-section-title title-font2">
              Due Date
            </h3>
            <ToggleSwitch
              className="plan-editgoal-inputs-dueDate-toggle"
              id="dueDateToggle"
              checked={checkToggle}
              onChange={(checked) => {
                updateCheckToggle(checked);
              }}
            />
          </div>
          <UncontrolledDatePicker
            disabled={!checkToggle}
            className="plan-editgoal-input-dueDate-picker font-family"
            onChange={(newDate) => {
              if (newDate) updateGoalLengthAsDate(newDate);
            }}
            date={goalLengthAsDate}
            type="date"
          />
        </div>
      </div>
      {(existingSteps.length > 0 || newSteps.length > 0) && (
        <GoalWeight
          isMobile={isMobile}
          goalSteps={
            existingSteps.length > 0
              ? newSteps.length > 0
                ? existingSteps
                    .concat(newSteps)
                    .filter((step) => step.status !== "ocDISABLED")
                : existingSteps.filter((step) => step.status !== "ocDISABLED")
              : newSteps.filter((step) => step.status !== "ocDISABLED")
          }
          updateGoalSteps={(goalSteps) => {
            var tempExistingSteps = [...existingSteps];
            var tempNewSteps = [...newSteps];
            goalSteps.forEach((step) => {
              var existingStepIndex = existingSteps.findIndex(
                (innerStep) => innerStep.gsGoalItemKey === step.gsGoalItemKey
              );
              if (existingStepIndex > -1) {
                tempExistingSteps[existingStepIndex].itemWeight =
                  step.itemWeight;
                updateExistingSteps(tempExistingSteps);
              } else {
                var newStepIndex = newSteps.findIndex(
                  (innerStep) => innerStep.gsGoalItemKey === step.gsGoalItemKey
                );
                if (newStepIndex > -1) {
                  tempNewSteps[newStepIndex].itemWeight = step.itemWeight;
                  updateNewSteps(tempNewSteps);
                }
              }
            });
          }}
        />
      )}

      <div className="plan-editgoal-image-and-steps-wrapper title-font2">
        <EditGoalSection title="TASK">
          <p className="plan-editgoal-steps-desc title-font3">
            PLEASE LIST OUT ALL TASKS THAT YOU NEED TO ACCOMPLISH TO ACHIEVE
            THIS GOAL
          </p>
          {legacySteps && (
            <div className="cg-goalstories-currentgoal-legacy-steps">
              <div className="cg-goalstories-currentgoal-legacy-steps-title-btns-cont">
                <h3 className="cg-goalstories-currentgoal-legacy-steps-title">Legacy Tasks.</h3>
                <div className="cg-goalstories-currentgoal-legacy-steps-btns-right-cont">
                  <TextButton
                    className="cg-goalstories-currentgoal-legacy-steps-btns-autogen-btn"
                    disabled={hasAutoGenerated}
                    onClick={() => {
                      const itemsAsText = getDOMChildrenContentAsTasks();

                      if (!itemsAsText?.length) return;

                      const asItems = itemsAsText.map((item, curIdx) => createTempStep(existingSteps?.length ?? 0, newSteps.length + curIdx, { description: item }))
                      updateHasAutoGenerated(true);

                      updateNewSteps([
                        ...newSteps,
                        ...asItems
                      ]);
                    }}
                    value={hasAutoGenerated ? "Generated! (See Below)" : "Generate Tasks"}
                  />

                  <Icon
                    isButton={true}
                    onClick={() => {
                      updateLegacySteps("");
                    }}
                    size={16}
                    icon="trash-alt"
                  />
                </div>
              </div>
              <p className="cg-goalstories-currentgoal-legacy-steps-msg">Select “Generate Tasks” to create tasks below using this text from your legacy PPF Steps to Achieve.</p>
              <div className="cg-goalstories-currentgoal-legacy-steps-renderbox" id={LEGACY_STEPS_ID} dangerouslySetInnerHTML={{ __html: legacySteps }}></div>
            </div>
          )}
          <GoalSteps
            isMobile={isMobile}
            createNewGoalStep={() => {
              updateNewSteps([
                ...newSteps,
                createTempStep(existingSteps?.length ?? 0, newSteps.length),
              ]);
            }}
            updating={props.creating}
            parentGoalType={props.parentGoalType}
            goalSteps={existingSteps}
            unsavedGoalSteps={newSteps}
            updateGoalSteps={(newGoalSteps) => {
              updateExistingSteps(newGoalSteps);
            }}
            updateUnsavedGoalSteps={(newGoalSteps) => {
              console.log("newGoalSteps: ", newGoalSteps);
              updateNewSteps(newGoalSteps);
            }}
          />
          <div className="plan-editgoal-add-step-section title-font2">
            <Icon
              className="plan-editgoal-add-step"
              isButton={true}
              buttonProps={{
                type: "button",
                disabled: goalStepLength > 99,
              }}
              onClick={() => {
                updateNewSteps([
                  ...newSteps,
                  createTempStep(existingSteps?.length ?? 0, newSteps.length),
                ]);
              }}
              icon="plus"
              size={isMobile ? 10 : 15}
              title={
                goalStepLength > 99
                  ? "A goal cannot have more than 100 steps"
                  : "Add a step"
              }
            />
            <p>Add task...</p>
          </div>
        </EditGoalSection>
      </div>

      {props.errorMsg && (
        <p className="plan-editgoal-save-error-msg">{props.errorMsg}</p>
      )}
      <div className="plan-editgoal-buttons title-font2">
        <Button
          className="plan-editgoal-cancel-btn"
          theme="white-ghost"
          value="CANCEL"
          onClick={props.onCancel}
        />
        <Button
          className="plan-editgoal-save-btn"
          hoverBGSize="hidden"
          disabled={!canSave}
          onClick={() => {
            if (!canSave) {
              return;
            }

            const unsavedSteps = (newSteps ?? []).filter(
              (item) => !!item.description && item.status !== "ocDISABLED"
            );

            props.updateCreating?.(true);

            let imgData = {};

            if (
              removeExistingImage &&
              !newGoalTemplateImage?.imageKey &&
              !newGoalImage
            ) {
              imgData = {
                profileImageID: 0,
                newTemplateImageKey: "",
              };
            } else {
              imgData = {
                newTemplateImageKey: newGoalTemplateImage?.imageKey,
              };
            }
            uploadThenSave({
              imageBlobOrFile: newGoalImage,
              onSave: props.onSave,
              saveData: {
                goalDuration: goalDuration
                  ? parseInt(goalDuration.split(" ")[0])
                  : -1,
                targetStatus: targetStatus,
                goalTitle,
                goalDesc,
                gsParentGoalID: parentGoalID,
                updatedExistingGoalSteps: existingSteps,
                newGoalSteps: unsavedSteps,
                items: legacySteps,
                targetDateFinal: checkToggle
                  ? goalLengthAsDate.toISOString()
                  : undefined,
                ...imgData,
              },
            });
          }}
          theme={props.parentGoalType}
          value={btnText}
        />
      </div>
    </div>
  );
};

function getFriendlyStatus(goalStatus: TytoData.PPF.Plan.Plan["targetStatus"]) {
  switch (goalStatus) {
    case "ocATRISK":
    case "ocSTALLED":
      return "😰 At Risk";
    case "ocCOMPLETE":
      return "✅ Completed";
    case "ocINPROGRESS":
    case "ocNOTSTARTED":
    default:
      return "👍 On Track";
  }
}

interface EditGoalTopProps extends Pick<EditGoalProps, "parentGoalType"> {
  goalLengths: number[];
  goalEndDate?: string;
  plan?: EditGoalProps["plan"];
  passBackNewDate?: (newDate: Date) => void;
}

const EditGoalTop = (props: EditGoalTopProps) => {
  const [newDate, updateNewDate] = React.useState("");

  return (
    <section className="plan-editgoal-topbar-years">
      <ParentTypeBadge parentType={props.parentGoalType} />
      {!props.goalEndDate && !!props.goalLengths.length && (
        <span className="plan-editgoal-topbar-years-year">
          {props.goalLengths[0]} Year
        </span>
      )}

      {!!props.goalEndDate && props.plan?.startTime && (
        <div>
          <Timestamp
            className="plan-editgoal-topbar-years-year"
            date={props.goalEndDate}
            startCompareDate={props.plan?.startTime}
            titleClarificationText="Goal Due"
            updateable={true}
            updateDateCallBack={(date) => {
              if (props.passBackNewDate) {
                updateNewDate(date.toISOString());
                props.passBackNewDate(date);
              }
            }}
          />
        </div>
      )}
    </section>
  );
};

interface EditGoalSectionProps {
  title: string;
  children?: any;
  hasSMARTGoalIcon?: boolean;
  required?: string;
  className?: string;
  isMobile?: boolean;
}

const EditGoalSection = (props: EditGoalSectionProps) => {
  const [isSMARTActive, updateIsSMARTActive] = React.useState(false);
  return (
    <section className={cx("plan-editgoal-section", props.className)}>
      <h3 className="plan-editgoal-section-title title-font2">
        {props.title}
        {props.hasSMARTGoalIcon && (
          <span className="plan-editgoal-section-SMART title-font2">
            <Icon
              className="plan-editgoal-section-SMART-icon"
              isButton={true}
              icon="question-circle"
              size={props.isMobile ? 12 : 16}
              onClick={() => {
                updateIsSMARTActive(true);
              }}
              buttonProps={{ type: "button" }}
            />
            SMART GOALS
          </span>
        )}
        {/* <span className="plan-editgoal-section-required">
          {!!props.required ? props.required : ""}
        </span> */}
      </h3>
      {props.children ?? null}
      {isSMARTActive && (
        <SMARTGoals onDismiss={() => updateIsSMARTActive(false)} />
      )}
    </section>
  );
};

function getDOMChildrenContentAsTasks() {
  const domItems = document.getElementById(LEGACY_STEPS_ID);
  debugger;
  if (!domItems || !domItems.childNodes?.length) {
    window.alert("Unable to convert existing Steps.");
    return;
  }
  
  const itemsAsText = Array.from(domItems.childNodes).reduce((items: string[], childNode) => {
    switch (_.get(childNode, "tagName", "p").toLowerCase()) {
      case "ol":
      case "ul":
        const asArr = Array.from(childNode.childNodes);

        if (!asArr.length) {
          break;
        }

        asArr.forEach(child => {
          items.push(_.get(child, "innerText"));
        });

        break;
      default:
        items.push(_.get(childNode, "innerText"));
        break;
    }

    return items;
  }, [])
  
  if (!itemsAsText?.length) {
    window.alert("Unable to convert existing Steps.");
    return;
  }

  return itemsAsText;
}

export default EditGoal;
