import * as _ from "lodash";
import {
  DEFAULT_APP_BRAND,
  IMAGE_BASE_URL,
  MOBILE_WIDTH_BREAKPOINT,
  SET_STATUSES,
  START_CONFIG_PATH,
  PPF_LIBRARY_CONFIG_PATH,
  URL_ORIGIN,
  PPF_MEMBER_FALSE_DATA,
  PPF_ROLES_BY_NAME,
} from "../constants";
import TytoCalls from "../network/tyto/";
import { usePPFPlan, usePPFPlanMembers } from "../network/hooks/";
import * as Storage from "../storage/";

const { SessionHandling } = Storage;

export function isMobileSize() {
  return window.innerWidth <= MOBILE_WIDTH_BREAKPOINT;
}

export function mutatePDFImagePathToBeReadable(
  encoding: Data.Encoding,
  targetPage: number = 1
) {
  if (!encoding) {
    return undefined;
  } else if (!encoding.pathURL) {
    return encoding;
  }

  if (/\{(0){4}\}/i.test(encoding.pathURL)) {
    const targetPageAsStr = `${targetPage}`;
    const pageNumAsString = `${"0".repeat(4 - targetPageAsStr.length)}`;
    const mutatedPathURL = encoding.pathURL.replace("{0000}", pageNumAsString);
    encoding.pathURL = mutatedPathURL;
  } else if (/\{0\}/i.test(encoding.pathURL)) {
    const mutatedPathURL = encoding.pathURL.replace("{0}", `${targetPage}`);
    encoding.pathURL = mutatedPathURL;
  }

  return encoding;
}

export function getPublicImagePath(imageName: string) {
  if (!imageName) {
    return "";
  }

  return `${process.env.PUBLIC_URL}/${imageName}`;
}

export function filterNonEnabledEncodings(encodings?: Data.Encoding[]) {
  if (!encodings || !encodings.length) {
    return [];
  }

  return encodings.filter((enc) => enc.activeStatus === "ocENABLED");
}

export function getEncoding(
  encodings: Data.Encoding[],
  targetEncoding: keyof typeof TytoData.EncodingType
) {
  if (!encodings || !encodings.length) {
    return undefined;
  }

  const keyedEncodings = _.keyBy(
    filterNonEnabledEncodings(encodings),
    "encodingType"
  );

  // * Treating ocTHUMBNAIL as a special case since in the case of PDFs there are other potentially usable encodings to use in place of a thumbnail
  if (targetEncoding === "ocTHUMBNAIL") {
    if (keyedEncodings[targetEncoding]) {
      return keyedEncodings[targetEncoding];
    }

    const backupEnc =
      keyedEncodings["ocPDFIMAGES/imgtmp"] ||
      keyedEncodings["ocPDFJSON/jspageimg"] ||
      keyedEncodings["ocPDFIMAGES/thumbtmp"] ||
      keyedEncodings["ocPDFJSON/jspageimg"];

    if (backupEnc) {
      return mutatePDFImagePathToBeReadable(backupEnc);
    }
  }

  return (
    keyedEncodings[targetEncoding] ||
    keyedEncodings["ocDEFAULT"] ||
    keyedEncodings["ocLARGE"] ||
    keyedEncodings["ocMEDIUM"] ||
    keyedEncodings["ocSMALL"] ||
    keyedEncodings["ocTHUMBNAIL"] ||
    keyedEncodings["ocORIGINAL"]
  );
}

export function getEncodingExplicitly(
  encodings: Data.Encoding[],
  targetEncoding: keyof typeof TytoData.EncodingType
) {
  if (!encodings || !encodings.length) {
    return undefined;
  }

  const keyedEncodings = _.keyBy(
    filterNonEnabledEncodings(encodings),
    "encodingType"
  );

  return keyedEncodings[targetEncoding];
}

export function getScopedEncodings({
  encodings,
  // targetEncoding = "ocDEFAULT",
  mimeTypeScope,
}: {
  encodings: Data.Encoding[];
  // targetEncoding?: keyof typeof TytoData.EncodingType;
  mimeTypeScope?: "video" | "image" | "text" | "pdf" | "application";
}) {
  if (!encodings || !encodings.length) {
    return [];
  }

  const scopedEncodings = !!mimeTypeScope
    ? encodings.filter((enc) => {
        const regExp = new RegExp(_.escapeRegExp(mimeTypeScope), "i");

        return regExp.test(enc.mimeType);
      })
    : encodings;

  return scopedEncodings;
}

export function getScopedEncoding({
  encodings,
  targetEncoding = "ocDEFAULT",
  mimeTypeScope,
}: {
  encodings: Data.Encoding[];
  targetEncoding?: keyof typeof TytoData.EncodingType;
  mimeTypeScope?:
    | "video"
    | "image"
    | "text"
    | "pdf"
    | "application"
    | "octet-stream";
}) {
  const enabledEncodings = (encodings || []).filter(
    (enc) => enc.activeStatus === "ocENABLED"
  );

  if (!enabledEncodings || !enabledEncodings.length) {
    return undefined;
  }

  const scopedEncodings = !!mimeTypeScope
    ? enabledEncodings.filter((enc) => {
        const regExp = new RegExp(_.escapeRegExp(mimeTypeScope), "i");

        return regExp.test(enc.mimeType);
      })
    : enabledEncodings;

  if (!scopedEncodings.length) {
    return undefined;
  }

  return getEncoding(scopedEncodings, targetEncoding);
}

export function getEncodingViewAssetPath({
  encoding,
  includeSessionKey,
}: {
  encoding?: Data.Encoding;
  includeSessionKey?: boolean;
}) {
  if (!encoding || !encoding.pathURL) {
    return "";
  }

  const paramPrefix = /\?(\w)+=/i.test(encoding.pathURL) ? "&" : "?";

  return `${IMAGE_BASE_URL}${encoding.pathURL}${
    includeSessionKey
      ? `${paramPrefix}sessionKey=${SessionHandling.getActiveSessionKey()}`
      : ""
  }`;
}

export function getViewAssetPath({
  encodings,
  targetEncoding = "ocDEFAULT",
  mimeTypeScope,
  includeSessionKey,
}: {
  encodings: Data.Encoding[];
  targetEncoding?: keyof typeof TytoData.EncodingType;
  mimeTypeScope?: "video" | "image" | "text" | "pdf" | "application";
  includeSessionKey?: boolean;
}) {
  // if (!encodings || !encodings.length) {
  //   return "";
  // }

  // const scopedEncodings = !!mimeTypeScope
  //   ? encodings.filter((enc) => {
  //       const regExp = new RegExp(_.escapeRegExp(mimeTypeScope), "i");

  //       return regExp.test(enc.mimeType);
  //     })
  //   : encodings;

  // if (!scopedEncodings.length) {
  //   return "";
  // }

  // const targetEnc = getEncoding(scopedEncodings, targetEncoding);
  const targetEnc = getScopedEncoding({
    encodings,
    targetEncoding,
    mimeTypeScope,
  });

  if (!targetEnc) {
    return "";
  }

  const paramPrefix = /\?(\w)+=/i.test(targetEnc.pathURL) ? "&" : "?";

  const isFullyQualifiedURL = /^(http(s)?\:\/\/)?(\w|\d)+\./i.test(
    targetEnc.pathURL
  );
  // const isRelPath = !/^\//i.test(targetEnc.pathURL);

  return `${isFullyQualifiedURL ? "" : IMAGE_BASE_URL}${targetEnc.pathURL}${
    includeSessionKey
      ? `${paramPrefix}sessionKey=${SessionHandling.getActiveSessionKey()}`
      : ""
  }`;
}

// export function getTrainingItemPath({
//   id,
//   type,
// }: {
//   id: string | number;
//   type: keyof typeof TytoData.CatalogTakeableItem;
// }) {
//   let base = INTERFACE_PATHS.COURSE;
//   let subPath = "";

//   if (type === "ocBLOCK") {
//     base = INTERFACE_PATHS.COURSE;
//     subPath = `/${id}`;
//   } else if (type === "ocDEVPLAN") {
//     base = INTERFACE_PATHS.PLAN;
//     subPath = `/${id}`;
//   }

//   return `${base}${subPath}`;
// }

export function makePathURLQualified(
  pathURL: string,
  opts?: {
    omitSessionKey?: boolean;
  }
) {
  const paramPrefix = /\?(\w)+=/i.test(pathURL) ? "&" : "?";

  return `${IMAGE_BASE_URL}${pathURL}${
    _.get(opts, "omitSessionKey", false)
      ? ""
      : `${paramPrefix}sessionKey=${SessionHandling.getActiveSessionKey()}`
  }`;
}

export function handleInvalidSession({
  AppStore,
  errorData,
}: {
  AppStore: StoreAPI.AppStoreProps;
  errorData: Data.TytoErrorObject;
}) {
  const errorSts = _.get(errorData, "sts", 0);

  if (errorSts === -299) {
    Storage.SessionHandling.clearActiveSession();

    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {},
        type: "LOGOUT_USER",
      });
    }
  }
}

export async function logoutUser({
  AppStore,
  onSuccess,
  onError,
  withPrompt,
}: {
  AppStore: StoreAPI.AppStoreProps;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
  withPrompt?: boolean;
}) {
  try {
    const confirmed = withPrompt
      ? window.confirm(`Sign out of ${DEFAULT_APP_BRAND}?`)
      : true;

    if (!confirmed) {
      return;
    }

    // * If we await a successful callback it seems to take upwards of 5 seconds sometimes and appears like nothing is happening.
    // TODO: update to await response and put meaningful UI locking then interface and making it clear the app is
    // TODO: in process of logging the user out
    TytoCalls.Session.Logout.post({}).catch((err) => {
      // * Even if the request fails, we still want to clear the data locally and redirect to login screen
    });

    const successfullyCleared = SessionHandling.clearActiveSession();

    if (!successfullyCleared) {
      onError("Could not clear session locally");
      return;
    }

    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {},
        type: "LOGOUT_USER",
      });
    }

    onSuccess();
  } catch (err: any) {
    onError(
      typeof err === "string" ? err : _.get(err, "error.msg", "Error Occurred")
    );
  }
}

export function makeLocalForageKey(
  key: string,
  ids?: Array<string | number | null>
) {
  const userID = SessionHandling.getUserIDOfActiveSession();

  if (!userID) {
    return "";
  }

  const keyCore = `${key}::${userID}`;

  if (!ids || !ids.length) {
    return keyCore;
  }

  return `${keyCore}::${ids.join("::")}`;
}

export function createUniqueDOMID({
  parentID,
  sectionID,
}: {
  parentID?: string | number;
  sectionID?: string | number;
}) {
  if (!parentID && !sectionID) {
    return undefined;
  }

  return `${parentID || 0}-${sectionID || 0}`;
}

const DEFAULT_RANDOM_STRING_LENGTH = 15;

export function createPseudoRandomNumber(length?: number) {
  const decimalPlaces = Math.pow(10, length ?? 10);

  return Math.floor(Math.random() * decimalPlaces);
}

export function createPseudoRandomString(length?: number) {
  return Array.from(
    { length: length || DEFAULT_RANDOM_STRING_LENGTH },
    (__, curIdx) => {
      const charCode = Math.round(Math.random() * 42) + 48;

      let str = String.fromCharCode(charCode);

      if (/\W/i.test(str)) {
        return "O";
      }

      const asLowerCase = Math.round(Math.random());

      return asLowerCase ? str.toLocaleLowerCase() : str;
    }
  ).join("");
}

interface SearchParamCommand {
  command: "delete" | "add" | "update";
  key: string;
  value?: string;
}

export function getUpdatedSearchParams(
  commands: SearchParamCommand[],
  locationAsString?: string
) {
  const searchParams = new URLSearchParams(
    locationAsString || window.location.search
  );

  // * [1] - If no commands found, return searchParams as is
  if (!commands || !commands.length) {
    return searchParams.toString();
  }

  // * [2] - Loop through commands and do their respective updates
  commands.forEach(({ command, key, value }) => {
    switch (command) {
      case "add":
      case "update":
        searchParams.set(key, `${value}`);
        return;
      case "delete":
        if (searchParams.has(key)) {
          searchParams.delete(key);
        }

        return;
      default:
        return;
    }
  });

  // * [3] - Return updated searchParams as string
  return searchParams.toString();
}

export function getSearchParamValue(searchParamKey: string) {
  if (!searchParamKey) {
    return null;
  }

  const url = new URL(window.location.href);

  if (!url) {
    return null;
  }

  return url.searchParams.get(searchParamKey);
}

export function getRedirectPathFromURL() {
  return getSearchParamValue("redirect");
}

export function setStatusHelpfulText({
  assetType,
  setStatus,
}: {
  assetType?: keyof typeof TytoData.AssetType;
  setStatus: number;
}) {
  let title = "";
  let desc = "";

  switch (setStatus) {
    case 1:
      return {
        setStatus,
        title: `Automatically Set`,
        desc: "System will automatically set this complete when appropriate.",
      };
    case 2:
      return {
        setStatus,
        title: `Mark Complete`,
        desc: "You must manually mark this complete when you have finished the step.",
      };
    case 3:
      title = `Complete on Launch`;
      desc = "";

      if (/ocphoto/i.test(`${assetType}`.toLocaleLowerCase())) {
        title = `When First Photo is Viewed`;
      }

      return {
        setStatus,
        title,
        desc,
      };
    case 4:
      return {
        setStatus,
        title: `Verified by 3rd Party`,
        desc: "A 3rd party will review the information and update your completion status appropriately.",
      };
    case 5:
      return {
        setStatus,
        title: `Complete on Upload`,
        desc: "After uploading your resource, this step will automatically be marked complete.",
      };
    case 6:
      return {
        setStatus,
        title: `Complete after Upload is Verfied`,
        desc: "After uploading your resource a 3rd part will verify the validty of your upload and update the completion status appropriately.",
      };
    case 7:
      title = `After Video Ends`;
      desc =
        "This step will automatically be marked complete after you have completed the resource.";

      if (/ocphoto/i.test(`${assetType}`.toLocaleLowerCase())) {
        title = `When Last Photo is Viewed`;
        desc = `Once the last Photo is viewed this step will be marked complete.`;
      }

      return {
        setStatus,
        title,
        desc,
      };
    // return `Complete at End`;
    case 8:
      return {
        setStatus,
        title: "",
        desc: "",
      };
    case 9:
      return {
        setStatus,
        title: "",
        desc: "",
      };
    case 10:
      return {
        setStatus,
        title: "",
        desc: "",
      };
    case 11:
      return {
        setStatus,
        title: "",
        desc: "",
      };
    default:
      return {
        setStatus,
        title: "Unkown",
        desc: "",
      };
  }
}

export function parseAssignmentInfo({
  masteryScore: mScore,
  setStatus,
  taskStatus,
}: {
  masteryScore?: number;
  setStatus: number;
  taskStatus: keyof typeof TytoData.CompleteStatus;
}): TytoData.AssignmentInfo {
  //   const { about, setStatus, taskStatus } = task;
  const masteryScore = mScore || 0;

  // const reviewers = this.isVerify
  //   ? Array.isArray(resp.assigned)
  //     ? resp.assigned.map(toBaseUser)
  //     : [toBaseUser(resp.assigned)]
  //   : this._getStudentReviewers(
  //       resp,
  //       this.isReviewerMentor,
  //       this.isReviewerChosen
  //     );

  // if (!isStudent && this._hasLibraryItem(resp)) {
  //   this.reviewerInstructionFiles = this._getLibraryItems(resp);
  // }

  const isReviewerChosen =
    [SET_STATUSES.VERIFY_CHOICE, SET_STATUSES.VERIFY_CHOICE_UPLOAD].indexOf(
      setStatus
    ) >= 0;
  const isReviewerMentor =
    [
      SET_STATUSES.VERFIED_BY_3RD_PARTY,
      SET_STATUSES.COMPLETE_AFTER_UPLOAD_IS_VERIFIED,
    ].indexOf(setStatus) >= 0;

  const isVerifyRequired =
    [
      SET_STATUSES.COMPLETE_AFTER_UPLOAD_IS_VERIFIED,
      SET_STATUSES.VERIFY_CHOICE_UPLOAD,
      SET_STATUSES.VERFIED_BY_3RD_PARTY,
      SET_STATUSES.UPLOAD_VERIFIED_LIST,
      SET_STATUSES.VERIFY_CHOICE,
      SET_STATUSES.VERIFY_LIST,
    ].indexOf(setStatus) >= 0;
  const isScoredComplete = masteryScore === 0;
  const isScoredPercent = masteryScore > 0 && isVerifyRequired;
  const isScoredPoints = false;
  const isScoredPassFail = masteryScore < 0;
  const isMarkComplete = typeof mScore === "undefined" || masteryScore === 0;
  const isUploadRequired =
    [
      SET_STATUSES.COMPLETE_ON_UPLOAD,
      SET_STATUSES.COMPLETE_AFTER_UPLOAD_IS_VERIFIED,
      SET_STATUSES.UPLOAD_VERIFIED_LIST,
      SET_STATUSES.VERIFY_CHOICE_UPLOAD,
    ].indexOf(setStatus) >= 0;
  const isCompleteAtLaunch = setStatus === SET_STATUSES.COMPLETE_AT_LAUNCH;
  const isCompleteAtEnd = setStatus === SET_STATUSES.AT_END_OF_CONTENT;
  const isRequestingVerification = taskStatus === "ocREQUESTVERIFY";

  return {
    isReviewerChosen,
    isReviewerMentor,
    isVerifyRequired,
    isScoredComplete,
    isScoredPercent,
    isScoredPoints,
    isScoredPassFail,
    isMarkComplete,
    isCompleteAtLaunch,
    isCompleteAtEnd,
    isRequestingVerification,
    isUploadRequired,
  };
}

export function getDomainStartConfigURL(domainID: number) {
  if (!domainID) {
    return undefined;
  }

  const path = `${START_CONFIG_PATH}`.replace(/\:domainid/i, `${domainID}`);

  return `${URL_ORIGIN}${path}`;
}

//* Used for PPF Library
export function getDomainConfigURL(domainID: number) {
  if (!domainID) {
    return undefined;
  }

  const path = `${PPF_LIBRARY_CONFIG_PATH}`.replace(
    /\:domainid/i,
    `${domainID}`
  );

  return `${URL_ORIGIN}${path}`;
}

export function userPrefersReducedMotion() {
  return window.matchMedia("(prefers-reduced-motion: reduce)")?.matches;
}

export function getScrollToBehavior(): ScrollBehavior | undefined {
  return userPrefersReducedMotion() ? "auto" : "smooth";
}

export function scrollElementIntoView(_elementRef: Element | HTMLElement) {
  const elementRef = _elementRef as HTMLElement;

  if (elementRef) {
    if (document.documentElement === document.scrollingElement) {
      const iThinkImInAParent = !!Storage.SES_STOR.get(
        Storage.keys.SITE_HAS_PARENT
      );
      const navHeight = iThinkImInAParent ? 0 : 70;

      let offsetParentOffsetAmount = 0;

      // * Account for Exam Questions where the Exam Section itself becomes the reference point for what the element is offset from
      if (
        elementRef.offsetParent &&
        elementRef.offsetParent !== document.scrollingElement &&
        elementRef.offsetParent !== document.body
      ) {
        offsetParentOffsetAmount =
          (elementRef.offsetParent as HTMLElement)?.offsetTop || 0;
      }

      // * The '- 70' is because scrollTo won't account for 'scroll-margin-top' like scrollIntoView does.
      // * NOTE: 70px from the above is because that's the Nav bar height plus some extra space. May need adjusted in the future
      document.scrollingElement.scrollTo({
        top: Math.max(
          (elementRef?.offsetTop || 0) - navHeight + offsetParentOffsetAmount,
          0
        ),
        behavior: getScrollToBehavior(),
      });
    } else {
      elementRef.scrollIntoView({ behavior: getScrollToBehavior() });
    }
  }
}

function createCourseParenstObject({
  blockIDs = [],
  currentObject,
  planIDs = [],
}: {
  blockIDs?: number[];
  currentObject?: StoreAPI.CourseParentsList;
  planIDs?: number[];
}) {
  const { blockIDs: curBlockIDs, planTaskIDs } = currentObject || {};

  const newBlockIDs = [
    ...Array.from(new Set([...(curBlockIDs || []), ...blockIDs])),
  ];

  const newPlanIDs = [
    ...Array.from(new Set([...(planTaskIDs || []), ...planIDs])),
  ];

  return {
    blockIDs: newBlockIDs,
    planTaskIDs: newPlanIDs,
  } as StoreAPI.CourseParentsList;
}

export function createCourseParentsMapsFromPlanTasks(
  tasks: TytoData.Tasks.Task[]
) {
  if (!tasks || !tasks.length) {
    return undefined;
  }

  const courses = tasks.reduce(
    (accum: { [x: string]: StoreAPI.CourseParentsList }, task) => {
      if (task.aboutType === "ocBLOCK") {
        accum[`${task.aboutID}`] = createCourseParenstObject({
          planIDs: [task.rootTaskID],
        });
      }

      return accum;
    },
    {}
  );

  return Object.entries(courses);
}

export function createNewCoursesMapsFromAllTaskStructures(
  allTasks: Array<TytoData.Tasks.Task[]>
) {
  if (!allTasks || !allTasks.length) {
    return undefined;
  }

  const allCourses = allTasks.reduce(
    (accum: { [x: string]: StoreAPI.CourseParentsList }, tasks) => {
      if (tasks && tasks.length) {
        tasks.forEach((task) => {
          if (task.aboutType === "ocBLOCK") {
            accum[`${task.aboutID}`] = createCourseParenstObject({
              planIDs: [task.rootTaskID],
              currentObject: accum[`${task.aboutID}`],
            });
          }
        });
      }

      return accum;
    },
    {}
  );

  return Object.entries(allCourses);
}

export function timeInMS({
  timeQuantity,
  timeType,
}: {
  timeQuantity: number;
  timeType: "ms" | "s" | "min" | "hours" | "days" | "tomorrow-morning";
}) {
  if (timeType === "tomorrow-morning") {
    const rightNow = new Date();

    const hourLeftInDay = 23 - rightNow.getHours();
    const minutesLeftInHour = 60 - rightNow.getMinutes();

    const fiveAMInMins = 60 * 5;
    const minsTil5AmTomorrow =
      fiveAMInMins + hourLeftInDay * 60 + minutesLeftInHour;

    return minutesLeftInHour * 60 * 1000;
  }

  switch (timeType) {
    case "days":
      return timeQuantity * 1000 * 60 * 60 * 24;
    case "hours":
      return timeQuantity * 1000 * 60 * 60;
    case "min":
      return timeQuantity * 1000 * 60;
    case "s":
      return timeQuantity * 1000;
    case "ms":
    default:
      return timeQuantity;
  }
}

function getCoursePlanContextStoreKey(courseID: number | string) {
  const curSessKey = Storage.SES_STOR.get(Storage.keys.ACTIVE_SESSION_KEY);
  const curSessionData = Storage.SessionHandling.getSessionData(
    `${curSessKey ?? ""}`
  );

  const loggedInUserID = curSessionData?.userID;

  return `${loggedInUserID}-${Storage.keys.COURSE_PLAN_CONTEXT}-${courseID}`;
}

export function getCoursePlanContext(courseID?: number | string) {
  if (!courseID) {
    return undefined;
  }

  return Storage.SES_STOR.get(getCoursePlanContextStoreKey(courseID));
}

export function getPrimaryTrainingFromTrainingQuery(
  trainingQuery: SITE.QueryProp<Endpoints.Responses.Training.Get>
) {
  return (
    trainingQuery?.data?.training ??
    trainingQuery?.storedValueQuery?.data?.training
  );
}

export function getSubBlocksFromTrainingQuery(
  trainingQuery: SITE.QueryProp<Endpoints.Responses.Training.Get>
) {
  return (
    trainingQuery?.data?.subBlocks ??
    trainingQuery?.storedValueQuery?.data?.subBlocks
  );
}

export function findCourseFromPrimaryTraining({
  curriculumID,
  primaryTraining,
}: {
  curriculumID?: number;
  primaryTraining: Array<TytoData.Training.Enrollment | TytoData.Training.Task>;
}) {
  if (!curriculumID || !primaryTraining?.length) {
    return undefined;
  }

  return primaryTraining.find(
    ({ curriculumID: curID }) => curID === curriculumID
  );
}

export function findCourseFromSubBlocks({
  curriculumID,
  subBlocks,
}: {
  curriculumID?: number;
  subBlocks: Array<TytoData.Training.SubBlock>;
}) {
  if (!curriculumID || !subBlocks?.length) {
    return undefined;
  }

  return subBlocks.find(({ curriculumID: curID }) => curID === curriculumID);
}

export function findCourseInTraining({
  curriculumID,
  primaryTraining,
  subBlocks,
}: {
  curriculumID?: number;
  primaryTraining: Array<TytoData.Training.Enrollment | TytoData.Training.Task>;
  subBlocks: Array<TytoData.Training.SubBlock>;
}):
  | TytoData.Training.SubBlock
  | TytoData.Training.Enrollment
  | TytoData.Training.Task
  | undefined {
  if (!curriculumID || (!primaryTraining?.length && !subBlocks?.length)) {
    return undefined;
  }

  const matchingPrimaryTraining = findCourseFromPrimaryTraining({
    curriculumID,
    primaryTraining,
  });

  if (matchingPrimaryTraining) {
    return matchingPrimaryTraining;
  }

  return findCourseFromSubBlocks({ curriculumID, subBlocks });
}

export function findIfCourseIsPrimaryOrSubBlock({
  curriculumID,
  primaryTraining,
  subBlocks,
}: {
  curriculumID?: number;
  primaryTraining: Array<TytoData.Training.Enrollment | TytoData.Training.Task>;
  subBlocks: Array<TytoData.Training.SubBlock>;
}) {
  // let isPrimaryTraining = false;
  // let isSubBlock = false;

  const isPrimaryTraining = !!findCourseFromPrimaryTraining({
    curriculumID,
    primaryTraining,
  });

  // * We will implicitly decide if it is not in 'training' it must be in 'subBlocks'.
  // * What *should* happen even if it isn't is that it simply doesn't find it there when it tries to off of this; no errors?
  // if (!isPrimaryTraining) {
  //   isSubBlock = !!findCourseFromSubBlocks({ curriculumID, subBlocks });
  // }

  return {
    isPrimaryTraining,
    isSubBlock: !isPrimaryTraining,
  };
}

export function findParentTraining({
  primaryTraining,
  targetParentID,
}: {
  primaryTraining: Array<TytoData.Training.Enrollment | TytoData.Training.Task>;
  targetParentID?: number | string;
}) {
  if (!targetParentID) {
    return undefined;
  }

  const matchingBlock = primaryTraining.find((courseOrPlan) => {
    if (courseOrPlan.curriculumType === "ocDEVPLAN") {
      return (
        `${(courseOrPlan as TytoData.Training.Task).taskID ?? 0}` ===
        `${targetParentID ?? ""}`
      );
    }

    return (
      `${(courseOrPlan as TytoData.Training.Enrollment).curriculumID ?? 0}` ===
      `${targetParentID ?? ""}`
    );
  });

  return matchingBlock;
}

export function setCoursePlanContext(
  courseID: number | string,
  parentID: number | string
) {
  console.log(
    `%c Setting plan context of courseID: ${courseID} and planID: ${parentID}`,
    "color: #AF7AC5; background-color: #5B2C6F"
  );

  Storage.SES_STOR.set(getCoursePlanContextStoreKey(courseID), `${parentID}`);
}

export function makeRelPathAbsolute(
  relPath: string,
  includeSessionKey?: boolean
) {
  if (!relPath || typeof relPath !== "string") {
    return "";
  }

  const sessKeyParam = includeSessionKey
    ? `${
        /\?/i.test(relPath) ? "&" : "?"
      }sessionKey=${Storage.SessionHandling.getActiveSessionKey()}`
    : "";

  return `${IMAGE_BASE_URL}${
    /^\//i.test(relPath) ? "" : "/"
  }${relPath}${sessKeyParam}`;
}

const DEFAULT_ERROR_MSG = "Error occurred";

export function pullMessageFromError(err: any) {
  if (typeof err === "string") {
    return err;
  } else if ((err as Error).message) {
    return `${err.message}`;
  } else if (
    (err as Data.TytoErrorObject).msg ||
    (err as Data.TytoErrorObject).technical
  ) {
    return `${err.msg || err.technical || DEFAULT_ERROR_MSG}`;
  } else {
    return DEFAULT_ERROR_MSG;
  }
}

/**
 * Given a unique key, it creates a LocalStorage key to be used for both
 * retrieving and setting a string value. It is intended for locally storing
 * input/message drafts
 * @param {String} subKey - The key indentifier for this specific input context
 * @returns
 */
export function createLFInputKey(subKey: string) {
  return `${Storage.keys.INPUT_TEXT}::${subKey}`;
}

export function clearLFInputValue(subKey: string) {
  return Storage.LOC_STOR.remove(createLFInputKey(subKey));
}

export function detemerminePPFParentGoal(
  planParentgoal?: TytoData.PPF.Plan.Goals.ParentGoal
): keyof typeof TytoData.PPFParentGoalName | undefined {
  const goalName = planParentgoal?.name ?? "";

  switch (`${goalName}`.toLocaleLowerCase()) {
    case "personal":
      return "personal";
    case "professional":
      return "professional";
    case "financial":
      return "financial";
    default:
      return undefined;
  }
}

export function keyPrimaryParentGoalsByCommonName(
  parenGoals: TytoData.PPF.Plan.Goals.ParentGoal[]
) {
  return (parenGoals ?? []).reduce(
    (
      accum: {
        personal?: TytoData.PPF.Plan.Goals.ParentGoal;
        professional?: TytoData.PPF.Plan.Goals.ParentGoal;
        financial?: TytoData.PPF.Plan.Goals.ParentGoal;
      },
      item
    ) => {
      const caseInsensitiveName = `${item.name}`.toLocaleLowerCase();

      switch (caseInsensitiveName) {
        case "personal":
          accum["personal"] = item;

          break;
        case "professional":
          accum["professional"] = item;

          break;
        case "financial":
          accum["financial"] = item;

          break;
        default:
          break;
      }

      return accum;
    },
    {}
  );
}

export function createFakeFalsePPFPermissions() {
  return {
    activeStatus: "ocIMPLICIT",
    memberElement: {} as any,
    modifiedByID: 0,
    modifiedDate: "01/01/1900",
    createdByID: 0,
    gsPlanID: 0,
    memberID: 0,
    gsMemberID: 0,
    createdDate: "01/01/1900",
    ...PPF_MEMBER_FALSE_DATA,
  };
}

// Omit<TytoData.PPF.Plan.MemberPermissionData, "gsPlanID" | "createdByID" | "createdDate" | "modifiedByID" | "modifiedDate" | "memberID" | "gsMemberID" | "memberElement">

export function pullPermissionFromPlanMemberObject(
  planMember: TytoData.PPF.Plan.Member
): TytoData.PPF.Plan.MemberPermissionData {
  if (!planMember) {
    return {
      activeStatus: "ocIMPLICIT",
      memberElement: {} as any,
      modifiedByID: 0,
      modifiedDate: "01/01/1900",
      createdByID: 0,
      gsPlanID: 0,
      memberID: 0,
      gsMemberID: 0,
      createdDate: "01/01/1900",
      ...PPF_MEMBER_FALSE_DATA,
    };
  }

  // const {
  //   activeStatus,
  //   goalAdd,
  //   goalChange,
  //   goalDelete,
  //   goalView,
  //   planChange,
  //   planDelete,
  //   planView,
  //   noticeStandardAdd,
  //   noticeStandardChange,
  //   noticeStandardDelete,
  //   noticeStandardView,
  //   measureLogAdd,
  //   measureLogChange,
  //   measureLogDelete,
  //   measureLogView,
  //   memberAdd,
  //   memberChange,
  //   memberDelete,
  // } = planMember ?? {};

  return planMember;
}

export function getRoleNameByPermissions(
  permissions: TytoData.PPF.Plan.MemberPermissionData
) {
  if (!permissions.planView) {
    return "Cannot View Plan";
  }

  if (
    permissions.planChange &&
    permissions.goalChange &&
    permissions.noticeStandardChange &&
    permissions.measureLogChange
  ) {
    return PPF_ROLES_BY_NAME.MANAGER.title;
  } else if (
    permissions.goalChange &&
    permissions.noticeStandardChange &&
    permissions.measureLogChange &&
    !permissions.planChange &&
    !permissions.planDelete
  ) {
    return PPF_ROLES_BY_NAME.CONTRIBUTOR.title;
  } else if (
    // !permissions.planChange &&
    // !permissions.goalChange &&
    // !permissions.noticeStandardChange &&
    // !permissions.measureLogChange &&
    // !permissions.planDelete &&
    permissions.goalView &&
    permissions.planView &&
    permissions.memberView
  ) {
    return PPF_ROLES_BY_NAME.READER.title;
  }

  return "Unknown";
}

/**
 * Takes in a Blob or File and returns a URL for an Image
 * @param {Blob | File} imageBlobOrFile - File or Blob to get a URL for
 * @returns
 */
export function createObjectURL(imageBlobOrFile: Blob | File) {
  const newURL = URL.createObjectURL(imageBlobOrFile);

  return newURL;
}

export function alertCountHasDateChange({
  keysArray,
  userAlertCounts,
}: {
  keysArray: Array<keyof typeof TytoData.UserAlertVarName>;
  userAlertCounts: TytoData.UserAlertCount[];
}) {
  if (!userAlertCounts?.length || !keysArray?.length) {
    return false;
  }

  const keysSet = new Set(keysArray);

  const changeExists = userAlertCounts.some((alertCount) => {
    if (alertCount.maxDate && keysSet.has(alertCount.varName)) {
      const storedDate = Storage.LOC_STOR.get(
        `${Storage.keys.USER_ALERT_COUNT}-${alertCount.varName}`
      );

      if (!storedDate) {
        return false;
      }

      return storedDate < alertCount.maxDate;
    }

    return false;
  });

  return changeExists;
}

export function elementIsCurrentlyFocused(elem?: HTMLElement | null) {
  if (!elem) {
    return false;
  }

  return document.activeElement === elem;
}
