/*
 * Component Description - ToastMessages Is a wrapper that renders ToastMessage common component
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

//TODO probably switch out icons
import checkIcon from "../../../assets/check.svg";
import errorIcon from "../../../assets/error.svg";
import warningIcon from "../../../assets/warning.svg";
import infoIcon from "../../../assets/info.svg";
import { ToastMessage } from "components/common/";
// import Icon from "../../common/icon";

import { useState, useEffect } from "react";
import { StoreContext as ToastStoreContext } from "../../../data/stores/ToastStore";

const ToastMessages = () => {
  let ToastStore = React.useContext(ToastStoreContext);
  const messageObj = ToastStore.state?.message;
  const messageType = messageObj?.toastMessageType;
  const toastMessageText = messageObj?.toastMessage;

  const [messageList, setMessageList] = useState<any[]>([]);
  //* ! Toast Settings !
  const [position, setPosition] = useState("toast-location-top-right"); // toast-location-top-left | toast-location-top-right | toast-location-bottom-left | toast-location-bottom-right
  let [autoDelete, setAutoDelete] = useState(true); // true or false
  const [autoDeleteTime, setAutoDeleteTime] = useState(3000); // number in ms

  const showToast = (type: any, toastMessageText: any) => {
    if (type === "success") {
      const updatedCard = {
        id: Math.floor(Math.random() * 101 + 1),
        title: "Success",
        description: toastMessageText ? toastMessageText : "Successful!",
        color: "var(--success)",
        icon: checkIcon,
      };
      setMessageList([...messageList, updatedCard]);
    } else if (type === "error") {
      const updatedCard = {
        id: Math.floor(Math.random() * 101 + 1),
        title: "Error",
        description: toastMessageText
          ? toastMessageText
          : "There was an error.",
        color: "var(--alert)",
        icon: errorIcon,
      };
      setMessageList([...messageList, updatedCard]);
    } else if (type === "warning") {
      const updatedCard = {
        id: Math.floor(Math.random() * 101 + 1),
        title: "Warning",
        description: toastMessageText ? toastMessageText : "Warning message.",
        color: "var(--warning)",
        icon: warningIcon,
      };
      setMessageList([...messageList, updatedCard]);
    } else if (type === "info") {
      const updatedCard = {
        id: Math.floor(Math.random() * 101 + 1),
        title: "Info",
        description: toastMessageText ? toastMessageText : "Information",
        icon: infoIcon,
      };
      setMessageList([...messageList, updatedCard]);
    }
  };

  React.useEffect(() => {
    // callback updates local state which causes a render.
    showToast(messageType, toastMessageText);
  }, [messageObj]);

  return (
    <div>
      <ToastMessage
        toastList={messageList}
        position={position}
        autoDelete={autoDelete}
        autoDeleteTime={autoDeleteTime}
      />
    </div>
  );
};

export default ToastMessages;
