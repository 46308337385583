import { ENDPOINT_GS_GOAL_ITEM } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_GS_GOAL_ITEM;

export const GSGoalItem = {
  post(
    params: Endpoints.Tyto.GS.Goal.Item.PostParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "post",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Goal.Item.Post>;
  },
  put(params: Endpoints.Tyto.GS.Goal.Item.PutParameters, callOpts?: CallOpts) {
    return callWrapper(
      "put",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Goal.Item.Put>;
  },
};
