import * as React from "react";

import { IconProps } from "../typings";

const Vault = ({
  className,
  color,
  gradient,
  gradientKey,
  size,
}: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-1 -2 20 20"
      height={size + 6}
      width={size + 6}
      fill={color}
    >
      {Array.isArray(gradient) && (
        <defs>
          <linearGradient id={gradientKey} x1="0" x2="1" y1="0" y2="1">
            {gradient.map((grad) => (
              <stop
                key={`${grad.offsetPercent}-${grad.color}`}
                offset={`${grad.offsetPercent}%`}
                stopColor={grad.color}
              />
            ))}
          </linearGradient>
        </defs>
      )}

      <path
        d="M17 12V2C17 0.9 16.1 0 15 0H2C0.9 0 0 0.9 0 2V12C0 13.1 0.9 14 2 14H15C16.1 14 17 13.1 17 12ZM6 11V3L12 7L6 11Z"
        fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""}
      />
    </svg>
  );
};

export default Vault;
