/**
 * This component wraps the typical Nav's content,
 * but circumvents the normal 'sticky' (CSS Position)
 * behavior, and simply renders it statically, wherever
 * it is rendered/told be.
 */
import * as React from "react";
import cx from "classnames";

import { Props as LinkProps } from "components/common/link/";
import { Nav } from "components/meta/nav/";

import "./style.scss";

interface Props {
  children?: any;
  className?: string;
  centerButtons?: LinkProps[];
  navClassName?: string;
}

const StandardNav = (props: Props) => {
  return (
    <div className={cx("cm-subnavs-standardnav-cont", props.className)}>
      <Nav
        centerButtons={props.centerButtons}
        navClassName={props.navClassName}
      >
        {props.children ?? null}
      </Nav>
    </div>
  );
};

export default StandardNav;
