/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";
import { StandardNav } from "components/meta/";
import { PPF_LIBRARY_CONFIG_PATH } from "../../data/constants";
import { Nav, Menu } from "components/meta/";
import Library from "./Library";
import { SessionHandling } from "data/storage/";
import {
  usePPFPlans,
  usePPFPlanDashboard,
  useLibraryConfig,
  useFallbackConfig,
} from "data/network/hooks/";

import "./style.scss";

interface Props extends SITE.GeneralStoreInjectedProps {
  // isMobile: boolean;
}

const LibraryWrapper = (props: Props) => {
  const bgVideoElement = React.useRef<HTMLVideoElement | null>(null);
  const [showSearch, updateShowSearch] = React.useState(false);
  const [searchTerm, updateSearchTerm] = React.useState("");
  const [menuHasBeenOpen, updateMenuHasBeenOpen] = React.useState(
    _.get(props, "GeneralStore.state.showMenu", false)
  );

  const storeMenuOpenValue = _.get(props, "GeneralStore.state.showMenu", false);

  const [usersPlans, updateUsersPlans] = React.useState<
    TytoData.PPF.Plans.Plan[] | null
  >(null);

  const [hasManagerAccess, updatehasManagerAccess] = React.useState(false);
  const [permsFallback, updatePermsFallback] = React.useState(false);
  const [shouldUseFallbackConfig, updateShouldUseFallbackConfig] =
    React.useState(false);

  // * Trigger flag so that turning menu off animates back left
  React.useEffect(() => {
    if (!menuHasBeenOpen && storeMenuOpenValue) {
      updateMenuHasBeenOpen(true);
    }
  }, [storeMenuOpenValue, menuHasBeenOpen]);

  const menuIsOpen = storeMenuOpenValue;

  //Regular points to domainID folder
  let { eagerData } = useLibraryConfig({
    onError: () => {
      console.log("=== Error in useLibraryConfig Triggering Fallback ");
      updateShouldUseFallbackConfig(true);
    },
  });

  //Fallback points to top level CV
  let { eagerDataFallback } = useFallbackConfig({
    onError: () => {
      console.log("=== Error in Fallback");
    },
  });

  let config: SITE.Library.Config | undefined = eagerData;
  if (!!shouldUseFallbackConfig) {
    config = eagerDataFallback;
  }

  //* Part 1 Load Catalog -  NOT DOING - Load that teams library with tyto/api/library/legacy
  //* The problem with this approach is it can have cross browser issues forcing assets to be loaded into each domain.
  //* The current plan is uploading videos into a single domain folder so there is single source of truth for files

  //* Part 2 - Permission Check - Quicker Manage Access Check
  //* Get allplans the user is a member of not including yourself.
  //* If true then you can assume they have manageAccess
  //*

  let planChecker: any;
  const queryPlans = usePPFPlans({
    onSuccess: (plansResp) => {
      const { gsPlans } = plansResp ?? {};

      planChecker = getPlansUserIsAMemberOf(gsPlans);
      // console.log("=== planChecker", planChecker);
      updatehasManagerAccess(!!planChecker);

      //If planChecker returns false initiate fallback perms process
      if (planChecker === false) {
        updatePermsFallback(true);
      }
    },
  });

  function getPlansUserIsAMemberOf(plans?: TytoData.PPF.Plans.Plan[]) {
    if (!plans?.length) {
      return [];
    }
    // Filter all plans user has a membership to
    let allPlans = plans.filter((plan) => !!plan?.permission?.gsMemberID); // return plans.filter((plan) => !!plan?.permission?.gsMemberID);
    // Remove your own plan
    const personID = SessionHandling.getUserIDOfActiveSession();
    const plansWithoutUser = allPlans.find((item: any) => {
      return item.aboutID != personID;
    });
    //TODO is there a better way to handle undefined on purpose?
    return !!plansWithoutUser;
  }

  //* Permission Fallback - Determines if they can manage - Only runs if plansEagerData is false due to being a slower call
  //* Example is if they are just a new user with permissions and are not currently members of any plan.
  //*
  //TODO Can't Wrap in conditional due to hook rule - Find way to defer load
  const APRIL_FIRST = new Date("04/01/2022");
  let { eagerData: dashboardEagerData } = usePPFPlanDashboard({
    isCascade: true,
    startDate: APRIL_FIRST,
    endDate: APRIL_FIRST,
    teamID: SessionHandling.getPropertyFromActiveSession("domainID") ?? 0,
    // onDownloadProgress: (progEvent) => {
    //   console.log("ProgEvent: ", progEvent);
    // },
  });

  // If state permsFallback from Perm part 1 true then run fallback
  // Using PersonID to find the user and check if they have "hasPlanAdd".
  if (!!permsFallback) {
    console.log("=== INITIATE FALLBACK PERMISSION CHECK");
    const fallBackPlanPeople: TytoData.PPF.Dashboard.Person[] | undefined =
      dashboardEagerData?.dashboard.allPersons;
    const personID = SessionHandling.getUserIDOfActiveSession();
    const foundPerson = fallBackPlanPeople?.find((item: any) => {
      return item.personID === personID;
    });
    let canManage: boolean = !!foundPerson?.hasPlanAdd;
    // console.log("=== canManage Fallback", canManage);
    updatehasManagerAccess(canManage);
    updatePermsFallback(false);
  }

  //TODO Make default a fallback?
  // const localDevConfig = {
  //   manager: [
  //     {
  //       type: "VIDEO",
  //       title: "TITLE GOES HERE",
  //       description: "",
  //       videoURL:
  //         "https://oc.mocaworks.com/v2/domains/551/PPF-LIBRARY-ASSETS/22.07.01PP-ScottsdaleVersion2-Edition3.mp4",
  //       videoPosterURL:
  //         "https://oc.mocaworks.com/v2/domains/551/PPF-LIBRARY-ASSETS/ppf-poster-22.07.01PP-ScottsdaleVersion2-Edition3.png",

  //       pdfTitle: "THE PURPOSE OF THIS PROGRAM",
  //       pdfFileURL:
  //         "https://oc.mocaworks.com/v2/domains/551/PPF-LIBRARY-ASSETS/ppf-pdf-file.pdf",
  //       pdfEncoding:
  //         "/v2/domains/551/assets/253157/253191_5qixwgvdq4z/page_{0000}.jpg",
  //       pdfPreviewImages: [
  //         "https://oc.mocaworks.com/v2/domains/551/PPF-LIBRARY-ASSETS/ppf-test-page_0001.jpg",
  //         "https://oc.mocaworks.com/v2/domains/551/PPF-LIBRARY-ASSETS/ppf-test-page_0002.jpg",
  //         "https://oc.mocaworks.com/v2/domains/551/PPF-LIBRARY-ASSETS/ppf-test-page_0003.jpg",
  //       ],
  //     },
  //     {
  //       type: "VIDEO",
  //       title: "MANAGEMENT 102",
  //       description: "DESCRIPTION TEXT SPACE IF WANTED IN THE FUTURE.",
  //       videoURL:
  //         "https://oc.mocaworks.com/v2/domains/1698652/videos/cv_bg_video.mp4",
  //       videoPosterURL:
  //         "https://oc.mocaworks.com/v2/domains/1698652/videos/cv_bg_poster.png",
  //       pdfTitle: "THE PURPOSE OF THIS PROGRAM",
  //       pdfEncoding:
  //         "/v2/domains/551/assets/253157/253191_5qixwgvdq4z/page_{0000}.jpg",
  //       pdfPreviewImages: [
  //         "https://oc.mocaworks.com/v2/domains/551/PPF-LIBRARY-ASSETS/ppf-test-page_0001.jpg",
  //       ],
  //       pdfFileURL:
  //         "https://oc.mocaworks.com/v2/domains/551/PPF-LIBRARY-ASSETS/ppf-pdf-file.pdf",
  //     },
  //   ],
  //   employee: [
  //     {
  //       type: "VIDEO",
  //       title: "STUDENT 201",
  //       description: "Description for Student 201",
  //       videoURL:
  //         "https://oc.mocaworks.com/v2/domains/551/PPF-LIBRARY-ASSETS/22.07.01PP-ScottsdaleVersion2-Edition3.mp4",
  //       videoPosterURL:
  //         "https://oc.mocaworks.com/v2/domains/551/PPF-LIBRARY-ASSETS/ppf-poster-22.07.01PP-ScottsdaleVersion2-Edition3.png",
  //       pdfTitle: "THE PURPOSE OF THIS PROGRAM",
  //       pdfEncoding:
  //         "/v2/domains/551/PPF-LIBRARY-ASSETS/ppf-test-page_{0000}.jpg",
  //       pdfPreviewImages: [
  //         "https://oc.mocaworks.com/v2/domains/551/PPF-LIBRARY-ASSETS/ppf-test-page_0001.jpg",
  //         "https://oc.mocaworks.com/v2/domains/551/PPF-LIBRARY-ASSETS/ppf-test-page_0002.jpg",
  //         "https://oc.mocaworks.com/v2/domains/551/PPF-LIBRARY-ASSETS/ppf-test-page_0003.jpg",
  //       ],
  //       pdfFileURL:
  //         "https://oc.mocaworks.com/v2/domains/551/PPF-LIBRARY-ASSETS/ppf-pdf-file.pdf",
  //     },
  //     {
  //       type: "VIDEO",
  //       title: "STUDENT 202",
  //       description: "Description for Student 202",
  //       videoURL: "/v2/domains/551/videos/r3_intro_video.mp4",
  //       videoPosterURL: "/v2/domains/551/videos/r3_intro_video_poster.png",
  //       pdfTitle: "DOC TITLE",
  //       pdfEncoding:
  //         "/v2/domains/551/assets/253157/253191_5qixwgvdq4z/page_{0000}.jpg",
  //       pdfPreviewImages: [
  //         "https://oc.mocaworks.com/v2/domains/551/PPF-LIBRARY-ASSETS/ppf-test-page_0001.jpg",
  //         "https://oc.mocaworks.com/v2/domains/551/PPF-LIBRARY-ASSETS/ppf-test-page_0002.jpg",
  //         "https://oc.mocaworks.com/v2/domains/551/PPF-LIBRARY-ASSETS/ppf-test-page_0003.jpg",
  //       ],
  //       pdfFileURL:
  //         "https://oc.mocaworks.com/v2/domains/551/PPF-LIBRARY-ASSETS/ppf-pdf-file.pdf",
  //     },
  //   ],
  // };

  let isLoadingConfig = !!config?.employee || !!config?.manager;
  return (
    <div className={cx("interface-wrapper", menuIsOpen && "menu-open")}>
      <Menu className="home-menu" />
      <main
        className={cx(
          "interface-main-cont interface-home",
          menuHasBeenOpen && "animate-back"
        )}
      >
        {isLoadingConfig && (
          <Library
            isMobile={_.get(props, "GeneralStore.state.isMobile", false)}
            showSearch={showSearch}
            toggleShowSearch={updateShowSearch}
            hasManagerAccess={hasManagerAccess}
            config={config} //localDevConfig
            viewDocument={false}
          />
        )}
      </main>
    </div>
  );
};

export default LibraryWrapper;
