/*
 * Component Description
 */
import * as React from "react";

import "./EncodingNotFound.scss";

interface Props {}

const EncodingNotFound = (props: Props) => {
  return (
    <section className="document-viewer-encoding-not-found">
      <h2 className="document-viewer-encoding-not-found-msg font-size-title title-font">
        Encoding Not Found.
      </h2>
    </section>
  );
};

export default EncodingNotFound;
