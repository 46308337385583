/**
 * [WIP] - Friendly Wrapper around 'react-image-crop' NPM package
 */
import * as React from "react";
import cx from "classnames";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";
import "./style.scss";

const DEFAULT_ASPECT_RATIO = 16 / 9;

// * Demo: https://codesandbox.io/s/react-image-crop-demo-with-react-hooks-y831o?file=/src/App.tsx:1743-1760

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

interface Props {
  innerContainerRef?: React.MutableRefObject<HTMLDivElement | null>;
  className?: string;
  containerClassName?: string;
  containerStyles?: React.CSSProperties;
  imgSrc?: string;
  onCropChange: (cropData: PixelCrop) => void;
  aspectRatio?: number;
  withWrapper?: boolean;
  // onCompleteCrop: () => void;
}

const ImageCropper = (props: Props) => {
  const [imgSrc, updateImgSrc] = React.useState(props.imgSrc ?? "");
  const previewCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const imgRef = React.useRef<HTMLImageElement>(null);
  const [crop, updateCrop] = React.useState<Crop>();
  const [completedCrop, updateCompletedCrop] = React.useState<PixelCrop>();
  const [aspect, updateAspect] = React.useState<number | undefined>(
    props.aspectRatio ?? DEFAULT_ASPECT_RATIO
  );

  // * props.imgSrc Update
  React.useEffect(() => {
    updateImgSrc(props.imgSrc ?? "");
  }, [props.imgSrc]);

  // * props.aspectRatio update
  React.useEffect(() => {
    updateAspect(props.aspectRatio);
  }, [props.aspectRatio]);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      updateCrop(centerAspectCrop(width, height, aspect));
    }
  }

  if (!imgSrc) {
    return null;
  }

  return (
    <ReactCrop
      crop={crop}
      onChange={(_, percentCrop) => updateCrop(percentCrop)}
      onComplete={(c) => {
        updateCompletedCrop(c);
        // ?? Maybe this should just be in a 'useEffect' so it always reflects true value of 'completedCrop' state value ??
        props.onCropChange?.(c);
      }}
      aspect={aspect}
    >
      <img
        className={cx("cc-image-cropper-img", props.className)}
        ref={imgRef}
        alt="Crop me"
        src={imgSrc}
        // style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
        onLoad={onImageLoad}
      />
    </ReactCrop>
  );
};

const ImageCropperWrapper = (props: Props) => {
  if (props.withWrapper) {
    return (
      <div
        ref={props.innerContainerRef}
        className={cx("cc-image-cropper-img-cont", props.containerClassName)}
        style={props.containerStyles}
      >
        <ImageCropper {...props} />
      </div>
    );
  }

  return <ImageCropper {...props} />;
};

export default ImageCropperWrapper;
