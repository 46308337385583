/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { createPseudoRandomString } from "../../../data/helpers/";
import { TrackingStatus, useBookmarkPoll } from "./helpers";

import "./style.scss";

export interface Props {
  autoPlay?: boolean;
  className?: string;
  controls?: boolean;
  // determineSize?: boolean;
  encodingLength?: number;
  id?: string;
  innerRef?: any;
  initialStartTime?: number; // * In Seconds
  loop?: boolean;
  muted?: boolean;
  onBookMarkUpdate?: (newBookMark: number, length?: number) => void;
  onLaunch?: () => void;
  onPause?: (e: React.ReactEventHandler<HTMLVideoElement>, id: string) => void;
  onPlay?: (e: React.ReactEventHandler<HTMLVideoElement>, id: string) => void;
  onEnded?: (e: React.ReactEventHandler<HTMLVideoElement>, id: string) => void;
  playsInline?: boolean;
  poster: string;
  src: string;
  style?: React.CSSProperties;
  type?: string;
}

const Video = (props: Props) => {
  const [id] = React.useState(props.id ?? createPseudoRandomString());
  const [trackingStatus, updateTrackingStatus] =
    React.useState<TrackingStatus>("void");

  const bookmarkUtils = useBookmarkPoll({
    domID: id,
    encodingLengthInSeconds: props.encodingLength || 0,
    initialBookmark: `${props.initialStartTime}`,
    status: trackingStatus,
    onUpdate: (newBookMark) => {
      if (props.onBookMarkUpdate && newBookMark) {
        props.onBookMarkUpdate(newBookMark, props.encodingLength);
      }
    },
    watchForUpdates: !!props.onBookMarkUpdate,
  });

  return (
    <video
      className={cx("cc-video", props.className)}
      crossOrigin="anonymous"
      autoPlay={props.autoPlay}
      playsInline={props.playsInline}
      controls={props.controls}
      controlsList="nodownload"
      id={id}
      loop={props.loop}
      muted={props.muted}
      onEnded={(e: any) => {
        if (props.onEnded) {
          props.onEnded(e, id);
        }

        updateTrackingStatus("void");
      }}
      onLoadedData={(e) => {
        if (props.initialStartTime) {
          // * Video Data has loaded, set the 'currentTime' of the video to be the supplied 'initialStartTime', if such is supplied.
          e.currentTarget.currentTime = props.initialStartTime || 0;
        }
      }}
      onPause={(e: any) => {
        if (props.onPause) {
          props.onPause(e, id);
        }

        updateTrackingStatus("pause");
      }}
      onPlay={(e: any) => {
        if (props.onPlay) {
          props.onPlay(e, id);
        }

        updateTrackingStatus("play");
      }}
      poster={props.poster}
      ref={props.innerRef}
      style={props.style}
    >
      <source key={props.src} src={props.src} type={props.type}></source>
    </video>
  );
};

export default Video;
