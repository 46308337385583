/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import API from "../../data/network/tyto/";
import { Menu, Nav, StandardNav } from "../../components/meta";
import { GenericNav } from "components/meta/sub-navs/";

import { SearchPlansRouter } from "components/meta/menu/subcomponents/searching";
// import PPFPlans from "./Plans";

import "./style.scss";

interface Props extends SITE.GeneralStoreInjectedProps {}

const PPFPlanWrapper = (props: Props) => {
  const [menuHasBeenOpen, updateMenuHasBeenOpen] = React.useState(
    _.get(props, "GeneralStore.state.showMenu", false)
  );

  const storeMenuOpenValue = _.get(props, "GeneralStore.state.showMenu", false);

  // * If config client has not previously loaded, do so now
  React.useEffect(() => {
    const uploadURL = props.GeneralStore.state?.uploadURL;

    if (!uploadURL) {
      API.Configuration.Client.get({})
        .then((resp) => {
          props.GeneralStore.dispatch?.({
            payload: {
              configurationClient: resp,
            },
            type: "GENERAL_STORE_CONFIGURATION_CLIENT_LOADED",
          });
        })
        .catch((err) => {
          // TODO: Handle error
        });
    }
  }, []);

  // * Trigger flag so that turning menu off animates back left
  React.useEffect(() => {
    if (!menuHasBeenOpen && storeMenuOpenValue) {
      updateMenuHasBeenOpen(true);
    }
  }, [storeMenuOpenValue, menuHasBeenOpen]);

  const menuIsOpen = storeMenuOpenValue;
  const isMobile = !!props.GeneralStore?.state?.isMobile;

  return (
    <div className={cx("interface-wrapper", menuIsOpen && "menu-open")}>
      {/* {!isMobile && <Nav className="profile-nav" />} */}
      <Menu className="profile-menu" />

      <div
        className={cx("interface-main-cont", menuHasBeenOpen && "animate-back")}
      >
        <main className="interface-ppfplans centered-content-wrapper">
          {!isMobile ? (
            <StandardNav className="plans-main-nav" />
          ) : (
            <GenericNav>
              <h2 className="font-size-title title-font">Search People</h2>
            </GenericNav>
          )}

          <SearchPlansRouter
            searchType="all-people"
            GeneralStore={props.GeneralStore}
          />
        </main>
        {/* <PPFPlans
          isMobile={_.get(props, "GeneralStore.state.isMobile", false)}
          GeneralStore={props.GeneralStore}
        /> */}
      </div>
    </div>
  );
};

export default PPFPlanWrapper;
