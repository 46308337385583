/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { Button, Icon, Image } from "components/common/";
import { usePPFTemplateImages } from "data/network/hooks/";

import "components/common/button/style.scss";
import "./style.scss";

const FAKE_TILES = Array.from({ length: 30 }, (__, curIdx) => curIdx + 1);
const INPUT_ID = "modals-imagecropper-file-select";

interface Props extends Pick<Modals.ImagePickerProps, "theme"> {
  onTemplateImageSelect: (templateImage: Data.PPFTemplateImage | null) => void;
  onSystemImageSelect: (imageFile: File) => void;
}

const ImageGrid = (props: Props) => {
  const [selectedTemplateImage, updateSelectedTemplateImage] =
    React.useState<Data.PPFTemplateImage | null>(null);

  const { eagerData, isLoading } = usePPFTemplateImages({
    isEnabled: true,
    onError: () => {},
    onSuccess: (resp) => {
      debugger;
    },
  });

  const templateImages = React.useMemo(() => {
    const images = eagerData?.gsPPFimages ?? [];

    if (!images || !images.length) {
      return [];
    }

    if (!props.theme) {
      return images;
    }

    return images.filter((templateImage) => {
      const themeAsStr = templateImage.major.join(",");

      switch (props.theme) {
        case "personal":
          return /personal/i.test(themeAsStr);
        case "professional":
          return /professional/i.test(themeAsStr);
        case "financial":
          return /financial/i.test(themeAsStr);
        default:
          return true;
      }
    });
  }, [eagerData?.gsPPFimages]);

  return (
    <article className="modals-imagecropper-imagegrid-cont">
      <section className="modals-imagecropper-imagegrid">
        <div className="modals-imagecropper-imagegrid-inner-cont">
          {isLoading && !eagerData ? (
            FAKE_TILES.map((fakeTileIdx) => (
              <figure
                className="modals-imagecropper-imagegrid-tile"
                key={fakeTileIdx}
              >
                <figcaption className="modals-imagecropper-imagegrid-tile-name">
                  {fakeTileIdx}
                </figcaption>
              </figure>
            ))
          ) : (
            <>
              {(templateImages ?? []).map((templateImage) => (
                <button
                  className={cx(
                    "modals-imagecropper-imagegrid-tile-btn button-generic",
                    selectedTemplateImage &&
                      templateImage.imageKey ===
                        selectedTemplateImage?.imageKey &&
                      "is-selected"
                  )}
                  onClick={() => {
                    if (
                      selectedTemplateImage?.imageKey === templateImage.imageKey
                    ) {
                      updateSelectedTemplateImage(null);
                    } else {
                      updateSelectedTemplateImage(templateImage);
                    }
                  }}
                  name={`${
                    selectedTemplateImage?.imageKey === templateImage.imageKey
                      ? "Un"
                      : ""
                  }select image ${templateImage.alt}`}
                  key={templateImage.imageKey}
                >
                  <figure
                    className="modals-imagecropper-imagegrid-tile"
                    key={templateImage.imageKey}
                  >
                    <Image
                      alt={templateImage.alt}
                      className="modals-imagecropper-imagegrid-tile-img"
                      isRelPath={true}
                      src={templateImage.ulrPathRect525}
                    />

                    <figcaption className="modals-imagecropper-imagegrid-tile-name">
                      {templateImage.alt}
                    </figcaption>
                  </figure>

                  {selectedTemplateImage &&
                    templateImage.imageKey ===
                      selectedTemplateImage?.imageKey && (
                      <section className="modals-imagecropper-imagegrid-selected-cont">
                        <Icon
                          className="modals-imagecropper-imagegrid-selected-icon"
                          icon="check"
                          size={32}
                        />
                      </section>
                    )}
                </button>
              ))}
            </>
          )}
        </div>
      </section>

      <section className="modals-imagecropper-imagegrid-btn-cont">
        {selectedTemplateImage ? (
          <Button
            className="modals-imagecropper-imagegrid-btn modals-imagecropper-imagegrid-input-label"
            theme={props.theme ?? "action"}
            onClick={() => {
              props.onTemplateImageSelect(selectedTemplateImage);
            }}
            hoverBGSize="hidden"
            value="Confirm"
          />
        ) : (
          <>
            <label
              className={cx(
                "cc-btn",
                props.theme,
                "modals-imagecropper-imagegrid-btn modals-imagecropper-imagegrid-input-label"
              )}
              htmlFor={INPUT_ID}
            >
              Upload Photo
            </label>
            <input
              className="modals-imagecropper-imagegrid-input"
              accept="image/*"
              multiple={false}
              type="file"
              id={INPUT_ID}
              onChange={(e) => {
                const fileFromEvent = e.target.files?.[0];

                if (!fileFromEvent || !(fileFromEvent instanceof File)) {
                  return;
                }

                props.onSystemImageSelect(fileFromEvent ?? null);
              }}
            />
          </>
        )}
      </section>
    </article>
  );
};

export default ImageGrid;
