/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import AgnosticModal from "../agnostic";
import ProfileImage from "./subcomponents/ProfileImage";

import "./style.scss";

interface Props extends Modals.CommonModalProps {
  onImageUpload: () => void;
  memberID: number;
  tryybProfile?: TytoData.Person;
  uploadURL: string;
}

const ProfileImageModal = (props: Props) => {
  return (
    <AgnosticModal
      className={cx("profileimage-modal-dialog-container", props.className)}
      overlayClassName={props.overlayClassName}
      isOpen={props.isOpen}
      modalStyle={props.modalStyle}
      onDismiss={props.onDismiss}
      overlayStyle={props.overlayStyle}
    >
      <ProfileImage
        onImageUpload={props.onImageUpload}
        closePopup={props.onDismiss}
        memberID={props.memberID}
        tryybProfile={props.tryybProfile}
        uploadURL={props.uploadURL}
      />
    </AgnosticModal>
  );
};

export default ProfileImageModal;
