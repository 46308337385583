/*
 * Renders top level component for Profile Interface
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import API from "../../data/network/tyto/";
import { Nav, Menu } from "../../components/meta";

import Profile from "./Profile";
import { StoreContext as ToastStoreContext } from "../../data/stores/ToastStore";

import "./style.scss";

import ToastMessages from "../../components/meta/toast-messages";

interface Props extends SITE.GeneralStoreInjectedProps {
  subInterface?: ProfilePage.Subinterface;
}

const ProfileWrapper = (props: Props) => {
  const ToastStore = React.useContext(ToastStoreContext);

  const [menuHasBeenOpen, updateMenuHasBeenOpen] = React.useState(
    _.get(props, "GeneralStore.state.showMenu", false)
  );

  const storeMenuOpenValue = _.get(props, "GeneralStore.state.showMenu", false);

  // * If config client has not previously loaded, do so now
  React.useEffect(() => {
    const uploadURL = props.GeneralStore.state?.uploadURL;

    if (!uploadURL) {
      API.Configuration.Client.get({})
        .then((resp) => {
          props.GeneralStore.dispatch?.({
            payload: {
              configurationClient: resp,
            },
            type: "GENERAL_STORE_CONFIGURATION_CLIENT_LOADED",
          });
        })
        .catch((err) => {
          // TODO: Handle error
        });
    }
  }, []);

  // * Trigger flag so that turning menu off animates back left
  React.useEffect(() => {
    if (!menuHasBeenOpen && storeMenuOpenValue) {
      updateMenuHasBeenOpen(true);
    }
  }, [storeMenuOpenValue, menuHasBeenOpen]);

  const menuIsOpen = storeMenuOpenValue;

  return (
    <div className={cx("interface-wrapper", menuIsOpen && "menu-open")}>
      {/* <Nav className="profile-nav" /> */}
      <Menu className="profile-menu" />

      <div
        className={cx(
          "interface-main-cont interface-profile",
          menuHasBeenOpen && "animate-back"
        )}
      >
        <Profile
          isMobile={_.get(props, "GeneralStore.state.isMobile", false)}
          // catalogQuery={props.catalogQuery}
          GeneralStore={props.GeneralStore}
          subInterface={props.subInterface}
        />

        {/* //Wrapper for ToastMessage */}
        <ToastMessages />
      </div>
    </div>
  );
};

export default ProfileWrapper;
