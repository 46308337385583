import * as React from "react";

const SET_INTERVAL_TIME_MS = 1000 * 10; // * 10 Seconds

export type TrackingStatus = "pause" | "play" | "void";

export function parseBookMarkString(bookmarkString: string) {
  if (!bookmarkString) {
    return 0;
  }

  const parsedString = `${bookmarkString}`.match(/^(\d)+/i);

  if (!parsedString) {
    return 0;
  }

  const parsedNumber = parseInt(`${parsedString[0] || 0}`, 10);
  
  if (parsedNumber === NaN) {
    return 0;
  }
  
  // * Return full, first matching case, or default to 0
  return  parsedNumber ?? 0;
}

function getCurrentTime(domID: string) {
  const videoElement = document.getElementById(`${domID}`);

  if (!videoElement) {
    return -1;
  }

  // * Return currentTime as a new Number as to not get weird ref stuff going on
  return Number((videoElement as HTMLVideoElement).currentTime || 0);
}

export function useBookmarkPoll({
  initialBookmark,
  domID,
  encodingLengthInSeconds,
  onUpdate,
  status,
  watchForUpdates,
}: {
  initialBookmark?: string;
  domID: string;
  encodingLengthInSeconds: number;
  onUpdate: (timeInSeconds: number) => void;
  status: TrackingStatus;
  watchForUpdates: boolean;
}) {
  const [currentBookmark, updateCurrentBookmark] = React.useState(
    parseBookMarkString(initialBookmark || "")
  );
  const [intervalKey, updateIntervalKey] =
    React.useState<NodeJS.Timeout | undefined>(undefined);

  React.useEffect(() => {
    // * If told not to watchForUpdates, don't both setting up recursive loop to check
    if (!watchForUpdates) {
      if (intervalKey) {
        clearInterval(intervalKey);
        updateIntervalKey(undefined);
      }

      return;
    }

    // * If new status is 'pause' or 'void', clear the timeout
    if (status === "pause" || status == "void") {
      if (intervalKey) {
        clearInterval(intervalKey);
        updateIntervalKey(undefined);
      }

      if (status === "pause") {
        // TODO: check time difference and save if enough time difference?
        const currentVideoTime = getCurrentTime(domID);

        if (currentVideoTime >= 0 && currentVideoTime - currentBookmark >= 10) {
          updateCurrentBookmark(currentVideoTime);
          onUpdate(currentVideoTime);
        }
      }
    } else if (status === "play") {
      debugger;
      // * If new status is 'play' then setup the 10 second recursive function
      if (intervalKey) {
        clearInterval(intervalKey);
        updateIntervalKey(undefined);
      }

      // * [start] - SET_INTERVAL invocation ===========
      const key = setInterval(() => {
        // * If videoTag isn't found clearInterval and return early
        if (document.getElementById(`${domID}`) === null) {
          if (intervalKey) {
            clearInterval(intervalKey);
            updateIntervalKey(undefined);
          }
          return;
        }

        const newBookMark = getCurrentTime(domID);
        const initialBookmarkAsNumber = parseBookMarkString(
          `${initialBookmark}`
        );

        if (newBookMark >= 0) {
          const timeElapsedInSeconds = newBookMark - currentBookmark;

          if (
            timeElapsedInSeconds > 5 &&
            newBookMark > initialBookmarkAsNumber
          ) {
            updateCurrentBookmark(newBookMark);
            onUpdate(newBookMark);
          }

          // * If less than 10 seconds away from supplied total length, stop polling and updating bookmark.
          if (encodingLengthInSeconds - newBookMark < 10) {
            if (intervalKey) {
              clearInterval(intervalKey);
              updateIntervalKey(undefined);
            }
          }
        }
      }, SET_INTERVAL_TIME_MS);
      // * [end] - SET_INTERVAL invocation =============

      updateIntervalKey(key);
    }

    return () => {
      if (intervalKey) {
        clearInterval(intervalKey);
        updateIntervalKey(undefined);
      }
    };
  }, [status]);

  const stopInterval = () => {
    if (intervalKey) {
      clearInterval(intervalKey);
      updateIntervalKey(undefined);
    }
  };

  return {
    intervalKey,
    stopInterval,
  };
}
