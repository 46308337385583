/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import { DialogContent, DialogOverlay } from "@reach/dialog";

import { Icon } from "components/common/";
import { BackNav } from "components/meta/sub-navs/";
import { isMobileSize } from "data/helpers/";

import "../style.scss";
import "./style.scss";

interface Props extends Modals.CommonModalProps {
  ariaLabel?: string;
  closeButtonSize?: number;
  disallowOverlayClose?: boolean;
  modalType?: "normal" | "super";
  blurBody?: boolean;
  hasCloseBtn?: boolean;
  children?: any;
}

const AgnosticModal = ({
  className,
  closeButtonSize = 25,
  disallowOverlayClose,
  isOpen,
  modalStyle,
  modalType = "normal",
  onDismiss,
  blurBody = true,
  hasCloseBtn = true,
  overlayClassName,
  overlayStyle,
  title,
  ...props
}: Props) => {
  const [isMobile] = React.useState(() => {
    return isMobileSize();
  });

  React.useEffect(() => {
    if (isOpen && blurBody) {
      const mainDiv = document.getElementById("main");

      if (mainDiv) {
        // mainDiv.style.filter = "blur(3px)";
        mainDiv.className = `${mainDiv.className} main-div-is-blurred`;
      }
    } else {
      const mainDiv = document.getElementById("main");

      if (mainDiv) {
        mainDiv.className = `App`;
      }
    }
  }, [isOpen]);

  const isSuperModal = modalType === "super";

  return (
    <DialogOverlay
      className={cx(
        "modals-dialog-overlay",
        disallowOverlayClose && "disallow-close",
        isSuperModal && "is-supermodal",
        overlayClassName
      )}
      onDismiss={onDismiss}
      isOpen={isOpen}
      style={overlayStyle}
    >
      <DialogContent
        aria-label={props.ariaLabel || "Dialog Content"}
        className={cx(
          "modals-dialog-content",
          isSuperModal && "is-supermodal",
          className
        )}
        style={modalStyle}
      >
        {hasCloseBtn && (
          <Icon
            buttonProps={{
              className: cx(
                "modals-agnostic-close-button",
                isSuperModal && "is-supermodal"
              ),
            }}
            icon="window-close"
            isButton={true}
            onClick={onDismiss}
            size={isSuperModal ? 45 : closeButtonSize}
          />
        )}

        {isMobile && title && <BackNav onBack={onDismiss} title={title} />}

        {props.children}
      </DialogContent>
    </DialogOverlay>
  );
};

export default AgnosticModal;
