/*
 * Component Description
 */
import * as React from "react";
import { Button, Link } from "components/common/";

import cx from "classnames";
import "./style.scss";

interface Props {
  curGoalLength?: TytoData.PPF.Plan.Goals.ChildGoal["durationYears"];
  domID?: string;
  hasPersonalGoals: boolean;
  hasProfessionGoals: boolean;
  hasFinancialGoals: boolean;
  has1YearGoals: boolean;
  has3YearGoals: boolean;
  has5YearGoals: boolean;
  onGoalTypeSelect: (
    goalType: "personal" | "professional" | "financial"
  ) => void;
  onGoalDurationSelect: (duration: 1 | 3 | 5) => void;
  selectedGoalType: string;
}

const GoalNav = (props: Props) => {
  return (
    <section id={props.domID} className="goal-nav-section-cont">
      <div className="goal-nav-type-links parent-goals">
        <h1
          className={cx(
            "goal-nav-type-link",
            props.selectedGoalType === "personal" ? "selected-goal-type" : ""
          )}
        >
          <Link
            className="goal-nav-type-link-actual"
            disabled={!props.hasPersonalGoals}
            onClick={() => {
              if (!props.hasPersonalGoals) return;

              props.onGoalTypeSelect("personal");
            }}
            value="Personal"
          />
        </h1>
        <h1
          className={cx(
            "goal-nav-type-link",
            props.selectedGoalType === "professional"
              ? "selected-goal-type"
              : ""
          )}
        >
          <Link
            className="goal-nav-type-link-actual"
            disabled={!props.hasProfessionGoals}
            onClick={() => {
              if (!props.hasProfessionGoals) return;

              props.onGoalTypeSelect("professional");
            }}
            value="Professional"
          />
        </h1>
        <h1
          className={cx(
            "goal-nav-type-link",
            props.selectedGoalType === "financial" ? "selected-goal-type" : ""
          )}
        >
          <Link
            className="goal-nav-type-link-actual"
            disabled={!props.hasFinancialGoals}
            onClick={() => {
              if (!props.hasFinancialGoals) return;

              props.onGoalTypeSelect("financial");
            }}
            value="Financial"
          />
        </h1>
      </div>
      <div className="goal-nav-type-links goal-lengths">
        <Button
          className={cx(
            "goal-nav-year-type-link",
            props.curGoalLength === 1 && "selected-goal-type"
          )}
          disabled={!props.has1YearGoals}
          onClick={() => {
            if (!props.hasPersonalGoals) return;

            props.onGoalDurationSelect(1);
          }}
          theme={props.curGoalLength === 1 ? "white" : "transparent"}
          value="1 Year"
        />

        <Button
          className={cx(
            "goal-nav-year-type-link",
            props.curGoalLength === 3 && "selected-goal-type"
          )}
          disabled={!props.has3YearGoals}
          onClick={() => {
            if (!props.hasProfessionGoals) return;

            props.onGoalDurationSelect(3);
          }}
          theme={props.curGoalLength === 3 ? "white" : "transparent"}
          value="3 Years"
        />

        <Button
          className={cx(
            "goal-nav-year-type-link",
            props.curGoalLength === 5 && "selected-goal-type"
          )}
          disabled={!props.has5YearGoals}
          theme={props.curGoalLength === 5 ? "white" : "transparent"}
          onClick={() => {
            if (!props.hasFinancialGoals) return;

            props.onGoalDurationSelect(5);
          }}
          value="5 Years"
        />
      </div>
    </section>
  );
};

export default GoalNav;
