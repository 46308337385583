/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { StoreContext as GeneralStoreContext } from "data/stores/GeneralStore";

import AgnosticModal from "../agnostic";

import "./style.scss";

interface Props extends Modals.CommonModalProps {}

const SMARTGoals = (props: Props) => {
  const GeneralStore = React.useContext(GeneralStoreContext);

  return (
    <AgnosticModal
      className={cx("smartGoals-modal-dialog-container", props.className)}
      overlayClassName={props.overlayClassName}
      isOpen={props.isOpen}
      modalStyle={props.modalStyle}
      onDismiss={props.onDismiss}
      overlayStyle={props.overlayStyle}
      title={"Smart Goals"}
      hasCloseBtn={!GeneralStore.state?.isMobile}
    >
      <div className="smatGoals-modal-letter-container">
        <div className="smartGoals-modal-letter-section">
          <h2 className="smartGoals-modal-letter-head">S - SPECIFIC</h2>
          <p className="smartGoals-modal-letter-description">
            State specifically what is going to be changed or accomplished, the
            amount of change needed, and quality of the result.
          </p>
        </div>
        <div className="smartGoals-modal-letter-section">
          <h2 className="smartGoals-modal-letter-head">M - MEASURABLE</h2>
          <p className="smartGoals-modal-letter-description">
            How will you measure your results? Make achievement of success clear
            and unambiguous.
          </p>
        </div>
        <div className="smartGoals-modal-letter-section">
          <h2 className="smartGoals-modal-letter-head">A - ATTAINABLE</h2>
          <p className="smartGoals-modal-letter-description">
            Research shows that the best goals are challenging but can be
            achieved through planning and persistence. They stretch us and force
            us to grow and improve.
          </p>
        </div>
        <div className="smartGoals-modal-letter-section">
          <h2 className="smartGoals-modal-letter-head">R - RELEVANT</h2>
          <p className="smartGoals-modal-letter-description">
            You should know why this goal is important to achieve. What will
            achieving your goals do to enrich your life or the lives of others?
          </p>
        </div>
        <div className="smartGoals-modal-letter-section">
          <h2 className="smartGoals-modal-letter-head">T - Timely</h2>
          <p className="smartGoals-modal-letter-description">
            Clearly specify a start date, due date and the milestones that will
            help you track progress, allow you to celebrate or make the larger
            project more manageable.
          </p>
        </div>
      </div>
    </AgnosticModal>
  );
};

export default SMARTGoals;
