/**
 * Where 'System' refers to high-level Back End stuff
 * I.E., constants the pertain to how Security and similar things work.
 */

export const SECURITY_FUNCTIONS_BY_ID: {
  [functionID: number]: {
    functionID: number;
    functionName: string;
  };
} = {
  2: {
    functionID: 2,
    functionName: "People",
  },
  3: {
    functionID: 3,
    functionName: "Teams",
  },
  5: {
    functionID: 5,
    functionName: "Assessments",
  },
  7: {
    functionID: 7,
    functionName: "Goal Sharing",
  },
  8: {
    functionID: 8,
    functionName: "Person Password",
  },
  9: {
    functionID: 9,
    functionName: "Person Address",
  },
  10: {
    functionID: 10,
    functionName: "Person Admin",
  },
  11: {
    functionID: 11,
    functionName: "Person Demographics",
  },
  12: {
    functionID: 12,
    functionName: "Team Membership",
  },
  13: {
    functionID: 13,
    functionName: "Blocks",
  },
  14: {
    functionID: 14,
    functionName: "Lessons",
  },
  18: {
    functionID: 18,
    functionName: "Block Enroll",
  },
  22: {
    functionID: 22,
    functionName: "Roles",
  },
  24: {
    functionID: 24,
    functionName: "Reports",
  },
  29: {
    functionID: 29,
    functionName: "Bugs",
  },
  30: {
    functionID: 30,
    functionName: "Impersonate",
  },
  31: {
    functionID: 31,
    functionName: "System",
  },
  33: {
    functionID: 33,
    functionName: "News Team",
  },
  34: {
    functionID: 34,
    functionName: "Notice Team",
  },
  35: {
    functionID: 35,
    functionName: "LibraryFront Team",
  },
  36: {
    functionID: 36,
    functionName: "LibraryBack Team",
  },
  37: {
    functionID: 37,
    functionName: "Notices Person",
  },
  38: {
    functionID: 38,
    functionName: "Function",
  },
  41: {
    functionID: 41,
    functionName: "onCourseLegacy",
  },
  42: {
    functionID: 42,
    functionName: "Domain",
  },
  43: {
    functionID: 43,
    functionName: "Beta",
  },
  44: {
    functionID: 44,
    functionName: "Exams",
  },
  45: {
    functionID: 45,
    functionName: "System Log",
  },
  46: {
    functionID: 46,
    functionName: "Lesson Answerkey",
  },
  47: {
    functionID: 47,
    functionName: "Debug",
  },
  48: {
    functionID: 48,
    functionName: "Page Library",
  },
  49: {
    functionID: 49,
    functionName: "Page Team",
  },
  50: {
    functionID: 50,
    functionName: "Page News",
  },
  51: {
    functionID: 51,
    functionName: "Page Search",
  },
  54: {
    functionID: 54,
    functionName: "Page Home",
  },
  55: {
    functionID: 55,
    functionName: "Page Manage",
  },
  57: {
    functionID: 57,
    functionName: "Surveys",
  },
  58: {
    functionID: 58,
    functionName: "DomainLoginURI",
  },
  59: {
    functionID: 59,
    functionName: "Page Home Beta",
  },
  60: {
    functionID: 60,
    functionName: "Page Portal",
  },
  61: {
    functionID: 61,
    functionName: "Replication Data",
  },
  63: {
    functionID: 63,
    functionName: "LibraryFront Person",
  },
  64: {
    functionID: 64,
    functionName: "Person Activity",
  },
  65: {
    functionID: 65,
    functionName: "DevTasks",
  },
  66: {
    functionID: 66,
    functionName: "DevPlans",
  },
  67: {
    functionID: 67,
    functionName: "Page Training",
  },
  68: {
    functionID: 68,
    functionName: "Page Reports",
  },
  69: {
    functionID: 69,
    functionName: "Teams Analytics",
  },
  70: {
    functionID: 70,
    functionName: "Page Library Beta",
  },
  71: {
    functionID: 71,
    functionName: "Chat Private",
  },
  72: {
    functionID: 72,
    functionName: "TPA",
  },
  73: {
    functionID: 73,
    functionName: "Page ToDo",
  },
  74: {
    functionID: 74,
    functionName: "Lessons QuickDoc",
  },
  75: {
    functionID: 75,
    functionName: "Blocks Published",
  },
  76: {
    functionID: 76,
    functionName: "Catalog CurriculumPub",
  },
  77: {
    functionID: 77,
    functionName: "DevTasks Team",
  },
  78: {
    functionID: 78,
    functionName: "Page Reports Users",
  },
  79: {
    functionID: 79,
    functionName: "Page Reports Courses",
  },
  80: {
    functionID: 80,
    functionName: "Page Reports Todos",
  },
  81: {
    functionID: 81,
    functionName: "Page Reports Roles",
  },
  82: {
    functionID: 82,
    functionName: "Page Reports Email",
  },
  83: {
    functionID: 83,
    functionName: "Page Reports Activity",
  },
  91: {
    functionID: 91,
    functionName: "Curriculum Credit",
  },
  96: {
    functionID: 96,
    functionName: "Events",
  },
  97: {
    functionID: 97,
    functionName: "EventAttendees Team",
  },
  98: {
    functionID: 98,
    functionName: "EventAttendees Person",
  },
  99: {
    functionID: 99,
    functionName: "Page Events",
  },
  101: {
    functionID: 101,
    functionName: "Page Bugs",
  },
  103: {
    functionID: 103,
    functionName: "Page Notice",
  },
  104: {
    functionID: 104,
    functionName: "Page Catalog Curriculum",
  },
  105: {
    functionID: 105,
    functionName: "Catalog SharedService",
  },
  106: {
    functionID: 106,
    functionName: "Inbox Team",
  },
  107: {
    functionID: 107,
    functionName: "Page TeamEditor",
  },
  109: {
    functionID: 109,
    functionName: "Notice Team Cascade",
  },
  110: {
    functionID: 110,
    functionName: "Page Verify",
  },
  111: {
    functionID: 111,
    functionName: "Checklist",
  },
  112: {
    functionID: 112,
    functionName: "Checklist Tasks",
  },
  113: {
    functionID: 113,
    functionName: "Page Asset",
  },
  114: {
    functionID: 114,
    functionName: "Person TraitR3",
  },
  115: {
    functionID: 115,
    functionName: "Catalog EventTemplate",
  },
  116: {
    functionID: 116,
    functionName: "EventTemplates",
  },
  117: {
    functionID: 117,
    functionName: "VCEPlan",
  },
  118: {
    functionID: 118,
    functionName: "Page GSPPF",
  },
  119: {
    functionID: 119,
    functionName: "TransGrowth Tasks Team",
  },
  121: {
    functionID: 121,
    functionName: "Page Manage TrainingDashboard",
  },
  147: {
    functionID: 147,
    functionName: "PublicWall",
  },
  148: {
    functionID: 148,
    functionName: "Page PublicWallPost",
  },
  149: {
    functionID: 149,
    functionName: "Page PublicWall",
  },
  150: {
    functionID: 150,
    functionName: "ProjectTeam",
  },
  155: {
    functionID: 155,
    functionName: "ServiceBid",
  },
  156: {
    functionID: 156,
    functionName: "Catalog Playbook",
  },
  157: {
    functionID: 157,
    functionName: "Lesson Policy",
  },
  158: {
    functionID: 158,
    functionName: "User Function",
  },
  159: {
    functionID: 159,
    functionName: "ServiceBidMethod",
  },
  160: {
    functionID: 160,
    functionName: "Page Survey",
  },
  161: {
    functionID: 161,
    functionName: "Page Manage RestorePerson",
  },
  162: {
    functionID: 162,
    functionName: "TeamTools",
  },
};

export const SECURITY_FUNCTIONS_BY_NAME = {
  People: SECURITY_FUNCTIONS_BY_ID[2],
  Teams: SECURITY_FUNCTIONS_BY_ID[3],
  Assessments: SECURITY_FUNCTIONS_BY_ID[5],
  "Goal Sharing": SECURITY_FUNCTIONS_BY_ID[7],
  "Person Password": SECURITY_FUNCTIONS_BY_ID[8],
  "Person Address": SECURITY_FUNCTIONS_BY_ID[9],
  "Person Admin": SECURITY_FUNCTIONS_BY_ID[10],
  "Person Demographics": SECURITY_FUNCTIONS_BY_ID[11],
  "Team Membership": SECURITY_FUNCTIONS_BY_ID[12],
  Blocks: SECURITY_FUNCTIONS_BY_ID[13],
  Lessons: SECURITY_FUNCTIONS_BY_ID[14],
  "Block Enroll": SECURITY_FUNCTIONS_BY_ID[18],
  Roles: SECURITY_FUNCTIONS_BY_ID[22],
  Reports: SECURITY_FUNCTIONS_BY_ID[24],
  Bugs: SECURITY_FUNCTIONS_BY_ID[29],
  Impersonate: SECURITY_FUNCTIONS_BY_ID[30],
  System: SECURITY_FUNCTIONS_BY_ID[31],
  "News Team": SECURITY_FUNCTIONS_BY_ID[33],
  "Notice Team": SECURITY_FUNCTIONS_BY_ID[34],
  "LibraryFront Team": SECURITY_FUNCTIONS_BY_ID[35],
  "LibraryBack Team": SECURITY_FUNCTIONS_BY_ID[36],
  "Notices Person": SECURITY_FUNCTIONS_BY_ID[37],
  Function: SECURITY_FUNCTIONS_BY_ID[38],
  onCourseLegacy: SECURITY_FUNCTIONS_BY_ID[41],
  Domain: SECURITY_FUNCTIONS_BY_ID[42],
  Beta: SECURITY_FUNCTIONS_BY_ID[43],
  Exams: SECURITY_FUNCTIONS_BY_ID[44],
  "System Log": SECURITY_FUNCTIONS_BY_ID[45],
  "Lesson Answerkey": SECURITY_FUNCTIONS_BY_ID[46],
  Debug: SECURITY_FUNCTIONS_BY_ID[47],
  "Page Library": SECURITY_FUNCTIONS_BY_ID[48],
  "Page Team": SECURITY_FUNCTIONS_BY_ID[49],
  "Page News": SECURITY_FUNCTIONS_BY_ID[50],
  "Page Search": SECURITY_FUNCTIONS_BY_ID[51],
  "Page Home": SECURITY_FUNCTIONS_BY_ID[54],
  "Page Manage": SECURITY_FUNCTIONS_BY_ID[55],
  Surveys: SECURITY_FUNCTIONS_BY_ID[57],
  DomainLoginURI: SECURITY_FUNCTIONS_BY_ID[58],
  "Page Home Beta": SECURITY_FUNCTIONS_BY_ID[59],
  "Page Portal": SECURITY_FUNCTIONS_BY_ID[60],
  "Replication Data": SECURITY_FUNCTIONS_BY_ID[61],
  "LibraryFront Person": SECURITY_FUNCTIONS_BY_ID[63],
  "Person Activity": SECURITY_FUNCTIONS_BY_ID[64],
  DevTasks: SECURITY_FUNCTIONS_BY_ID[65],
  DevPlans: SECURITY_FUNCTIONS_BY_ID[66],
  "Page Training": SECURITY_FUNCTIONS_BY_ID[67],
  "Page Reports": SECURITY_FUNCTIONS_BY_ID[68],
  "Teams Analytics": SECURITY_FUNCTIONS_BY_ID[69],
  "Page Library Beta": SECURITY_FUNCTIONS_BY_ID[70],
  "Chat Private": SECURITY_FUNCTIONS_BY_ID[71],
  TPA: SECURITY_FUNCTIONS_BY_ID[72],
  "Page ToDo": SECURITY_FUNCTIONS_BY_ID[73],
  "Lessons QuickDoc": SECURITY_FUNCTIONS_BY_ID[74],
  "Blocks Published": SECURITY_FUNCTIONS_BY_ID[75],
  "Catalog CurriculumPub": SECURITY_FUNCTIONS_BY_ID[76],
  "DevTasks Team": SECURITY_FUNCTIONS_BY_ID[77],
  "Page Reports Users": SECURITY_FUNCTIONS_BY_ID[78],
  "Page Reports Courses": SECURITY_FUNCTIONS_BY_ID[79],
  "Page Reports Todos": SECURITY_FUNCTIONS_BY_ID[80],
  "Page Reports Roles": SECURITY_FUNCTIONS_BY_ID[81],
  "Page Reports Email": SECURITY_FUNCTIONS_BY_ID[82],
  "Page Reports Activity": SECURITY_FUNCTIONS_BY_ID[83],
  "Curriculum Credit": SECURITY_FUNCTIONS_BY_ID[91],
  Events: SECURITY_FUNCTIONS_BY_ID[96],
  "EventAttendees Team": SECURITY_FUNCTIONS_BY_ID[97],
  "EventAttendees Person": SECURITY_FUNCTIONS_BY_ID[98],
  "Page Events": SECURITY_FUNCTIONS_BY_ID[99],
  "Page Bugs": SECURITY_FUNCTIONS_BY_ID[101],
  "Page Notice": SECURITY_FUNCTIONS_BY_ID[103],
  "Page Catalog Curriculum": SECURITY_FUNCTIONS_BY_ID[104],
  "Catalog SharedService": SECURITY_FUNCTIONS_BY_ID[105],
  "Inbox Team": SECURITY_FUNCTIONS_BY_ID[106],
  "Page TeamEditor": SECURITY_FUNCTIONS_BY_ID[107],
  "Notice Team Cascade": SECURITY_FUNCTIONS_BY_ID[109],
  "Page Verify": SECURITY_FUNCTIONS_BY_ID[110],
  Checklist: SECURITY_FUNCTIONS_BY_ID[111],
  "Checklist Tasks": SECURITY_FUNCTIONS_BY_ID[112],
  "Page Asset": SECURITY_FUNCTIONS_BY_ID[113],
  "Person TraitR3": SECURITY_FUNCTIONS_BY_ID[114],
  "Catalog EventTemplate": SECURITY_FUNCTIONS_BY_ID[115],
  EventTemplates: SECURITY_FUNCTIONS_BY_ID[116],
  VCEPlan: SECURITY_FUNCTIONS_BY_ID[117],
  "Page GSPPF": SECURITY_FUNCTIONS_BY_ID[118],
  "TransGrowth Tasks Team": SECURITY_FUNCTIONS_BY_ID[119],
  "Page Manage TrainingDashboard": SECURITY_FUNCTIONS_BY_ID[121],
  PublicWall: SECURITY_FUNCTIONS_BY_ID[147],
  "Page PublicWallPost": SECURITY_FUNCTIONS_BY_ID[148],
  "Page PublicWall": SECURITY_FUNCTIONS_BY_ID[149],
  ProjectTeam: SECURITY_FUNCTIONS_BY_ID[150],
  ServiceBid: SECURITY_FUNCTIONS_BY_ID[155],
  "Catalog Playbook": SECURITY_FUNCTIONS_BY_ID[156],
  "Lesson Policy": SECURITY_FUNCTIONS_BY_ID[157],
  "User Function": SECURITY_FUNCTIONS_BY_ID[158],
  ServiceBidMethod: SECURITY_FUNCTIONS_BY_ID[159],
  "Page Survey": SECURITY_FUNCTIONS_BY_ID[160],
  "Page Manage RestorePerson": SECURITY_FUNCTIONS_BY_ID[161],
  TeamTools: SECURITY_FUNCTIONS_BY_ID[162],
};

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const FIREBASE_CONFIG = {
//   apiKey: "AIzaSyBRe8nzR8N3YFixo1RfEVnRvqYk4dpxmlo",
//   authDomain: "knowledge-vault-8c1f5.firebaseapp.com",
//   databaseURL: "https://knowledge-vault-8c1f5.firebaseio.com",
//   projectId: "knowledge-vault-8c1f5",
//   storageBucket: "knowledge-vault-8c1f5.appspot.com",
//   messagingSenderId: "837603499293",
//   appId: "1:837603499293:web:6d75f31c66119e66a555f4"
// };

// // Initialize Firebase
// const app = initializeApp(FIREBASE_CONFIG);
