import { PixelCrop } from "react-image-crop";

import { GOAL_IMAGE_RATIOS } from "data/constants/";

const TO_RADIANS = Math.PI / 180;
const DEFAULT_IMAGE_MIME_TYPE = "image/jpg"; // * mimeType to create Blob Image as, I believe?
const DEFAULT_IMAGE_QUALITY = 0.7; // * Decimal Percentage; 0.0 <-> 1.0

export async function getImageBlobFromCropInfo({
  cropData,
  imgRef,
  rotate = 0,
  scale = 1,
  saveDimensions = GOAL_IMAGE_RATIOS.SQUARE,
}: {
  cropData: PixelCrop;
  imgRef: HTMLImageElement;
  rotate?: number;
  scale?: number;
  saveDimensions: {
    height: number;
    width: number;
    aspectRatio: number;
  };
}) {
  try {
    const canvas = document.createElement("canvas");

    // * Code in this Function is largely ripped from Docs linked in CC/ImageCropper/index.tsx
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("No 2d context");
    }

    const scaleX = imgRef.naturalWidth / imgRef.width;
    const scaleY = imgRef.naturalHeight / imgRef.height;
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio;
    // const pixelRatio = 1

    // * Used to make sure the image gets saved meets a "minimum dimension"
    const drawWidthScale = Math.max(0.5, saveDimensions.width / cropData.width);
    const drawHeightScale = Math.max(
      0.5,
      saveDimensions.height / cropData.height
    );

    debugger;
    // * Get H/W values of how the will be drawn and then saved
    const preProperlyScaledWidth = cropData.width * scaleX * pixelRatio;
    const preProperlyScaledHeight = cropData.height * scaleY * pixelRatio;

    // * Get ratio of check up above compared to desired Dimensions
    const requiredDimWidthRatio = preProperlyScaledWidth / saveDimensions.width;
    const requiredDimHeightRatio =
      preProperlyScaledHeight / saveDimensions.height;

    // * Cap ratio so ensure to 5kx5k dimensions
    const cappedWidth = preProperlyScaledWidth / requiredDimWidthRatio;
    const cappedHeight = preProperlyScaledHeight / requiredDimHeightRatio;

    canvas.width = Math.floor(cappedWidth);
    canvas.height = Math.floor(cappedHeight);
    // canvas.width = Math.floor(cropData.width * scaleX * pixelRatio);
    // canvas.height = Math.floor(cropData.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";

    const cropX = (cropData.x * scaleX) / requiredDimWidthRatio;
    const cropY = (cropData.y * scaleY) / requiredDimHeightRatio;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = imgRef.naturalWidth / 2 / requiredDimWidthRatio;
    const centerY = imgRef.naturalHeight / 2 / requiredDimHeightRatio;

    ctx.save();

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 3) Rotate around the origin
    ctx.rotate(rotateRads);
    // 2) Scale the image
    ctx.scale(scale, scale);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      imgRef,
      0,
      0,
      imgRef.naturalWidth,
      imgRef.naturalHeight,
      0,
      0,
      imgRef.naturalWidth / requiredDimWidthRatio,
      imgRef.naturalHeight / requiredDimHeightRatio
    );

    ctx.restore();

    const blob = await getBlobFromCanvas(canvas);

    // * Cleanup before returning; remove canvas from document now that we have a blob off of it.
    // document.removeChild(canvas);

    return blob;
  } catch (err) {
    // TODO: Handle Error somehow?
    return null;
  }
}

export function getBlobFromCanvas(
  canvasElement: HTMLCanvasElement
): Promise<Blob | null> {
  return new Promise((res, rej) => {
    canvasElement.toBlob(
      (createdBlob) => {
        res(createdBlob);
      },
      DEFAULT_IMAGE_MIME_TYPE,
      DEFAULT_IMAGE_QUALITY
    );
  });
}
