/*
 * Component Description
 */
import * as React from "react";

import { makePathURLQualified } from "../../../../data/helpers/";

import DocumentViewerImage from "./DocumentViewerImage";

interface Props {
  encoding: Data.Encoding;
}

export interface ImageData {
  src: string;
  height: number;
  width: number;
}

function getRatioAsString(encoding: Props["encoding"]) {
  const { width, height } = encoding;

  if (!width || !height) {
    return undefined;
  }

  return `${width} / ${height}`;
}

const ImagesWrapper = (props: Props) => {
  const [images, updateImages] = React.useState(
    createImageData(props.encoding)
  );
  // const [imageRatio] = React.useState(getRatioAsString(props.encoding));

  return (
    <>
      {!!images.length
        ? images.map((img, curIdx, imgs) => (
            <DocumentViewerImage
              {...img}
              // imageRatio={imageRatio}
              pageNum={curIdx + 1}
              totalPagesCount={imgs.length}
              key={img.src}
            />
          ))
        : null}
    </>
  );
};

function getPathUsingTemplate({
  pathURL,
  pageNum,
}: {
  pathURL: string;
  pageNum: number;
}) {
  if (!pathURL || typeof pageNum !== "number") {
    return "";
  }

  const pageNumStr = `${pageNum}`;
  const safePageNum = `${"0".repeat(4 - pageNumStr.length)}${pageNumStr}`;

  return pathURL.replace(/\{\d+\}/i, `${safePageNum}`);
}

function createImageData(encoding: Data.Encoding): ImageData[] {
  if (!encoding.length) {
    return [];
  }

  const { length, pathURL, height, width } = encoding;

  const photoSwipeSourceObjects = Array.from({ length }, (__, curIdx) => {
    const pagePathURL = getPathUsingTemplate({ pathURL, pageNum: curIdx + 1 });

    return {
      height: Math.min(height),
      width: Math.min(width),
      src: makePathURLQualified(pagePathURL),
      //   title: `Page ${curIdx + 1} of ${length}`,
    };
  });

  return photoSwipeSourceObjects;
}

export default ImagesWrapper;
