function getStorage(type: Storage.StorageType) {
  return type === "session" ? sessionStorage : localStorage;
}

function getItem({
  key,
  storageType = "local",
  onError,
}: {
  key: string;
  storageType: Storage.StorageType;
  onError?: (errorMsg: string) => void;
}) {
  try {
    const browserStore = getStorage(storageType);

    if (!key) {
      return undefined;
    }

    return browserStore.getItem(key);
  } catch (err: any) {
    onError?.(err);
  }
}

function setItem({
  key,
  storageType = "local",
  value,
  onError,
}: {
  key: string;
  value: string;
  storageType: Storage.StorageType;
  onError?: (errorMsg: string) => void;
}) {
  try {
    const browserStore = getStorage(storageType);

    if (!key) {
      return undefined;
    }

    browserStore.setItem(key, value);

    return true;
  } catch (err: any) {
    onError?.(err);

    return false;
  }
}

function removeItem({
  key,
  storageType = "local",
  onError,
}: {
  key: string;
  storageType: Storage.StorageType;
  onError?: (errorMsg: string) => void;
}) {
  try {
    const browserStore = getStorage(storageType);

    if (!key) {
      return undefined;
    }

    browserStore.removeItem(key);

    return true;
  } catch (err: any) {
    onError?.(err);

    return false;
  }
}

function clear({
  storageType = "local",
  onError,
}: {
  storageType: Storage.StorageType;
  onError?: (errorMsg: string) => void;
}) {
  try {
    const browserStore = getStorage(storageType);

    browserStore.clear();

    return true;
  } catch (err: any) {
    onError?.(err);

    return false;
  }
}

export const LOC_STOR = {
  clear(opts?: Storage.Methods.Opts) {
    const { onError } = opts || {};

    return clear({ onError, storageType: "local" });
  },
  get(key: string, opts?: Storage.Methods.Opts) {
    const { onError } = opts || {};

    return getItem({ key, onError, storageType: "local" });
  },
  set(key: string, value: any, opts?: Storage.Methods.Opts) {
    const { onError, returnValue } = opts || {};

    const wasSuccessful = setItem({
      key,
      value,
      onError,
      storageType: "local",
    });

    if (wasSuccessful && returnValue) {
      return getItem({ key, onError, storageType: "local" });
    }

    return wasSuccessful;
  },
  remove(key: string, opts?: Storage.Methods.Opts) {
    const { onError } = opts || {};

    return removeItem({ key, storageType: "local", onError });
  },
};

export const SES_STOR = {
  clear(opts?: Storage.Methods.Opts) {
    const { onError } = opts || {};

    return clear({ onError, storageType: "session" });
  },
  get(key: string, opts?: Storage.Methods.Opts) {
    const { onError } = opts || {};

    return getItem({ key, onError, storageType: "session" });
  },
  set(key: string, value: any, opts?: Storage.Methods.Opts) {
    const { onError, returnValue } = opts || {};

    const wasSuccessful = setItem({
      key,
      value,
      onError,
      storageType: "session",
    });

    if (wasSuccessful && returnValue) {
      return getItem({ key, onError, storageType: "session" });
    }

    return wasSuccessful;
  },
  remove(key: string, opts?: Storage.Methods.Opts) {
    const { onError } = opts || {};

    removeItem({ key, storageType: "session", onError });
  },
};
