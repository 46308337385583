/*
 * Component Description
 */
import * as React from "react";

import { GoalStories } from "components/goal/";
import "./style.scss";

interface Props extends PlanPage.PlanData {
  targetGoalID?: number;
  isMemberOfPlan: boolean;
  isMobile: boolean;
  showNavButtons: boolean;
  showAll?: boolean;
}

const Goals = (props: Props) => {
  const isLoading = !props.plan || !props.planGoals;

  return (
    <article className="plan-goals">
      {!props.plan || !props.planGoals ? (
        <div className="plan-goals-loading-center">Loading...</div>
      ) : (
        <GoalStories
          isMemberOfPlan={props.isMemberOfPlan}
          isMobile={props.isMobile}
          plan={props.plan}
          parentGoals={props.planGoals}
          goalNotices={props.planNotices}
          targetGoalID={props.targetGoalID}
          statusFilter={props.filter}
          goalChangelog={props.planChangelog ?? []}
          showNavButtons={props.showNavButtons}
          showAll={props.showAll}
        />
      )}
    </article>
  );
};

export default Goals;
