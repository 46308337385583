/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import AgnosticModal from "../agnostic";
import ImagePicker from "./ImagePicker";

import "./style.scss";

const DEFAULT_TITLE = "Photo";

interface Props extends Modals.CommonModalProps, Modals.ImagePickerProps {
  //   uploadURL: string;
}

const ProfileImageModal = (props: Props) => {
  return (
    <AgnosticModal
      className={cx("imagepicker-modal-dialog-container", props.className)}
      overlayClassName={props.overlayClassName}
      isOpen={props.isOpen}
      modalStyle={props.modalStyle}
      onDismiss={props.onDismiss}
      overlayStyle={props.overlayStyle}
      title={props.title ?? DEFAULT_TITLE}
    >
      <ImagePicker
        closePopup={props.onDismiss}
        onImageSelect={props.onImageSelect}
        onTemplateImageSelect={props.onTemplateImageSelect}
        initialFile={props.initialFile}
        profileImageAsset={props.profileImageAsset}
        theme={props.theme}
      />
    </AgnosticModal>
  );
};

export default ProfileImageModal;
