import * as React from "react";

import { IconProps } from "../typings";

import "./celebrate.scss";

const Celebrate = ({ className, color, size }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 58 58"
      height={size}
      width={size}
      fill={color}
    >
      <g fill="none">
        <g className="" fillRule="nonzero">
          <g>
            {/**
             * Yellow: 1
             * Purple: 2
             * Red: 3
             * Green: 4
             * Blue: 5
             */}
            <path
              data-celebrateicon="1"
              className="cc-icon-celebrate-shape cc-icon-celebrate-color-1"
              d="m31 20.531c-.3034036 1.7012084-.7647328 3.3704298-1.378 4.986-.0726454.2841909-.3286712.4830031-.622.4830031s-.5493546-.1988122-.622-.4830031c-.6132672-1.6155702-1.0745964-3.2847916-1.378-4.986-.0948523-.7730179.2642625-1.5314848.9223344-1.9480123.6580719-.4165274 1.4972593-.4165274 2.1553312 0 .6580719.4165275 1.0171867 1.1749944.9223344 1.9480123z"
            />
            <path
              data-celebrateicon="2"
              className="cc-icon-celebrate-shape cc-icon-celebrate-color-2"
              d="m31 8.336c-.3026701 1.7593658-.763883 3.487743-1.378 5.164-.0644729.291816-.3231466.4995773-.622.4995773s-.5575271-.2077613-.622-.4995773c-.614117-1.676257-1.0753299-3.4046342-1.378-5.164-.085756-1.19504943.8060057-2.23662719 2-2.336 1.1939943.09937281 2.085756 1.14095057 2 2.336z"
            />
            <path
              data-celebrateicon="3"
              className="cc-icon-celebrate-shape cc-icon-celebrate-color-4"
              d="m19.857 1.831c.5547838 2.16323048.9125775 4.37224706 1.069 6.6.0665276.36249163-.1231944.72352822-.4594654.8743522-.3362711.15082398-.7320682.05240384-.9585346-.2383522-1.5562723-1.59923454-2.9646787-3.33597904-4.208-5.189-.7096359-1.31801522-.22787-2.96163468 1.081-3.688 1.4125202-.49670693 2.9618646.23472981 3.476 1.641z"
            />
            <path
              data-celebrateicon="4"
              className="cc-icon-celebrate-shape cc-icon-celebrate-color-4"
              d="m3.351 16.035c2.09793543.7625174 4.12253169 1.7133982 6.049 2.841.33739247.1494625.52877903.5103098.46326882.8734642-.0655102.3631544-.37091844.6343838-.73926882.6565358-2.19820274.3837663-4.42662077.5678501-6.658.55-1.48898712-.16024-2.57220278-1.4881821-2.43-2.979.38776265-1.4451304 1.86481837-2.3104221 3.315-1.942z"
            />
            <path
              data-celebrateicon="5"
              className="cc-icon-celebrate-shape cc-icon-celebrate-color-4"
              d="m54.649 16.035c-2.0979354.7625174-4.1225317 1.7133982-6.049 2.841-.3373925.1494625-.528779.5103098-.4632688.8734642s.3709184.6343838.7392688.6565358c2.1982027.3837663 4.4266208.5678501 6.658.55 1.4889871-.16024 2.5722028-1.4881821 2.43-2.979-.3877626-1.4451304-1.8648184-2.3104221-3.315-1.942z"
            />
            <path
              data-celebrateicon="6"
              className="cc-icon-celebrate-shape cc-icon-celebrate-color-4"
              d="m3.351 41.946c2.09824414-.7638016 4.12287404-1.7163717 6.049-2.846.3362813-.1500591.52669067-.5102892.46127627-.8726754-.06541439-.3623863-.36975328-.6333093-.73727627-.6563246-2.19820274-.3837663-4.42662077-.5678501-6.658-.55-1.48979206.1592295-2.57415223 1.4874816-2.432 2.979.38598189 1.447731 1.86495066 2.3154045 3.317 1.946z"
            />
            <path
              data-celebrateicon="7"
              className="cc-icon-celebrate-shape cc-icon-celebrate-color-4"
              d="m54.649 41.946c-2.0982441-.7638016-4.122874-1.7163717-6.049-2.846-.3362813-.1500591-.5266907-.5102892-.4612763-.8726754.0654144-.3623863.3697533-.6333093.7372763-.6563246 2.1982027-.3837663 4.4266208-.5678501 6.658-.55 1.4897921.1592295 2.5741522 1.4874816 2.432 2.979-.3859819 1.447731-1.8649507 2.3154045-3.317 1.946z"
            />
            <path
              data-celebrateicon="8"
              className="cc-icon-celebrate-shape cc-icon-celebrate-color-4"
              d="m38.11 1.831c-.555191 2.16318473-.9133205 4.3722081-1.07 6.6-.0665276.36249163.1231944.72352822.4594654.8743522.3362711.15082398.7320682.05240384.9585346-.2383522 1.5583361-1.59719549 2.9687674-3.33228706 4.214-5.184.7116078-1.32056207.2271847-2.96786837-1.086-3.693-1.4125202-.49670693-2.9618646.23472981-3.476 1.641z"
            />
            <path
              data-celebrateicon="9"
              className="cc-icon-celebrate-shape cc-icon-celebrate-color-4"
              d="m19.857 56.169c.5544865-2.1615978.912278-4.3689275 1.069-6.595.0665276-.3624916-.1231944-.7235282-.4594654-.8743522-.3362711-.150824-.7320682-.0524038-.9585346.2383522-1.5560336 1.5976495-2.9644333 3.3327122-4.208 5.184-.7088332 1.3193231-.2247132 2.9633698 1.086 3.688 1.4110364.4934645 2.9568645-.2373634 3.471-1.641z"
            />
            <path
              data-celebrateicon="10"
              className="cc-icon-celebrate-shape cc-icon-celebrate-color-4"
              d="m38.11 56.169c-.5548935-2.1615521-.9130209-4.3688886-1.07-6.595-.0665276-.3624916.1231944-.7235282.4594654-.8743522.3362711-.150824.7320682-.0524038.9585346.2383522 1.5583361 1.5971955 2.9687674 3.3322871 4.214 5.184.7082518 1.3193833.2242572 2.9630041-1.086 3.688-1.4125202.4967069-2.9618646-.2347298-3.476-1.641z"
            />
          </g>
          <path
            data-celebrateicon="11"
            className="cc-icon-celebrate-shape cc-icon-celebrate-color-5"
            d="m11.56 8.731c1.0296315 1.4583592 1.9255962 3.0066344 2.677 4.626.1614621.2520085.1257054.5824365-.0859291.7940709-.2116344.2116345-.5420624.2473912-.7940709.0859291-1.6193656-.7514038-3.1676408-1.6473685-4.626-2.677-.90495438-.7848204-1.01059242-2.15142892-.237-3.066.91457108-.77359242 2.2811796-.66795438 3.066.237z"
          />
          <path
            data-celebrateicon="12"
            className="cc-icon-celebrate-shape cc-icon-celebrate-color-5"
            d="m49.269 46.44c-1.4583592-1.0296315-3.0066344-1.9255962-4.626-2.677-.2520085-.1614621-.5824365-.1257054-.7940709.0859291-.2116345.2116344-.2473912.5420624-.0859291.7940709.7514038 1.6193656 1.6473685 3.1676408 2.677 4.626.4713756.6430443 1.276353.9494395 2.0560035.7825682.7796504-.1668713 1.3886934-.7759143 1.5555647-1.5555647.1668713-.7796505-.1395239-1.5846279-.7825682-2.0560035z"
          />
          <path
            data-celebrateicon="13"
            className="cc-icon-celebrate-shape cc-icon-celebrate-color-5"
            d="m8.731 46.44c1.4583592-1.0296315 3.0066344-1.9255962 4.626-2.677.2520085-.1614621.5824365-.1257054.7940709.0859291.2116345.2116344.2473912.5420624.0859291.7940709-.7514038 1.6193656-1.6473685 3.1676408-2.677 4.626-.7848204.9049544-2.15142892 1.0105924-3.066.237-.77359242-.9145711-.66795438-2.2811796.237-3.066z"
          />
          <path
            data-celebrateicon="14"
            className="cc-icon-celebrate-shape cc-icon-celebrate-color-5"
            d="m46.44 8.731c-1.0296315 1.4583592-1.9255962 3.0066344-2.677 4.626-.1614621.2520085-.1257054.5824365.0859291.7940709.2116344.2116345.5420624.2473912.7940709.0859291 1.6193656-.7514038 3.1676408-1.6473685 4.626-2.677.9049544-.7848204 1.0105924-2.15142892.237-3.066-.9145711-.77359242-2.2811796-.66795438-3.066.237z"
          />
          <path
            data-celebrateicon="15"
            className="cc-icon-celebrate-shape cc-icon-celebrate-color-2"
            d="m31 49.664c-.3026701-1.7593658-.763883-3.487743-1.378-5.164-.0644729-.291816-.3231466-.4995773-.622-.4995773s-.5575271.2077613-.622.4995773c-.614117 1.676257-1.0753299 3.4046342-1.378 5.164-.085756 1.1950494.8060057 2.2366272 2 2.336 1.1939943-.0993728 2.085756-1.1409506 2-2.336z"
          />
          <path
            data-celebrateicon="16"
            className="cc-icon-celebrate-shape cc-icon-celebrate-color-2"
            d="m8.336 27c1.7593658.3026701 3.487743.763883 5.164 1.378.291816.0644729.4995773.3231466.4995773.622s-.2077613.5575271-.4995773.622c-1.676257.614117-3.4046342 1.0753299-5.164 1.378-1.19504943.085756-2.23662719-.8060057-2.336-2 .09937281-1.1939943 1.14095057-2.085756 2.336-2z"
          />
          <path
            data-celebrateicon="17"
            className="cc-icon-celebrate-shape cc-icon-celebrate-color-2"
            d="m49.664 27c-1.7593658.3026701-3.487743.763883-5.164 1.378-.291816.0644729-.4995773.3231466-.4995773.622s.2077613.5575271.4995773.622c1.676257.614117 3.4046342 1.0753299 5.164 1.378 1.1950494.085756 2.2366272-.8060057 2.336-2-.0993728-1.1939943-1.1409506-2.085756-2.336-2z"
          />
          <path
            data-celebrateicon="18"
            className="cc-icon-celebrate-shape cc-icon-celebrate-color-3"
            d="m20.841 17.944c.9884273 1.4175816 1.8427781 2.9240779 2.552 4.5.1501853.2524107.1099255.5745539-.0977603.7822397s-.529829.2479456-.7822397.0977603c-1.5761218-.7088282-3.0826538-1.5631992-4.5-2.552-.6109184-.4801888-.8910982-1.2689921-.7199686-2.0269612.1711297-.7579692.7630382-1.3498777 1.5210074-1.5210074.7579691-.1711296 1.5467724.1090502 2.0269612.7199686z"
          />
          <path
            data-celebrateicon="19"
            className="cc-icon-celebrate-shape cc-icon-celebrate-color-3"
            d="m39.987 37.09c-1.4174166-.9884058-2.9239426-1.8424387-4.5-2.551-.2522013-.1494948-.5736389-.1091452-.7810659.0980461-.207427.2071912-.248142.5285828-.0989341.7809539.7092219 1.5759221 1.5635727 3.0824184 2.552 4.5.4801888.6109184 1.2689921.8910982 2.0269612.7199686.7579692-.1711297 1.3498777-.7630382 1.5210074-1.5210074.1711296-.7579691-.1090502-1.5467724-.7199686-2.0269612z"
          />
          <path
            data-celebrateicon="20"
            className="cc-icon-celebrate-shape cc-icon-celebrate-color-3"
            d="m18.013 37.09c1.4174166-.9884058 2.9239426-1.8424387 4.5-2.551.2522013-.1494948.5736389-.1091452.7810659.0980461.207427.2071912.248142.5285828.0989341.7809539-.7092219 1.5759221-1.5635727 3.0824184-2.552 4.5-.4801888.6109184-1.2689921.8910982-2.0269612.7199686-.7579692-.1711297-1.3498777-.7630382-1.5210074-1.5210074-.1711296-.7579691.1090502-1.5467724.7199686-2.0269612z"
          />
          <path
            data-celebrateicon="21"
            className="cc-icon-celebrate-shape cc-icon-celebrate-color-3"
            d="m37.159 17.944c-.9884273 1.4175816-1.8427781 2.9240779-2.552 4.5-.1501853.2524107-.1099255.5745539.0977603.7822397s.529829.2479456.7822397.0977603c1.5761218-.7088282 3.0826538-1.5631992 4.5-2.552.6109184-.4801888.8910982-1.2689921.7199686-2.0269612-.1711297-.7579692-.7630382-1.3498777-1.5210074-1.5210074-.7579691-.1711296-1.5467724.1090502-2.0269612.7199686z"
          />
          <path
            data-celebrateicon="22"
            className="cc-icon-celebrate-shape cc-icon-celebrate-color-1"
            d="m31 37.469c-.3034036-1.7012084-.7647328-3.3704298-1.378-4.986-.0726454-.2841909-.3286712-.4830031-.622-.4830031s-.5493546.1988122-.622.4830031c-.6132672 1.6155702-1.0745964 3.2847916-1.378 4.986-.0948523.7730179.2642625 1.5314848.9223344 1.9480123.6580719.4165274 1.4972593.4165274 2.1553312 0 .6580719-.4165275 1.0171867-1.1749944.9223344-1.9480123z"
          />
          <path
            data-celebrateicon="23"
            className="cc-icon-celebrate-shape cc-icon-celebrate-color-1"
            d="m20.531 27c1.7012084.3034036 3.3704298.7647328 4.986 1.378.2841909.0726454.4830031.3286712.4830031.622s-.1988122.5493546-.4830031.622c-1.6155702.6132672-3.2847916 1.0745964-4.986 1.378-.7730179.0948523-1.5314848-.2642625-1.9480123-.9223344-.4165274-.6580719-.4165274-1.4972593 0-2.1553312.4165275-.6580719 1.1749944-1.0171867 1.9480123-.9223344z"
          />
          <path
            data-celebrateicon="24"
            className="cc-icon-celebrate-shape cc-icon-celebrate-color-1"
            d="m37.469 27c-1.7012084.3034036-3.3704298.7647328-4.986 1.378-.2841909.0726454-.4830031.3286712-.4830031.622s.1988122.5493546.4830031.622c1.6155702.6132672 3.2847916 1.0745964 4.986 1.378.7730179.0948523 1.5314848-.2642625 1.9480123-.9223344.4165274-.6580719.4165274-1.4972593 0-2.1553312-.4165275-.6580719-1.1749944-1.0171867-1.9480123-.9223344z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Celebrate;
