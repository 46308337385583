/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import API from "data/network/tyto/";
import { Menu, Nav } from "components/meta/";

import PPFPlan from "./Plan";
import { Redirect } from "react-router-dom";

function parseParamID(planID?: string | Number) {
  if (!planID) {
    return undefined;
  }

  const parsedID = parseInt(`${planID}`, 10);

  if (!parsedID || parsedID === NaN) {
    return undefined;
  }

  return parsedID;
}

function parseGoalID(goalID?: string | Number) {
  if (!goalID) {
    return undefined;
  }

  const parsedID = parseInt(`${goalID}`, 10);

  if (!parsedID || parsedID === NaN) {
    return undefined;
  }

  return parsedID;
}

interface Props extends SITE.GeneralStoreInjectedProps {
  filter?: string;
  planID?: string;
  goalID?: string;
  subInterface?: PlanPage.Subinterface;
}

const PPFPlanWrapper = (props: Props) => {
  const [menuHasBeenOpen, updateMenuHasBeenOpen] = React.useState(
    _.get(props, "GeneralStore.state.showMenu", false)
  );

  const storeMenuOpenValue = _.get(props, "GeneralStore.state.showMenu", false);

  const [redirectURL, updateRedirectURL] = React.useState("");

  // * If config client has not previously loaded, do so now
  React.useEffect(() => {
    const uploadURL = props.GeneralStore.state?.uploadURL;

    if (!uploadURL) {
      API.Configuration.Client.get({})
        .then((resp) => {
          props.GeneralStore.dispatch?.({
            payload: {
              configurationClient: resp,
            },
            type: "GENERAL_STORE_CONFIGURATION_CLIENT_LOADED",
          });
        })
        .catch((err) => {
          // TODO: Handle error
        });
    }
  }, []);

  // * Trigger flag so that turning menu off animates back left
  React.useEffect(() => {
    if (!menuHasBeenOpen && storeMenuOpenValue) {
      updateMenuHasBeenOpen(true);
    }
  }, [storeMenuOpenValue, menuHasBeenOpen]);

  const planID = React.useMemo(() => {
    return parseParamID(props.planID);
  }, [props.planID]);
  const goalID = React.useMemo(() => {
    return parseGoalID(props.goalID);
  }, [props.goalID]);

  const menuIsOpen = storeMenuOpenValue;
  // const isMobile = !!props.GeneralStore?.state?.isMobile;
  return (
    <div className={cx("interface-wrapper", menuIsOpen && "menu-open")}>
      {/* {!isMobile && <Nav className="profile-nav" />} */}
      <Menu className="profile-menu" menuType="plan" planID={planID} />
      <div
        className={cx(
          "interface-main-cont interface-plan",
          menuHasBeenOpen && "animate-back"
        )}
      >
        {planID ? (
          <PPFPlan
            isMobile={_.get(props, "GeneralStore.state.isMobile", false)}
            planID={planID}
            targetGoalID={goalID}
            filter={props.filter}
            GeneralStore={props.GeneralStore}
            subInterface={props.subInterface}
            showAll={props.goalID === "all"}
          />
        ) : (
          // <div>No Plan Identifier Supplied.</div>
          //Redirecting to avoid black screen.
          <Redirect to={redirectURL} />
        )}
      </div>
    </div>
  );
};

export default PPFPlanWrapper;
