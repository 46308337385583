/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { Icon, Input, Select } from "components/common/";
import { useTimezones } from "data/network/hooks/";

import "./DataItem.scss";

interface Props {
  curIdx: number;
  isSaving: boolean;
  dataItem: ProfilePage.PersonDataItem<any>;
  updateValue: ProfilePage.PersonDataItemsProps["updateValue"];
}

const DataItem = ({ curIdx, dataItem, isSaving, updateValue }: Props) => {
  const [animationDuration] = React.useState(600 + curIdx * 100);
  const hasChange = dataItem.localValue !== dataItem.storedValue;

  return (
    <section
      className={cx("profile-tabs-dataitem", hasChange && "has-change")}
      style={{ animationDuration: `${animationDuration}ms` }}
    >
      <div className="profile-tabs-dataitem-label-cont one-third">
        <label
          className="profile-tabs-dataitem-label"
          htmlFor={dataItem.friendlyLabel}
        >
          {dataItem.friendlyLabel}
        </label>
      </div>

      <div className="profile-tabs-dataitem-input-cont one-third">
        {dataItem.key === "timeZoneNameGeneral" ? (
          <TimeZoneSelect
            updateValue={updateValue}
            isSaving={isSaving}
            dataItem={dataItem}
          />
        ) : (
          <Input
            className="profile-tabs-dataitem-input"
            disabled={isSaving}
            name={dataItem.friendlyLabel}
            onChange={(newValue) => updateValue(dataItem, newValue)}
            value={dataItem.localValue}
          />
        )}

        {hasChange && (
          <Icon
            className="profile-tabs-data-undo-btn"
            isButton={true}
            onClick={() => {
              updateValue(dataItem, dataItem.storedValue);
            }}
            icon="close"
            size={20}
          />
        )}
      </div>
    </section>
  );
};

interface TimeZoneSelectProps {
  dataItem: ProfilePage.PersonDataItem<TytoData.Person["timeZoneNameGeneral"]>;
  isSaving: boolean;
  updateValue: Props["updateValue"];
}

const TimeZoneSelect = ({
  dataItem,
  isSaving,
  updateValue,
}: TimeZoneSelectProps) => {
  const { data, storedValueQuery, isFetched } = useTimezones({
    filterDate: new Date().toISOString().split("Z")[0],
  });

  const timezones: TytoData.TimeZones.TimeZone[] =
    data?.timeZones ?? storedValueQuery.data?.timeZones ?? [];

  const timezoneOptions = React.useMemo(() => {
    return timezones.map((timeZone) => {
      const {
        nameGeneral,
        observanceType,
        offSetFromMinutes,
        offSetToMinutes,
      } = timeZone;

      const offSetMinutes =
        observanceType === "ocDAYLIGHT" ? offSetFromMinutes : offSetToMinutes;
      const offsetHours = offSetMinutes / 60;

      return {
        label: `${nameGeneral}`,
        value: `${nameGeneral}`,
      };
    });
  }, [timezones]);

  // * If no timeznes don't render list
  if (!timezones || !timezones.length) {
    return <p>{dataItem.localValue}</p>;
  }

  const unsetTimezone = !dataItem.localValue;

  return (
    <>
      <Select
        className={cx(
          "profile-tabs-dataitem-select",
          unsetTimezone && "unset-value"
        )}
        disabled={isSaving}
        options={timezoneOptions || []}
        onChange={(newValue) => updateValue(dataItem, newValue)}
        placeholder="Select a Timezone"
        value={dataItem.localValue}
      />

      {unsetTimezone && (
        <span className="profile-tabs-dataitem-unset-timezone-msg">
          No Timezone Specified
        </span>
      )}
    </>
  );
};

export default DataItem;
