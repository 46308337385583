/*
 * Component Description
 */
import * as React from "react";

import { useLesson } from "data/network/hooks/";
import { getEncoding } from "data/helpers/";

import ImagesWrapper from "./subcomponents/ImagesWrapper";
import EncodingNotFound from "./subcomponents/EncodingNotFound";

interface Props {
  asset?: Data.Asset;
  lessonID?: number;
  markComplete?: () => void;
}

const DocumentViewer = (props: Props) => {
  const lessonQuery = useLesson({
    lessonID: props.lessonID ?? 0,
    isEnabled: !!props.lessonID && !props.asset,
  });

  const [pdfImagesEnc, updatePDFImagesEnc] = React.useState(
    getEncoding(
      props.asset?.encodings ??
        lessonQuery.data?.lesson?.assets?.[0]?.encodings ??
        lessonQuery.storedValueQuery?.data?.lesson?.assets?.[0]?.encodings ??
        [],
      "ocPDFIMAGES/imgtmp"
    )
  );

  React.useEffect(() => {
    const assetEncodings =
      props.asset?.encodings ??
      lessonQuery.data?.lesson?.assets?.[0]?.encodings ??
      lessonQuery.storedValueQuery?.data?.lesson?.assets?.[0]?.encodings ??
      [];

    if (!assetEncodings) {
      if (pdfImagesEnc) {
        updatePDFImagesEnc(undefined);
      }

      return;
    }

    updatePDFImagesEnc(getEncoding(assetEncodings, "ocPDFIMAGES/imgtmp"));
  }, [
    props.asset,
    lessonQuery.data?.lesson,
    lessonQuery.storedValueQuery?.data?.lesson,
  ]);

  return (
    <main className="document-viewer-modal-main-cont">
      {pdfImagesEnc ? (
        <article className="document-viewer-modal-article-cont">
          <ImagesWrapper encoding={pdfImagesEnc} />
        </article>
      ) : (
        <EncodingNotFound />
      )}
    </main>
  );
};

export default DocumentViewer;
