import * as React from "react";

import { IconProps } from "../typings";

const ElipsisV = ({
  className,
  color,
  gradient,
  gradientKey,
  size,
}: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 512"
      // viewBox="0 0 320 512"
      height={size}
      width={size}
      fill={color}
    >
      <path
        // fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""}
        d="M32 224c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zM0 136c0 17.7 14.3 32 32 32s32-14.3 32-32-14.3-32-32-32-32 14.3-32 32zm0 240c0 17.7 14.3 32 32 32s32-14.3 32-32-14.3-32-32-32-32 14.3-32 32z"
      />
    </svg>
  );
};

export default ElipsisV;
