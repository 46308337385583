/*
 * Component Description
 */
import * as React from "react";

import { Button, CheckBox } from "../../../../components/common/";

import DataItem from "./DataItem";
import { determineIfChangesExist } from "../ProfileTabs";

import "./style.scss";

interface Props extends ProfilePage.PersonDataItemsProps {}

const Information = (props: Props) => {
  const [changesExist, updateChangesExist] = React.useState(
    determineIfChangesExist(props.dataItems)
  );

  React.useEffect(() => {
    updateChangesExist(determineIfChangesExist(props.dataItems));
  }, [props.dataItems]);

  return (
    <>
      <section className="profile-tabs-information">
        {props.dataItems.map((dataItem, curIdx) => (
          <DataItem
            key={dataItem.key}
            curIdx={curIdx}
            dataItem={dataItem}
            isSaving={props.isSaving}
            updateValue={props.updateValue}
          />
        ))}

        <div className="profile-tabs-information-save-wrapper one-third">
          <Button
            className="profile-tabs-information-save-btn"
            disabled={!changesExist || props.isSaving}
            onClick={props.saveChanges}
            theme="action"
            value={props.isSaving ? "Saving..." : "Save"}
          />
          <br />
          <br />
        </div>
      </section>
    </>
  );
};

export default Information;
