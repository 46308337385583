/*
 * Component Description
 */
import * as React from "react";

import { MenuToggleButton } from "components/common/";
import { usePrimaryParentGoalsByName } from "data/helpers/Hooks";

import DisabledMsg from "../disabled-msg/";

import ChildGoalsList from "../child-goals-list/";

import "./style.scss";

interface Props extends PlanPage.PlanData {}

const Financial = (props: Props) => {
  const { financial } = usePrimaryParentGoalsByName(props.planGoals);

  return (
    <article>
        <DisabledMsg plan={props.plan} planID={props.planID} />

      <h3 className="plan-parentgoal-title title-font font-size-large">
        <MenuToggleButton className="plan-parentgoal-menu-btn" />
        Financial Goals
      </h3>

      {financial && (
        <ChildGoalsList planID={props.planID} parentGoal={financial} />
      )}
    </article>
  );
};

export default Financial;
