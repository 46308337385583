/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import {
  useNewNoticeThreadMutation,
  useNewCommentMutation,
} from "data/network/hooks/";

import { Icon, Input, SessionUserThumb } from "components/common/";

interface NoticeInputProps {
  messageText: string;
  goalNotices: TytoData.Notices.Notice[];
  isMobile: boolean;
  newMessageInputRef: React.MutableRefObject<HTMLInputElement | null>;
  onPost?: () => void;
  permissions: TytoData.PPF.Plan.MemberPermissionData;
  plan: TytoData.PPF.Plan.Plan;
  childGoal: TytoData.PPF.Plan.Goals.ChildGoal;
  replyNoticeID: number;
  updateMessageText: (newMessageText: string) => void;
  updateReplyNoticeID: (noticeID: number) => void;
  isMemberOfPlan: boolean;
}

const NoticeInput = (props: NoticeInputProps) => {
  const [focusedNotice, updateFocusedNotice] = React.useState(() => {
    if (!props.replyNoticeID || !props.goalNotices?.length) {
      return undefined;
    }

    return props.goalNotices.find(
      (notice) => notice.noticeID === props.replyNoticeID
    );
  });
  const noticeMutation = useNewNoticeThreadMutation({
    planID: props.plan.gsPlanID,
    goalID: props.childGoal.gsGoalID,
    onError: () => {},
    onSuccess: () => {
      props.onPost?.();
    },
  });

  const commentMutation = useNewCommentMutation({
    planID: props.plan.gsPlanID,
    noticeID: props.replyNoticeID,
    onError: () => {},
    onSuccess: () => {
      props.onPost?.();

      if (props.replyNoticeID) {
        props.updateReplyNoticeID(0);
      }
    },
  });

  React.useEffect(() => {
    let newFocusedNotice = undefined;

    if (props.replyNoticeID && props.goalNotices?.length) {
      newFocusedNotice = props.goalNotices.find(
        (notice) => notice.noticeID === props.replyNoticeID
      );
    }

    updateFocusedNotice(newFocusedNotice);
  }, [props.goalNotices, props.replyNoticeID]);

  const isLoading = noticeMutation.isLoading || commentMutation.isLoading;

  return (
    <form
      className="currentgoal-notice-newcomment-cont"
      onSubmit={(e) => {
        if (isLoading || !props.messageText) {
          return;
        } else {
          if (props.replyNoticeID) {
            commentMutation.mutate({
              messageText: props.messageText,
            });
          } else {
            noticeMutation.mutate({
              messageText: props.messageText,
            });
          }
        }

        e.preventDefault?.();
      }}
    >
      {/* <div className="currentgoal-notice-newcomment-userthumb-cont">
        <SessionUserThumb
          size={34}
          // userID={props.plan.aboutID}
          // userName={props.plan.about?.elementName ?? ""}
        />
      </div> */}

      <div className="currentgoal-notice-newcomment-input-cont">
        {focusedNotice && !!props.replyNoticeID && (
          <section className="currentgoal-notice-newcomment-replying-cont">
            <span style={{ width: "65px", flexBasis: "65px" }}>
              Replying to:
            </span>
            <p className="currentgoal-notice-newcomment-replying-msg">
              {focusedNotice.noticeText}
            </p>
            <Icon
              className="currentgoal-notice-newcomment-replying-close-icon"
              isButton={true}
              buttonProps={{
                type: "button",
              }}
              onClick={() => props.updateReplyNoticeID(0)}
              icon="close"
              size={12}
            />
          </section>
        )}
        <Input
          disabled={!props.isMemberOfPlan}
          containerClassName="currentgoal-notice-newcomment-wrapper"
          className="currentgoal-notice-newcomment-input"
          readOnly={isLoading}
          rows={1}
          innerRef={props.newMessageInputRef}
          name="New Message"
          placeholder={
            !props.isMemberOfPlan
              ? "Only managers can comment..."
              : "Write a comment..."
          }
          onChange={(newVal) => props.updateMessageText?.(newVal)}
          type="textarea"
          value={props.messageText}
          //   rows={1}
        />
      </div>

      <div className="currentgoal-notice-newcomment-btn-cont">
        <Icon
          isButton={true}
          buttonProps={{
            disabled: isLoading || !props.messageText,
            className: cx(
              "currentgoal-notice-newcomment-btn",
              !!props.messageText && "active"
            ),
            type: "submit",
          }}
          onClick={_.noop}
          size={props.isMobile ? 18 : 25}
          icon="send"
        />
      </div>
    </form>
  );
};

export default NoticeInput;
