/*
 * Component Description
 */
import * as React from "react";

import { IconProps } from "../typings";

const Message = ({ className, color, size, style }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      fill={color}
    >
      <g id="Layer_2" data-name="Layer 2">
        <path d="m12 1a10.995 10.995 0 0 0 -9.464 16.6l-1.475 4.058a1 1 0 0 0 .939 1.342 1.019 1.019 0 0 0 .342-.06l4.058-1.476a11 11 0 1 0 5.6-20.464z" />
      </g>
    </svg>
  );
};

export default Message;
