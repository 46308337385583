import * as React from "react";

import { IconProps } from "../typings";

const ArrowLeftSquare = ({ className, color, size }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 21"
      height={size}
      width={size}
      fill={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6875 2.625C19.6875 2.2769 19.5492 1.94306 19.3031 1.69692C19.0569 1.45078 18.7231 1.3125 18.375 1.3125H2.625C2.2769 1.3125 1.94306 1.45078 1.69692 1.69692C1.45078 1.94306 1.3125 2.2769 1.3125 2.625V18.375C1.3125 18.7231 1.45078 19.0569 1.69692 19.3031C1.94306 19.5492 2.2769 19.6875 2.625 19.6875H18.375C18.7231 19.6875 19.0569 19.5492 19.3031 19.3031C19.5492 19.0569 19.6875 18.7231 19.6875 18.375V2.625ZM0 2.625C0 1.92881 0.276562 1.26113 0.768845 0.768845C1.26113 0.276562 1.92881 0 2.625 0L18.375 0C19.0712 0 19.7389 0.276562 20.2312 0.768845C20.7234 1.26113 21 1.92881 21 2.625V18.375C21 19.0712 20.7234 19.7389 20.2312 20.2312C19.7389 20.7234 19.0712 21 18.375 21H2.625C1.92881 21 1.26113 20.7234 0.768845 20.2312C0.276562 19.7389 0 19.0712 0 18.375V2.625ZM15.0938 9.84375C15.2678 9.84375 15.4347 9.91289 15.5578 10.036C15.6809 10.159 15.75 10.326 15.75 10.5C15.75 10.674 15.6809 10.841 15.5578 10.964C15.4347 11.0871 15.2678 11.1562 15.0938 11.1562H7.49044L10.3084 13.9729C10.3694 14.0339 10.4178 14.1063 10.4508 14.186C10.4838 14.2658 10.5008 14.3512 10.5008 14.4375C10.5008 14.5238 10.4838 14.6092 10.4508 14.689C10.4178 14.7687 10.3694 14.8411 10.3084 14.9021C10.2474 14.9631 10.1749 15.0115 10.0952 15.0446C10.0155 15.0776 9.93004 15.0946 9.84375 15.0946C9.75746 15.0946 9.67202 15.0776 9.5923 15.0446C9.51258 15.0115 9.44014 14.9631 9.37912 14.9021L5.44162 10.9646C5.38051 10.9037 5.33202 10.8312 5.29894 10.7515C5.26586 10.6718 5.24883 10.5863 5.24883 10.5C5.24883 10.4137 5.26586 10.3282 5.29894 10.2485C5.33202 10.1688 5.38051 10.0963 5.44162 10.0354L9.37912 6.09787C9.50235 5.97465 9.66948 5.90542 9.84375 5.90542C10.018 5.90542 10.1851 5.97465 10.3084 6.09787C10.4316 6.2211 10.5008 6.38823 10.5008 6.5625C10.5008 6.73677 10.4316 6.9039 10.3084 7.02713L7.49044 9.84375H15.0938Z"
      />
    </svg>
  );
};

export default ArrowLeftSquare;
