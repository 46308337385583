import { ENDPOINT_GS_MEMBER_CLONE } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_GS_MEMBER_CLONE;

export const GSMemberClone = {
  get(params: Endpoints.Tyto.GS.Member.Clone.PutParameters, callOpts?: CallOpts) {
    return callWrapper(
      "put",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Member.Clone.Put>;
  },
};
