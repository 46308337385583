import * as React from "react";

import { IconProps } from "../typings";

const People = ({
  className,
  color,
  gradient,
  gradientKey,
  size,
}: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 23"
      height={size + 5}
      width={size + 5}
      fill={color}
    >
      {Array.isArray(gradient) && (
        <defs>
          <linearGradient id={gradientKey} x1="0" x2="1" y1="0" y2="1">
            {gradient.map((grad) => (
              <stop
                key={`${grad.offsetPercent}-${grad.color}`}
                offset={`${grad.offsetPercent}%`}
                stopColor={grad.color}
              />
            ))}
          </linearGradient>
        </defs>
      )}

      <path
        fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""}
        d="M14.5 10.9694C16.4733 10.7235 18.0003 9.04026 18.0003 7.00034C18.0003 4.96043 16.4733 3.27716 14.5 3.03125C15.4337 4.08899 16.0003 5.4785 16.0003 7.00034C16.0003 8.52218 15.4337 9.91169 14.5 10.9694Z"
      />
      <path
        fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""}
        d="M19.999 19.9989C19.999 20.5511 20.4467 20.9989 20.999 20.9989C21.5513 20.9989 21.999 20.5511 21.999 19.9989V17.9989C21.999 15.3388 19.9217 13.1638 17.3008 13.0078C18.3529 13.9396 19.1578 15.1442 19.6046 16.5108C19.8555 16.9494 19.999 17.4574 19.999 17.9989V19.9989Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""}
        d="M7 15C5.34315 15 4 16.3431 4 18V20C4 20.5523 3.55228 21 3 21C2.44772 21 2 20.5523 2 20V18C2 15.2386 4.23858 13 7 13H13C15.7614 13 18 15.2386 18 18V20C18 20.5523 17.5523 21 17 21C16.4477 21 16 20.5523 16 20V18C16 16.3431 14.6569 15 13 15H7Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""}
        d="M10 5C8.89543 5 8 5.89543 8 7C8 8.10457 8.89543 9 10 9C11.1046 9 12 8.10457 12 7C12 5.89543 11.1046 5 10 5ZM6 7C6 4.79086 7.79086 3 10 3C12.2091 3 14 4.79086 14 7C14 9.20914 12.2091 11 10 11C7.79086 11 6 9.20914 6 7Z"
      />
    </svg>
  );
};

export default People;
