import TytoCalls from "../data/network/tyto/";
import {
  handleInvalidSession,
  createNewCoursesMapsFromAllTaskStructures,
} from "../data/helpers/";

export async function onSessionDataChange({
  AppStore,
  sessionData,
}: {
  AppStore: StoreAPI.AppStoreProps;
  sessionData: Data.SessionData;
}) {
  try {
    // loadProfileData({
    //   AppStore,
    //   sessionData,
    // });
  } catch (err) {
    // TODO: Something Errored I guess?
    console.log("Error occurred: ", err);
  }
}

async function loadProfileData({
  AppStore,
  sessionData,
}: {
  AppStore: StoreAPI.AppStoreProps;
  sessionData: Data.SessionData;
}) {
  try {
    const { person: personData } = await TytoCalls.Person.get({
      personID: sessionData.userID,
    });

    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          personData,
        },
        type: "APP_STORE_PERSONAL_DATA_LOADED",
      });
    }
  } catch (err) {
    console.log("Error occurred: ", err);

    handleInvalidSession({
      AppStore,
      errorData: err as Data.TytoErrorObject,
    });
  }
}

export function parseParentsMap({
  AppStore,
  taskStructures,
  isStoredData,
}: {
  AppStore: StoreAPI.AppStoreProps;
  isStoredData?: boolean;
  taskStructures?: (Endpoints.Responses.Task.Structure.Get | undefined)[];
}) {
  // * [0.5] - Attempt to load TaskStructures from storage and use those right away, but

  // * [1] - Call /api/TaskStructure GET for every plan
  const structuresSafe = (taskStructures || []).map(
    (taskStructure) => taskStructure?.task?.tasks || []
  );

  // * [2] - Once all TaskStructures are loaded run 'createNewCoursesMapsFromAllTaskStructures' from main Helpers file
  const parsedCoursesParentsList =
    createNewCoursesMapsFromAllTaskStructures(structuresSafe);

  // * [3] - Send parsed list to AppStore for usage
  AppStore.dispatch?.({
    payload: {
      isStoredData: !!isStoredData,
      parentsListMap: parsedCoursesParentsList,
    },
    type: "APP_STORE_UPDATE_COURSES_PARENT_LIST_MULTIPLE",
  });
}

// async function loadPersonalDiscMini({
//   AppStore,
//   sessionData,
// }: {
//   AppStore: StoreAPI.AppStoreProps;
//   sessionData: Data.SessionData;
// }) {
//   try {
//     const { discProfiles, permit } = await TytoCalls.DISCProfilesMini.get({
//       personIDs: `${sessionData.userID}`,
//     });

//     const personalDiscMini = (discProfiles || []).find(
//       (discMiniProfile) => discMiniProfile.personID === sessionData.userID
//     );

//     if (personalDiscMini && AppStore.dispatch) {
//       AppStore.dispatch({
//         payload: {
//           discMini: personalDiscMini,
//         },
//         type: "APP_STORE_DISC_MINI_LOADED",
//       });
//     }
//   } catch (err) {
//     console.log("Error occurred: ", err);
//   }
// }
