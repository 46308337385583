import { ENDPOINT_GS_PLAN_NOTICE_COMMENT, ENDPOINT_GS_PLAN_NOTICE_COMMENT_LIKE, ENDPOINT_GS_PLAN_NOTICE_COMMENT_UNLIKE } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_GS_PLAN_NOTICE_COMMENT;
const endpointLike = ENDPOINT_GS_PLAN_NOTICE_COMMENT_LIKE;
const endpointUnlike = ENDPOINT_GS_PLAN_NOTICE_COMMENT_UNLIKE;

export const GSPlanNoticeComment = {
  delete(params: Endpoints.Tyto.GS.Plan.Notice.Comment.DeleteParameters, callOpts?: CallOpts) {
    return callWrapper(
      "delete",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Plan.Notice.Comment.Delete>;
  },
  post(params: Endpoints.Tyto.GS.Plan.Notice.Comment.PostParameters, callOpts?: CallOpts) {
    return callWrapper(
      "post",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Plan.Notice.Comment.Post>;
  },
  put(params: Endpoints.Tyto.GS.Plan.Notice.Comment.PutParameters, callOpts?: CallOpts) {
    return callWrapper(
      "post",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Plan.Notice.Comment.Put>;
  },
};

export const GSPlanNoticeCommentLike = {
  put(params: Endpoints.Tyto.GS.Plan.Notice.Comment.Like.PutParameters, callOpts?: CallOpts) {
    return callWrapper(
      "put",
      endpointLike,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Plan.Notice.Comment.Like.Put>;
  },
};

export const GSPlanNoticeCommentUnlike = {
  put(params: Endpoints.Tyto.GS.Plan.Notice.Comment.Unlike.PutParameters, callOpts?: CallOpts) {
    return callWrapper(
      "put",
      endpointUnlike,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Plan.Notice.Comment.Unlike.Put>;
  },
};