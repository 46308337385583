/*
 * Component Description
 */
import * as React from "react";

import { IconProps } from "../typings";

const AngleRight = ({ className, color, size, style }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 192 512"
      height={size}
      width={size}
      fill={color}
    >
      <path d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z" />
    </svg>
  );
};

export default AngleRight;
