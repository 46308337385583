import { DEFAULT_APP_BRAND } from "data/constants/";

export const keys = {
  ACTIVE_SESSION_KEY: "active-session-key",
  COURSE_PLAN_CONTEXT: "course-plan-context",
  LAST_SESSION_ACTIVITY: "last-session-activity",
  LAST_LOGIN_SCREEN_EMAIL_ADDRESS_USED: "last-login-screen-email-address-used",
  SES_CHECK_REDIRECT_URL: "session-check-redirect-url",
  SESSION_DATA: "session-data",
  SITE_HAS_PARENT: "site-has-parent",
  SITE_THEME: "site-theme",
  STORED_SESSIONS: "stored-sessions",
  TEMP_SESSION_KEY: "temp-session-key",
  INPUT_TEXT: "input-text",
  MOST_RECENT_NOTICE_DATE: "notices-recent",
  USER_ALERT_COUNT: "u-a-c",
};

const LOCAL_FORAGE_CONFIG_NAME = `${DEFAULT_APP_BRAND}-LocalForage-Store`;

export const LOCAL_FORAGE_CONFIG = {
  name: LOCAL_FORAGE_CONFIG_NAME,
  description: `Used for storing information for the ${DEFAULT_APP_BRAND} Web Application`,
};
