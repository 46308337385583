/*
 * Component Description
 */
import * as React from "react";

import { Icon } from "components/common/";

import GoalStep from "./GoalStep";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import "./GoalSteps.scss";
import _ from "lodash";

interface LocalGoalStep extends TytoData.PPF.Plan.Goals.GoalItem {
  seq: number;
}

interface Props {
  createNewGoalStep: () => void;
  parentGoalType: keyof typeof TytoData.PPFParentGoalName;
  goalSteps: TytoData.PPF.Plan.Goals.GoalItem[];
  unsavedGoalSteps: TytoData.PPF.Plan.Goals.GoalItem[];
  updateGoalSteps: (steps: TytoData.PPF.Plan.Goals.GoalItem[]) => void;
  updateUnsavedGoalSteps: (steps: TytoData.PPF.Plan.Goals.GoalItem[]) => void;
  updating: boolean;
  isMobile: boolean;
}

const GoalSteps = (props: Props) => {
  const STEP_SIZE = props.isMobile ? 31 : 42;

  const [height, updateHeight] = React.useState(() => {
    var nondeletedSteps = props.goalSteps
      .concat(props.unsavedGoalSteps)
      .filter((step) => step.status !== "ocDISABLED");

    const heightPx =
      nondeletedSteps.length * STEP_SIZE + (nondeletedSteps.length - 1) * 15;

    return `${heightPx}px`;
  });
  const [keyedSavedGoalSteps, updateKeyedSavedGoalSteps] = React.useState(
    () => {
      return _.keyBy(props.goalSteps, "gsGoalItemKey");
    }
  );
  const [keyedUnsavedGoalSteps, updateKeyedUnsavedGoalSteps] = React.useState(
    () => {
      return _.keyBy(props.goalSteps, "gsGoalItemKey");
    }
  );

  const reSequence = React.useMemo<LocalGoalStep[]>(() => {
    return getSortedSteps({
      goalSteps: props.goalSteps,
      unsavedGoalSteps: props.unsavedGoalSteps,
    });
  }, [props.goalSteps, props.unsavedGoalSteps]);

  React.useEffect(() => {
    var nondeletedSteps = props.goalSteps
      .concat(props.unsavedGoalSteps)
      .filter((step) => step.status !== "ocDISABLED");

    updateHeight(
      `${
        nondeletedSteps.length * STEP_SIZE + (nondeletedSteps.length - 1) * 15
      }px`
    );
  }, [props.goalSteps, props.unsavedGoalSteps]);

  React.useEffect(() => {
    updateKeyedSavedGoalSteps(_.keyBy(props.goalSteps, "gsGoalItemKey"));
    updateKeyedUnsavedGoalSteps(
      _.keyBy(props.unsavedGoalSteps, "gsGoalItemKey")
    );
  }, [props.goalSteps, props.unsavedGoalSteps]);

  const onDragEnd = (result: DropResult) => {
    // * [1] - Make local copy of sorted list
    const listCopy = [...reSequence];

    if (
      result.destination?.index !== undefined &&
      listCopy[result.source.index]
    ) {
      listCopy[result.source.index].seq = result.destination.index;
    }

    // * [2] - Make no Array from copy, but with updated 'seq' values
    const newStepsList = listCopy
      .map((step, index) => {
        // * No Destination
        if (result?.destination?.index === undefined) {
          return step;
        }

        if (
          result?.destination?.index < result.source.index &&
          step.seq >= result.destination.index &&
          result.source.index !== index
        ) {
          step.seq += 1;
        } else if (
          result.destination.index > result.source.index &&
          step.seq <= result.destination.index &&
          result.source.index !== index
        ) {
          step.seq -= 1;
        }

        return step;
      })
      .sort((a, b) => a.seq - b.seq);

    // * [3] - Iterate over list and (A) Sort by saved or unsaved, and (B) Filter out no longer existing items
    // * NOTE: This saves looping over Array twice, once for each of the subsequent 'update' calls below
    const { savedSteps, unsavedSteps } = newStepsList.reduce(
      (
        accum: {
          savedSteps: Props["goalSteps"];
          unsavedSteps: Props["unsavedGoalSteps"];
        },
        step
      ) => {
        if (!!keyedSavedGoalSteps[step.gsGoalItemKey]) {
          accum.savedSteps.push(step);
        }

        if (!!keyedUnsavedGoalSteps[step.gsGoalItemKey]) {
          accum.unsavedSteps.push(step);
        }

        return accum;
      },
      {
        savedSteps: [],
        unsavedSteps: [],
      }
    );

    // * [4] - Update appropriate State values
    props.updateGoalSteps(savedSteps);
    props.updateUnsavedGoalSteps(unsavedSteps);
  };

  return (
    <DragDropContext
      onDragEnd={(element) => {
        onDragEnd(element);
      }}
    >
      <Droppable droppableId="goal-step-drop">
        {(provided) => (
          <ul
            className="cg-editgoal-goal-steps-list"
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ minHeight: height }}
          >
            {reSequence.map?.((goalStep, curIdx) => (
              <Draggable
                index={curIdx}
                key={goalStep.gsGoalItemKey ?? curIdx}
                draggableId={`${goalStep.gsGoalItemKey ?? curIdx}`}
              >
                {(provided, _snapshot) => (
                  <GoalStep
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    provided={provided}
                    isMobile={props.isMobile}
                    key={goalStep.gsGoalItemKey ?? curIdx}
                    deleteStep={() => {
                      const isSavedStep =
                        !!keyedSavedGoalSteps[goalStep.gsGoalItemKey];

                      if (!isSavedStep) {
                        // * Create copy of array that will be mutated
                        const stepsCopy = [...(props.unsavedGoalSteps ?? [])];

                        const removeIndx = stepsCopy.findIndex(
                          (step) =>
                            step.gsGoalItemKey === goalStep.gsGoalItemKey
                        );
                        if (removeIndx > -1) {
                          // * Remove this item from Array (item return in current iteration of '.map(thisItem => (...))')
                          stepsCopy.splice(removeIndx, 1);

                          if (stepsCopy) {
                            props.updateUnsavedGoalSteps?.(stepsCopy);
                          }
                        }
                      } else {
                        const newStepsList = getUpdatedStepsList({
                          index: curIdx,
                          seq: curIdx,
                          goalSteps: reSequence,
                          goalItem: goalStep,
                          status: "ocDISABLED",
                          savedSteps: props.goalSteps,
                        });
                        if (newStepsList) {
                          props.updateGoalSteps?.(newStepsList);
                        }
                      }
                    }}
                    // key={goalStep.gsGoalItemKey}
                    parentGoalType={props.parentGoalType}
                    stepID={curIdx}
                    stepText={goalStep.description}
                    index={curIdx}
                    isComplete={goalStep.status === "ocCOMPLETE"}
                    updating={props.updating}
                    updateIsComplete={(isComplete) => {
                      const isSavedStep =
                        !!keyedSavedGoalSteps[goalStep.gsGoalItemKey];

                      const newStepsList = getUpdatedStepsList({
                        index: curIdx,
                        seq: curIdx,
                        goalSteps: reSequence,
                        goalItem: goalStep,
                        status: isComplete ? "ocCOMPLETE" : "ocNOTSTARTED",
                        unsavedSteps: isSavedStep
                          ? undefined
                          : props.unsavedGoalSteps,
                        savedSteps: isSavedStep ? props.goalSteps : undefined,
                      });
                      if (newStepsList) {
                        if (isSavedStep) {
                          props.updateGoalSteps?.(newStepsList);
                        } else {
                          props.updateUnsavedGoalSteps?.(newStepsList);
                        }
                      }
                    }}
                    updateStepText={(newStepText) => {
                      const isSavedStep =
                        !!keyedSavedGoalSteps[goalStep.gsGoalItemKey];

                      const newStepsList = getUpdatedStepsList({
                        index: curIdx,
                        seq: curIdx,
                        goalSteps: reSequence,
                        goalItem: goalStep,
                        description: newStepText,
                        unsavedSteps: isSavedStep
                          ? undefined
                          : props.unsavedGoalSteps,
                        savedSteps: isSavedStep ? props.goalSteps : undefined,
                      });

                      console.log("newStepsList: ", newStepsList);

                      if (newStepsList) {
                        if (isSavedStep) {
                          props.updateGoalSteps?.(newStepsList);
                        } else {
                          props.updateUnsavedGoalSteps?.(newStepsList);
                        }
                      }
                    }}
                  />
                )}
              </Draggable>
            )) ?? null}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

function getUpdatedStepsList({
  description,
  status,
  // index,
  // goalSteps,
  goalItem,
  seq,
  unsavedSteps,
  savedSteps,
}: // deletingStep,
{
  index: number;
  description?: string;
  status?: keyof typeof TytoData.GoalItemStatus;
  seq: number;
  goalItem: TytoData.PPF.Plan.Goals.GoalItem;
  goalSteps: TytoData.PPF.Plan.Goals.GoalItem[];
  unsavedSteps?: TytoData.PPF.Plan.Goals.GoalItem[];
  savedSteps?: TytoData.PPF.Plan.Goals.GoalItem[];
  deletingStep?: boolean;
}) {
  // const newSteps = [...goalSteps];

  // let targetItem = newSteps[index];

  // if (targetItem.gsGoalItemKey !== goalStep.gsGoalItemKey) {
  //   let targetIdx = goalSteps.findIndex(
  //     (goal) => goal.gsGoalItemKey === goalItem.gsGoalItemKey
  //   );

  //   let goalAtIdx = goalSteps[targetIdx];

  //   if (targetIdx < 0 || !goalAtIdx) {
  //     // ! Uhh... what?
  //     return null;
  //   }
  // }

  // const newGoalData = {
  //   ...goalAtIdx,
  //   seq: seq ?? goalAtIdx.seq,
  //   description: description ?? goalAtIdx.description,
  //   status: status ?? goalAtIdx.status,
  // };
  // if (deletingStep) {
  //   newSteps.splice(targetIdx, 1);
  // } else {
  //   newSteps.splice(targetIdx, 1, newGoalData);
  // }
  // if (unsavedSteps) {
  //   return newSteps.filter((step) =>
  //     unsavedSteps.find(
  //       (innerStep) => innerStep.gsGoalItemKey === step.gsGoalItemKey
  //     )
  //   );
  // }

  // if (savedSteps) {
  //   return newSteps.filter((step) =>
  //     savedSteps.find(
  //       (innerStep) => innerStep.gsGoalItemKey === step.gsGoalItemKey
  //     )
  //   );
  // }

  // * [1] - If no goalItem or releveant list of steps to mutate, return null (something is wrong)
  if (!goalItem || (!savedSteps?.length && !unsavedSteps?.length)) {
    return null;
  }

  // * [2] - If [saved] goalSteps is supplied, that is meant to implicitly be the array we want to mutate
  if (savedSteps?.length) {
    const newGoalSteps = savedSteps.map((goalStep) => {
      if (goalStep.gsGoalItemKey === goalItem.gsGoalItemKey) {
        return {
          ...goalStep,
          seq: seq ?? goalStep.seq,
          description: description ?? goalStep.description,
          status: status ?? goalStep.status,
        };
      }

      return goalStep;
    });

    return newGoalSteps;
  } else if (unsavedSteps?.length) {
    // * [3] - If goalSteps was *not* supplied and unsavedGoalSteps is supplied, that is meant to implicitly be the array we want to mutate
    const newUnsavedGoals = unsavedSteps.map((unsavedGoalStep) => {
      if (unsavedGoalStep.gsGoalItemKey === goalItem.gsGoalItemKey) {
        return {
          ...unsavedGoalStep,
          seq: seq ?? unsavedGoalStep.seq,
          description: description ?? unsavedGoalStep.description,
          status: status ?? unsavedGoalStep.status,
        };
      }

      return unsavedGoalStep;
    });

    return newUnsavedGoals;
  }

  return null;
}

function getSortedSteps({
  goalSteps,
  unsavedGoalSteps,
}: Pick<Props, "goalSteps" | "unsavedGoalSteps">): LocalGoalStep[] {
  // TODO
  const arr = goalSteps
    .concat(unsavedGoalSteps)
    .filter((step) => step.status !== "ocDISABLED")
    .sort((a, b) => a.seq - b.seq)
    .map((step, seq) => ({ ...step, seq }));

  return arr;
}

export default GoalSteps;
