/*
 * Component Description
 */
import * as React from "react";

import { IconProps } from "../typings";

const PaperAirplane = ({ className, color, size, gradientKey }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 17"
      height={size}
      width={size}
      fill={color}
    >
      <g clipPath={`url(#${gradientKey})`}>
        <path
          d="M16.2805 0.0278936L0.377519 5.22435C-0.0716017 5.3711 -0.13655 5.982 0.272197 6.21965L6.28207 9.71428C6.47167 9.82454 6.70864 9.81124 6.88467 9.68049L8.57408 8.42587L7.31946 10.1153C7.18874 10.2913 7.17544 10.5283 7.28567 10.7179L10.7803 16.7278C11.0182 17.1369 11.629 17.0712 11.7756 16.6225L16.9721 0.719534C17.1115 0.29252 16.7054 -0.111051 16.2805 0.0278936ZM11.1132 15.1184L8.41543 10.479L11.6684 6.09866C11.8305 5.88034 11.8082 5.57623 11.6159 5.38396C11.4237 5.19168 11.1195 5.16934 10.9012 5.33145L6.52089 8.58449L1.88148 5.88672L15.5939 1.40603L11.1132 15.1184ZM5.75765 12.0178L2.11649 15.6589C1.90235 15.873 1.55512 15.8731 1.34098 15.6589C1.12681 15.4447 1.12681 15.0975 1.34098 14.8834L4.98214 11.2423C5.19635 11.0282 5.54358 11.0281 5.75765 11.2423C5.97182 11.4564 5.97182 11.8036 5.75765 12.0178ZM0.947386 12.1556C0.73321 11.9414 0.73321 11.5942 0.947386 11.38L2.38413 9.94326C2.59828 9.72909 2.9455 9.72909 3.15964 9.94326C3.37382 10.1574 3.37382 10.5046 3.15964 10.7188L1.72289 12.1556C1.50879 12.3697 1.16153 12.3697 0.947386 12.1556ZM7.05665 13.8402C7.27083 14.0544 7.27083 14.4016 7.05665 14.6157L5.61987 16.0525C5.5128 16.1596 5.37245 16.2131 5.23213 16.2131C4.74795 16.2131 4.49796 15.6234 4.84439 15.277L6.28118 13.8402C6.49528 13.6261 6.84251 13.6261 7.05665 13.8402Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={gradientKey}>
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PaperAirplane;
