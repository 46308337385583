/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import {
  PPF_PERSONAL_IMAGE,
  PPF_PROFESSIONAL_IMAGE,
  PPF_FINANCIAL_IMAGE,
} from "data/constants/";
import { Button, Message } from "components/common/";
import { getPublicImagePath } from "data/helpers/";
import { usePPFParentGoalClarifier } from "data/helpers/Hooks";

import NewGoalHeader from "./NewGoalHeader";

import "./ScopeSelection.scss";

const DEFAULT_GEAL_YEAR_OPTIONS = [1, 3, 5];

const SUB_INFO = {
  personal: {
    subtitle: "Focus on Passion",
    desc: "Examples: Travel, Hobbies, Sports, Personal Relationships, Family, or Health",
    bgImageSrc: getPublicImagePath(PPF_PERSONAL_IMAGE),
  },
  professional: {
    subtitle: "Focus on Accomplishment",
    desc: "Examples: Learning new skills, Award Recognition, Taking on New Responsibilties, or Attracting Mentors",
    bgImageSrc: getPublicImagePath(PPF_PROFESSIONAL_IMAGE),
  },
  financial: {
    subtitle: "Focus on Wealth",
    desc: "Examples: Retirement Fund, Emergency Fund, Passive Income, Investment Properties",
    bgImageSrc: getPublicImagePath(PPF_FINANCIAL_IMAGE),
  },
};

interface Props extends PlanPage.PlanData {
  parentGoals?: TytoData.PPF.Plan.Goals.ParentGoal[];
  selectedParentGoalID: number;
  theme?: keyof typeof TytoData.PPFParentGoalName;
  goalTime: number;
  updateSelectedParentGoalID: React.Dispatch<React.SetStateAction<number>>;
  updateGoalTime: React.Dispatch<React.SetStateAction<number>>;
  updateFormIdx: React.Dispatch<React.SetStateAction<number>>;
  isMemberOfPlan: boolean;
}

const ScopeSelection = (props: Props) => {
  return (
    <>
      <NewGoalHeader plan={props.plan} />

      <form
        className={cx("plan-newgoal-scopeselection-form", props.theme)}
        onSubmit={(e) => {
          props.updateFormIdx(1);

          e.preventDefault?.();
        }}
      >
        <ParentGoals
          parentGoals={props.parentGoals}
          updateSelectedParentGoalID={props.updateSelectedParentGoalID}
          selectedParentGoalID={props.selectedParentGoalID}
        />

        <GoalYears
          theme={props.theme}
          goalTime={props.goalTime}
          updateGoalTime={props.updateGoalTime}
        />

        <div className="plan-newgoal-scopeselection-form-btn-cont">
          <Button
            className="plan-newgoal-scopeselection-form-btn plan-newgoal-form-btn"
            theme={props.theme ?? "blank"}
            disabled={
              !props.selectedParentGoalID ||
              !props.goalTime ||
              !props.isMemberOfPlan
            }
            onClick={_.noop}
            hoverBGSize="hidden"
            value={
              !props.isMemberOfPlan
                ? "You need to become a manager to continue"
                : "Continue"
            }
            type="submit"
          />
        </div>
      </form>
    </>
  );
};

interface ParentGoalsProps
  extends Pick<
    Props,
    "parentGoals" | "selectedParentGoalID" | "updateSelectedParentGoalID"
  > {}

const ParentGoals = (props: ParentGoalsProps) => {
  const sortedParentGoals = React.useMemo(() => {
    if (!props.parentGoals?.length) {
      return [];
    }

    const parentGoalsSorted = props.parentGoals.reduce(
      (accum: TytoData.PPF.Plan.Goals.ParentGoal[], parentGoal) => {
        if (/(personal)/i.test(parentGoal.name)) {
          accum[0] = parentGoal;
        } else if (/(professional)/i.test(parentGoal.name)) {
          accum[1] = parentGoal;
        } else if (/(financial)/i.test(parentGoal.name)) {
          accum[2] = parentGoal;
        }

        return accum;
      },
      []
    );

    return parentGoalsSorted;
  }, [props.parentGoals]);

  return (
    <section className="plan-newgoal-parentgoals-cont">
      {!!sortedParentGoals?.length ? (
        sortedParentGoals.map((parentGoal) => {
          if (!/(personal|professional|financial)/i.test(parentGoal.name)) {
            return null;
          }

          return (
            <ParentGoal
              key={parentGoal.gsGoalID}
              parentGoal={parentGoal}
              selectedParentGoalID={props.selectedParentGoalID}
              updateSelectedParentGoalID={props.updateSelectedParentGoalID}
            />
          );
        })
      ) : (
        <Message text="No Parent Goals Found" />
      )}
    </section>
  );
};

interface ParentGoalProps
  extends Pick<Props, "selectedParentGoalID" | "updateSelectedParentGoalID"> {
  parentGoal: TytoData.PPF.Plan.Goals.ParentGoal;
}

const ParentGoal = ({
  parentGoal,
  selectedParentGoalID,
  updateSelectedParentGoalID,
}: ParentGoalProps) => {
  const ppfGoalName = usePPFParentGoalClarifier(parentGoal);

  const isSelected = selectedParentGoalID === parentGoal.gsGoalID;
  const subInfo = ppfGoalName && SUB_INFO[ppfGoalName];

  return (
    <>
      <input
        className="plan-newgoal-parentgoals-goal-input"
        checked={isSelected}
        id={`${parentGoal.name}`}
        onChange={(e) => {
          updateSelectedParentGoalID(parentGoal.gsGoalID);
        }}
        name="Parent Goal"
        type="radio"
        value={parentGoal.gsPlanID}
      />
      <label
        className={cx(
          "plan-newgoal-parentgoals-goal-label",
          ppfGoalName,
          isSelected && "is-selected"
        )}
        htmlFor={`${parentGoal.name}`}
      >
        <div
          className="plan-newgoal-parentgoals-goal-label-inner-cont"
          style={
            subInfo?.bgImageSrc
              ? {
                  backgroundImage: `url(${subInfo?.bgImageSrc})`,
                }
              : undefined
          }
        >
          <h3
            className={cx(
              "plan-newgoal-parentgoals-goal-label-inner-name",
              isSelected && "animated-underline"
            )}
          >
            {parentGoal.name ?? "Parent Goal"}
          </h3>

          {isSelected && (
            <div className="plan-newgoal-parentgoals-goal-label-inner-details">
              <h4 className="plan-newgoal-parentgoals-goal-label-inner-details-subtitle">
                {subInfo?.subtitle}
              </h4>
              <p className="plan-newgoal-parentgoals-goal-label-inner-details-desc">
                {subInfo?.desc}
              </p>
            </div>
          )}
          {/* {isSelected && (
            <p className="plan-newgoal-parentgoals-goal-label-inner-desc">
              {parentGoal.elementDesc}
            </p>
          )} */}
        </div>
      </label>
    </>
  );
};

interface GoalYearsProps {
  goalTime: number;
  theme?: keyof typeof TytoData.PPFParentGoalName;
  updateGoalTime: React.Dispatch<React.SetStateAction<number>>;
}

const GoalYears = (props: GoalYearsProps) => {
  return (
    <section className="plan-newgoal-goal-lengths-cont">
      <h3 className="plan-newgoal-goal-lengths-title">
        Target Year of Completion
      </h3>

      <div className="plan-newgoal-goal-lengths-wrapper">
        {DEFAULT_GEAL_YEAR_OPTIONS.map((goalYearOption) => (
          <GoalYear {...props} key={goalYearOption} goalYear={goalYearOption} />
        ))}
      </div>
    </section>
  );
};

interface GoalYearProps extends GoalYearsProps {
  goalYear: number;
}

const GoalYear = ({
  goalYear,
  theme,
  goalTime,
  updateGoalTime,
}: GoalYearProps) => {
  const isSelected = goalYear === goalTime;

  return (
    <>
      <input
        className="plan-newgoal-goal-length-input"
        checked={isSelected}
        id={`${goalYear}`}
        name="Goal Length"
        onChange={() => {
          updateGoalTime(goalYear);
        }}
        type="radio"
        value={goalYear}
      />
      <label
        className={cx(
          "plan-newgoal-goal-length-label",
          theme,
          isSelected && "is-selected"
        )}
        htmlFor={`${goalYear}`}
      >
        <div className="plan-newgoal-goal-length-label-inner-cont btn-touch-reaction">
          <h3 className="plan-newgoal-goal-length-label-inner-name">
            {goalYear ?? "Goal Year"}
          </h3>
          {/* {isSelected && (
            <p className="plan-newgoal-goal-length-label-inner-desc">
              {parentGoal.elementDesc}
            </p>
          )} */}
        </div>
      </label>
    </>
  );
};

export default ScopeSelection;
