/*
 * Component Description
 */
import * as React from "react";

import { CheckBox, Icon, Input } from "components/common/";

interface Props {
  deleteStep?: () => void;
  index: number;
  isComplete?: boolean;
  isDisabled?: boolean;
  parentGoalType: keyof typeof TytoData.PPFParentGoalName;
  stepID: number;
  stepText: string;
  updating: boolean;
  updateStepText: (newStepText: string) => void;
  updateIsComplete: (isComplete?: boolean) => void;
  provided?: any;
  isMobile: boolean;
}

const GoalStep = (props: Props) => {
  const BUTTON_SIZE = props.isMobile ? 12 : 18;

  React.useEffect(() => {
    console.log("%cGOALSTEP MOUNTED!", "background-color: purple;");
  }, []);

  if (props.isDisabled) {
    return null;
  }

  return (
    <li
      className="cs-editgoal-step-cont"
      ref={props.provided.innerRef}
      {...props.provided.draggableProps}
      {...props.provided.dragHandleProps}
    >
      <div className="cs-editgoal-step-cont-inner">
        {props.isMobile ? (
          <div className="cg-editgoal-step-cont-inner-mobile-adjustment">
            <CheckBox
              // disabledCheckSymbol={true}
              className="cg-editgoal-step-checkbox"
              label={`Step ${props.index}`}
              isChecked={!!props.isComplete}
              onChange={(newCheckedValue) => {
                props.updateIsComplete?.(!!newCheckedValue);
              }}
              size={BUTTON_SIZE}
              theme={props.parentGoalType}
            />

            <Input
              className="cg-editgoal-step-input input-seemless title-font2"
              disabled={props.updating}
              name={`Goal Step ${props.index}`}
              onChange={(newStepText) => props.updateStepText?.(newStepText)}
              value={props.stepText}
            />
          </div>
        ) : (
          <>
            <CheckBox
              className="cg-editgoal-step-checkbox"
              label={`Step ${props.index}`}
              isChecked={!!props.isComplete}
              onChange={(newCheckedValue) => {
                props.updateIsComplete?.(!!newCheckedValue);
              }}
              size={BUTTON_SIZE}
              theme={props.parentGoalType}
            />
            <Input
              className="cg-editgoal-step-input input-seemless title-font2"
              disabled={props.updating}
              name={`Goal Step ${props.index}`}
              onChange={(newStepText) => props.updateStepText?.(newStepText)}
              value={props.stepText}
            />
          </>
        )}

        <Icon icon="bars" size={BUTTON_SIZE} />
      </div>

      {!!props.deleteStep && (
        <Icon
          buttonProps={{
            className: "cg-editgoal-step-delete-icon",
          }}
          size={BUTTON_SIZE}
          onClick={props.deleteStep}
          isButton={true}
          icon="trash-alt"
        />
      )}
    </li>
  );
};

export default GoalStep;
