import { ENDPOINT_PERSON_NOTICES } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_PERSON_NOTICES;

export const PersonNotices = {
  get(
    params: Endpoints.Tyto.Person.Notices.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      { ...params },
      { circumventChangePasswordCheck: true, ...(callOpts || {}) }
    ) as Promise<Endpoints.Responses.Person.Notices.Get>;
  },
};
