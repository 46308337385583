/*
 * Component Description
 */
import * as React from "react";

import { detemerminePPFParentGoal } from "data/helpers/";
import { Timestamp, UserThumbByID } from "components/common/";

import { ParentTypeBadge } from "components/goal/";

import GenericNav from "../generic-nav/";

import "./style.scss";

function determineGoalDurationInYears({
  plan,
  goalEndDate,
}: {
  plan: TytoData.PPF.Plan.Plan;
  goalEndDate: string | Date;
}) {
  /**
   * This fnuction perhaps is a bit... verbose
   * Probably a more perofrmant way of doing this
   * This attempt was a quick and dirty method while getting the
   * Project to MVP status
   */
  const endDateAsDate = new Date(goalEndDate);
  const planStartDate = new Date(plan.startTime);

  if (
    !endDateAsDate ||
    !planStartDate ||
    !endDateAsDate.getFullYear() ||
    !endDateAsDate.getFullYear() ||
    +endDateAsDate < 0
  ) {
    return 0;
  }

  const endDateMonth = endDateAsDate.getMonth() + 1;
  const startDateMonth = planStartDate.getMonth() + 1;
  const monthsAdd = endDateMonth - startDateMonth;

  const planStartYear = planStartDate.getFullYear();
  const goalEndYear = endDateAsDate.getFullYear();
  const yearsDiff = goalEndYear - planStartYear;
  const monthsDiff = yearsDiff * 12 + monthsAdd;

  const roundedYearsDiff = Math.round(monthsDiff / 12);

  return Math.max(0, roundedYearsDiff);
}

interface Props {
  plan: TytoData.PPF.Plan.Plan;
  goal: TytoData.PPF.Plan.Goals.ChildGoal;
  parentGoal: TytoData.PPF.Plan.Goals.ParentGoal;
}

const GoalNav = ({ goal, parentGoal, plan }: Props) => {
  const [theme, updateTheme] = React.useState(() => {
    return detemerminePPFParentGoal(parentGoal);
  });
  // const [goalDurationInYears, updateGoalDurationInYears] = React.useState(() => {
  //     return determineGoalDurationInYears({ plan, goalEndDate: goal.targetDateNext })
  // });

  React.useEffect(() => {
    updateTheme(detemerminePPFParentGoal(parentGoal));
  }, [parentGoal]);

  // React.useEffect(() => {
  //     updateGoalDurationInYears(determineGoalDurationInYears({ plan, goalEndDate: goal.targetDateNext }));
  // }, [plan, goal]);

  return (
    <GenericNav>
      <div className="cm-subnavs-goalnav-cont-left">
        <UserThumbByID
          className="cm-subnavs-goalnav-person-userthumb"
          size={34}
          userName={plan.about?.elementName ?? ""}
          userID={plan.aboutID}
        />
        <p className="cm-subnavs-goalnav-person-name">
          {plan?.about?.elementName ?? "Planee"}
        </p>
      </div>
      <div className="cm-subnavs-goalnav-cont-right">
        {theme && <ParentTypeBadge parentType={theme} />}
        {goal && <GoalTimes goal={goal} plan={plan} />}
      </div>
    </GenericNav>
  );
};

interface GoalTimesProps {
  plan: TytoData.PPF.Plan.Plan;
  goal: TytoData.PPF.Plan.Goals.ChildGoal;
}

const GoalTimes = (props: GoalTimesProps) => {
  const [lastTargetItemDate, updateLastTargetItemDate] = React.useState(() => {
    return props.goal?.targets?.slice?.(-1)[0]?.targetDate;
  });

  React.useEffect(() => {
    updateLastTargetItemDate(() => {
      return props.goal?.targets?.slice?.(-1)[0]?.targetDate;
    });
  }, [props.goal]);

  const [agoText, updateAgoText] = React.useState(" ago");
  return (
    <>
      {lastTargetItemDate && (
        <>
          <Timestamp
            className="cm-subnavs-goalnav-goal-time"
            startCompareDate={props.plan.startTime}
            date={lastTargetItemDate}
            titleClarificationText="Goal Due"
          />

          <span style={{ fontSize: "11px" }}>|</span>
        </>
      )}
      <Timestamp
        className="cm-subnavs-goalnav-goal-time"
        date={props.goal?.createdDate}
        titleClarificationText="Goal Created"
        nowCallBack={(isNow) => {
          if (isNow) {
            updateAgoText("");
          }
        }}
      >
        {agoText}
      </Timestamp>
    </>
  );
};

export default GoalNav;
