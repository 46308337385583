import { ENDPOINT_DISC_PROFILES } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DISC_PROFILES;

export const DISCProfiles = {
  get(params: Endpoints.Tyto.DISCProfiles.GetParameters, callOpts?: CallOpts) {
    return callWrapper("get", endpoint, params || {}, callOpts) as Promise<{
      discProfiles: any[];
      session: Data.SessionData;
    }>;
  },
};
