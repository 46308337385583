import { ENDPOINT_GS_GOAL_PROFILE_IMAGE } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_GS_GOAL_PROFILE_IMAGE;

export const GSGoalProfileImage = {
  post(
    params: Endpoints.Tyto.GS.Goal.ProfileImage.PostParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "post",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Goal.ProfileImage.Post>;
  },
};
