import * as React from "react";
import { Button, DatePicker, TeamPick, CheckBox } from "components/common/";
interface Props {
  passDates: (startDate: Date, endDate: Date) => void;
  passTeamID: (teamID: number) => void;
  passCascade: (cascade: boolean) => void;
}

const StatisticsSelection = (props: Props) => {
  const [activeButton, updateActiveButton] = React.useState("last30Days");
  const [startDate, updateStartDate] = React.useState(new Date().toString());
  const [endDate, updateEndDate] = React.useState(new Date().toString());
  const [customText, updateCustomText] = React.useState(
    switchToStringMonth(new Date().getMonth()) + " " + new Date().getDate()
  );
  const [cascade, updateCascade] = React.useState(false);
  React.useEffect(() => {
    props.passCascade(cascade);
  }, [cascade]);

  return (
    <div className="statistics-selection-cont">
      <div className="statistics-selection-buttons">
        <Button
          theme={activeButton === "last30Days" ? "white" : "white-ghost"}
          value="Last 30 days"
          style={{ marginRight: "6px" }}
          onClick={() => {
            var startDate = new Date();
            startDate.setDate(startDate.getDate() - 30);
            activeButton !== "last30Days" &&
              props.passDates(startDate, new Date());
            updateActiveButton("last30Days");
          }}
        />
        <Button
          theme={activeButton === "last6Months" ? "white" : "white-ghost"}
          value="Last 6 months"
          style={{
            marginRight: "6px",
          }}
          onClick={() => {
            var startDate = new Date();
            startDate.setMonth(startDate.getMonth() - 5);
            startDate.setDate(1);
            startDate.setHours(0);
            startDate.setMinutes(0);
            startDate.setSeconds(1);
            activeButton !== "last6Months" &&
              props.passDates(startDate, new Date());
            updateActiveButton("last6Months");
          }}
        />
        <Button
          theme={activeButton === "thisYear" ? "white" : "white-ghost"}
          value="This year"
          style={{ marginRight: "6px" }}
          onClick={() => {
            var startDate = new Date();
            startDate.setMonth(0);
            startDate.setDate(1);
            startDate.setHours(0);
            startDate.setMinutes(0);
            startDate.setSeconds(1);
            startDate.setMilliseconds(0);
            activeButton !== "thisYear" &&
              props.passDates(startDate, new Date());
            updateActiveButton("thisYear");
          }}
        />
        <Button
          theme={activeButton === "custom" ? "white" : "white-ghost"}
          value={activeButton === "custom" ? customText : "Custom"}
          onClick={() => {
            updateActiveButton("custom");
          }}
        />
      </div>
      {activeButton === "custom" && (
        <div className="statistics-selection-datepicker">
          <div>
            <span className="statistics-selection-start">Start</span>
            <span className="statistics-selection-end">End</span>
          </div>
          <div>
            <DatePicker
              date={new Date(startDate)}
              onChange={(newDate) => {
                newDate
                  ? setCustomText(true, newDate)
                  : updateCustomText("Error");
              }}
              type="date"
              style={{
                backgroundColor: "#29303C",
                color: "var(--white)",
                border: "none",
              }}
            />
            <DatePicker
              date={new Date(endDate)}
              onChange={(newDate) => {
                newDate
                  ? setCustomText(false, newDate)
                  : updateCustomText("Error");
              }}
              type="date"
              style={{
                backgroundColor: "#29303C",
                color: "var(--white)",
                border: "none",
                marginLeft: "12px",
              }}
            />
          </div>
        </div>
      )}
      <div className="statistics-selection-teams">
        <p>Workspace</p>
        <TeamPick
          className="statistics-selection-teams-select"
          styles={{
            option: (style: CSSStyleDeclaration) => ({
              ...style,
              backgroundColor: "#29303C",
              borderTop: "1px solid #535353",
              color: "var(--white)",
            }),
            control: (style: CSSStyleDeclaration) => ({
              ...style,
              backgroundColor: "#29303C",
              color: "var(--white)",
              borderColor: "transparent ",
            }),
            menu: (style: CSSStyleDeclaration) => ({
              ...style,
              backgroundColor: "#29303C",
              color: "var(--white)",
            }),
            input: (style: CSSStyleDeclaration) => ({
              ...style,
              color: "var(--white)",
            }),
            singleValue: (style: CSSStyleDeclaration) => ({
              ...style,
              color: "var(--white)",
            }),
            placeholder: (style: CSSStyleDeclaration) => ({
              ...style,
              color: "var(--white)",
            }),
          }}
          passBack={(teamID) => {
            props.passTeamID(teamID);
          }}
        />
        <CheckBox
          className="statistics-selection-teams-cascade"
          label="Include teams below"
          onChange={(newValue) => updateCascade(newValue)}
          showLabel={true}
          isChecked={cascade}
          native={true}
        />
      </div>
    </div>
  );

  function setCustomText(isStart: boolean, date: Date) {
    var newDate;
    if (isStart) {
      var compareDate = new Date(endDate);
      if (date.getTime() > compareDate.getTime()) {
        date = new Date(compareDate.setTime(compareDate.getTime()));
      }
      updateStartDate(date.toString());
      newDate = new Date(date);
      if (date.getFullYear() !== compareDate.getFullYear()) {
        updateCustomText(
          switchToStringMonth(date.getMonth()) +
            " " +
            date.getDate() +
            " " +
            date.getFullYear() +
            " - " +
            switchToStringMonth(compareDate.getMonth()) +
            " " +
            compareDate.getDate() +
            " " +
            compareDate.getFullYear()
        );
      } else if (
        date.getDate() === compareDate.getDate() &&
        date.getMonth() === compareDate.getMonth()
      ) {
        updateCustomText(
          switchToStringMonth(date.getMonth()) + " " + date.getDate()
        );
      } else if (date.getMonth() === compareDate.getMonth()) {
        updateCustomText(
          switchToStringMonth(date.getMonth()) +
            " " +
            date.getDate() +
            "-" +
            compareDate.getDate()
        );
      } else {
        updateCustomText(
          switchToStringMonth(date.getMonth()) +
            " " +
            date.getDate() +
            " - " +
            switchToStringMonth(compareDate.getMonth()) +
            " " +
            compareDate.getDate()
        );
      }
      props.passDates(newDate, new Date(endDate));
    } else {
      var compareDate = new Date(startDate);
      if (date.getTime() < compareDate.getTime()) {
        date = new Date(compareDate.setTime(compareDate.getTime()));
      }
      updateEndDate(date.toString());
      newDate = new Date(date);
      if (date.getFullYear() !== compareDate.getFullYear()) {
        updateCustomText(
          switchToStringMonth(compareDate.getMonth()) +
            " " +
            compareDate.getDate() +
            " " +
            compareDate.getFullYear() +
            " - " +
            switchToStringMonth(date.getMonth()) +
            " " +
            date.getDate() +
            " " +
            date.getFullYear()
        );
      } else if (
        date.getDate() === compareDate.getDate() &&
        date.getMonth() === compareDate.getMonth()
      ) {
        updateCustomText(
          switchToStringMonth(date.getMonth()) + " " + date.getDate()
        );
      } else if (date.getMonth() === compareDate.getMonth()) {
        updateCustomText(
          switchToStringMonth(compareDate.getMonth()) +
            " " +
            compareDate.getDate() +
            "-" +
            date.getDate()
        );
      } else {
        updateCustomText(
          switchToStringMonth(compareDate.getMonth()) +
            " " +
            compareDate.getDate() +
            " - " +
            switchToStringMonth(date.getMonth()) +
            " " +
            date.getDate()
        );
      }
      props.passDates(new Date(startDate), newDate);
    }
  }

  function switchToStringMonth(number: number) {
    switch (number) {
      case 0:
        return "Jan";
      case 1:
        return "Feb";
      case 2:
        return "Mar";
      case 3:
        return "Apr";
      case 4:
        return "May";
      case 5:
        return "Jun";
      case 6:
        return "Jul";
      case 7:
        return "Aug";
      case 8:
        return "Sep";
      case 9:
        return "Oct";
      case 10:
        return "Nov";
      case 11:
        return "Dec";
      default:
        return "Error";
    }
  }
};
export default StatisticsSelection;
