/*
 * Component Description
 */
import * as React from "react";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import { useMutation } from "react-query";
import API from "data/network/tyto";
import * as RQ_CACHE_HELPERS from "data/helpers/react-query-cache";

import { BackNav } from "components/meta/sub-navs/";
import { EditGoal } from "components/goal/";
import { INTERFACE_PATHS, SUB_INTERFACE_PATHS } from "data/constants";

interface GoalPostData {
  goalText: string;
  goalDesc: string;
  parentGoalID: number;
  templateImageKey?: string;
  newSteps?: TytoData.PPF.Plan.Goals.GoalItem[];
  updatedExistingSteps?: TytoData.PPF.Plan.Goals.GoalItem[];
  uploadGUID?: string;
  targetDateFinal?: string;
  durationYears?: number;
  targetStatus?: keyof typeof TytoData.GoalStatus;
}

interface Props extends Pick<PlanPage.PlanData, "plan"> {
  creatingGoal: boolean;
  errorMsg: string;
  goalTime: number;
  goalTitle: string;
  goalDesc: string;
  plan: TytoData.PPF.Plan.Plan;
  selectedParentGoalID: number;
  selectedParentGoal?: TytoData.PPF.Plan.Goals.ParentGoal;
  theme: keyof typeof TytoData.PPFParentGoalName;
  updateCreatingGoal: React.Dispatch<React.SetStateAction<boolean>>;
  updateErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  updateFormIdx: React.Dispatch<React.SetStateAction<number>>;
  updateGoalDesc: React.Dispatch<React.SetStateAction<string>>;
  updateGoalTitle: React.Dispatch<React.SetStateAction<string>>;
  parentGoals?: TytoData.PPF.Plan.Goals.ParentGoal[];
}

const NewGoal = (props: Props) => {
  const [redirectURL, updateRedirectURL] = React.useState<string | null>(null);
  const [uploadingImage, updateUploadingImage] = React.useState(false);

  const mutation = useMutation(
    (data: GoalPostData) => {
      const name = `${data.goalText ?? ""}`;
      const desc = `${data.goalDesc ?? ""}`;

      let targetDate = data.targetDateFinal;

      if (!targetDate) {
        const fiveYearsFromNow = new Date();
        fiveYearsFromNow.setFullYear(fiveYearsFromNow.getFullYear() + 5);

        targetDate = fiveYearsFromNow.toISOString();
      }

      const postData = {
        gsPlanID: props.plan.gsPlanID,
        gsParentGoalID: data.parentGoalID,
        gsGoalName: name,
        gsGoalDesc: desc,
        impact: desc,
        // impact: desc,
        goalType: "ocNOMEASURE" as "ocNOMEASURE",
        target00: 20,
        target01: 40,
        target02: 60,
        target03: 80,
        target04: 100,
        // templateImageKey: data.templateImageKey
        targetDateFinal: targetDate,
        durationYears: data.durationYears,
        seq: 0, // TODO: Maybe pass an actual sequence value? This is what Tryyb PPF interface is sending it seems?
        ...(data.uploadGUID ? { profileAssetUploadKey: data.uploadGUID } : {}),
        ...(data.templateImageKey
          ? { profileImageKey: data.templateImageKey }
          : {}),
        // impact: "",
        // items: ""
      };

      console.log("Creating Goal: ", postData);

      return API.GS.Goal.post(postData);
    },
    {
      onError: (err) => {
        const newErrorMsg =
          typeof err === "string" ? err : _.get(err, "msg", "Error Occurred");

        props.updateErrorMsg?.(newErrorMsg);
        props.updateCreatingGoal?.(false);
      },
      onSuccess: async (result, postDataVariables, context) => {
        const planID = props.plan.gsPlanID;
        const safePlanName =
          props.theme === "personal" ||
          props.theme === "professional" ||
          props.theme === "financial"
            ? props.theme
            : undefined;
        const { gsGoalID: newGoalID } = result ?? {};

        try {
          if (
            postDataVariables.uploadGUID &&
            !postDataVariables.templateImageKey
          ) {
            await API.GS.Goal.ProfileImage.post({
              // domainID: childGoal.domainID,
              gsGoalID: newGoalID,
              profileAssetUploadKey: postDataVariables.uploadGUID,
            });
          }

          // * If steps were supplied, iterate over list and create each step
          // * using newly created GoalID from successful Goal 'POST' request
          if (postDataVariables.newSteps?.length) {
            await Promise.all(
              postDataVariables.newSteps.map(
                ({ description, status, seq, itemWeight }) =>
                  API.PPF.Goal.Item.post({
                    gsGoalID: newGoalID,
                    description,
                    itemWeight,
                    seq,
                    status: status ?? "ocNOTSTARTED",
                  })
              )
            );
          }

          // * Shouldn't be any existing steps when creating a new Child Goal??
          // if (postDataVariables.updatedExistingSteps?.length) {
          //   await Promise.all(
          //     postDataVariables.updatedExistingSteps.map((existingStep) =>
          //       API.PPF.Goal.Item.put({ ...existingStep })
          //     )
          //   );
          // }
          debugger;

          if (planID) {
            RQ_CACHE_HELPERS.invalidateGoalsCache(planID);

            updateRedirectURL(
              `${INTERFACE_PATHS.PLAN}/${planID}${
                newGoalID
                  ? `/${SUB_INTERFACE_PATHS.PLAN.GOALS}/${newGoalID}`
                  : ""
              }`
            );
          }
        } catch (err) {
          if (planID) {
            RQ_CACHE_HELPERS.invalidateGoalsCache(planID);

            updateRedirectURL(
              `${INTERFACE_PATHS.PLAN}/${planID}${
                newGoalID
                  ? `/${SUB_INTERFACE_PATHS.PLAN.GOALS}/${newGoalID}`
                  : ""
              }`
            );
          }
        }
      },
    }
  );

  const onCancel = () => {
    const planID = props.plan.gsPlanID;
    if (planID) {
      RQ_CACHE_HELPERS.invalidateGoalsCache(planID);

      updateRedirectURL(`${INTERFACE_PATHS.PLAN}/${planID}`);
    }
  };

  if (redirectURL) {
    return <Redirect to={redirectURL} />;
  }

  return (
    <>
      <EditGoal
        className="plan-newgoal-details-form"
        formType="create"
        goalDesc={props.goalDesc}
        goalTitle={props.goalTitle}
        creating={uploadingImage || props.creatingGoal}
        errorMsg={props.errorMsg}
        goalLength={props.goalTime}
        parentGoal={props.selectedParentGoal}
        goalTargetStatus={"ocNOTSTARTED"}
        onCancel={onCancel}
        goalDuration={props.goalTime}
        goalCreatedDate={new Date().toISOString()}
        onSave={(data) => {
          // TODO: Make actual 'POST' request with Goal Data
          props.updateCreatingGoal?.(true);
          props.updateErrorMsg?.("");

          mutation.mutate({
            goalText: data.goalTitle,
            goalDesc: data.goalDesc,
            parentGoalID: props.selectedParentGoalID,
            targetDateFinal: data.targetDateFinal,
            durationYears: data.goalDuration,
            targetStatus: data.targetStatus,
            templateImageKey: data.newTemplateImageKey,
            updatedExistingSteps: data.updatedExistingGoalSteps,
            newSteps: data.newGoalSteps,
            uploadGUID: data.uploadGUID,
          });
        }}
        plan={props.plan}
        parentGoalType={props.theme}
        updateCreating={updateUploadingImage}
        updateGoalDesc={props.updateGoalDesc}
        updateGoalTitle={props.updateGoalTitle}
        parentGoals={props.parentGoals}
      />
    </>
  );
};

// * Intended to be spun off into own file and used as Dumb Component for rendering an editable Goal Details form
// * Used in both creating new and editing and existing goals.

export default NewGoal;
