/*
 * Uses
 */
import * as React from "react";
import * as _ from "lodash";
import cx from "classnames";
import { Link } from "react-router-dom";
import {
  Menu,
  MenuList,
  MenuButton as ReachMenuButton,
  MenuItem,
} from "@reach/menu-button";

import "./style.scss";

interface MenuListItem {
  as?: string | React.FC;
  children?: any;
  className?: string;
  disabled?: boolean;
  key?: string;
  label: string;
  onSelect?: () => void;
  href?: string;
}

interface Props {
  animate?: boolean;
  className?: string;
  menuListClassName?: string;
  menuItemClassName?: string;
  omitButtonBGColor?: boolean;
  style?: string;
  value?: string;
  children?: any;
  menuItems: MenuListItem[];
}

const DEFAULT_FUNCTION = _.noop;

const MenuButton = ({
  animate = true,
  className,
  children,
  menuItems,
  menuItemClassName,
  menuListClassName,
  omitButtonBGColor,
  style,
  value = "Menu",
}: Props) => {
  return (
    <Menu>
      <ReachMenuButton
        className={cx(
          "cc-menubtn button-generic",
          omitButtonBGColor && "no-bg-color",
          className
        )}
      >
        {children || value}
      </ReachMenuButton>
      <MenuList
        className={cx(
          "cc-menubtn-list",
          animate && "animate-reveal",
          menuListClassName
        )}
      >
        {(menuItems || []).map((item) => (
          <React.Fragment key={item.key || item.label}>
            {item.as === "a" || item.as === "link" ? (
              <MenuItem
                className={cx(
                  "cc-menubtn-list-item",
                  menuItemClassName,
                  item.className
                )}
                onSelect={item.onSelect || DEFAULT_FUNCTION}
              >
                {/* If MenuLink component is used, it makes App Reinitialize at new route */}
                <Link to={`${item.href}`}>{item.label}</Link>
              </MenuItem>
            ) : (
              <MenuItem
                className={cx(
                  "cc-menubtn-list-item",
                  menuItemClassName,
                  item.className
                )}
                onSelect={item.onSelect || DEFAULT_FUNCTION}
              >
                <span>{item.label}</span>
              </MenuItem>
            )}
          </React.Fragment>
        ))}

        <MenuItem
          className={cx(
            "cc-menubtn-list-item",
            "cc-menubtn-cancel-list-item",
            menuItemClassName
          )}
          onSelect={_.noop}
        >
          <span>Cancel</span>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default MenuButton;
