/*
 * Component Description
 */
import * as React from "react";

import { IconProps } from "../typings";

const Replay = ({ className, color, size, style }: IconProps) => {
  return (
    <svg
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 66 72"
      height={size}
      width={size}
      fill={color}
    >
      <path d="M34.5713 0.722107C33.4973 -0.145693 31.9233 0.0213732 31.0557 1.09527C30.1877 2.16921 30.3547 3.74327 31.4287 4.61107L33.4287 6.22721H33C15.0271 6.22721 0.5 20.9374 0.5 39.0303C0.5 57.1229 15.0271 71.8333 33 71.8333C50.973 71.8333 65.5 57.1229 65.5 39.0303C65.5 27.5874 59.692 17.5062 50.8863 11.6378C49.7373 10.8721 48.1853 11.1828 47.4197 12.3317C46.654 13.4807 46.9647 15.0328 48.1137 15.7985C55.5747 20.771 60.5 29.3153 60.5 39.0303C60.5 54.4093 48.1643 66.8333 33 66.8333C17.8358 66.8333 5.5 54.4093 5.5 39.0303C5.5 23.6513 17.8358 11.2272 33 11.2272H33.4287L31.4287 12.8433C30.3547 13.7111 30.1877 15.2852 31.0557 16.3591C31.9233 17.433 33.4973 17.6001 34.5713 16.7323L42.0713 10.6717C42.6587 10.197 43 9.48234 43 8.72721C43 7.97204 42.6587 7.25734 42.0713 6.78271L34.5713 0.722107Z" />
      <path d="M25.2504 23.7466C26.1166 23.3303 27.1447 23.4474 27.895 24.0477L44.5617 37.3809C45.1547 37.8556 45.5 38.5739 45.5 39.3332C45.5 40.0926 45.1547 40.8109 44.5617 41.2852L27.895 54.6186C27.1447 55.2189 26.1166 55.3362 25.2504 54.9199C24.3842 54.5036 23.8334 53.6276 23.8334 52.6666V25.9999C23.8334 25.0389 24.3842 24.1629 25.2504 23.7466Z" />
    </svg>
  );
};

export default Replay;
