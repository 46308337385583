/*
 * Component Description
 */
import * as React from "react";

import { MenuToggleButton } from "components/common/";
import { usePrimaryParentGoalsByName } from "data/helpers/Hooks";

import ChildGoalsList from "../child-goals-list/";
import DisabledMsg from "../disabled-msg/";

import "./style.scss";

interface Props extends PlanPage.PlanData {}

// * This render is just a basic render showing rendering some data for a plan
const Overview = (props: Props) => {
  const { personal, professional, financial } = usePrimaryParentGoalsByName(props.planGoals);

  return (
    <article>
      {/* <ol>
        {!!props.planGoals?.length &&
          props.planGoals.map((goal) => (
            <li
              className="parent-goal title-font font-size-medium"
              key={goal.gsGoalID}
            >
              {goal.elementName}

              <ChildGoalsList
                childGoals={goal.childGoals}
                notices={props.planNotices}
              />
            </li>
          ))}
      </ol> */}

      <DisabledMsg plan={props.plan} planID={props.planID} />

      <h3 className="plan-parentgoal-title title-font font-size-large">
        <MenuToggleButton className="plan-parentgoal-menu-btn" />
        {props.plan?.gsPlanName ?? "Plan"} Goals
      </h3>

      {personal && (
        <ChildGoalsList planID={props.planID} parentGoal={personal} />
      )}

      {professional && (
        <ChildGoalsList planID={props.planID} parentGoal={professional} />
      )}

      {financial && (
        <ChildGoalsList planID={props.planID} parentGoal={financial} />
      )}
    </article>
  );
};

export default Overview;
