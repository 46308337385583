import * as React from "react";

import { IconProps } from "../typings";

const LineHorizontal = ({ className, color, size }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 4"
      height={size}
      width={size}
      fill={color}
    >
      <line
        fill={color}
        x1="2"
        y1="2"
        x2="26"
        y2="2"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default LineHorizontal;
