import * as _ from "lodash";

import { DOMAINS_FOLDER_ORIGIN } from "data/constants/";

export function addCSSScriptToHead({
  callback,
  fileNameSrc,
}: {
  callback?: () => void;
  fileNameSrc?: string;
}) {
  if (!fileNameSrc) {
    return;
  }

  const alreadyExists = stylesheetExistsOnDocument(fileNameSrc);

  if (alreadyExists) {
    console.warn("Stylesheet with same source already found on document.");
    return;
  }

  // * Not sure why it would ever be the fileName without the extension, or wrong extension, buuuuut.....
  const nameWithExtension = /.css$/.test(`${fileNameSrc}`)
    ? `${fileNameSrc}`
    : `${fileNameSrc}.css`;

  const head = getDocumentHead();

  // * Something really funky going on? Might need to double check this works when app is loaded inside an <iframe />
  if (!head) {
    return;
  }

  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href = nameWithExtension;

  if (callback) {
    link.onload = () => {
      callback?.();
    };

    link.onerror = () => {
      callback?.();
    };
  }

  if (/localhost\:(\d)+/i.test(link.href)) {
    callback?.();
  } else {
    head.appendChild(link);
  }
}

function getDocumentHead() {
  return document.querySelector("head");
}

export function stylesheetExistsOnDocument(fileHRef: string) {
  if (!fileHRef) {
    return false;
  }

  const curStyleSheets = getExistingStylesheetsOnDocument();

  return curStyleSheets.some((stylesheet) => stylesheet.href === fileHRef);
}

export function getExistingStylesheetsOnDocument() {
  const styleSheetsAsArray = Array.from(document.styleSheets);

  return styleSheetsAsArray;
}

function getStylesheetBySrc(fileNameSrc: string) {
  if (!fileNameSrc) {
    return undefined;
  }

  const head = getDocumentHead();

  if (!head) {
    return undefined;
  }

  const links = Array.from(head.querySelectorAll(`link[rel=stylesheet]`));

  return links.find((stylesheetLink) => {
    const regExp = new RegExp(_.escapeRegExp(fileNameSrc), "i");

    return regExp.test(stylesheetLink.getAttribute("href") ?? "");
  });

  // return head.querySelector(`link[rel=stylesheet][href*=${fileNameSrc}]`);
}

export function removeCSSScriptFromHead({
  callback,
  fileNameSrc,
}: {
  callback?: () => void;
  fileNameSrc?: string;
}) {
  if (!fileNameSrc) {
    return;
  }

  const existsOnDocument = stylesheetExistsOnDocument(fileNameSrc);

  if (!existsOnDocument) {
    return;
  }

  const stylesheet = getStylesheetBySrc(fileNameSrc);

  if (!stylesheet) {
    return undefined;
  }

  stylesheet.remove();

  callback?.();
}

function getHostStylesheetName() {
  const host = window.location.host;

  const stylesheetName = `${host}.css`;

  return stylesheetName;
}

function getDomainStyleSheetPath(domainID: number) {
  return `${DOMAINS_FOLDER_ORIGIN}/v2/domains/${domainID}/styles/ppf-overrides.css`;
}

export function loadHostStyleOverrides(callback?: () => void) {
  const styleSheetName = getHostStylesheetName();

  addCSSScriptToHead({
    callback,
    fileNameSrc: styleSheetName,
  });
}

export function removeDomainStyleSheet(domainID: number) {
  if (!domainID) {
    return;
  }

  const domainStyleSheetPath = getDomainStyleSheetPath(domainID);

  removeCSSScriptFromHead({
    fileNameSrc: domainStyleSheetPath,
  });
}

export async function addStylesheetForSession(
  callback: () => void,
  domainID: number
) {
  const hostStyleSheetName = getHostStylesheetName();

  removeCSSScriptFromHead({
    fileNameSrc: hostStyleSheetName,
  });

  addCSSScriptToHead({
    fileNameSrc: getDomainStyleSheetPath(domainID),
    callback,
  });
}
