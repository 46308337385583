/*
 * Component Description
 */
import * as React from "react";

import { IconProps } from "../typings";

const Refresh = ({ className, color, size, style }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      height={size}
      width={size}
      fill={color}
    >
        <path d="m512 86.401v91.199c0 17.673-14.327 32-32 32h-91.199c-17.673 0-32-14.327-32-32s14.327-32 32-32h24.45c-35.335-50.645-93.244-81.6-157.251-81.6-79.639 0-149.848 47.913-178.866 122.063-6.442 16.458-25.006 24.577-41.462 18.137-16.458-6.441-24.578-25.004-18.137-41.461 18.559-47.423 50.547-87.906 92.506-117.073 42.977-29.875 93.448-45.666 145.959-45.666s102.982 15.791 145.959 45.665c17.076 11.87 32.501 25.616 46.041 40.94v-.203c0-17.673 14.327-32 32-32s32 14.326 32 31.999zm-35.672 221.4c-16.457-6.442-35.02 1.68-41.462 18.137-29.018 74.15-99.227 122.062-178.866 122.062-62.647 0-119.454-29.654-154.97-78.4h22.169c17.673 0 32-14.327 32-32s-14.327-32-32-32h-91.199c-17.673 0-32 14.327-32 32v91.2c0 17.673 14.327 32 32 32s32-14.327 32-32v-3.404c13.54 15.324 28.965 29.069 46.041 40.939 42.977 29.874 93.448 45.665 145.959 45.665s102.982-15.791 145.959-45.665c41.959-29.167 73.947-69.65 92.506-117.073 6.441-16.457-1.679-35.02-18.137-41.461z"/>
    </svg>
  );
};

export default Refresh;