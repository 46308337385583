import * as React from "react";
import * as _ from "lodash";
import { useQuery, useMutation, UseQueryResult } from "react-query";
import axios, { AxiosRequestConfig } from "axios";

import { queryClient } from "../";

import { IMAGE_BASE_URL, REACT_QUERY_KEYS, URL_ORIGIN } from "data/constants/";
import {
  SECURITY_FUNCTIONS_BY_ID,
  SECURITY_FUNCTIONS_BY_NAME,
} from "data/constants/system";
import {
  alertCountHasDateChange,
  findIfCourseIsPrimaryOrSubBlock,
  makeLocalForageKey,
  timeInMS,
  createFakeFalsePPFPermissions,
  pullPermissionFromPlanMemberObject,
  pullMessageFromError,
  getDomainConfigURL,
} from "../../helpers/";
import * as QueryCacheHelpers from "../../helpers/react-query-cache";
import * as Storage from "../../storage/";
import * as SpecialNetworkRequests from "../special-calls/";
import TytoCalls from "../tyto/";

const { LF, SessionHandling } = Storage;

export const DEFAULT_RQ_OPTS = {
  refetchOnWindowFocus: false,
  staleTime: timeInMS({ timeQuantity: 30, timeType: "min" }),
};

export function useLocalForage<T>(
  key: string,
  params?: Array<string | number | null> | null,
  opts?: {
    onError?: (data: Data.TytoErrorObject) => void;
    onSuccess?: (data: any) => void;
  }
) {
  const localForageKey = makeLocalForageKey(key, params ?? undefined);

  return useQuery(
    localForageKey,
    async () => {
      const locallyStoredData = await LF.getItem(localForageKey);

      return locallyStoredData;
    },
    {
      refetchOnWindowFocus: false,
      onError: (err) => {
        opts?.onError?.(err);
      },
      onSuccess: (data) => {
        opts?.onSuccess?.(data);
      },
      // ...opts
    }
  ) as UseQueryResult<Omit<T, "session" | "error">, any>;
}

export function useAssetEncoding({
  assetID,
  enrollmentID,
  isEnabled = true,
  onError,
  onSuccess,
}: {
  assetID: number;
  enrollmentID?: number;
  isEnabled?: boolean;
  onError?: (errorMsg: string) => void;
  onSuccess?: (
    data: Omit<Endpoints.Responses.Asset.Encoding.Post, "session" | "error">
  ) => void;
}) {
  return {
    ...useQuery(
      [REACT_QUERY_KEYS.ASSET_ENCODING, assetID],
      async () => {
        const data = await TytoCalls.Asset.Encoding.post({
          assetID,
        });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: !!isEnabled && !!assetID,
        onError: (err: any) => {
          onError?.(err);
        },
        retry: false,
        onSuccess: (data: Endpoints.Responses.Asset.Encoding.Post) => {
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.ASSET_ENCODING, [assetID]),
            _.omit(data, ["session", "error"])
          );

          // * If an enrollmentID was passed, invalidate the cache so the launchEnrollment will
          // * reload with updated encodings array in the first comment's asset attachment
          if (enrollmentID) {
            queryClient.invalidateQueries([
              REACT_QUERY_KEYS.LAUNCH_ENROLLMENT,
              enrollmentID,
            ]);
          }

          onSuccess?.(_.omit(data, ["session", "error"]));
        },
      }
    ),
  };
}

export function useCatalog() {
  const storedValueQuery = useLocalForage(REACT_QUERY_KEYS.CATALOG);

  return {
    storedValueQuery,
    ...useQuery(
      REACT_QUERY_KEYS.CATALOG,
      async () => {
        const data = await TytoCalls.CatalogCurriculumPublication.get({});

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        staleTime: timeInMS({ timeQuantity: 2, timeType: "hours" }),
        onSuccess: (data) => {
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.CATALOG),
            _.omit(data, ["session", "error"])
          );
        },
      }
    ),
  };
}

export function useTaskStructure({
  taskID,
  rqOpts,
  onError,
  onSuccess,
}: {
  taskID: number;
  rqOpts?: any;
  onError?: (errorData: any) => void;
  onSuccess?: (data: Endpoints.Responses.Task.Structure.Get) => void;
}) {
  const storedValueQuery = useLocalForage(REACT_QUERY_KEYS.TASK_STRUCTURE, [
    taskID,
  ]);

  return {
    storedValueQuery,
    ...useQuery(
      `${REACT_QUERY_KEYS.TASK_STRUCTURE}/${taskID}`,
      async () => {
        const data = await TytoCalls.Task.Structure.get({ taskID });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        ...(rqOpts || {}),
        onError: (err: any) => {
          if (onError) {
            onError(err);

            // TODO Auto enroll user is err.sts === -301
          }
        },
        onSuccess: (data) => {
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.TASK_STRUCTURE, [taskID]),
            _.omit(data, ["session", "error"])
          );

          if (onSuccess) {
            onSuccess(data);
          }
        },
      }
    ),
  };
}

export function useCourse({
  blockID,
  onSuccess,
}: {
  blockID: number;
  onSuccess?: (data: Endpoints.Responses.Block.Get) => void;
}) {
  const storedValueQuery = useLocalForage(REACT_QUERY_KEYS.BLOCK, [blockID]);

  return {
    storedValueQuery,
    ...useQuery(
      [REACT_QUERY_KEYS.BLOCK, blockID],
      async () => {
        const data = await TytoCalls.Block.get({ blockID });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        onSuccess: (data) => {
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.BLOCK, [blockID]),
            _.omit(data, ["session", "error"])
          );

          onSuccess?.(data);
        },
      }
    ),
  };
}

// * Not a hook, but putting it here
export async function enrollUser({
  blockID,
  memberID: memID,
  onSuccess,
  onError,
}: {
  blockID: number;
  memberID?: number;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  try {
    const memberID =
      memID || Storage.SessionHandling.getUserIDOfActiveSession();

    if (!memberID) {
      onError("UserID not found.");
      debugger;
      return;
    }

    const resp = await TytoCalls.Block.Enrollment.post({ blockID, memberID });

    if (!resp.registrationCount) {
      onError(
        "Something wetn awry; successful request suggests not enrollment records exist, still."
      );
      return;
    }

    onSuccess();
  } catch (err: any) {
    onError(
      typeof err === "string" ? err : _.get(err, "error.msg", "Error Occurred")
    );
  }
}

// * Not a hook, but putting it here next to hook below it, since it is a non-hook functionally equivalent
// * method for exams, which themselves automatically update status to ocCOMPLETE
export function triggerEnrollmentReload({
  enrollmentID,
  blockID,
  memberID,
  onSuccess,
  onError,
}: {
  blockID: number;
  memberID?: number;
  enrollmentID: number;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  if (!blockID || !enrollmentID) {
    return;
  }

  try {
    const memID =
      memberID || Storage.SessionHandling.getUserIDOfActiveSession();

    queryClient.invalidateQueries([
      REACT_QUERY_KEYS.PREREQUISITE_ENROLLMENTS,
      blockID,
      memID,
    ]);

    onSuccess();
  } catch (err) {
    const msg = _.get(err, "msg", "Error occurred.");

    onError(`${msg}`);
  }
}

// * Not a hook, but putting it here next to hook below it, since it is a non-hook functionally equivalent
// * method for exams, which themselves automatically update status to ocCOMPLETE
export function triggerTaskStructureReload({
  rootTaskID,
  onSuccess,
  onError,
}: {
  rootTaskID?: number;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  if (!rootTaskID) {
    return;
  }

  try {
    queryClient.invalidateQueries(
      `${REACT_QUERY_KEYS.TASK_STRUCTURE}/${rootTaskID}`
    );

    onSuccess();
  } catch (err) {
    const msg = _.get(err, "msg", "Error occurred.");

    onError(`${msg}`);
  }
}

export function useEnrollmentMutation({
  enrollmentID,
  blockID,
  memberID,
  showContent,
  onSuccess,
  onError,
}: {
  blockID: number;
  memberID?: number;
  enrollmentID: number;
  showContent?: boolean;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  // * This still needs handles for onSuccess mutating cached GET response info
  return useMutation(
    (updateData: Partial<Endpoints.Tyto.Launch.Enrollment.PutParameters>) => {
      debugger;
      return TytoCalls.LaunchEnrollment.put({ enrollmentID, ...updateData });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: (result, variables, context) => {
        /**
         * NOTE:
         * This is an absurdly long and complex method, doing a lot of things. It...
         * [1] First finds and updates the prerequisite for the locally cached item/list,
         * [2] Then checks if this is a prerequisite in a subBlock,
         * [3 (Case 1)] Updates the SubBlock in the 'subBlocks' list *if* it is,
         * [4 (Case 1)] If that subBlock is now complete, it finds any parents and updates them as well
         * [5 (Case 2)] If it is *not* a 'subBlock', it looks for the item in the 'training' list and updates that item
         */

        const memID =
          memberID || Storage.SessionHandling.getUserIDOfActiveSession();

        const enrID = variables.enrollmentID || enrollmentID;
        const showContentKey = `showContent=${
          !!showContent ? "true" : "false"
        }`;

        // * [1] - Update ReactQuery cache to mark enrollment completStatus and appropriately reflect what just happened server-side
        const currentPreReqEnrollmentsQuery = queryClient.getQueryState([
          REACT_QUERY_KEYS.PREREQUISITE_ENROLLMENTS,
          blockID,
          memID,
          showContentKey,
        ]);

        const currentPreReqEnrollmentsValue = _.get(
          currentPreReqEnrollmentsQuery,
          "data"
        ) as Endpoints.Responses.PrerequisiteEnrollments.Get;

        if (!currentPreReqEnrollmentsQuery || !currentPreReqEnrollmentsValue) {
          // TODO: Handle weird scenario where such is undefined?
          debugger;
          onSuccess();
          return;
        }

        // * Cycle through prereqEnrollments for course and update one with matching enrollmentID
        const newPreReqEnrollmentsValue = _.update(
          { ...currentPreReqEnrollmentsValue },
          "prerequisiteEnrollments",
          (prereqEnrollments: TytoData.Blocks.PrerequisiteEnrollment[]) => {
            return prereqEnrollments.map((prereqEnrollment) => {
              // * If this is the matching prereqEnrollment, update the 'prereqEnrollment.enrollment' object with the updates
              if (
                _.get(prereqEnrollment, "enrollment.enrollmentID", 0) === enrID
              ) {
                return _.update(
                  { ...prereqEnrollment },
                  "enrollment",
                  (innerEnrollmentObj) => {
                    return {
                      ...innerEnrollmentObj,
                      ..._.pick(variables, ["bookMark", "completeStatus"]), // * Pull out whitelisted variables from PUT call (don't want to include things like 'sessionKey'!)
                    };
                  }
                );
              }

              // * Not the matching prereqEnrollment; just return it as it is, untouched.
              return prereqEnrollment;
            });
          }
        );

        // * Update the cached ReacyQuery data with what we just updated locally
        queryClient.setQueryData(
          [
            REACT_QUERY_KEYS.PREREQUISITE_ENROLLMENTS,
            blockID,
            memID,
            showContentKey,
          ],
          newPreReqEnrollmentsValue
        );

        // * If all prereqs are updated now, create variable to update training list item appropriately
        const allRequiredStepsAreNowComplete = (
          (newPreReqEnrollmentsValue?.prerequisiteEnrollments as TytoData.Blocks.PrerequisiteEnrollment[]) ||
          []
        ).every(
          (prereqEnrollment) =>
            prereqEnrollment.completeStatus === "ocCOMPLETE" ||
            prereqEnrollment.enrollment?.completeStatus === "ocCOMPLETE" ||
            !!prereqEnrollment.isOptional
        );

        // * If all required steps are now complete, then invalidate taskStructure

        // ? [2] - Update LocalForage stored query appropriately. NOT SURE IF onSuccess GETS CALLED IN ORIGINAL QUERY AFTER UPDATE
        // TODO

        // * If update was NOT setting the prereqEnrollment to complete, then we don't need to update the training collection
        // * since the 'subTaskCompleteCount' doesn't need to be increased
        if (variables.completeStatus !== "ocCOMPLETE") {
          onSuccess();
          return;
        }

        // * [3] - If new completeStatus is ocCOMPLETE, update the block inside Training appropriately
        const currentTrainingQuery = queryClient.getQueryState(
          REACT_QUERY_KEYS.TRAINING
        );

        let currentTrainingValue = _.get(
          currentTrainingQuery,
          "data"
        ) as Endpoints.Responses.Training.Get;

        if (!currentTrainingValue || !currentTrainingValue) {
          // TODO: Handle weird scenario where training query doesn't exist or isn't found??
          debugger;
          onSuccess();
          return;
        }

        // * Determine if this is a subCourse and appropriately update data
        const { isSubBlock } = findIfCourseIsPrimaryOrSubBlock({
          curriculumID: blockID,
          primaryTraining: currentTrainingValue.training,
          subBlocks: currentTrainingValue.subBlocks,
        });

        debugger;
        // * Case 1: This is a SubCourse
        if (isSubBlock) {
          const targetSubBlock = currentTrainingValue?.subBlocks?.find(
            (subBlock) => subBlock.curriculumID === blockID
          );

          if (allRequiredStepsAreNowComplete && targetSubBlock) {
            // * This SubCourse just became complete, which means we need to mutated it's parent courses/plans as well
            const parentIDsSet = new Set([
              ...(targetSubBlock.parentTasks?.map(
                (parentTask) => parentTask.rootTaskID
              ) ?? []),
              ...(targetSubBlock.parentBlocks?.map(
                (parentBlock) => parentBlock.blockID
              ) ?? []),
            ]);

            const newTrainingValue = _.update(
              { ...currentTrainingValue },
              "training",
              (training) => {
                return training.map(
                  (
                    trainingCourseOrDevPlan:
                      | TytoData.Training.Enrollment
                      | TytoData.Training.Task
                  ) => {
                    // * If this item matches an ID of the course's parents, update it approriately.
                    if (
                      parentIDsSet.has(
                        _.get(trainingCourseOrDevPlan, "curriculumID", 0)
                      )
                    ) {
                      let newCompleteStatus =
                        trainingCourseOrDevPlan.completeStatus ===
                          "ocNOTSTARTED" ||
                        trainingCourseOrDevPlan.completeStatus ===
                          "ocNOTATTEMPTED"
                          ? "ocINCOMPLETE"
                          : trainingCourseOrDevPlan.completeStatus;

                      const subTaskCompleteCount =
                        trainingCourseOrDevPlan.subTaskCompleteCount + 1;

                      if (
                        trainingCourseOrDevPlan.subTaskCount &&
                        subTaskCompleteCount ===
                          trainingCourseOrDevPlan.subTaskCount
                      ) {
                        newCompleteStatus = "ocCOMPLETE";
                      }

                      return {
                        ...trainingCourseOrDevPlan,
                        completeStatus: newCompleteStatus,
                        subTaskCompleteCount: subTaskCompleteCount,
                      };
                    }

                    // * Does not match course we just updated, return as is.
                    return trainingCourseOrDevPlan;
                  }
                );
              }
            );
            currentTrainingValue = newTrainingValue;
          }

          const newTrainingValue = _.update(
            { ...currentTrainingValue },
            "subBlocks",
            (subBlocks) => {
              return subBlocks.map((subBlock: TytoData.Training.SubBlock) => {
                // * If this item matches the blockID of the course whose enrollment we just updated, update it approriately
                if (_.get(subBlock, "curriculumID", 0) === blockID) {
                  let newCompleteStatus =
                    subBlock.completeStatus === "ocNOTSTARTED" ||
                    subBlock.completeStatus === "ocNOTATTEMPTED"
                      ? "ocINCOMPLETE"
                      : subBlock.completeStatus;

                  if (allRequiredStepsAreNowComplete) {
                    newCompleteStatus = "ocCOMPLETE";
                  }

                  return {
                    ...subBlock,
                    completeStatus: newCompleteStatus,
                    subTaskCompleteCount: subBlock.subTaskCompleteCount + 1,
                  };
                }

                // * Does not match course we just updated, return as is.
                return subBlock;
              });
            }
          );

          debugger;

          // * Once again, update the cached ReacyQuery data with what we just updated locally
          queryClient.setQueryData(REACT_QUERY_KEYS.TRAINING, newTrainingValue);
        } else {
          // * Case 2: This is a Primary Course (in the 'training' Array, not 'subBlocks' Array)
          const newTrainingValue = _.update(
            { ...currentTrainingValue },
            "training",
            (training) => {
              return training.map(
                (
                  trainingCourseOrDevPlan:
                    | TytoData.Training.Enrollment
                    | TytoData.Training.Task
                ) => {
                  // * If this item matches the blockID of the course whose enrollment we just updated, update it approriately
                  if (
                    _.get(trainingCourseOrDevPlan, "curriculumID", 0) ===
                    blockID
                  ) {
                    let newCompleteStatus =
                      trainingCourseOrDevPlan.completeStatus ===
                        "ocNOTSTARTED" ||
                      trainingCourseOrDevPlan.completeStatus ===
                        "ocNOTATTEMPTED"
                        ? "ocINCOMPLETE"
                        : trainingCourseOrDevPlan.completeStatus;

                    if (allRequiredStepsAreNowComplete) {
                      newCompleteStatus = "ocCOMPLETE";
                    }

                    return {
                      ...trainingCourseOrDevPlan,
                      completeStatus: newCompleteStatus,
                      subTaskCompleteCount:
                        trainingCourseOrDevPlan.subTaskCompleteCount + 1,
                    };
                  }

                  // * Does not match course we just updated, return as is.
                  return trainingCourseOrDevPlan;
                }
              );
            }
          );

          debugger;

          // * Once again, update the cached ReacyQuery data with what we just updated locally
          queryClient.setQueryData(REACT_QUERY_KEYS.TRAINING, newTrainingValue);
        }

        onSuccess();
      },
    }
  );
}

export function useEnrollmentAssignmentMutation({
  enrollmentID,
  lessonID,
  blockID,
  memberID,
  showContent,
  onSuccess,
  onError,
}: {
  enrollmentID: number;
  lessonID: number;
  memberID?: number;
  blockID: number;
  showContent: boolean;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  // * This still needs handles for onSuccess mutating cached GET response info
  return useMutation(
    (
      updateData: Partial<Endpoints.Tyto.Enrollment.VerificationRequest.PutParameters>
    ) => {
      debugger;
      return TytoCalls.Enrollment.VerificationRequest.put({
        enrollmentID,
        ...updateData,
      });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        const showContentKey = `showContent=${
          !!showContent ? "true" : "false"
        }`;

        try {
          const memID =
            memberID || Storage.SessionHandling.getUserIDOfActiveSession();
          const bID = blockID;
          console.log("bID: ", bID);

          // * [0] - Invalidate /Launch/Enrollment data so it can start reloading
          console.log("variables: ", variables);
          if (!!variables.commentAboutIDs) {
            queryClient.invalidateQueries([
              REACT_QUERY_KEYS.LAUNCH_ENROLLMENT,
              enrollmentID,
            ]);
          }

          // * [1] - Update ReactQuery cache to mark enrollment completStatus and appropriately reflect what just happened server-side
          const currentPreReqEnrollmentsQuery = queryClient.getQueryState([
            REACT_QUERY_KEYS.PREREQUISITE_ENROLLMENTS,
            blockID,
            memID,
            showContentKey,
          ]);

          const currentPreReqEnrollmentsValue = _.get(
            currentPreReqEnrollmentsQuery,
            "data"
          ) as Endpoints.Responses.PrerequisiteEnrollments.Get;

          if (
            !currentPreReqEnrollmentsQuery ||
            !currentPreReqEnrollmentsValue
          ) {
            // TODO: Handle weird scenario where such is undefined?
            onSuccess();
            return;
          }

          // * Cycle through prereqEnrollments for course and update one with matching enrollmentID
          const prereqEnrollmentsResp =
            await TytoCalls.PrerequisiteEnrollments.get({
              memberID: memID,
              blockID: bID,
              showContent: true,
            });

          // * Update the cached ReacyQuery data with what we just updated locally
          queryClient.setQueryData(
            [
              REACT_QUERY_KEYS.PREREQUISITE_ENROLLMENTS,
              blockID,
              memID,
              showContentKey,
            ],
            prereqEnrollmentsResp
          );

          // * [3] - If new completeStatus is ocCOMPLETE, update the block inside Training appropriately
          const currentTrainingQuery = queryClient.getQueryState(
            REACT_QUERY_KEYS.TRAINING
          );

          const currentTrainingValue = _.get(
            currentTrainingQuery,
            "data"
          ) as Endpoints.Responses.Training.Get;

          if (!currentTrainingValue || !currentTrainingValue) {
            // TODO: Handle weird scenario where training query doesn't exist or isn't found??
            debugger;
            onSuccess();
            return;
          }

          const { isPrimaryTraining, isSubBlock } =
            findIfCourseIsPrimaryOrSubBlock({
              curriculumID: blockID,
              primaryTraining: currentTrainingValue.training,
              subBlocks: currentTrainingValue.subBlocks,
            });

          if (isPrimaryTraining) {
            const newTrainingValue = _.update(
              { ...currentTrainingValue },
              "training",
              (training) => {
                return training.map(
                  (
                    trainingCourseOrDevPlan:
                      | TytoData.Training.Enrollment
                      | TytoData.Training.Task
                  ) => {
                    // * If this item matches the blockID of the course whose enrollment we just updated, update it approriately
                    if (
                      _.get(trainingCourseOrDevPlan, "curriculumID", 0) ===
                      blockID
                    ) {
                      const newCompleteStatus =
                        trainingCourseOrDevPlan.completeStatus ===
                          "ocNOTSTARTED" ||
                        trainingCourseOrDevPlan.completeStatus ===
                          "ocNOTATTEMPTED"
                          ? "ocINCOMPLETE"
                          : trainingCourseOrDevPlan.completeStatus;

                      return {
                        ...trainingCourseOrDevPlan,
                        completeStatus: newCompleteStatus,
                        subTaskCompleteCount:
                          trainingCourseOrDevPlan.subTaskCompleteCount + 1,
                      };
                    }

                    // * Does not match course we just updated, return as is.
                    return trainingCourseOrDevPlan;
                  }
                );
              }
            );

            // * Once again, update the cached ReacyQuery data with what we just up
            queryClient.setQueryData(
              REACT_QUERY_KEYS.TRAINING,
              newTrainingValue
            );
          } else if (isSubBlock) {
            const newTrainingValueWithUpdatedSubBlocks = _.update(
              { ...currentTrainingValue },
              "subBlocks",
              (subBlocks) => {
                return subBlocks.map((subBlock: TytoData.Training.SubBlock) => {
                  // * If this item matches the blockID of the course whose enrollment we just updated, update it approriately
                  if (_.get(subBlock, "curriculumID", 0) === blockID) {
                    const newCompleteStatus =
                      subBlock.completeStatus === "ocNOTSTARTED" ||
                      subBlock.completeStatus === "ocNOTATTEMPTED"
                        ? "ocINCOMPLETE"
                        : subBlock.completeStatus;

                    return {
                      ...subBlock,
                      completeStatus: newCompleteStatus,
                      subTaskCompleteCount: subBlock.subTaskCompleteCount + 1,
                    };
                  }

                  // * Does not match course we just updated, return as is.
                  return subBlock;
                });
              }
            );

            // * Once again, update the cached ReacyQuery data with what we just up
            queryClient.setQueryData(
              REACT_QUERY_KEYS.TRAINING,
              newTrainingValueWithUpdatedSubBlocks
            );
          }

          onSuccess();
        } catch (err) {
          const errMsg = typeof err === "string" ? err : JSON.stringify(err);

          onError?.(`${errMsg}`);
        }
      },
    }
  );
}

export function useTaskAssignmentMutation({
  taskID,
  rootTaskID,
  onSuccess,
  onError,
}: {
  rootTaskID: number;
  taskID: number;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  // * This still needs handles for onSuccess mutating cached GET response info
  return useMutation(
    (
      updateData: Partial<Endpoints.Tyto.Task.VerificationRequest.PutParameters>
    ) => {
      return TytoCalls.Task.VerificationRequest.put({ taskID, ...updateData });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        try {
          const rTaskID = rootTaskID;
          console.log("rTaskID: ", rTaskID);
          debugger;

          // * [1] - Update ReactQuery cache to mark enrollment completStatus and appropriately reflect what just happened server-side
          const currentTaskStructureData = queryClient.getQueryState(
            `${REACT_QUERY_KEYS.TASK_STRUCTURE}/${rTaskID}`
          );

          const currentTaskStructureValue = _.get(
            currentTaskStructureData,
            "data"
          ) as Endpoints.Responses.Task.Structure.Get;

          console.log("currentTaskStructureData: ", currentTaskStructureData);
          console.log("currentTaskStructureValue: ", currentTaskStructureValue);

          if (!currentTaskStructureData || !currentTaskStructureValue) {
            // TODO: Handle weird scenario where such is undefined?
            debugger;
            onSuccess();
            return;
          }

          // * Cycle through prereqEnrollments for course and update one with matching enrollmentID
          const taskResp = await TytoCalls.Task.Structure.get({
            taskID: rTaskID,
          });

          // * Update the cached ReacyQuery data with what we just updated locally
          queryClient.setQueryData(
            `${REACT_QUERY_KEYS.TASK_STRUCTURE}/${rTaskID}`,
            taskResp
          );

          // * [3] - If new completeStatus is ocCOMPLETE, update the block inside Training appropriately
          const currentTrainingQuery = queryClient.getQueryState(
            REACT_QUERY_KEYS.TRAINING
          );

          const currentTrainingValue = _.get(
            currentTrainingQuery,
            "data"
          ) as Endpoints.Responses.Training.Get;

          if (!currentTrainingValue || !currentTrainingValue) {
            // TODO: Handle weird scenario where training query doesn't exist or isn't found??
            debugger;
            onSuccess();
            return;
          }

          const newTrainingValue = _.update(
            { ...currentTrainingValue },
            "training",
            (training) => {
              return training.map(
                (
                  trainingCourseOrDevPlan:
                    | TytoData.Training.Enrollment
                    | TytoData.Training.Task
                ) => {
                  // * If this item matches the blockID of the course whose enrollment we just updated, update it approriately
                  if (_.get(trainingCourseOrDevPlan, "taskID", 0) === rTaskID) {
                    const newCompleteStatus =
                      taskResp?.task.tasks[0]?.taskStatus ||
                      trainingCourseOrDevPlan.completeStatus;
                    const rootTaskCompleteChildrenCount = (
                      taskResp?.task?.tasks || []
                    ).reduce((accum, task) => {
                      if (task.aboutType !== "ocDEVPLAN") {
                        if (task.taskStatus === "ocCOMPLETE") {
                          accum += 1;
                        }
                      }

                      return accum;
                    }, 0);

                    return {
                      ...trainingCourseOrDevPlan,
                      completeStatus: newCompleteStatus,
                      subTaskCompleteCount: Math.max(
                        rootTaskCompleteChildrenCount,
                        trainingCourseOrDevPlan.subTaskCompleteCount
                      ),
                    };
                  }

                  // * Does not match course we just updated, return as is.
                  return trainingCourseOrDevPlan;
                }
              );
            }
          );

          // * Once again, update the cached ReacyQuery data with what we just updated locally
          queryClient.setQueryData(REACT_QUERY_KEYS.TRAINING, newTrainingValue);

          onSuccess();
        } catch (err) {
          const errMsg = typeof err === "string" ? err : JSON.stringify(err);

          onError?.(`${errMsg}`);
        }
      },
    }
  );
}

export function useTaskMutation({
  taskID,
  rootTaskID,
  onSuccess,
  onError,
}: {
  rootTaskID: number;
  taskID: number;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  // * This still needs handles for onSuccess mutating cached GET response info
  return useMutation(
    (updateData: Partial<Endpoints.Tyto.Tasks.PutParameters>) => {
      debugger;
      return TytoCalls.Tasks.put({ taskID, ...updateData });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        try {
          const rTaskID = rootTaskID;
          console.log("rTaskID: ", rTaskID);

          // * [1] - Update ReactQuery cache to mark enrollment completStatus and appropriately reflect what just happened server-side
          const currentTaskStructureData = queryClient.getQueryState(
            `${REACT_QUERY_KEYS.TASK_STRUCTURE}/${rTaskID}`
          );

          const currentTaskStructureValue = _.get(
            currentTaskStructureData,
            "data"
          ) as Endpoints.Responses.Task.Structure.Get;

          console.log("currentTaskStructureData: ", currentTaskStructureData);
          console.log("currentTaskStructureValue: ", currentTaskStructureValue);

          if (!currentTaskStructureData || !currentTaskStructureValue) {
            // TODO: Handle weird scenario where such is undefined?
            debugger;
            onSuccess();
            return;
          }

          // * Cycle through prereqEnrollments for course and update one with matching enrollmentID
          const taskResp = await TytoCalls.Task.Structure.get({
            taskID: rTaskID,
          });

          // * Update the cached ReacyQuery data with what we just updated locally
          queryClient.setQueryData(
            `${REACT_QUERY_KEYS.TASK_STRUCTURE}/${rTaskID}`,
            taskResp
          );

          // * [3] - If new completeStatus is ocCOMPLETE, update the block inside Training appropriately
          const currentTrainingQuery = queryClient.getQueryState(
            REACT_QUERY_KEYS.TRAINING
          );

          const currentTrainingValue = _.get(
            currentTrainingQuery,
            "data"
          ) as Endpoints.Responses.Training.Get;

          if (!currentTrainingValue || !currentTrainingValue) {
            // TODO: Handle weird scenario where training query doesn't exist or isn't found??
            debugger;
            onSuccess();
            return;
          }

          const newTrainingValue = _.update(
            { ...currentTrainingValue },
            "training",
            (training) => {
              return training.map(
                (
                  trainingCourseOrDevPlan:
                    | TytoData.Training.Enrollment
                    | TytoData.Training.Task
                ) => {
                  // * If this item matches the blockID of the course whose enrollment we just updated, update it approriately
                  if (_.get(trainingCourseOrDevPlan, "taskID", 0) === rTaskID) {
                    const newCompleteStatus =
                      taskResp?.task.tasks[0]?.taskStatus ||
                      trainingCourseOrDevPlan.completeStatus;
                    const rootTaskCompleteChildrenCount = (
                      taskResp?.task?.tasks || []
                    ).reduce((accum, task) => {
                      if (task.aboutType !== "ocDEVPLAN") {
                        if (task.taskStatus === "ocCOMPLETE") {
                          accum += 1;
                        }
                      }

                      return accum;
                    }, 0);

                    return {
                      ...trainingCourseOrDevPlan,
                      completeStatus: newCompleteStatus,
                      subTaskCompleteCount: Math.max(
                        rootTaskCompleteChildrenCount,
                        trainingCourseOrDevPlan.subTaskCompleteCount
                      ),
                    };
                  }

                  // * Does not match course we just updated, return as is.
                  return trainingCourseOrDevPlan;
                }
              );
            }
          );

          // * Once again, update the cached ReacyQuery data with what we just updated locally
          queryClient.setQueryData(REACT_QUERY_KEYS.TRAINING, newTrainingValue);

          // ? [4] - Update LocalForage appropriately for Training. NOT SURE IF onSuccess GETS CALLED IN ORIGINAL QUERY AFTER UPDATE
          // TODO

          onSuccess();
        } catch (err) {
          const errMsg = typeof err === "string" ? err : JSON.stringify(err);

          onError?.(`${errMsg}`);
        }
      },
    }
  );
}

export function useLesson({
  lessonID,
  isEnabled = true,
}: {
  lessonID: number;
  isEnabled?: boolean;
}) {
  const storedValueQuery = useLocalForage<Endpoints.Responses.Lesson.Get>(
    REACT_QUERY_KEYS.LESSON,
    [lessonID]
  );

  return {
    storedValueQuery,
    ...useQuery(
      [REACT_QUERY_KEYS.LESSON, lessonID],
      async () => {
        const data = await TytoCalls.Lesson.get({ lessonID });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: !!isEnabled,
        onSuccess: (data) => {
          // debugger;
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.LESSON, [lessonID]),
            _.omit(data, ["session", "error"])
          );
        },
      }
    ),
  };
}

export function usePerson({
  userID,
  isEnabled = true,
  onSuccess,
}: {
  userID: number;
  isEnabled?: boolean;
  onSuccess?: (data: {
    person: TytoData.Person;
    session: Data.SessionData;
  }) => void;
}) {
  const storedValueQuery = useLocalForage<{
    person: TytoData.Person;
    session: Data.SessionData;
  }>(REACT_QUERY_KEYS.PERSON, [userID]);

  return {
    storedValueQuery,
    ...useQuery(
      [REACT_QUERY_KEYS.PERSON, userID],
      async () => {
        const data = await TytoCalls.Person.get({ personID: userID });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: !!isEnabled,
        onSuccess: (data) => {
          // debugger;
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.PERSON, [userID]),
            _.omit(data, ["session", "error"])
          );
          if (onSuccess) {
            onSuccess(data);
          }
        },
      }
    ),
  };
}

export function usePrerequisites({
  blockID,
  isEnabled = true,
}: {
  blockID: number;
  isEnabled?: boolean;
}) {
  const storedValueQuery = useLocalForage(
    REACT_QUERY_KEYS.BLOCK_PREREQUISITES,
    [blockID]
  );

  return {
    storedValueQuery,
    ...useQuery(
      [REACT_QUERY_KEYS.BLOCK_PREREQUISITES, blockID],
      async () => {
        const data = await TytoCalls.Block.Prerequisites.get({ blockID });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: isEnabled,
        onSuccess: (data) => {
          // debugger;
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.BLOCK_PREREQUISITES, [blockID]),
            _.omit(data, ["session", "error"])
          );
        },
      }
    ),
  };
}

export function usePrerequisiteEnrollments({
  blockID,
  memberID,
  showContent,
  retry = false,
  isEnabled = true,
  onError,
  onLocalStorageSucess,
  onSuccess,
}: {
  blockID: number;
  isEnabled?: boolean;
  memberID: number;
  showContent: boolean;
  retry?: boolean | number;
  onError?: (err: any) => void;
  onLocalStorageSucess?: (
    data: Endpoints.Responses.PrerequisiteEnrollments.Get
  ) => void;
  onSuccess?: (data: Endpoints.Responses.PrerequisiteEnrollments.Get) => void;
}) {
  const showContentKey = `showContent=${!!showContent ? "true" : "false"}`;
  const storedValueQuery = useLocalForage(
    REACT_QUERY_KEYS.PREREQUISITE_ENROLLMENTS,
    [blockID, memberID, showContentKey],
    {
      onSuccess: onLocalStorageSucess,
    }
  );

  return {
    storedValueQuery,
    ...useQuery(
      [
        REACT_QUERY_KEYS.PREREQUISITE_ENROLLMENTS,
        blockID,
        memberID,
        showContentKey,
      ],
      async () => {
        const data = await TytoCalls.PrerequisiteEnrollments.get({
          blockID,
          memberID,
          showContent: !!showContent,
        });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: isEnabled,
        onError,
        onSuccess: (data) => {
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.PREREQUISITE_ENROLLMENTS, [
              blockID,
              memberID,
              showContentKey,
            ]),
            _.omit(data, ["session", "error"])
          );

          if (onSuccess) {
            onSuccess(data);
          }
        },
        retry,
      }
    ),
  };
}

export function useLaunchEnrollment({
  enrollmentID,
  retry = false,
  isEnabled = true,
  onError,
  onSuccess,
}: {
  enrollmentID: number;
  retry?: boolean | number;
  isEnabled?: boolean;
  onError?: (err: any) => void;
  onSuccess?: (data: Endpoints.Responses.Launch.Enrollment.Get) => void;
}) {
  const storedValueQuery =
    useLocalForage<Endpoints.Responses.Launch.Enrollment.Get>(
      REACT_QUERY_KEYS.PREREQUISITE_ENROLLMENTS,
      [enrollmentID]
    );

  return {
    storedValueQuery,
    ...useQuery(
      [REACT_QUERY_KEYS.LAUNCH_ENROLLMENT, enrollmentID],
      async () => {
        const data = await TytoCalls.LaunchEnrollment.get({
          enrollmentID,
        });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: isEnabled,
        onError,
        onSuccess: (data) => {
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.LAUNCH_ENROLLMENT, [
              enrollmentID,
            ]),
            _.omit(data, ["session", "error"])
          );

          if (onSuccess) {
            onSuccess(data);
          }
        },
        retry,
      }
    ),
  };
}

export function useTrending() {
  const storedValueQuery = useLocalForage(REACT_QUERY_KEYS.TRENDING);

  return {
    storedValueQuery,
    ...useQuery(
      REACT_QUERY_KEYS.TRENDING,
      async () => {
        const data = await TytoCalls.CurriculumPubCatalogItemsTrending.get({});

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        staleTime: timeInMS({ timeQuantity: 1, timeType: "tomorrow-morning" }),
        onSuccess: (data) => {
          // debugger;
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.TRENDING),
            _.omit(data, ["session", "error"])
          );
        },
      }
    ),
  };
}

export function useTraining(opts?: {
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.Training.Get) => void;
}) {
  const storedValueQuery = useLocalForage(
    REACT_QUERY_KEYS.TRAINING,
    null,
    opts
  );

  return {
    storedValueQuery,
    ...useQuery(
      REACT_QUERY_KEYS.TRAINING,
      async () => {
        const data = await TytoCalls.Training.get({});

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        onSuccess: (data) => {
          try {
            LF.setItem(
              makeLocalForageKey(REACT_QUERY_KEYS.TRAINING),
              _.omit(data, ["session", "error"])
            );
          } catch (err) {
            console.log("ERROR: ", err);
            debugger;
          }

          opts?.onSuccess?.(data);
        },
        onError: (data) => {
          const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
          console.log("errorSts: ", errorSts, " onError data: ", data);

          if (opts && opts.onError) {
            opts.onError(data as Data.TytoErrorObject);
          }
        },
      }
    ),
  };
}

export function useHomeLinkSrc() {
  const [homeLinkSrc] = React.useState(() => {
    const domainID = SessionHandling.getActiveSession()?.domainID ?? 0;

    return domainID
      ? `${IMAGE_BASE_URL}/v2/domains/${domainID}/images/home_link.png`
      : "";
  });

  return {
    data: {
      homeLinkSrc,
    },
  };
}

export function usePlanSubCourseCompletionCounts(
  innerCourse: TytoData.Tasks.Task,
  prerequisiteEnrollments?: TytoData.Training.Enrollment[]
) {
  const completionData = React.useMemo(() => {
    const {
      countallchildren = 0,
      countcompletechildren = 0,
      taskStatus,
    } = innerCourse;

    let total = countallchildren;
    let completed = countcompletechildren;

    // * If both counts are 0, attempt to figure out completion data a different way
    if (!countallchildren && !countcompletechildren) {
      // * If prerequisiteEnrollments is supplied, use such to determine counts manually
      if (prerequisiteEnrollments && prerequisiteEnrollments.length) {
        const manualCompletedTally = prerequisiteEnrollments.reduce(
          (accum, prereq) => {
            if (prereq.completeStatus === "ocCOMPLETE") {
              accum += 1;
            }

            return accum;
          },
          0
        );

        completed = manualCompletedTally;
        total = prerequisiteEnrollments.length;
      } else if (taskStatus === "ocCOMPLETE") {
        // * Prereqs were not supplied, so just check if taskStatus is complete
        total = 1;
        completed = 1;
      }
    }

    return {
      total,
      completed,
      progressDecimal: completed / total,
    };
  }, [innerCourse, prerequisiteEnrollments]);

  return completionData;
}

export function useTimezones(
  args?: Partial<Endpoints.Tyto.TimeZone.GetParameters>
) {
  const storedValueQuery = useLocalForage<Endpoints.Responses.TimeZones.Get>(
    REACT_QUERY_KEYS.TIMEZONES
  );

  return {
    storedValueQuery,
    ...useQuery(
      REACT_QUERY_KEYS.TIMEZONES,
      async () => {
        const data = await TytoCalls.Timezones.get({ ...(args || {}) });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        staleTime: timeInMS({ timeQuantity: 4, timeType: "hours" }),
        onSuccess: (data) => {
          // debugger;
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.TRAINING),
            _.omit(data, ["session", "error"])
          );
        },
      }
    ),
  };
}

// * PPF RELATED HOOKS
export function usePPFPlans(opts?: {
  activeStatus?: Array<keyof typeof TytoData.ActiveStatus>;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.GS.Plans.Get) => void;
}) {
  const activeStatusFilter = opts?.activeStatus?.join?.(",") ?? "ocENABLED";

  const storedValueQuery = useLocalForage<Endpoints.Responses.GS.Plans.Get>(
    REACT_QUERY_KEYS.PLANS,
    activeStatusFilter ? [activeStatusFilter] : null,
    opts
  );
  const query = useQuery(
    [REACT_QUERY_KEYS.PLANS, opts?.activeStatus?.join?.(",")],
    async () => {
      const data = await TytoCalls.PPF.Plans.get({
        activeStatus: activeStatusFilter,
      });

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      onSuccess: (data) => {
        try {
          LF.setItem(
            makeLocalForageKey(
              REACT_QUERY_KEYS.PLANS,
              activeStatusFilter ? [activeStatusFilter] : undefined
            ),
            _.omit(data, ["session", "error"])
          );
        } catch (err) {
          console.log("ERROR: ", err);
          debugger;
        }

        opts?.onSuccess?.(data);
      },
      onError: (data) => {
        const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
        console.log("errorSts: ", errorSts, " onError data: ", data);

        if (opts && opts.onError) {
          opts.onError(data as Data.TytoErrorObject);
        }
      },
    }
  );

  return {
    storedValueQuery,
    ...query,
    eagerData: query.data ?? storedValueQuery.data,
  };
}

export function usePPFPlan(opts: {
  planID: number;
  isEnabled?: boolean;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.GS.Plan.Get) => void;
}) {
  const storedValueQuery = useLocalForage<Endpoints.Responses.GS.Plan.Get>(
    REACT_QUERY_KEYS.PLAN,
    [opts.planID]
  );

  return {
    storedValueQuery,
    ...useQuery(
      [REACT_QUERY_KEYS.PLAN, opts.planID],
      async () => {
        const data = await TytoCalls.PPF.Plan.get({ gsPlanID: opts.planID });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: opts?.isEnabled ?? true,
        onSuccess: (data) => {
          try {
            LF.setItem(
              makeLocalForageKey(REACT_QUERY_KEYS.PLAN, [opts.planID]),
              _.omit(data, ["session", "error"])
            );
          } catch (err) {
            console.log("ERROR: ", err);
            debugger;
          }

          opts?.onSuccess?.(data);
        },
        onError: (data) => {
          const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
          console.log("errorSts: ", errorSts, " onError data: ", data);

          if (opts && opts.onError) {
            opts.onError(data as Data.TytoErrorObject);
          }
        },
      }
    ),
  };
}

export function usePPFPlanGoals(opts: {
  planID: number;
  isEnabled?: boolean;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.GS.Goals.Get) => void;
}) {
  const storedValueQuery = useLocalForage<Endpoints.Responses.GS.Goals.Get>(
    REACT_QUERY_KEYS.PLAN_GOALS,
    [opts.planID]
  );

  return {
    storedValueQuery,
    ...useQuery(
      [REACT_QUERY_KEYS.PLAN_GOALS, opts.planID],
      async () => {
        const data = await TytoCalls.PPF.Goals.get({ gsPlanID: opts.planID });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: opts?.isEnabled ?? true,
        onSuccess: (data) => {
          try {
            LF.setItem(
              makeLocalForageKey(REACT_QUERY_KEYS.PLAN_GOALS, [opts.planID]),
              _.omit(data, ["session", "error"])
            );
          } catch (err) {
            console.log("ERROR: ", err);
            debugger;
          }

          opts?.onSuccess?.(data);
        },
        onError: (data) => {
          const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
          console.log("errorSts: ", errorSts, " onError data: ", data);

          if (opts && opts.onError) {
            opts.onError(data as Data.TytoErrorObject);
          }
        },
      }
    ),
  };
}

export function usePPFPlanMembers(opts: {
  planID: number;
  isEnabled?: boolean;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.GS.Members.Get) => void;
}) {
  const storedValueQuery = useLocalForage<Endpoints.Responses.GS.Members.Get>(
    REACT_QUERY_KEYS.PLAN_MEMBERS,
    [opts.planID]
  );

  const query = useQuery(
    [REACT_QUERY_KEYS.PLAN_MEMBERS, opts.planID],
    async () => {
      const data = await TytoCalls.PPF.Members.get({ gsPlanID: opts.planID });

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      enabled: opts?.isEnabled ?? true,
      onSuccess: (data) => {
        try {
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.PLAN_MEMBERS, [opts.planID]),
            _.omit(data, ["session", "error"])
          );
        } catch (err) {
          console.log("ERROR: ", err);
          debugger;
        }

        opts?.onSuccess?.(data);
      },
      onError: (data) => {
        const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
        console.log("errorSts: ", errorSts, " onError data: ", data);

        if (opts && opts.onError) {
          opts.onError(data as Data.TytoErrorObject);
        }
      },
    }
  );

  return {
    storedValueQuery,
    ...query,
    eagerData: query?.data ?? storedValueQuery?.data,
  };
}

export function usePlanPermissions({
  planID,
  userID,
}: {
  planID: number;
  userID?: number;
}) {
  const [memID, updateMemID] = React.useState(() => {
    return (
      userID ?? SessionHandling.getPropertyFromActiveSession("userID") ?? 0
    );
  });
  const planMembersQuery = usePPFPlanMembers({
    planID,
  });
  const planQuery = usePPFPlan({
    planID,
  });

  const permissions = React.useMemo(() => {
    const planPermissions = planQuery.data?.gsPlan?.permission;
    const matchingMember = (planMembersQuery.data?.gsMembers ?? []).find(
      (member) => member.memberID === memID
    );

    if (matchingMember) {
      const memberPermissions =
        pullPermissionFromPlanMemberObject(matchingMember);
    }

    return (planPermissions ??
      createFakeFalsePPFPermissions()) as TytoData.PPF.Plan.MemberPermissionData;
  }, [planMembersQuery.data, planQuery.data]);

  return {
    permissions,
    planQuery,
    planMembersQuery,
  };
}

export function usePPFPlanByID({ userID }: { userID: number }) {
  const [planID, updatePlanID] = React.useState(0);

  const data = usePPFPlans({
    onSuccess: (plansResp) => {
      const { gsPlans } = plansResp ?? {};

      if (gsPlans?.length) {
        const usersOwnPlan = gsPlans.filter(
          (plan) => plan.aboutID === userID && plan.activeStatus === "ocENABLED"
        );

        if (usersOwnPlan[0]?.gsPlanID) {
          updatePlanID(usersOwnPlan[0].gsPlanID);
        }
      }
    },
  });

  return {
    planID: planID,
  };
}

export function useGoalMemberAddMutation({
  gsPlanID,
  onSuccess,
  onError,
}: {
  gsPlanID: number;
  onSuccess: (wasSuccessful: boolean) => void;
  onError: (errorMsg: string) => void;
}) {
  return useMutation(
    (payload: { memberID: number; managerPerms: any }) => {
      return TytoCalls.PPF.Member.post({
        gsPlanID,
        memberID: payload.memberID,
        ...payload.managerPerms,
      });
    },
    {
      onError: (error, variables, context) => {
        onError(pullMessageFromError(error));
      },
      onSuccess: async (result, variables, context) => {
        let wasSuccessful = false;

        if (result.recordsAffected) {
          const planMembersQuery =
            QueryCacheHelpers.invalidateMembersCache(gsPlanID);

          wasSuccessful = !!planMembersQuery;
        }

        onSuccess(wasSuccessful);
      },
    }
  );
}
interface GoalMemberPayload {
  gsMemberID: number;
}
export function useGoalMembersDeleteMutation({
  gsPlanID,
  onSuccess,
  onError,
}: {
  gsMemberID: number;
  gsPlanID: number;
  onSuccess: (wasSuccessful: boolean) => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  return useMutation(
    (payload: { gsMemberID: number }) => {
      return TytoCalls.PPF.Member.delete({
        gsMemberID: payload.gsMemberID,
      });
    },
    {
      onError: (error, variables, context) => {
        onError(pullMessageFromError(error));
        alert(pullMessageFromError(error));
      },
      onSuccess: async (result, variables, context) => {
        let wasSuccessful = false;

        if (result.recordsAffected) {
          const planMembersQuery =
            QueryCacheHelpers.getRQPlanMembersData(gsPlanID);
          const newMembers = planMembersQuery?.filter(
            (member: any) => member.gsMemberID != variables.gsMemberID
          );

          QueryCacheHelpers.setRQPlanMembersData(
            gsPlanID,
            newMembers ? newMembers : []
          );

          //! TODO Not correctly clearing plans. "my people" still shows old data when member is removed.
          //! note at an unknown time it will catch up but it's sometimes hours.
          QueryCacheHelpers.invalidatePlansCache();
          // const plansResp = await TytoCalls.PPF.Plans.get({});
          // QueryCacheHelpers.setRQPlansData(plansResp);

          wasSuccessful = !!newMembers;
        }

        onSuccess(wasSuccessful);
      },
    }
  );
}

export function useGoalDeleteMutation({
  planID,
  gsGoalID,
  onSuccess,
  onError,
}: {
  gsGoalID: number;
  planID: number;
  onSuccess: (wasSuccessful: boolean) => void;
  onError: (errorMsg: string) => void;
}) {
  return useMutation(
    (payload: { gsGoalID: number }) => {
      return TytoCalls.PPF.Goal.delete({
        gsGoalID: payload.gsGoalID,
      });
    },
    {
      onError: (error, variables, context) => {
        onError(pullMessageFromError(error));
        alert(pullMessageFromError(error));
      },
      onSuccess: async (result, variables, context) => {
        let wasSuccessful = false;

        if (result.recordsAffected) {
          const queryUpdateGoals = QueryCacheHelpers.removeChildGoal(
            gsGoalID,
            planID
          );

          wasSuccessful = !!queryUpdateGoals;
        }

        onSuccess(wasSuccessful);
      },
    }
  );
}

interface GoalPayload
  extends Partial<
    Omit<
      TytoData.PPF.Plan.Goals.ChildGoal,
      "gsGoalID" | "gsPlanID" | "parentGoalID"
    >
  > {
  gsGoalDesc?: string;
  gsGoalName?: string;
  templateImageKey?: string;
  profileImageID?: number;
  durationYears?: number;
  gsParentGoalID?: number;
  plan?: TytoData.PPF.Plan.Plan;
  updatedItems?: TytoData.PPF.Plan.Goals.GoalItem[];
  newItems?: TytoData.PPF.Plan.Goals.GoalItem[];
  uploadGUID?: string;
  targetDateFinal?: string;
}

// * December 31st, 2030
const DEFAULT_PLAN_END_DATE = "2030-12-31T00:00:00.000Z";

async function updatePlanIfNecessary({
  plan,
  targetDateFinal,
}: Pick<GoalPayload, "plan" | "targetDateFinal">) {
  try {
    if (!plan?.endTime || !targetDateFinal) {
      return;
    }

    const targetFinalDate = new Date(targetDateFinal);
    const planEndDate = new Date(targetDateFinal);

    // * Plan endTime is greater than targetFinalDate. Nothing needs ot be done; return.
    if (+planEndDate > +targetFinalDate) {
      return;
    }

    const { gsPlanID } = plan;

    await TytoCalls.PPF.Plan.put({
      gsPlanID,
      endTime: DEFAULT_PLAN_END_DATE,
    });

    QueryCacheHelpers.invalidatePlanCache(gsPlanID);

    return;
  } catch (err) {
    return;
  }
}

export function useGoalMutation({
  childGoal,
  onSuccess,
  onError,
}: {
  childGoal: TytoData.PPF.Plan.Goals.ChildGoal;
  onSuccess: (payload: GoalPayload, wasSuccessful: boolean) => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  return useMutation(
    async (goalData: GoalPayload) => {
      let imageUpdateObj = {};
      debugger;

      // * If template image, remove profileImage
      if (goalData.templateImageKey) {
        imageUpdateObj = {
          profileImageID: 0,
          profileImageKey: goalData.templateImageKey,
          // templateImageKey: goalData.templateImageKey
        };
      } else if (goalData.uploadGUID) {
        // * If uploadID, remove templateImage
        imageUpdateObj = {
          profileImageKey: "",
          // templateImageKey: ""
        };
      } else if (
        goalData.profileImageID === 0 &&
        goalData.templateImageKey === ""
      ) {
        imageUpdateObj = {
          profileImageID: 0,
          profileImageKey: "",
        };
      }

      await updatePlanIfNecessary(
        _.pick(goalData, ["plan", "targetDateFinal"])
      );

      const goalPutResponse = await TytoCalls.PPF.Goal.put({
        ..._.omit(goalData, [
          "desc",
          "elementName",
          "elementDesc",
          "name",
          "newItems",
          "profileImageID",
          "plan",
          "templateImageKey",
          "updatedItems",
          "uploadGUID",
        ]),
        gsGoalID: childGoal.gsGoalID,
        ...(imageUpdateObj ?? {}),
        ...(goalData.uploadGUID
          ? { profileAssetUploadKey: goalData.uploadGUID }
          : {}),
      });

      return goalPutResponse;
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        let wasSuccessful = false;

        if (variables.uploadGUID) {
          await TytoCalls.GS.Goal.ProfileImage.post({
            gsGoalID: childGoal.gsGoalID,
            profileAssetUploadKey: variables.uploadGUID,
          });

          QueryCacheHelpers.updateChildGoalData({
            goal: childGoal,
            data: {
              profileImageKey: "",
            },
          });
        } else if (typeof variables.templateImageKey === "string") {
          // * Update templateImageKey before navigating so no delayed image switch
          QueryCacheHelpers.updateChildGoalData({
            goal: childGoal,
            data: {
              profileImageKey: variables.templateImageKey,
            },
          });
        } else if (variables.profileImageID === 0) {
          // * If profileImage was just removed, remove it locally before navigating back to Goal
          QueryCacheHelpers.updateChildGoalData({
            goal: childGoal,
            data: {
              profileImageID: 0,
              profileImageAsset: undefined,
            },
          });
        }

        if (result.recordsAffected) {
          const queryUpdateReport = QueryCacheHelpers.updateChildGoalData({
            goal: childGoal,
            data: variables,
          });

          wasSuccessful = !!queryUpdateReport;
        }

        onSuccess(variables, wasSuccessful);
      },
    }
  );
}

interface GoalItemPayload {
  description: string;
  status: keyof typeof TytoData.GoalItemStatus;
  seq: number;
  itemWeight: number;
  gsGoalItemKey: number;
}

export function useGoalItemMutation({
  childGoal,
  onSuccess,
  onError,
}: {
  childGoal: TytoData.PPF.Plan.Goals.ChildGoal;
  onSuccess: (wasSuccessful: boolean) => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  return useMutation(
    (newNoticeData: GoalItemPayload) => {
      return TytoCalls.PPF.Goal.Item.put({ ...newNoticeData });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        let wasSuccessful = false;

        if (result.recordsAffected) {
          const queryUpdateReport = QueryCacheHelpers.updateChildGoalItems({
            goal: childGoal,
            existingItemUpdates: [variables],
          });

          wasSuccessful = !!queryUpdateReport;
        }

        onSuccess(wasSuccessful);
      },
    }
  );
}

interface NewGoalItemPayload {
  description: string;
  seq: number;
  itemWeight: number;
  status: keyof typeof TytoData.GoalItemStatus;
}

export function useNewGoalItemMutation({
  childGoal,
  onSuccess,
  onError,
}: {
  childGoal: TytoData.PPF.Plan.Goals.ChildGoal;
  onSuccess: (wasSuccessful: boolean) => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  return useMutation(
    (newNoticeData: NewGoalItemPayload) => {
      return TytoCalls.PPF.Goal.Item.post({
        ...newNoticeData,
        gsGoalID: childGoal.gsGoalID,
      });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        let wasSuccessful = false;

        if (result.gsGoalItemKey) {
          const queryUpdateReport = QueryCacheHelpers.updateChildGoalItems({
            goal: childGoal,
            newItems: [
              {
                description: variables.description,
                status: variables.status,
                seq: variables.seq,
                itemWeight: variables.itemWeight,
                gsGoalItemKey: result.gsGoalItemKey,
              },
            ],
          });

          wasSuccessful = !!queryUpdateReport;
        }

        onSuccess(wasSuccessful);
      },
    }
  );
}

interface NewNoticePayload {
  messageText: string;
}

export function useNewNoticeThreadMutation({
  goalID,
  planID,
  onSuccess,
  onError,
}: {
  goalID: number;
  planID: number;
  onSuccess: (newNoticeID: number) => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  return useMutation(
    (newNoticeData: NewNoticePayload) => {
      return TytoCalls.PPF.Plan.Notice.post({
        gsPlanID: planID,
        gsGoalID: goalID,
        // aboutType: "ocGSGOAL",
        noticeText: newNoticeData.messageText,
      });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        if (result.newNoticeID) {
          /**
           * NOTE: Would be worth making a scoped NoticeBoard call
           * with 'goalID' param, then merging those specific notices
           * into cache for noticeBoards for the planID
           *
           * TODO: What is stated above...
           */

          queryClient.invalidateQueries([
            REACT_QUERY_KEYS.PLAN_NOTICEBOARD,
            planID,
          ]);
        }

        onSuccess(result.newNoticeID);
      },
    }
  );
}

interface NewCommentPayload {
  messageText: string;
}

export function useNewCommentMutation({
  noticeID,
  planID,
  onSuccess,
  onError,
}: {
  noticeID: number;
  planID: number;
  onSuccess: (newNoticeID: number) => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  return useMutation(
    (newCommentData: NewCommentPayload) => {
      return TytoCalls.PPF.Plan.Notice.Comment.post({
        noticeID,
        // aboutType: "ocGSGOAL",
        commentText: newCommentData.messageText,
      });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        if (result.noticeCommentID) {
          /**
           * NOTE: Would be worth making a scoped NoticeBoard call
           * with 'goalID' param, then merging those specific notices
           * into cache for noticeBoards for the planID
           *
           * TODO: What is stated above...
           */

          queryClient.invalidateQueries([
            REACT_QUERY_KEYS.PLAN_NOTICEBOARD,
            planID,
          ]);
        }

        onSuccess(result.noticeCommentID);
      },
    }
  );
}

interface CommentLikePayload {
  newLikeStatus: boolean;
}

export function useCommentLikeMutation({
  commentID,
  noticeID,
  planID,
  onSuccess,
  onError,
}: {
  commentID: number;
  noticeID: number;
  planID: number;
  onSuccess: (wasSuccesful: boolean, successfullyUpdatedCache: boolean) => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  return useMutation(
    (newNoticeData: CommentLikePayload) => {
      if (newNoticeData.newLikeStatus) {
        return TytoCalls.PPF.Plan.Notice.Comment.Like.put({
          noticeCommentID: commentID,
        });
      } else {
        return TytoCalls.PPF.Plan.Notice.Comment.Unlike.put({
          noticeCommentID: commentID,
        });
      }
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        let successfullyUpdatedCache = false;

        if (commentID) {
          successfullyUpdatedCache = QueryCacheHelpers.updateCommentLike({
            isNowLiked: variables.newLikeStatus,
            commentID,
            noticeID,
            planID,
          });
        }

        onSuccess(!!result.recordsAffected, successfullyUpdatedCache);
      },
    }
  );
}

export function usePPFPlanNoticeBoard(opts: {
  planID: number;
  isEnabled?: boolean;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.GS.Plan.Notices.Get) => void;
}) {
  const storedValueQuery =
    useLocalForage<Endpoints.Responses.GS.Plan.Notices.Get>(
      REACT_QUERY_KEYS.PLAN_NOTICEBOARD,
      [opts.planID]
    );

  return {
    storedValueQuery,
    ...useQuery(
      [REACT_QUERY_KEYS.PLAN_NOTICEBOARD, opts.planID],
      async () => {
        const data = await TytoCalls.PPF.Plan.Notices.get({
          gsPlanID: opts.planID,
        });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: opts?.isEnabled ?? true,
        retry: false,
        onSuccess: (data) => {
          try {
            LF.setItem(
              makeLocalForageKey(REACT_QUERY_KEYS.PLAN_NOTICEBOARD, [
                opts.planID,
              ]),
              _.omit(data, ["session", "error"])
            );
          } catch (err) {
            console.log("ERROR: ", err);
            debugger;
          }

          opts?.onSuccess?.(data);
        },
        onError: (data) => {
          const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
          console.log("errorSts: ", errorSts, " onError data: ", data);

          if (opts && opts.onError) {
            opts.onError(data as Data.TytoErrorObject);
          }
        },
      }
    ),
  };
}

export function usePPFPlanDashboard(opts: {
  isCascade?: boolean;
  endDate?: Date;
  startDate?: Date;
  historyAfterDate?: Date;
  historyBeforeDate?: Date;
  teamID: number;
  isEnabled?: boolean;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.GS.PPFDashboard.Get) => void;
  onDownloadProgress?: (proggEvent: any) => void;
}) {
  const storedValueQuery =
    useLocalForage<Endpoints.Responses.GS.PPFDashboard.Get>(
      REACT_QUERY_KEYS.PLAN_DASHBOARD,
      [
        opts.teamID,
        +(opts.startDate ?? 0),
        +(opts.endDate ?? 0),
        opts.isCascade ? "TRUE" : "FALSE",
      ],
      {
        onSuccess: (data) => {
          console.log(
            "((STORED)) DASHBOARD.allPersons: ",
            data?.dashboard?.allPersons
          );
        },
      }
    );
  const query = useQuery(
    [
      REACT_QUERY_KEYS.PLAN_DASHBOARD,
      opts.teamID,
      +(opts.startDate ?? 0),
      +(opts.endDate ?? 0),
      opts.isCascade ? "TRUE" : "FALSE",
    ],
    async () => {
      const { endDate, isCascade, startDate, teamID } = opts;

      const now = new Date();
      const yesterday = new Date(+now - 84_400_000);

      const data = await TytoCalls.PPF.Dashboard.get(
        {
          beforeDate: (endDate ?? new Date()).toISOString(),
          afterDate: (startDate ?? yesterday).toISOString(),
          isCascade: isCascade,
          teamID: teamID,
          historyAfterDate: startDate?.toISOString(),
          historyBeforeDate: endDate?.toISOString(),
        },
        {
          axiosConfig: {
            onDownloadProgress: opts?.onDownloadProgress,
          },
        }
      );

      if (data.dashboard.allPersons) {
        data.dashboard.allPersons = _.uniqBy(
          data.dashboard.allPersons,
          "personID"
        );
      }
      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      enabled: opts?.isEnabled ?? true,
      onSuccess: (data) => {
        try {
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.PLAN_DASHBOARD, [
              opts.teamID,
              +(opts.startDate ?? 0),
              +(opts.endDate ?? 0),
              opts.isCascade ? "TRUE" : "FALSE",
            ]),
            _.omit(data, ["session", "error"])
          );
        } catch (err) {
          console.log("ERROR: ", err);
          debugger;
        }

        console.log("DASHBOARD.allPersons: ", data.dashboard?.allPersons);

        opts?.onSuccess?.(data);
      },
      onError: (data) => {
        const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
        console.log("errorSts: ", errorSts, " onError data: ", data);

        if (opts && opts.onError) {
          opts.onError(data as Data.TytoErrorObject);
        }
      },
    }
  );

  return {
    storedValueQuery,
    ...query,
    eagerData: query.data ?? storedValueQuery.data,
  };
}

export function usePPFPlanDashboardSummary(opts: {
  isCascade?: boolean;
  beforeDate?: Date;
  afterDate?: Date;
  interval?: keyof typeof TytoData.Interval;
  teamID: number;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.GS.PPFDashboard.summary.Get) => void;
}) {
  const storedValueQuery =
    useLocalForage<Endpoints.Responses.GS.PPFDashboard.summary.Get>(
      REACT_QUERY_KEYS.PLAN_DASHBOARD_SUMMARY,
      [
        opts.teamID,
        +(opts.beforeDate ?? 0),
        +(opts.afterDate ?? 0),
        opts.isCascade ? "TRUE" : "FALSE",
      ]
    );
  const query = useQuery(
    [
      REACT_QUERY_KEYS.PLAN_DASHBOARD_SUMMARY,
      opts.teamID,
      +(opts.beforeDate ?? 0),
      +(opts.afterDate ?? 0),
      opts.isCascade ? "TRUE" : "FALSE",
    ],
    async () => {
      const { beforeDate, isCascade, afterDate, teamID, interval } = opts;

      const now = new Date();
      const yesterday = new Date(+now - 84_400_000);

      const data = await TytoCalls.PPF.DashboardSummary.get({
        beforeDate: (beforeDate ?? new Date()).toISOString(),
        afterDate: (afterDate ?? yesterday).toISOString(),
        isCascade: isCascade,
        teamID: teamID,
        interval: interval,
      });
      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      onSuccess: (data) => {
        try {
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.PLAN_DASHBOARD_SUMMARY, [
              opts.teamID,
              +(opts.beforeDate ?? 0),
              +(opts.afterDate ?? 0),
              opts.isCascade ? "TRUE" : "FALSE",
            ]),
            _.omit(data, ["session", "error"])
          );
        } catch (err) {
          console.log("ERROR: ", err);
          debugger;
        }

        opts?.onSuccess?.(data);
      },
      onError: (data) => {
        const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
        console.log("errorSts: ", errorSts, " onError data: ", data);

        if (opts && opts.onError) {
          opts.onError(data as Data.TytoErrorObject);
        }
      },
    }
  );

  return {
    storedValueQuery,
    ...query,
    eagerData: query.data ?? storedValueQuery.data,
  };
}

export function useAdvancedPersonSearch(opts: {
  searchTerm: string;
  extraOpts?: Partial<Endpoints.Tyto.PersonAdvanced.GetParameters>;
  isEnabled?: boolean;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.Person.AdvancedSearched.Get) => void;
}) {
  // const storedValueQuery =
  //   useLocalForage<Endpoints.Responses.GS.Plan.Notices.Get>(
  //     REACT_QUERY_KEYS.ADVANCED_PERSON_SEARCH,
  //     [opts.searchTerm]
  //   );

  return {
    // storedValueQuery,
    ...useQuery(
      [REACT_QUERY_KEYS.ADVANCED_PERSON_SEARCH, opts.searchTerm],
      async () => {
        const data = await TytoCalls.PersonAdvanced.get({
          generalName: `%${opts.searchTerm}%`,
          ...(opts.extraOpts ?? {}),
        });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: (opts?.isEnabled ?? true) && !!opts.searchTerm,
        onSuccess: (data) => {
          // try {
          //   LF.setItem(
          //     makeLocalForageKey(REACT_QUERY_KEYS.ADVANCED_PERSON_SEARCH, [
          //       opts.searchTerm,
          //     ]),
          //     _.omit(data, ["session", "error"])
          //   );
          // } catch (err) {
          //   console.log("ERROR: ", err);
          //   debugger;
          // }

          opts?.onSuccess?.(data);
        },
        onError: (data) => {
          const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
          console.log("errorSts: ", errorSts, " onError data: ", data);

          if (opts && opts.onError) {
            opts.onError(data as Data.TytoErrorObject);
          }
        },
      }
    ),
  };
}

export function usePersonProfilePhoto(opts: {
  personID: number;
  extraOpts?: Partial<Endpoints.Tyto.Person.ProfilePhoto.GetParameters>;
  isEnabled?: boolean;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.Person.ProfilePhoto.Get) => void;
}) {
  const storedValueQuery =
    useLocalForage<Endpoints.Responses.Person.ProfilePhoto.Get>(
      REACT_QUERY_KEYS.PERSON_PROFILE_PHOTO,
      [opts.personID, opts.extraOpts?.encoding ?? "ocDEFAULT"]
    );

  return {
    // storedValueQuery,
    ...useQuery(
      [
        REACT_QUERY_KEYS.PERSON_PROFILE_PHOTO,
        opts.personID,
        opts.extraOpts?.encoding ?? "ocDEFAULT",
      ],
      async () => {
        const data = await TytoCalls.Person.ProfilePhoto.get({
          personID: opts.personID,
          ...(opts.extraOpts ?? {}),
        });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: (opts?.isEnabled ?? true) && !!opts.personID,
        onSuccess: (data) => {
          try {
            LF.setItem(
              makeLocalForageKey(REACT_QUERY_KEYS.PERSON_PROFILE_PHOTO, [
                opts.personID,
                opts.extraOpts?.encoding ?? "ocDEFAULT",
              ]),
              _.omit(data, ["session", "error"])
            );
          } catch (err) {
            console.log("ERROR: ", err);
            debugger;
          }

          opts?.onSuccess?.(data);
        },
        onError: (data) => {
          const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
          console.log("errorSts: ", errorSts, " onError data: ", data);

          if (opts && opts.onError) {
            opts.onError(data as Data.TytoErrorObject);
          }
        },
      }
    ),
  };
}

export function usePPFTemplateImages({
  isEnabled,
  onError,
  onSuccess,
}: {
  isEnabled?: boolean;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.PPF.TemplateImages.Get) => void;
}) {
  const storedValueQuery =
    useLocalForage<Endpoints.Responses.PPF.TemplateImages.Get>(
      REACT_QUERY_KEYS.PPF_TEMPLATE_IMAGES
    );

  const query = useQuery(
    [REACT_QUERY_KEYS.PPF_TEMPLATE_IMAGES],
    async () => {
      const resp = await SpecialNetworkRequests.getPPFTemplateImages();

      return resp;
    },
    {
      ...DEFAULT_RQ_OPTS,
      enabled: isEnabled ?? true,
      onSuccess: (data) => {
        try {
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.PPF_TEMPLATE_IMAGES),
            _.omit(data, ["session", "error"])
          );
        } catch (err) {
          console.log("ERROR: ", err);
          debugger;
        }

        onSuccess?.(data);
      },
      onError: (data) => {
        const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
        console.log("errorSts: ", errorSts, " onError data: ", data);

        onError?.(data as Data.TytoErrorObject);
      },
    }
  );

  return {
    storedValueQuery,
    ...query,
    eagerData: query?.data ?? storedValueQuery.data,
  };
}

const SEARCH_TIMEOUT_DURATION_MS = 400;

export function useAsyncSearch<T>({
  data,
  searchTerm,
}: {
  data?: Array<T>;
  searchTerm: string;
}) {
  const [webWorkerError, updateWebWorkerError] = React.useState("");
  const [activeSearchTerm, updateActiveSearchTerm] = React.useState("");
  const [activeSearchResults, updateActiveSearchResults] = React.useState<
    Array<T>
  >([]);
  const timeoutKey = React.useRef<number | null>(null);

  // * On 'Mount'
  React.useEffect(() => {
    // TODO: Setup Worker?
  }, []);

  // * On Data Change
  React.useEffect(() => {}, [data]);

  // * On searchTerm change
  React.useEffect(() => {
    if (timeoutKey.current) {
      clearTimeout(timeoutKey.current);
    }

    timeoutKey.current = null;

    updateActiveSearchTerm(searchTerm ?? "");

    if (activeSearchTerm) {
      timeoutKey.current = window.setTimeout(() => {},
      SEARCH_TIMEOUT_DURATION_MS);
    }

    // * 'Unmount' to clear potentially existing setTimeout queued to run
    return () => {
      if (timeoutKey.current) {
        clearTimeout(timeoutKey.current);

        timeoutKey.current = null;
      }
    };
  }, [searchTerm]);

  return {
    activeSearchTerm,
    activeSearchResults,
    webWorkerError,
  };
}

export function useTeamsByFunction(opts: {
  extraOpts?: Partial<Endpoints.Tyto.TeamsByFunction.GetParameters>;
  isEnabled?: boolean;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.TeamsByFunction.Get) => void;
}) {
  // const storedValueQuery =
  //   useLocalForage<Endpoints.Responses.GS.Plan.Notices.Get>(
  //     REACT_QUERY_KEYS.TEAMS_BY_FUNCTION
  //   );

  return {
    // storedValueQuery,
    ...useQuery(
      [REACT_QUERY_KEYS.TEAMS_BY_FUNCTION],
      async () => {
        const data = await TytoCalls.TeamsByFunction.get({
          ...(opts.extraOpts ?? {}),
        });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: opts?.isEnabled ?? true,
        onSuccess: (data) => {
          // try {
          //   LF.setItem(
          //     makeLocalForageKey(REACT_QUERY_KEYS.TEAMS_BY_FUNCTION),
          //     _.omit(data, ["session", "error"])
          //   );
          // } catch (err) {
          //   console.log("ERROR: ", err);
          //   debugger;
          // }
          opts.onSuccess?.(data);
        },
        onError: (data) => {
          const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
          console.log("errorSts: ", errorSts, " onError data: ", data);

          if (opts && opts.onError) {
            opts.onError(data as Data.TytoErrorObject);
          }
        },
      }
    ),
  };
}

export function useFunctionOps(opts: {
  extraOpts?: Partial<Endpoints.Tyto.Function.Ops.GetParameters>;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.Function.Ops.Get) => void;
}) {
  // const storedValueQuery =
  //   useLocalForage<Endpoints.Responses.GS.Plan.Notices.Get>(
  //     REACT_QUERY_KEYS.TEAMS_BY_FUNCTION
  //   );

  return {
    // storedValueQuery,
    ...useQuery(
      [REACT_QUERY_KEYS.FUNCTION_OPS],
      async () => {
        const data = await TytoCalls.FunctionOps.get({
          ...(opts.extraOpts ?? {}),
        });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        onSuccess: (data) => {
          // try {
          //   LF.setItem(
          //     makeLocalForageKey(REACT_QUERY_KEYS.TEAMS_BY_FUNCTION),
          //     _.omit(data, ["session", "error"])
          //   );
          // } catch (err) {
          //   console.log("ERROR: ", err);
          //   debugger;
          // }
          opts.onSuccess?.(data);
        },
        onError: (data) => {
          const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
          console.log("errorSts: ", errorSts, " onError data: ", data);

          if (opts && opts.onError) {
            opts.onError(data as Data.TytoErrorObject);
          }
        },
      }
    ),
  };
}

export function useGSChangelog(opts: {
  gsPlanID: number;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.gsChangelog.Get) => void;
}) {
  const storedValueQuery = useLocalForage<Endpoints.Responses.gsChangelog.Get>(
    REACT_QUERY_KEYS.PLAN_CHANGE_LOG,
    [opts.gsPlanID]
  );
  const query = useQuery(
    [REACT_QUERY_KEYS.PLAN_CHANGE_LOG, opts.gsPlanID],
    async () => {
      const data = await TytoCalls.GSChangelog.get({
        gsPlanID: opts.gsPlanID,
      });

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      onSuccess: (data) => {
        try {
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.PLAN_CHANGE_LOG, [
              opts.gsPlanID,
            ]),
            _.omit(data, ["session", "error"])
          );
        } catch (err) {
          console.log("ERROR: ", err);
          debugger;
        }

        opts.onSuccess?.(data);
      },
      onError: (data) => {
        const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
        console.log("errorSts: ", errorSts, " onError data: ", data);

        if (opts && opts.onError) {
          opts.onError(data as Data.TytoErrorObject);
        }
      },
    }
  );

  return {
    storedValueQuery,
    ...query,
    eagerData: query.data ?? storedValueQuery.data,
  };
}

const DEFAULT_FUNCTION_NAMES_FILTER = [
  SECURITY_FUNCTIONS_BY_NAME["Page GSPPF"].functionName,
];

export function usePersonNotices({
  userID,
  top = 30,
  functionNamesFilter = DEFAULT_FUNCTION_NAMES_FILTER,
  ...callbacks
}: {
  userID?: number;
  top?: number;
  functionNamesFilter?: string[];
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.Person.Notices.Get) => void;
}) {
  const lfKeyArray = [top, userID ?? null];

  const storedValueQuery =
    useLocalForage<Endpoints.Responses.Person.Notices.Get>(
      REACT_QUERY_KEYS.PERSON_NOTICES,
      lfKeyArray
    );
  const query = useQuery(
    [REACT_QUERY_KEYS.PERSON_NOTICES, ...lfKeyArray],
    async () => {
      const data = await TytoCalls.Person.Notices.get({
        functionNames: !!functionNamesFilter?.length
          ? functionNamesFilter.join(",")
          : undefined,
      });

      if (functionNamesFilter?.length) {
        const filtersSet = new Set(functionNamesFilter);

        const mutatedNotices = (data?.notices?.notices ?? []).filter(
          (notice) => {
            const functionName =
              !!notice.functionID &&
              SECURITY_FUNCTIONS_BY_ID[notice.functionID]?.functionName;

            return !!functionName && filtersSet.has(functionName);
          }
        );

        if (data.notices.notices) {
          data.notices.notices = mutatedNotices;
        }
      }

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      onSuccess: (data) => {
        try {
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.PERSON_NOTICES, lfKeyArray),
            _.omit(data, ["session", "error"])
          );
        } catch (err) {
          console.log("ERROR: ", err);
          debugger;
        }

        callbacks?.onSuccess?.(data);
      },
      onError: (data) => {
        const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
        console.log("errorSts: ", errorSts, " onError data: ", data);

        callbacks?.onError?.(data as Data.TytoErrorObject);
      },
    }
  );

  return {
    storedValueQuery,
    ...query,
    eagerData: query.data ?? storedValueQuery.data,
  };
}

const DEFAULT_VAR_NAMES_FILTER: Array<keyof typeof TytoData.UserAlertVarName> =
  ["newNoticeCount"];

export function useUserAlertCounts({
  userID = SessionHandling.getUserIDOfActiveSession(),
  varNamesFilter = DEFAULT_VAR_NAMES_FILTER,
  ...callbacks
}: {
  userID?: number;
  varNamesFilter?: Array<keyof typeof TytoData.UserAlertVarName>;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.UserAlertCounts.Get) => void;
}) {
  const varNamesFilterAsStr = (varNamesFilter ?? []).join("-");
  const lfKeyArray = [userID ?? 0, varNamesFilterAsStr];

  const storedValueQuery =
    useLocalForage<Endpoints.Responses.UserAlertCounts.Get>(
      REACT_QUERY_KEYS.USER_ALERT_COUNTS,
      lfKeyArray
    );
  const query = useQuery(
    [REACT_QUERY_KEYS.USER_ALERT_COUNTS, ...lfKeyArray],
    async () => {
      const data = await TytoCalls.UserAlertCounts.get({});

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      refetchOnWindowFocus: true,
      onSuccess: (data) => {
        try {
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.USER_ALERT_COUNTS, lfKeyArray),
            _.omit(data, ["session", "error"])
          );
        } catch (err) {
          console.log("ERROR: ", err);
          debugger;
        }

        const hasChange = alertCountHasDateChange({
          keysArray: varNamesFilter,
          userAlertCounts: data?.UserAlertCounts ?? [],
        });

        if (hasChange) {
          QueryCacheHelpers.invalidatePersonNotices({ userID, top: 50 });
        }

        callbacks?.onSuccess?.(data);
      },
      onError: (data) => {
        const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
        console.log("errorSts: ", errorSts, " onError data: ", data);

        callbacks?.onError?.(data as Data.TytoErrorObject);
      },
    }
  );

  return {
    storedValueQuery,
    ...query,
    eagerData: query.data ?? storedValueQuery.data,
  };
}

export function useLibraryConfig(opts?: {
  useDomainData?: boolean;
  // onSuccess?: (data?: Mastery.Start.Config) => void;
  onSuccess?: (data?: SITE.Library.Config) => void;
  onError?: () => void;
}) {
  const loggedInUsersDomainID = _.get(
    SessionHandling.getActiveSession(),
    "domainID",
    0
  );
  const libraryConfigPath = getDomainConfigURL(loggedInUsersDomainID);

  const storedValueQuery = useLocalForage(REACT_QUERY_KEYS.PPF_LIBRARY_CONFIG, [
    loggedInUsersDomainID,
  ]) as any as SITE.Library.Config | undefined;

  const query = useQuery(
    [REACT_QUERY_KEYS.PPF_LIBRARY_CONFIG, loggedInUsersDomainID],
    async () => {
      if (!libraryConfigPath) {
        new Error("domainID not found");
      }

      const { data } = await axios.get(`${libraryConfigPath}`);

      const config = typeof data === "string" ? JSON.parse(data) : data;

      console.log("PPF_LIBRARY_CONFIG resolved: ", config);

      return config as SITE.Library.Config;
    },
    {
      ...DEFAULT_RQ_OPTS,
      staleTime: timeInMS({ timeQuantity: 6, timeType: "hours" }),
      retry: 0,
      onSuccess: (data) => {
        LF.setItem(
          makeLocalForageKey(REACT_QUERY_KEYS.PPF_LIBRARY_CONFIG, [
            loggedInUsersDomainID,
          ]),
          data
        );

        opts?.onSuccess?.(data);
      },
      onError: () => {
        opts?.onError?.();
      },
    }
  );

  return {
    storedValueQuery,
    ...query,
    eagerData: query.data ?? storedValueQuery,
  };
}

export function useFallbackConfig(opts?: {
  useDomainData?: boolean;
  onSuccess?: (data?: SITE.Library.Config) => void;
  onError?: () => void;
}) {
  const loggedInUsersDomainID = _.get(
    SessionHandling.getActiveSession(),
    "domainID",
    0
  );
  //CV Domain - Hard coding a single domain for config
  //config lives in domains/1825957/start/ppf_library_config.json
  const mainDomainForConfig = 1825957;

  const libraryConfigPath = getDomainConfigURL(mainDomainForConfig);

  const storedValueQuery = useLocalForage(
    REACT_QUERY_KEYS.PPF_LIBRARY_CONFIG_FALLBACK,
    [mainDomainForConfig]
  ) as any as SITE.Library.Config | undefined;

  const query = useQuery(
    [REACT_QUERY_KEYS.PPF_LIBRARY_CONFIG, mainDomainForConfig],
    async () => {
      if (!libraryConfigPath) {
        new Error("domainID not found");
      }

      const { data } = await axios.get(`${libraryConfigPath}`);

      const configFallback = typeof data === "string" ? JSON.parse(data) : data;

      console.log("PPF_LIBRARY_CONFIG resolved: ", configFallback);

      return configFallback as SITE.Library.Config;
    },
    {
      ...DEFAULT_RQ_OPTS,
      staleTime: timeInMS({ timeQuantity: 6, timeType: "hours" }),
      retry: 0,
      onSuccess: (data) => {
        LF.setItem(
          makeLocalForageKey(REACT_QUERY_KEYS.PPF_LIBRARY_CONFIG_FALLBACK, [
            loggedInUsersDomainID,
          ]),
          data
        );

        opts?.onSuccess?.(data);
      },
      onError: () => {
        opts?.onError?.();
      },
    }
  );

  return {
    storedValueQuery,
    ...query,
    eagerDataFallback: query.data ?? storedValueQuery,
  };
}
