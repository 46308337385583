import { ENDPOINT_FUNCTION_OPS } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_FUNCTION_OPS;

export const FunctionOps = {
  get(params: Endpoints.Tyto.Function.Ops.GetParameters, callOpts?: CallOpts) {
    return callWrapper(
      "get",
      endpoint,
      params,
      callOpts
    ) as Promise<Endpoints.Responses.Function.Ops.Get>;
  },
};
