/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { detemerminePPFParentGoal } from "data/helpers/";
import { MenuToggleButton, UserThumb } from "components/common/";

import NewGoal from "./NewGoal";
import ScopeSelection from "./ScopeSelection";

import "./style.scss";

interface Props extends PlanPage.PlanData {
  isMemberOfPlan: boolean;
}

const NewGoalWrapper = (props: Props) => {
  const [errorMsg, updateErrorMsg] = React.useState("");
  const [creatingGoal, updateCreatingGoal] = React.useState(false);
  const [formIdx, updateFormIdx] = React.useState(0);
  const [selectedParentGoalID, updateSelectedParentGoalID] =
    React.useState<number>(0);
  const [goalTime, updateGoalTime] = React.useState(0);
  const [goalTitle, updateGoalTitle] = React.useState("");
  const [goalDesc, updateGoalDesc] = React.useState("");

  const { theme, selectedParentGoal } = React.useMemo(() => {
    const selectedGoal = selectedParentGoalID
      ? (props.planGoals ?? []).find(
          (parentGoal) => parentGoal.gsGoalID === selectedParentGoalID
        )
      : undefined;

    const themeEnum = selectedGoal
      ? detemerminePPFParentGoal(selectedGoal)
      : undefined;

    return {
      theme: themeEnum,
      selectedParentGoal: selectedGoal,
    };
  }, [props.planGoals, selectedParentGoalID]);

  if (!props.plan) {
    return (
      <div>
        <p>Plan not Loaded.</p>
      </div>
    );
  }

  return (
    <article className={cx("plan-createnew-goal", theme)}>
      {formIdx === 1 && !!selectedParentGoalID && !!goalTime && !!theme ? (
        <NewGoal
          creatingGoal={creatingGoal}
          errorMsg={errorMsg}
          goalTime={goalTime}
          goalTitle={goalTitle}
          goalDesc={goalDesc}
          selectedParentGoal={selectedParentGoal}
          selectedParentGoalID={selectedParentGoalID}
          plan={props.plan}
          theme={theme}
          updateCreatingGoal={updateCreatingGoal}
          updateErrorMsg={updateErrorMsg}
          updateFormIdx={updateFormIdx}
          updateGoalTitle={updateGoalTitle}
          updateGoalDesc={updateGoalDesc}
          parentGoals={props.planGoals}
        />
      ) : (
        <ScopeSelection
          {...props}
          isMemberOfPlan={props.isMemberOfPlan}
          parentGoals={props.planGoals}
          goalTime={goalTime}
          selectedParentGoalID={selectedParentGoalID}
          theme={theme}
          updateFormIdx={updateFormIdx}
          updateGoalTime={updateGoalTime}
          updateSelectedParentGoalID={updateSelectedParentGoalID}
        />
      )}
    </article>
  );
};

// interface NewGoalHeaderProps {
//   plan?: TytoData.PPF.Plan.Plan;
// }

// export const NewGoalHeader = (props: NewGoalHeaderProps) => {
//   return (
//     <nav className="plan-newgoal-header">
//       <div className="plan-newgoal-header-left">
//         <MenuToggleButton />
//         <h3 className="plan-newgoal-header-subtitle">
//           Hello {props.plan?.about?.elementName ?? "There"}
//         </h3>
//         <h2 className="plan-newgoal-header-title title-font">
//           What Type of Goal
//         </h2>
//       </div>

//       <div className="plan-newgoal-header-right">
//         {/* <UserThumb

//         /> */}
//       </div>
//     </nav>
//   );
// };

export default NewGoalWrapper;
