/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { Redirect } from "react-router-dom";

import { Button, Image, Input } from "components/common/";
import { getPublicImagePath, getRedirectPathFromURL } from "data/helpers/";
import { getSessionWithThumbnailFromAuth4Result } from "data/helpers/session";
import { INTERFACE_PATHS, PPF_LOGO_FULL } from "data/constants/";
import { SessionHandling, LOC_STOR, keys } from "data/storage/";
import API from "data/network/tyto/";
import { StoreContext } from "data/stores/AppStore";
import {
  addStylesheetForSession,
  loadHostStyleOverrides,
} from "data/helpers/meta-utils";

import ExistingSessions from "./subcomponents/ExistingSessions";
import MultipleSessionsFound from "./subcomponents/MultipleSessionsFound";

import "./style.scss";

interface Props extends SITE.GeneralStoreInjectedProps {}

const LOGIN_NAME_ID = "login_field";
const PASSWORD_ID = "password_field";

function getActiveSessionKeyFromStorage() {
  const activeSessionKey = SessionHandling.getActiveSessionKey();

  return activeSessionKey || undefined;
}

function getLastEmailUsedFromStorage() {
  const emailAddress = LOC_STOR.get(keys.LAST_LOGIN_SCREEN_EMAIL_ADDRESS_USED);

  return emailAddress || "";
}

async function retrieveFullSessionData({
  authResult,
}: {
  authResult: TytoData.AuthResult;
}) {
  try {
    const { session } = await API.AccountSession.get(
      { sessionKey: authResult.authSession?.sessionKey ?? "" },
      { omitSessionKey: true }
    );

    return {
      ...(session ?? {}),
      sessionKey: authResult.authSession?.sessionKey,
    };
  } catch (err) {
    // TODO
  }
}

const Login = (props: Props) => {
  let AppStore = React.useContext(StoreContext);

  const [redirectPathFromURL] = React.useState(getRedirectPathFromURL());
  const [activeSessionKey] = React.useState(getActiveSessionKeyFromStorage());
  const [existingSessions] = React.useState(SessionHandling.getAllSessions());
  const [hasMatchingSession] = React.useState(
    !!activeSessionKey &&
      !!existingSessions.length &&
      existingSessions.some(
        (session) => session.sessionKey === activeSessionKey
      )
  );
  const [logonName, updateLogonName] = React.useState(
    getLastEmailUsedFromStorage()
  );
  const [password, updatePassword] = React.useState("");
  const [isLoggingIn, updateIsLoggingIn] = React.useState(false);
  const [shouldRedirect, updateShouldRedirect] = React.useState(
    !!activeSessionKey && hasMatchingSession
  );
  const [errorMsg, updateErrorMsg] = React.useState("");
  const [authResults, updateAuthResults] = React.useState<
    TytoData.AuthResult[] | undefined
  >(undefined);

  // * Add Override
  React.useEffect(() => {
    if (!activeSessionKey) {
      loadHostStyleOverrides();
    }
  }, []);

  // // * Not sure this works as desired?
  // React.useEffect(() => {
  //   if (shouldRedirect) {
  //     window.location.href = redirectPathFromURL ?? INTERFACE_PATHS.HOME;
  //   }
  // }, [shouldRedirect]);

  if (shouldRedirect) {
    return (
      <Redirect to={redirectPathFromURL ?? INTERFACE_PATHS.DEFAULT_ROUTE} />
    );
  }

  const onAction = () => {
    startLogin({
      logonName,
      password,
      updateIsLoggingIn,
      onError: (newErrorMsg: string) => {
        updateErrorMsg(newErrorMsg);
        updateIsLoggingIn(false);
      },
      onSuccess: async (authResults) => {
        if (authResults.length === 1) {
          try {
            const sessionData = await retrieveFullSessionData({
              authResult: authResults[0],
            });

            addStylesheetForSession(() => {
              if (sessionData && AppStore.dispatch) {
                AppStore.dispatch({
                  callback: () => {
                    debugger;
                    window.location.href =
                      redirectPathFromURL ?? INTERFACE_PATHS.DEFAULT_ROUTE;
                  },
                  payload: {
                    session: sessionData,
                    // session: getSessionWithThumbnailFromAuth4Result(
                    //   authResults?.[0]
                    // ),
                  },
                  type: "USER_LOGGED_IN",
                });
              }
            }, sessionData?.domainID ?? authResults[0]?.domainID);
          } catch (err) {
            // TODO
          }
        } else {
          updateAuthResults(authResults);
          updateIsLoggingIn(false);
        }
      },
    });
  };

  return (
    <main className="interface-main-cont interface-login">
      <Image className="login-logo" src={getPublicImagePath(PPF_LOGO_FULL)} />

      <ExistingSessions
        activeSessionKey={activeSessionKey}
        hasMatchingSession={hasMatchingSession}
        allSessions={existingSessions}
        onChoose={(session: Data.SessionData) => {
          if (AppStore.dispatch) {
            AppStore.dispatch({
              callback: () => {
                debugger;
                updateShouldRedirect(true);
              },
              payload: {
                session,
              },
              type: "USER_LOGGED_IN",
            });
          }
        }}
      />

      {!!authResults && !!authResults.length ? (
        <MultipleSessionsFound
          authResults={authResults}
          onChoose={async (authResult: TytoData.AuthResult) => {
            const { authSession } = authResult;

            const sessionData = await retrieveFullSessionData({
              authResult: authSession,
            });

            addStylesheetForSession(() => {
              if (AppStore.dispatch) {
                AppStore.dispatch({
                  callback: () => {
                    updateShouldRedirect(true);
                  },
                  payload: {
                    session: sessionData,
                  },
                  type: "USER_LOGGED_IN",
                });
              }
            }, sessionData?.domainID ?? authSession.domainID);
          }}
          onCancel={() => {
            updateLogonName("");
            updatePassword("");
            updateAuthResults(undefined);
          }}
        />
      ) : (
        <section className="login-inner-cont">
          <label className="login-input-label text-color" id={LOGIN_NAME_ID}>
            Email Address
          </label>
          <Input
            autoFocus={!logonName}
            className="login-input font-size-normal"
            type="input"
            id={LOGIN_NAME_ID}
            name="Login Name"
            onChange={(newVal) => updateLogonName(newVal)}
            value={logonName}
          />

          <label className="login-input-label text-color" id={PASSWORD_ID}>
            Password
          </label>
          <Input
            autoFocus={!!logonName}
            className="login-input font-size-normal"
            type="password"
            id={PASSWORD_ID}
            name="Password"
            onChange={(newVal) => updatePassword(newVal)}
            onEnter={onAction}
            value={password}
          />

          {errorMsg && <p className="login-error-msg">{errorMsg}</p>}

          <Button
            className="login-button font-size-normal"
            onClick={onAction}
            disabled={isLoggingIn}
            theme="action"
            value={isLoggingIn ? "..." : "Sign in"}
          />
        </section>
      )}
    </main>
  );
};

async function startLogin({
  logonName,
  password,
  updateIsLoggingIn,
  onError,
  onSuccess,
}: {
  logonName: string;
  password: string;
  updateIsLoggingIn: (newVal: boolean) => void;
  onSuccess: (authResults: TytoData.AuthResult[]) => void;
  onError: (errorMessage: string) => void;
}) {
  if (!logonName || !password) {
    onError("Email or Password not supplied.");
    return;
  }

  updateIsLoggingIn(true);

  try {
    const { authResults } = await API.Login.Authenticate4.post({
      username: logonName,
      password,
    });

    if (!authResults || !authResults.length) {
      onError(
        "No Matching Sessions found for the supplied Email and Password combination."
      );
      return;
    }

    onSuccess(authResults);
  } catch (err) {
    debugger;
    const msg =
      typeof err === "string"
        ? err
        : _.get(err, "msg", _.get(err, "message", "Error Occurred"));

    onError(`${msg}`);
  }
}

export default Login;
