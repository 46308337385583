import * as React from "react";
import * as _ from "lodash";

import { IMAGE_BASE_URL } from "../constants/";
import { SessionHandling, keys, LOC_STOR } from "../storage/";
import { usePPFTemplateImages } from "data/network/hooks/";
import {
  clearLFInputValue,
  createLFInputKey,
  createObjectURL,
  detemerminePPFParentGoal,
  getCoursePlanContext,
  findCourseInTraining,
  findParentTraining,
  keyPrimaryParentGoalsByCommonName,
  makeRelPathAbsolute,
} from "./";

export * from "./agnostic-hooks";

function findThumbnailOverride(images?: Data.LessonImage[]) {
  if (!images || !images.length) {
    return undefined;
  }

  return images.find((img) => img.imageName === "thumbnailOverride");
}

function getThumbnailOverride(images?: Data.LessonImage[]) {
  const thumbnailOverride = findThumbnailOverride(images);

  return thumbnailOverride;
}

function getFormattedOverridePathURLFromImage({
  image,
  includeSessionKey = true,
}: {
  image?: Data.LessonImage;
  includeSessionKey?: boolean;
}) {
  if (!image) {
    return undefined;
  }

  const paramPrefix = /\?(\w)+=/i.test(image.pathURL) ? "&" : "?";

  return `${IMAGE_BASE_URL}${image.pathURL}${
    includeSessionKey
      ? `${paramPrefix}sessionKey=${SessionHandling.getActiveSessionKey()}`
      : ""
  }`;
}

function getFormattedOverridePathURL({
  images,
  includeSessionKey = true,
}: {
  images?: Data.LessonImage[];
  includeSessionKey?: boolean;
}) {
  const thumbnailOverride = findThumbnailOverride(images);

  if (!thumbnailOverride) {
    return undefined;
  }

  const paramPrefix = /\?(\w)+=/i.test(thumbnailOverride.pathURL) ? "&" : "?";

  return `${IMAGE_BASE_URL}${thumbnailOverride.pathURL}${
    includeSessionKey
      ? `${paramPrefix}sessionKey=${SessionHandling.getActiveSessionKey()}`
      : ""
  }`;
}

export function useLessonImage(images?: Data.LessonImage[]) {
  const [thumbnailOverride, updateThumbnailOverride] = React.useState(
    findThumbnailOverride(images)
  );

  React.useEffect(() => {
    updateThumbnailOverride(findThumbnailOverride(images));
  }, [images]);

  return thumbnailOverride;
}

export function useLessonThumbnailOverrideData(images?: Data.LessonImage[]) {
  const [thumbnailOverride, updateThumbnailOverride] = React.useState(
    getThumbnailOverride(images)
  );
  const [thumbnailOverridePathURL, updateThumbnailOverridePathURL] =
    React.useState(
      getFormattedOverridePathURLFromImage({ image: thumbnailOverride })
    );

  React.useEffect(() => {
    const newThumbnailOverride = getThumbnailOverride(images);
    const newThumbnailPath = getFormattedOverridePathURLFromImage({
      image: newThumbnailOverride,
    });

    updateThumbnailOverride(newThumbnailOverride);
    updateThumbnailOverridePathURL(newThumbnailPath);
  }, [images]);

  return {
    thumbnailOverride,
    thumbnailOverridePathURL,
  };
}

export function useLessonThumbnailOverride(images?: Data.LessonImage[]) {
  const [thumbnailOverridePathURL, updateThumbnailOverridePathURL] =
    React.useState(getFormattedOverridePathURL({ images }));

  React.useEffect(() => {
    updateThumbnailOverridePathURL(getFormattedOverridePathURL({ images }));
  }, [images]);

  return thumbnailOverridePathURL;
}

export function useCourseData({
  courseID,
  // coursePlanContextID,
  trainingQuery,
}: {
  courseID: number;
  // coursePlanContextID?: string | number;
  trainingQuery?: SITE.QueryProp<Endpoints.Responses.Training.Get>;
}) {
  // * [1] - Find course
  const courseFromTraining = React.useMemo(() => {
    console.log("Running useCourseData -> courseFromTraining (useMemo)");

    return findCourseInTraining({
      curriculumID: courseID,
      primaryTraining:
        trainingQuery?.data?.training ??
        trainingQuery?.storedValueQuery?.data?.training ??
        [],
      subBlocks:
        trainingQuery?.data?.subBlocks ??
        trainingQuery?.storedValueQuery?.data?.subBlocks ??
        [],
    });
    // return pullCourseFromTrainingList({
    //   courseID,
    //   trainingResp: _.get(
    //     trainingQuery,
    //     "data",
    //     _.get(trainingQuery, "storedValueQuery.data")
    //   ),
    // });
  }, [
    courseID,
    trainingQuery?.data?.subBlocks,
    trainingQuery?.data?.training,
    trainingQuery?.storedValueQuery.data?.subBlocks,
    trainingQuery?.storedValueQuery.data?.training,
  ]);

  // * [2] - Get Course Context parentID
  const courseParentContextID = React.useMemo(() => {
    let storedCntxID = getCoursePlanContext(courseID) ?? undefined;

    if (
      !storedCntxID &&
      ((courseFromTraining as TytoData.Training.SubBlock)?.parentTasks
        ?.length ||
        (courseFromTraining as TytoData.Training.SubBlock)?.parentBlocks
          ?.length)
    ) {
      const parentID =
        (courseFromTraining as TytoData.Training.SubBlock)?.parentTasks?.[0]
          ?.rootTaskID ??
        (courseFromTraining as TytoData.Training.SubBlock)?.parentBlocks?.[0]
          ?.blockID;

      if (parentID) {
        storedCntxID = `${parentID}`;
      }
    }

    return storedCntxID;
  }, [courseFromTraining]);

  // * [3] - Find course's target parent
  const parentCourseOrPlan = React.useMemo(() => {
    if (
      !courseFromTraining ||
      (!(courseFromTraining as TytoData.Training.SubBlock).parentBlocks
        ?.length &&
        !(courseFromTraining as TytoData.Training.SubBlock).parentTasks?.length)
    ) {
      return undefined;
    }

    return findParentTraining({
      primaryTraining:
        trainingQuery?.data?.training ??
        trainingQuery?.storedValueQuery?.data?.training ??
        [],
      targetParentID: courseParentContextID,
    });
  }, [
    courseFromTraining,
    courseParentContextID,
    trainingQuery?.data?.subBlocks,
    trainingQuery?.data?.training,
  ]);

  return {
    courseFromTraining,
    courseParentContextID,
    parentCourseOrPlan,
  };
}

export function useCourseFromTraining({
  courseID,
  taskID,
  training,
}: {
  courseID?: number;
  taskID?: number;
  training?: Array<TytoData.Training.Enrollment | TytoData.Training.Task>;
}) {
  return React.useMemo(() => {
    if (!courseID || !taskID || !training?.length) {
      return undefined;
    }

    return training.find((courseOrPlan) => {
      if (courseID) {
        return courseOrPlan.curriculumID === courseID;
      } else if (taskID) {
        return (courseOrPlan as TytoData.Training.Task).taskID === taskID;
      }

      return false;
    });
  }, [courseID, taskID, training]);
}

export function useCourseParentIDs(
  courseID: number,
  AppStoreState?: StoreAPI.AppStoreState
) {
  return React.useMemo(() => {
    return AppStoreState?.coursesParentMap.get(`${courseID}`);
  }, [courseID, AppStoreState?.coursesParentMap]);
}

const DEFAULT_PARENTS_DATA = {
  blocks: [],
  plans: [],
};

export function useCourseParents(
  parentMap?: StoreAPI.CourseParentsList,
  training?: Array<TytoData.Training.Enrollment | TytoData.Training.Task>
) {
  return React.useMemo(() => {
    if (!parentMap || !training?.length) {
      return DEFAULT_PARENTS_DATA;
    }

    const blockIDsSet = new Set(parentMap.blockIDs || []);
    const taskIDsSet = new Set(parentMap.planTaskIDs || []);

    if (!taskIDsSet.size && !blockIDsSet.size) {
      // TODO
      return DEFAULT_PARENTS_DATA as StoreAPI.CourseParentsListRetrieved;
    }

    const data = training.reduce(
      (
        accum: {
          blocks: TytoData.Training.Enrollment[];
          plans: TytoData.Training.Task[];
        },
        courseOrPlan
      ) => {
        if ((courseOrPlan as TytoData.Training.Task).taskID) {
          if (
            taskIDsSet.size &&
            taskIDsSet.has((courseOrPlan as TytoData.Training.Task).taskID)
          ) {
            accum.plans = accum.plans.concat(
              courseOrPlan as TytoData.Training.Task
            );
          }
        } else {
          if (blockIDsSet.size && blockIDsSet.has(courseOrPlan.curriculumID)) {
            accum.blocks = accum.blocks.concat(
              courseOrPlan as TytoData.Training.Enrollment
            );
          }
        }

        return accum;
      },
      {
        blocks: [],
        plans: [],
      }
    );

    return data as StoreAPI.CourseParentsListRetrieved;
    // return AppStoreState?.coursesParentMap.get(`${courseID}`);
  }, [parentMap, training]);
}

function getMainScrollElement() {
  // return window;
  return document.documentElement;
}

export function usePageScrollObserver(
  callback?: (data: {
    scrollTop: number;
    isScrolled: boolean;
    scrollDirection: "down" | "up" | null;
  }) => void
) {
  const scrollTop = React.useRef(document.documentElement.scrollTop ?? 0);
  // const isScrolling = React.useRef(false);
  // const scrollDirection = React.useRef<"down" | "up" | null>(null);

  // const [scrollTop, updateScrollTop] = React.useState(
  // document.documentElement.scrollTop ?? 0
  // );
  const [isScrolled, updateIsScrolled] = React.useState(!!scrollTop.current);
  const [scrollDirection, updateScrollDirection] = React.useState<
    "down" | "up" | null
  >(null);

  React.useEffect(() => {
    function onScroll(e: Event) {
      console.log(
        "%conScroll even triggered.",
        "background-color: #434343; color: #ececec;"
      );

      const mainScrollElement = getMainScrollElement();

      // const isDifferent = mainScrollElement.scrollTop !== scrollTop.current;
      const isDifferent = mainScrollElement.scrollTop !== scrollTop.current;
      const newIsScrolled = !!mainScrollElement.scrollTop;

      if (isDifferent) {
        const newScrollDirValue =
          scrollTop.current > mainScrollElement.scrollTop ? "up" : "down";

        updateScrollDirection(newScrollDirValue);
        // updateScrollTop(mainScrollElement.scrollTop);
        scrollTop.current = mainScrollElement.scrollTop;
      }

      console.log(
        `mainElement.scrollTop: `,
        mainScrollElement.scrollTop,
        " newIsScrolled: ",
        newIsScrolled,
        " cur isScrolled: ",
        isScrolled
      );

      updateIsScrolled(newIsScrolled);

      callback?.({
        scrollTop: scrollTop.current,
        isScrolled,
        scrollDirection,
      });
      // callback?.({
      //   scrollTop: scrollTop.current,
      //   isScrolling: isScrolling.current,
      //   scrollDirection: scrollDirection.current,
      // });
    }

    window.addEventListener(
      "scroll",
      _.throttle(onScroll, 200, { leading: true, trailing: true })
    );

    return () =>
      window?.removeEventListener(
        "scroll",
        _.throttle(onScroll, 200, { leading: true, trailing: true })
      );
  }, []);

  const data = React.useMemo(() => {
    return {
      scrollTop: scrollTop.current,
      isScrolled,
      scrollDirection,
    };
  }, [scrollTop.current, isScrolled, scrollDirection]);

  return data;

  // return {
  //   scrollTop,
  //   isScrolling,
  //   scrollDirection,
  //   // scrollTop: scrollTop.current,
  //   // isScrolling: isScrolling.current,
  //   // scrollDirection: scrollDirection.current,
  // };
}

// * Straight reipped from https://usehooks.com/useEventListener/
export function useEventListener<SpecificEventType>(
  eventName: keyof WindowEventMap,
  handler: (e: SpecificEventType | Event) => void,
  element = window
) {
  // Create a ref that stores handler
  const savedHandler =
    React.useRef<(e: SpecificEventType | Event) => void | undefined>();
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;

      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event: Event) => savedHandler.current?.(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}

function filterNoticesByAboutID(
  notices: TytoData.Notices.Notice[],
  aboutID: number
) {
  return (notices ?? []).filter((notice) => notice.aboutID === aboutID);
}

function filterChangelogByItemID(
  changelog: Array<TytoData.GoalChangeLogItem>,
  itemID: number
) {
  return (changelog ?? []).filter((change) => change.itemID === itemID);
}

function convertChangelogToReadable(
  changelog: Array<TytoData.GoalChangeLogItem>
) {
  var convertedChangelog: readableChangelog[] = [];
  if (changelog) {
    changelog.forEach((change) => {
      if (change.newState.impact) {
        convertedChangelog.push({
          text: "Changed goal description to " + change.newState.impact,
          sortDate: change.logDate,
          personID: change.userID,
          personName: change.userName,
        });
      }
      if (change.newState.gsGoalName) {
        convertedChangelog.push({
          text: "Changed goal title to " + change.newState.gsGoalName,
          sortDate: change.logDate,
          personID: change.userID,
          personName: change.userName,
        });
      }
      if (change.newState.targetStatus) {
        var targetStatusText = "";
        if (change.newState.targetStatus === "ocATRISK") {
          targetStatusText = "Changed goal status to at risk";
        } else if (change.newState.targetStatus === "ocNOTSTARTED") {
          targetStatusText = "Changed goal status to on track";
        } else if (change.newState.targetStatus === "ocCOMPLETE") {
          targetStatusText = "Marked goal as completed";
        }
        convertedChangelog.push({
          text: targetStatusText,
          sortDate: change.logDate,
          personID: change.userID,
          personName: change.userName,
        });
      }
    });
  }

  return convertedChangelog;
}

type readableChangelog = {
  text: string;
  sortDate: string;
  personID: number;
  personName: string;
};

export function useGoalChangelog({
  changelog,
  goalID,
}: {
  changelog?: Array<TytoData.GoalChangeLogItem>;
  goalID: number;
}) {
  // const [goalNotices, updateGoalNotices] = React.useState(
  //   filterNoticesByAboutID(noticeBoard, goalID)
  // );

  // React.useEffect(() => {
  //   updateGoalNotices(filterNoticesByAboutID(noticeBoard, goalID));
  // }, [noticeBoard, goalID]);

  const changelogSorted = React.useMemo(() => {
    if (!changelog) {
      return [];
    }

    const goalChangelog = filterChangelogByItemID(changelog, goalID);

    const convertedChangeLog = convertChangelogToReadable(goalChangelog);

    return _.sortBy(convertedChangeLog, ["sortDate"], ["asc"]);
  }, [changelog, goalID]);

  return changelogSorted;
}

function loadNoticesAndChangelogByGoalID(
  notices: TytoData.Notices.Notice[],
  changelog: any,
  goalID: number,
  isCVDomain?: boolean
) {
  const goalNotices = filterNoticesByAboutID(notices, goalID);
  const goalChangelog = filterChangelogByItemID(changelog, goalID);
  const convertedChangelog = convertChangelogToReadable(goalChangelog);
  var allGoalNotices: (TytoData.Notices.Notice | readableChangelog)[] = [
    ...convertedChangelog,
  ];
  if (!isCVDomain) {
    allGoalNotices = allGoalNotices.concat(goalNotices);
  }
  return allGoalNotices.sort(
    (first, second) =>
      new Date(first.sortDate).getTime() - new Date(second.sortDate).getTime()
  );
}

/**
 * Return only Notice Threads from list that pertain to a specific Goal
 * @param {TytoData.Notices.Notice[]} noticeBoard - List of all Notice Threads for a Plan
 * @returns {Number} GoalID - 'gsGoalID' of a Goal whose threads are desired
 */
export function useGoalNotices({
  noticeBoard,
  goalID,
}: {
  noticeBoard?: TytoData.Notices.Notice[];
  goalID: number;
}) {
  // const [goalNotices, updateGoalNotices] = React.useState(
  //   filterNoticesByAboutID(noticeBoard, goalID)
  // );

  // React.useEffect(() => {
  //   updateGoalNotices(filterNoticesByAboutID(noticeBoard, goalID));
  // }, [noticeBoard, goalID]);

  const goalNotices = React.useMemo(() => {
    if (!noticeBoard) {
      return [];
    }

    const filteredNotices = filterNoticesByAboutID(noticeBoard, goalID);

    return _.sortBy(filteredNotices, ["sortDate"], ["asc"]);
  }, [goalID, noticeBoard]);

  return goalNotices;
}

export function useGoalChangelogAndNotices({
  noticeBoard,
  changelog,
  goalID,
  isCVDomain,
}: {
  noticeBoard: TytoData.Notices.Notice[];
  changelog: Array<TytoData.GoalChangeLogItem>;
  goalID: number;
  isCVDomain?: boolean;
}) {
  const [allGoalNotices, updateAllGoalNotices] = React.useState(
    loadNoticesAndChangelogByGoalID(noticeBoard, changelog, goalID, isCVDomain)
  );
  React.useEffect(() => {
    updateAllGoalNotices(
      loadNoticesAndChangelogByGoalID(
        noticeBoard,
        changelog,
        goalID,
        isCVDomain
      )
    );
  }, [noticeBoard, changelog, goalID, isCVDomain]);
  return allGoalNotices;
}

export function usePPFParentGoalClarifier(
  planParentgoal?: TytoData.PPF.Plan.Goals.ParentGoal
) {
  const [goalName, updateGoalName] = React.useState(() =>
    detemerminePPFParentGoal(planParentgoal)
  );

  React.useEffect(() => {
    updateGoalName(detemerminePPFParentGoal(planParentgoal));
  }, [planParentgoal]);

  return goalName;
}

export function usePrimaryParentGoalsByName(
  parentGoals?: TytoData.PPF.Plan.Goals.ParentGoal[]
) {
  const [keyedParentGoals, updateKeyedParentGoals] = React.useState(
    keyPrimaryParentGoalsByCommonName(parentGoals ?? [])
  );

  React.useEffect(() => {
    updateKeyedParentGoals(
      keyPrimaryParentGoalsByCommonName(parentGoals ?? [])
    );
  }, [parentGoals]);

  return keyedParentGoals;
}

export function useStoredInputValue({
  subKey,
  initialValue,
}: {
  subKey: string;
  initialValue?: string;
}) {
  const [inputValue, updateStoredValue] = React.useState(() => {
    return initialValue ?? LOC_STOR.get(createLFInputKey(subKey)) ?? "";
  });

  // * If subKey changes, get stored value for the new key
  React.useEffect(() => {
    updateStoredValue(LOC_STOR.get(createLFInputKey(subKey)) ?? "");
  }, [subKey]);

  // * Simple 'set' function override that both updates the state value *and* updates LocalStorage value
  // * NOTE: LocalStorage update is after incase it fails (updateStoredValue will always occur)
  const updateValue = (newValue: string) => {
    try {
      updateStoredValue(newValue);

      LOC_STOR.set(createLFInputKey(subKey), newValue);
    } catch (err) {
      // TODO
    }
  };

  // * Dev Friendly method to have on hand
  // * Intended for clearing Data after a Successful 'PUT' or 'POST'
  const clearStoredValue = (resetLocalValue = true) => {
    if (resetLocalValue) {
      console.log("RESETTING VALUE: ", subKey);
      updateStoredValue("");
    }

    return clearLFInputValue(subKey);
  };

  return [inputValue, updateValue, clearStoredValue] as [
    string,
    (newValue: string) => void,
    () => void
  ];
}

/**
 * Takes in a Blob or File and returns a URL, including grabage collection.
 * @param {Blob | File | null | undefined} imageBlobOrFile - File or Blob to get a URL for
 * @returns
 */
export const useObjectURL = (imageBlobOrFile?: Blob | File | null) => {
  const [imageURL, updateImageURL] = React.useState(() => {
    return !imageBlobOrFile ? "" : createObjectURL(imageBlobOrFile);
  });

  React.useEffect(() => {
    // * [1] - Revoke Existing URL
    if (imageURL) {
      URL.revokeObjectURL(imageURL);
    }

    // * [2] - Clear revoked URL from local State
    if (imageURL) {
      updateImageURL("");
    }

    // * [3] - If new props values is null, return (can't create URL for 'null')
    if (!imageBlobOrFile) {
      return;
    }

    const newURL = createObjectURL(imageBlobOrFile);
    updateImageURL(newURL);

    // * [4] - Create 'Unmount' Function
    return () => {
      // * As component unmounts, if URL still exists, clear it from Browser
      // * Otherwise it will stick aorund in perpituity, unnecessarily.
      if (imageURL) {
        URL.revokeObjectURL(imageURL);
      }
    };
  }, [imageBlobOrFile]);

  return imageURL;
};

export function usePPFTemplateImageURL(templateImageKey?: string) {
  const [templateImageURL, updateTemplateImageURL] = React.useState("");

  const { eagerData } = usePPFTemplateImages({
    isEnabled: true,
  });

  // * When data changes, attempt to find targetImage in list and set fully qualified path to square image up above in state
  React.useEffect(() => {
    const images = eagerData?.gsPPFimages ?? [];

    if (!templateImageKey || !images || !images.length) {
      updateTemplateImageURL("");
    } else {
      const matchingImage = images.find(
        (img) => img.imageKey === templateImageKey
      );

      if (matchingImage?.ulrPathSqr137) {
        const absolutePath = makeRelPathAbsolute(
          matchingImage.ulrPathRect525,
          false
        );

        updateTemplateImageURL(absolutePath);
      } else {
        updateTemplateImageURL("");
      }
    }
  }, [eagerData, templateImageKey]);

  return {
    templateSquareImageURL: templateImageURL,
  };
}
