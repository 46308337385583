/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import "./style.scss";

type InputType = "input" | "textarea" | "password";

export interface Props {
  autoFocus?: boolean;
  coerseTextareaHeight?: boolean;
  containerClassName?: string;
  className?: string;
  disabled?: boolean;
  form?: string;
  id?: string;
  innerRef?:
    | React.MutableRefObject<HTMLInputElement | null>
    | React.MutableRefObject<HTMLTextAreaElement | null>;
  maxLength?: number;
  name: string;
  onChange: (newVal: string) => void;
  onEnter?: () => void;
  onBlur?: () => void;
  readOnly?: boolean;
  rows?: number;
  placeholder?: string;
  showCount?: boolean;
  style?: React.CSSProperties;
  type?: InputType;
  value: string;
}

const DEFAULT_INPUT_TYPE = "input";

const Input = (props: Props) => {
  const {
    autoFocus,
    containerClassName,
    className,
    disabled,
    form,
    id,
    innerRef,
    maxLength,
    name,
    onBlur,
    onChange,
    onEnter,
    placeholder,
    readOnly,
    rows,
    showCount,
    style,
    type = DEFAULT_INPUT_TYPE,
    value,
    ...rest
  } = props;

  if (type === "textarea") {
    return <TextArea {...props} />;
  }

  const ref = innerRef as
    | React.MutableRefObject<HTMLInputElement | null>
    | undefined;

  return (
    <input
      autoFocus={autoFocus}
      className={cx("cc-input text-color", className)}
      disabled={disabled}
      form={form}
      id={id}
      maxLength={maxLength}
      name={name}
      onBlur={onBlur ? () => onBlur?.() : undefined}
      onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
        const newVal = (e.target as HTMLInputElement).value;

        if (onChange) {
          onChange(newVal);
        }
      }}
      onKeyPress={
        onEnter
          ? (e) => {
              if (e.key === "Enter") {
                onEnter();
              }
            }
          : undefined
      }
      placeholder={placeholder}
      readOnly={readOnly}
      ref={ref}
      style={style}
      type={type === "input" ? undefined : type}
      value={value}
    />
  );
};

const TextArea = ({
  autoFocus,
  containerClassName,
  className,
  disabled,
  form,
  id,
  innerRef,
  maxLength,
  name,
  onBlur,
  onChange,
  onEnter,
  placeholder,
  readOnly,
  rows,
  showCount,
  style,
  type = DEFAULT_INPUT_TYPE,
  value,
}: Props) => {
  let rowsCount = rows;

  const ref = innerRef as
    | React.MutableRefObject<HTMLTextAreaElement | null>
    | undefined;

  return (
    <div
      className={cx("cc-input-textarea-wrapper", containerClassName)}
      data-replicated-value={value}
    >
      <textarea
        autoFocus={autoFocus}
        className={cx("cc-input text-color textarea", className)}
        disabled={disabled}
        form={form}
        id={id}
        maxLength={maxLength}
        name={name}
        onBlur={onBlur ? () => onBlur?.() : undefined}
        onChange={(e: React.SyntheticEvent<HTMLTextAreaElement>) => {
          const newVal = (e.target as HTMLInputElement).value;

          if (onChange) {
            onChange(newVal);
          }
        }}
        placeholder={placeholder}
        readOnly={readOnly}
        rows={rowsCount}
        ref={ref}
        style={style}
        value={value}
      >
        {value}
      </textarea>
    </div>
  );
};

export default Input;
