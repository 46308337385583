/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import "./style.scss";

interface Option {
  label: string;
  value: string | number;
}

interface Props {
  className?: string;
  disabled?: boolean;
  onChange: (newVal: string | number) => void;
  options: Option[];
  placeholder?: string;
  value?: string | number;
}

const Select = (props: Props) => {
  return (
    <select
      className={cx("cc-select text-color", props.className)}
      disabled={props.disabled}
      onChange={(e: React.SyntheticEvent<HTMLSelectElement>) => {
        const newVal = (e.target as HTMLSelectElement).value;

        if (props.onChange) {
          props.onChange(newVal);
        }
      }}
      placeholder={props.placeholder}
      value={props.value}
    >
      {props.options &&
        !!props.options.length &&
        props.options.map((opt) => (
          <option
            className="cc-select-option text-color"
            key={opt.label}
            value={opt.value}
          >
            {opt.label}
          </option>
        ))}
    </select>
  );
};

export default Select;
