import { ENDPOINT_PERSON_PROFILE_PHOTO } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_PERSON_PROFILE_PHOTO;

export const ProfilePhoto = {
  get(
    params: Endpoints.Tyto.Person.ProfilePhoto.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params,
      callOpts
    ) as Promise<Endpoints.Responses.Person.ProfilePhoto.Get>;
  },
  post(
    params: Endpoints.Tyto.Person.ProfilePhoto.PostParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "post",
      endpoint,
      params,
      callOpts
    ) as Promise<Endpoints.Responses.Person.ProfilePhoto.Post>;
  },
};
