/*
 * Component Description
 */
import * as React from "react";

import { Message } from "../../../../components/common";
// import CoursesList from "../../../certifications/subcomponents/courses-list";

import { Props as ProfileTabsProps } from "../ProfileTabs";

import "./style.scss";

interface Props extends ProfileTabsProps {
  // training?: (TytoData.Training.Enrollment | TytoData.Training.Task)[];
}

const MyCertifications = ({ subInterface, ...props }: Props) => {
  // const completedCourses = React.useMemo(() => {
  //   return (training ?? []).filter(
  //     (courseOrPlan) => courseOrPlan.completeStatus === "ocCOMPLETE"
  //   );
  // }, [training]);

  // const hasLoaded = !!(
  //   props.trainingQuery?.data?.training ||
  //   props.trainingQuery?.storedValueQuery?.data?.training
  // );

  const hasLoaded = false;

  return (
    <div>
      <div className="profile-certifications-tab-title-cont">
        <h2 className="profile-certifications-tab-title title-font">
          My Course Completion
        </h2>
      </div>

      {hasLoaded ? (
        <h2>Loaded.</h2>
      ) : (
        // <CoursesList
        //   {...props}
        //   training={completedCourses}
        //   subInterface="completed"
        // />
        <section>
          <Message
            className="profile-certifications-tab-loading-msg"
            text="Perpetually Waiting (no, really)..."
          />
        </section>
      )}
    </div>
  );
};

export default MyCertifications;
