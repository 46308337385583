import * as React from "react";
import Plus from "./plus";

import { IconProps } from "../typings";

const Close = (props: IconProps) => {
  return (
    <Plus
      {...props}
      style={{
        ...(props.style || {}),
        transform: "rotate(0.125turn)",
      }}
    />
  );
};

export default Close;
