/*
 * Component Description
 */
import * as React from "react";
import { PixelCrop } from "react-image-crop";

import { GOAL_IMAGE_RATIOS } from "data/constants/";
import { createObjectURL } from "data/helpers/";
import { Button, ImageCropper, Message } from "components/common/";
import { getImageBlobFromCropInfo } from "components/common/image-cropper/utils/helpers";

import "./style.scss";

function getImageSrcFromFile({
  imageFile,
  updateImageSrcURL,
  onFailure,
  onSuccess,
}: {
  imageFile: File;
  onFailure: (errorMsg: string) => void;
  onSuccess: (url: string) => void;
  updateImageSrcURL: (newImageSrcString: string) => void;
}) {
  const reader = new FileReader();
  reader.addEventListener("load", () => {
    const imageSrcString = reader?.result?.toString() ?? "";
    updateImageSrcURL(imageSrcString);
    onSuccess?.(imageSrcString);
  });
  reader.addEventListener("error", () => {
    onFailure("Failed to Process Image");
  });

  reader.readAsDataURL(imageFile);
}

function getImageAspectRatio({
  url,
  onLoad,
  onFailure,
}: {
  onLoad: (aspectRatio: string | null) => void;
  onFailure: () => void;
  url: string;
}) {
  if (!url) {
    return;
  }

  const img = new Image();

  img.onload = function () {
    const { naturalHeight, naturalWidth } = (this as HTMLImageElement) ?? {};

    if (naturalHeight && naturalWidth) {
      onLoad(`${naturalWidth} / ${naturalHeight}`);
    }
  };

  img.src = url;
}

interface Props
  extends Pick<Modals.ImagePickerProps, "theme" | "onImageSelect"> {
  imageFile: File;
}

const ImageCropperWrapper = (props: Props) => {
  const squareContRef = React.useRef<HTMLDivElement | null>(null);
  const squareImgPixelCrop = React.useRef<PixelCrop | null>(null);

  const [errorMsg, updateErrorMsg] = React.useState("");
  const [imageSrcURL, updateImageSrcURL] = React.useState<string | null>(null);
  const [imageAspectRatio, updateImageAspectRatio] = React.useState<
    string | null
  >(null);

  React.useEffect(() => {
    updateErrorMsg("");
    updateImageSrcURL(null);

    getImageSrcFromFile({
      imageFile: props.imageFile,
      onFailure: (newErrorMsg) => updateErrorMsg(newErrorMsg),
      onSuccess: (url) =>
        getImageAspectRatio({
          onLoad: updateImageAspectRatio,
          onFailure: () => {
            // TODO
          },
          url,
        }),
      updateImageSrcURL,
    });

    debugger;
    // const {  } = props.imageFile;
    // updateImageAspectRatio();
  }, [props.imageFile]);

  return (
    <article className="modals-imagecropper-cropper-cont">
      {!imageSrcURL || !imageAspectRatio ? (
        <Message
          className="modals-imagecropper-cropper-loading-msg"
          text="Processing Image..."
        />
      ) : (
        <>
          <section className="modals-imagecropper-cropper-wrapper">
            <ImageCropper
              containerClassName="modals-imagecropper-cropper-wrapper-cont"
              className="modals-imagecropper-cropper-cropper"
              containerStyles={{ aspectRatio: imageAspectRatio ?? undefined }}
              aspectRatio={GOAL_IMAGE_RATIOS.PORTRAIT.aspectRatio}
              imgSrc={imageSrcURL}
              innerContainerRef={squareContRef}
              onCropChange={(cropData) => {
                squareImgPixelCrop.current = cropData;
              }}
              withWrapper={true}
            />
          </section>

          <section className="modals-imagecropper-cropper-btn-cont">
            <Button
              className="modals-imagecropper-imagegrid-btn"
              hoverBGSize="hidden"
              theme={props.theme ?? "action"}
              onClick={async () => {
                const { squareImgBlob } = await createImagesFromCrops({
                  squareContRef,
                  squareImgPixelCrop,
                });

                props.onImageSelect(squareImgBlob);
              }}
              value="Use This Crop"
            />
          </section>
        </>
      )}
    </article>
  );
};

async function createImagesFromCrops({
  squareContRef,
  squareImgPixelCrop,
}: {
  squareContRef: React.MutableRefObject<HTMLElement | null>;
  squareImgPixelCrop: React.MutableRefObject<PixelCrop | null>;
}) {
  const squareImgRef: HTMLImageElement | null | undefined =
    squareContRef.current?.querySelector("img.cc-image-cropper-img");

  let squareImgBlob = null;

  if (squareImgRef && squareImgPixelCrop.current) {
    squareImgBlob = await getImageBlobFromCropInfo({
      cropData: squareImgPixelCrop.current,
      imgRef: squareImgRef,
      saveDimensions: GOAL_IMAGE_RATIOS.PORTRAIT,
    });
  }

  return {
    squareImgBlob,
  };
}

export default ImageCropperWrapper;
