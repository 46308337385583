import { ENDPOINT_GS_PLAN_NOTICE, ENDPOINT_GS_PLAN_NOTICE_LIKE, ENDPOINT_GS_PLAN_NOTICE_UNLIKE } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_GS_PLAN_NOTICE;
const endpointLike = ENDPOINT_GS_PLAN_NOTICE_LIKE;
const endpointUnlike = ENDPOINT_GS_PLAN_NOTICE_UNLIKE;

export const GSPlanNotice = {
  delete(params: Endpoints.Tyto.GS.Plan.Notice.DeleteParameters, callOpts?: CallOpts) {
    return callWrapper(
      "delete",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Plan.Notice.Delete>;
  },
  post(params: Endpoints.Tyto.GS.Plan.Notice.PostParameters, callOpts?: CallOpts) {
    return callWrapper(
      "post",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Plan.Notice.Post>;
  },
};

export const GSPlanNoticeLike = {
  put(params: Endpoints.Tyto.GS.Plan.Notice.Like.PutParameters, callOpts?: CallOpts) {
    return callWrapper(
      "put",
      endpointLike,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Plan.Notice.Like.Put>;
  },
};

export const GSPlanNoticeUnlike = {
  put(params: Endpoints.Tyto.GS.Plan.Notice.Unlike.PutParameters, callOpts?: CallOpts) {
    return callWrapper(
      "put",
      endpointUnlike,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Plan.Notice.Unlike.Put>;
  },
};
