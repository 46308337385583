import * as React from "react";
import { Bar, Pie } from "react-chartjs-2";
import type { ChartType, ChartOptions, DefaultDataPoint } from "chart.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement);

const DEFAULT_COLORS = ["#ffffff", "#0290ff"];

interface BarChartProps<T extends ChartType = ChartType> {
  colors?: string[];
  labels?: string[];
  data: DefaultDataPoint<T>[];
  options?: ChartOptions<T>;
  legendLabels?: string[];
  borderRadius?: number;
}

export const BarChart = ({
  colors,
  labels,
  data,
  options,
  legendLabels,
  borderRadius,
}: BarChartProps) => {
  if (legendLabels) ChartJS.register(Legend);
  const [dataSet, updateDataSet] = React.useState(() => {
    return {
      labels: labels ?? [],
      datasets: data.map((dataItem, curIdx) => ({
        borderRadius: borderRadius ?? 0,
        label: legendLabels ? legendLabels[curIdx] : "",
        data: dataItem,
        backgroundColor: (colors || DEFAULT_COLORS)[
          curIdx % (colors || DEFAULT_COLORS).length
        ],
      })),
    };
  });

  React.useEffect(() => {
    updateDataSet({
      labels: labels ?? [],
      datasets: data.map((dataItem, curIdx) => ({
        borderRadius: borderRadius ?? 0,
        label: legendLabels ? legendLabels[curIdx] : "",
        data: dataItem,
        backgroundColor: (colors || DEFAULT_COLORS)[
          curIdx % (colors || DEFAULT_COLORS).length
        ],
      })),
    });
  }, [data, labels, colors]);
  //Odd interaction with options where TypeScript complains but component functions properly.
  //@ts-ignore
  return <Bar data={dataSet} options={options ?? {}} />;
};

interface PieChartProps {
  className?: string;
  borderColor?: string;
  borderWidth?: number;
  filledColor: string;
  emptyColor: string;
  total: number;
  progress: number;
}

export const PieChart = ({
  borderColor,
  borderWidth = 2,
  className,
  filledColor,
  emptyColor,
  total,
  progress,
}: PieChartProps) => {
  const [dataset, updateDataset] = React.useState(() => ({
    data: [progress, total - progress],
    backgroundColor: [filledColor, emptyColor],
    borderColor: [borderColor ?? filledColor],
    borderWidth: borderWidth,
  }));

  React.useEffect(() => {
    updateDataset({
      data: [progress, total - progress],
      backgroundColor: [filledColor, emptyColor],
      borderColor: [borderColor ?? filledColor],
      borderWidth: borderWidth,
    });
  }, [progress, total, borderWidth, filledColor, emptyColor, borderColor]);

  return (
    <Pie
      className={className}
      data={{
        datasets: [dataset],
      }}
    />
  );
};
