import { ENDPOINT_GS_PPF_DASHBOARD } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_GS_PPF_DASHBOARD;

export const GSPPFDashboard = {
  get(
    params: Endpoints.Tyto.GS.PPFDashboard.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.PPFDashboard.Get>;
  },
};
