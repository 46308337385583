import { ENDPOINT_GS_PLAN_NOTICES } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_GS_PLAN_NOTICES;

export const GSPlanNotices = {
  get(
    params: Endpoints.Tyto.GS.Plan.Notices.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Plan.Notices.Get>;
  },
};
