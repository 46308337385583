/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Icon, Button, Input } from "components/common/";

import { Props as TextEditModalProps } from "../";

const INPUT_ID = "textedit-modal-input";

const TextEdit = (props: TextEditModalProps) => {
  const [currentText, updateCurrentText] = React.useState(() => {
    return props.startingText ?? "";
  });

  React.useEffect(() => {
    const input = document.getElementById(INPUT_ID);

    if (!input) {
      return;
    }

    const typedInpRef = input as HTMLTextAreaElement;

    typedInpRef.focus?.();
    typedInpRef.setSelectionRange?.(
      typedInpRef.value.length,
      typedInpRef.value.length
    );
  }, []);

  return (
    <article className="textedit-modal-inner-cont">
      <section className="textedit-modal-buttons-cont">
        <button
          className="textedit-modal-back-button"
          onClick={props.closePopup}
        >
          <Icon
            className="textedit-modal-back-button-icon"
            icon="angle-left"
            //   isButton={true}
            //   buttonProps={{}}
            size={18}
          />

          <span className="textedit-modal-back-button-text">Cancel</span>
        </button>

        <Button
          className="textedit-modal-done-button"
          theme={props.theme ?? "action"}
          onClick={() => props.onConfirm(currentText)}
          value="Done"
        />
      </section>

      <section className="textedit-modal-text-wrapper">
        <h2 className="textedit-modal-text-label title-font">{props.label}</h2>
        <Input
          id={INPUT_ID}
          autoFocus={true}
          className="textedit-modal-textfield"
          containerClassName="textedit-modal-textfield-cont"
          name="Text Editor"
          onChange={(newVal) => {
            updateCurrentText(newVal);
          }}
          type="textarea"
          value={currentText}
        />
      </section>
    </article>
  );
};

export default TextEdit;
