/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { MenuToggleButton } from "components/common/";

import "./style.scss";

interface Props {
  className?: string;
  containerClassName?: string;
  children?: any;
}

const GenericNav = (props: Props) => {
  return (
    <nav className={cx("cm-subnavs-generic-nav", props.className)}>
      <section className="cm-subnavs-generic-nav-btn-cont">
        <MenuToggleButton />
      </section>

      <section
        className={cx("cm-subnavs-generic-nav-cont", props.containerClassName)}
      >
        {props.children ?? null}
      </section>
    </nav>
  );
};

export default GenericNav;
