/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { useCommentLikeMutation } from "data/network/hooks/";
import {
  useGoalNotices,
  useGoalChangelogAndNotices,
  useGoalChangelog,
} from "data/helpers/Hooks";
import { TextButton, Timestamp, UserThumbByID } from "components/common/";

import "./style.scss";
import { PropertiesPlugin } from "@microsoft/applicationinsights-web";

type readableChangelog = {
  text: string;
  sortDate: string;
  personID: number;
  personName: string;
};

interface NoticeThreadsProps {
  childGoal?: TytoData.PPF.Plan.Goals.ChildGoal;
  permissions: TytoData.PPF.Plan.MemberPermissionData;
  plan?: TytoData.PPF.Plan.Plan;
  planID?: number;
  notices?: TytoData.Notices.Notice[];
  goalID: number;
  goalNotices?: TytoData.Notices.Notice[];
  innerRef?: React.MutableRefObject<HTMLUListElement | null>;
  replyNoticeID: number;
  updateReplyID: (noticeID: number) => void;
  changelog?: Array<TytoData.GoalChangeLogItem>;
  isCVDomain?: boolean;
  isComments?: boolean;
}

const NoticeThreads = (props: NoticeThreadsProps) => {
  const goalNotices = useGoalNotices({
    noticeBoard: props.notices,
    goalID: props.goalID,
  });

  // const goalNoticesAndDashboard = useGoalChangelogAndNotices({
  //   noticeBoard: props.notices,
  //   goalID: props.goalID,
  //   changelog: props.changelog,
  //   isCVDomain: props.isCVDomain,
  // });

  const goalChangelog = useGoalChangelog({
    changelog: props.changelog,
    goalID: props.goalID,
  });

  return (
    <ExistingNotices
      {...props}
      // notices={goalNotices}
      noticesAndChangelog={props.isComments ? goalNotices : goalChangelog}
    />
  );
};

interface ExistingNoticesProps extends NoticeThreadsProps {
  // notices: TytoData.Notices.Notice[];
  noticesAndChangelog: (TytoData.Notices.Notice | readableChangelog)[];
}

const ExistingNotices = (props: ExistingNoticesProps) => {
  // if (!props.notices?.length) {
  //   return null;
  // }

  if (!props.noticesAndChangelog?.length) {
    return null;
  }

  return (
    <ul className="currentgoal-notices-list">
      {props.noticesAndChangelog.map((noticeOrChange, key) => {
        return (
          <li className="currentgoal-notices-list-item" key={key}>
            <NoticeThread {...props} noticeThread={noticeOrChange} />
          </li>
        );
      })}
      {/* {props.notices.map((goalNoticeThread) => (
        <li
          className="currentgoal-notices-list-item"
          key={goalNoticeThread.noticeID}
        >
          <NoticeThread {...props} noticeThread={goalNoticeThread} />
        </li>
      ))} */}
    </ul>
  );
};
interface NoticeThreadProps extends NoticeThreadsProps {
  noticeThread: TytoData.Notices.Notice | readableChangelog;
}

const NoticeThread = ({ noticeThread, ...props }: NoticeThreadProps) => {
  return (
    <div className="currentgoal-notices-list-notice">
      <NoticeMessageWrapper
        noticeThread={noticeThread}
        replyNoticeID={props.replyNoticeID}
        updateReplyID={props.updateReplyID}
      />

      {!("text" in noticeThread) && (
        <NoticeComments
          noticeThread={noticeThread}
          planID={props.planID ?? props.plan?.gsPlanID ?? 0}
          updateReplyID={props.updateReplyID}
        />
      )}
    </div>
  );
};

interface NoticeCommentsProps {
  noticeThread: TytoData.Notices.Notice;
  planID: number;
  updateReplyID: NoticeThreadsProps["updateReplyID"];
}

const NoticeComments = (props: NoticeCommentsProps) => {
  if (!props.noticeThread?.comments?.length) {
    return null;
  }

  return (
    <ul className="currentgoal-comments-list">
      {props.noticeThread.comments.map((noticeComment) => (
        <li key={noticeComment.commentID}>
          <NoticeCommentWrapper
            noticeComment={noticeComment}
            planID={props.planID}
            updateReplyID={props.updateReplyID}
          />
        </li>
      ))}
    </ul>
  );
};

interface NoticeMessageWrapperProps {
  noticeThread: TytoData.Notices.Notice | readableChangelog;
  replyNoticeID: NoticeThreadsProps["replyNoticeID"];
  updateReplyID: NoticeThreadsProps["updateReplyID"];
}

const NoticeMessageWrapper = ({
  noticeThread,
  replyNoticeID,
  updateReplyID,
}: NoticeMessageWrapperProps) => {
  return (
    <div>
      {"text" in noticeThread && (
        <NoticeMessage
          messageText={noticeThread.text}
          messageDate={noticeThread.sortDate}
          canReply={false}
          personID={noticeThread.personID ?? 0}
          personName={noticeThread.personName ?? ""}
          isLog={true}
        />
      )}
      {!("text" in noticeThread) && (
        <NoticeMessage
          messageText={noticeThread.noticeText}
          messageDate={noticeThread.noticeDate}
          isBeingRepliedTo={
            !!replyNoticeID && replyNoticeID === noticeThread.noticeID
          }
          onReplyClick={() => updateReplyID(noticeThread.noticeID)}
          canReply={true}
          personID={noticeThread.fromPerson?.userID ?? 0}
          personName={noticeThread.fromPerson?.personName ?? ""}
        />
      )}
    </div>
  );
};

interface NoticeCommentWrapper {
  noticeComment: TytoData.Notices.Comment;
  planID: number;
  updateReplyID: NoticeThreadsProps["updateReplyID"];
}

const NoticeCommentWrapper = ({
  noticeComment,
  planID,
  updateReplyID,
}: NoticeCommentWrapper) => {
  const likeMutation = useCommentLikeMutation({
    commentID: noticeComment.commentID,
    noticeID: noticeComment.noticeID,
    planID,
    onError: () => {},
    onSuccess: () => {},
  });

  return (
    <NoticeMessage
      messageText={noticeComment.commentText}
      messageDate={noticeComment.createdDate}
      isComment={true}
      isLiked={!!noticeComment.ratingMemberLiked}
      onLikeToggle={() => {
        likeMutation.mutate({ newLikeStatus: !noticeComment.ratingSumLiked });
      }}
      onReplyClick={() => updateReplyID(noticeComment.noticeID)}
      personID={noticeComment.userID}
      personName={noticeComment.userName}
    />
  );
};

interface NoticeMessageProps {
  canLike?: boolean;
  canReply?: boolean;
  messageText: string;
  personName: string;
  personID: number;
  messageDate: string;
  isBeingRepliedTo?: boolean;
  isComment?: boolean;
  isLiked?: boolean;
  isLog?: boolean;
  onLikeToggle?: () => void;
  onReplyClick?: () => void;
}

const NoticeMessage = ({
  canLike,
  canReply,
  isBeingRepliedTo,
  isComment,
  isLiked,
  isLog,
  messageText,
  messageDate,
  onLikeToggle,
  onReplyClick,
  personID,
  personName,
}: NoticeMessageProps) => {
  return (
    <div
      className={cx("notice-msg-cont", isBeingRepliedTo && "focus-message")}
      data-msg-container="message"
    >
      <div className="notice-msg-userthumb-cont">
        <UserThumbByID
          className="notice-msg-userthumb"
          userID={personID}
          size={34}
          userName={personName}
        />
      </div>

      <div className="notice-msg-details-cont">
        <div className="notice-msg-name-cont">
          <p className="notice-msg-name title-font">{personName}</p>
        </div>

        <div className="notice-msg-text-cont">
          <p
            className={
              isLog ? "log-msg-text notice-msg-text" : "notice-msg-text"
            }
          >
            {messageText}
          </p>
        </div>

        <div className="notice-msg-actions-cont">
          <Timestamp date={messageDate} />

          {canLike && (
            <TextButton
              value={isLiked ? "Unlike" : "Like"}
              onClick={onLikeToggle}
            />
          )}
          {canReply && onReplyClick && (
            <TextButton
              onClick={onReplyClick}
              value={`Reply${!!isComment ? " To Thread" : ""}`}
              style={{ padding: "0px" }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NoticeThreads;
