/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import API from "data/network/tyto/";
import { Menu, Nav } from "components/meta/";

import Statistics from "./Statistics";

function parseParamID(planID?: string | number) {
  if (!planID) {
    return undefined;
  }

  const parsedID = parseInt(`${planID}`, 10);

  if (!parsedID || parsedID === NaN) {
    return undefined;
  }

  return parsedID;
}

interface Props extends SITE.GeneralStoreInjectedProps {
  planID?: string;
  goalID?: string;
  subInterface?: PlanPage.Subinterface;
}

const PPFPlanWrapper = (props: Props) => {
  const [menuHasBeenOpen, updateMenuHasBeenOpen] = React.useState(
    _.get(props, "GeneralStore.state.showMenu", false)
  );

  const storeMenuOpenValue = _.get(props, "GeneralStore.state.showMenu", false);

  // * Trigger flag so that turning menu off animates back left
  React.useEffect(() => {
    if (!menuHasBeenOpen && storeMenuOpenValue) {
      updateMenuHasBeenOpen(true);
    }
  }, [storeMenuOpenValue, menuHasBeenOpen]);

  const menuIsOpen = storeMenuOpenValue;

  return (
    <div className={cx("interface-wrapper", menuIsOpen && "menu-open")}>
      {/* <Nav className="profile-nav" /> */}
      <Menu className="profile-menu" menuType="default" />

      <div
        className={cx(
          "interface-main-cont interface-stats",
          menuHasBeenOpen && "animate-back"
        )}
      >
        <Statistics GeneralStore={props.GeneralStore} />
      </div>
    </div>
  );
};

export default PPFPlanWrapper;
