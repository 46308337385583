/*
 * Component Description
 */
import * as React from "react";

import { SessionUserThumb, Video, Button, Message } from "components/common";

import { createPPFPlan } from "data/network/special-calls";
import { usePPFPlans, useFunctionOps } from "data/network/hooks/";
import { SessionHandling } from "data/storage/";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import { INTERFACE_PATHS, SUB_INTERFACE_PATHS } from "data/constants";
import { StandardNav } from "components/meta/";
import { StoreContext } from "../../data/stores/GeneralStore";

import "./style.scss";
import Nav from "components/meta/sub-navs/generic-nav";

interface Props extends SITE.GeneralStoreInjectedProps {
  callbackSearching: (isSearching: boolean) => void;
}

const Intro = (props: Props) => {
  let GeneralStore = React.useContext(StoreContext);
  const [userName] = React.useState(() => {
    return SessionHandling.getPropertyFromActiveSession("userName") ?? "";
  });
  const [creatingOwnPlan, updateCreatingOwnPlan] = React.useState(true);
  // const [creatingOwnPlan, updateCreatingOwnPlan] = React.useState(false);

  const sessionData = SessionHandling.getActiveSession();

  // const [isLoading, updateIsLoading] = React.useState(true);

  const [redirectURL, updateRedirectURL] = React.useState<string | null>(null);

  const [doesNotHavePlan, updateDoesNotHavePlan] = React.useState(false);

  const [hasManagePlans, updateHasManagePlans] = React.useState(false);

  const [canCreatePlan, updateCanCreatePlan] = React.useState(true);

  const [planID, updatePlanID] = React.useState(0);

  const ppfPlansData = usePPFPlans({
    onSuccess: (data) => {
      if (data.gsPlans.length) {
        const { managedPlans, matchingPlans } = parsePlansList(data.gsPlans);

        if (managedPlans.length) {
          updateHasManagePlans(true);
        }

        if (!matchingPlans.length) {
          updateDoesNotHavePlan(true);
        } else {
          updatePlanID(matchingPlans[0].gsPlanID);
        }
      } else {
        updateDoesNotHavePlan(true);
      }
    },
  });

  const [errorMsg, updateErrorMsg] = React.useState("");

  const [creating, updateCreating] = React.useState(false);

  // const functionOpsData = useFunctionOps({
  //   extraOpts: { functionName: "Page GSPPF", operation: "ocADD" },
  //   onError: (err) => {
  //     updateErrorMsg(`${err?.msg ?? "Error Occurred: Cannot confirm permission."}`);
  //     updateIsLoading(false);
  //     updateCanCreatePlan(false);
  //   },
  //   onSuccess: (data) => {
  //     updateCanCreatePlan(data.hasScope);
  //     updateIsLoading(false);
  //   },
  // });

  React.useEffect(() => {
    if (!ppfPlansData.isLoading && ppfPlansData.data?.gsPlans) {
      const { managedPlans, matchingPlans } = parsePlansList(
        ppfPlansData.data?.gsPlans
      );

      if (managedPlans.length) {
        updateHasManagePlans(true);
      }

      if (!matchingPlans.length) {
        updateDoesNotHavePlan(true);
      } else {
        updatePlanID(matchingPlans[0].gsPlanID);
      }
    }
  }, []);

  if (redirectURL) {
    return <Redirect to={`${redirectURL}`} />;
  }

  const { isLoading } = ppfPlansData;

  return (
    <main className="interface-intro centered-content-wrapper">
      {!_.get(props, "GeneralStore.state.isMobile", false) && (
        <>
          <StandardNav className="introduction-main-nav" />
          <h1 className="intro-head-text">Introduction to PPF's</h1>
        </>
      )}
      {_.get(props, "GeneralStore.state.isMobile", false) && (
        <div className="introduction-mobile-nav">
          <Nav className="introduction-nav" />
          <h1 className="intro-head-text">Introduction to PPF's</h1>
        </div>
      )}

      <Video
        className="introduction-video"
        poster="https://oc.mocaworks.com/v2/domains/1825957/assets/videos/ppfv2-intro-poster.png"
        src="https://oc.mocaworks.com/v2/domains/1825957/assets/videos/ppfv2e3-intro.mp4"
        type="video/mp4"
        controls={true}
      />
      {isLoading && <div className="intro-buttons-loading">Loading ...</div>}
      {!isLoading && (
        <div className="intro-buttons">
          {canCreatePlan && doesNotHavePlan && (
            <Button
              value={creating ? "LOADING ..." : "CREATE GOAL"}
              onClick={() => {
                updateCreating(true);
                if (sessionData) {
                  createPPFPlan({
                    personID: sessionData.userID,
                    personName: sessionData.userName,
                    teamRootID: sessionData.teamRootID,
                    startDate: new Date(),
                    onError: (newErrorMsg) => {
                      updateErrorMsg(newErrorMsg);
                      updateCreating(false);
                    },
                    onSuccess: (planID, url) => {
                      updateCreating(false);
                      updateRedirectURL(url);
                    },
                  });
                }
              }}
              disabled={creating ? true : false}
              theme="white"
              className="intro-buttons-top"
              hoverBGSize="hidden"
            />
          )}
          {(!canCreatePlan || !doesNotHavePlan) && (
            <Button
              value="VIEW MY PPF'S"
              onClick={() => {
                updateRedirectURL(
                  `${INTERFACE_PATHS.PLAN}/${planID}/${SUB_INTERFACE_PATHS.PLAN.GOALS}`
                );
              }}
              theme="white"
              className="intro-buttons-top"
              hoverBGSize="hidden"
            />
          )}
          {hasManagePlans && (
            <Button
              value="MY PEOPLE"
              onClick={() => {
                if (GeneralStore.dispatch) {
                  console.log(GeneralStore.state?.showMenu);
                  if (GeneralStore.state?.showMenu) {
                    GeneralStore.dispatch({
                      payload: {},
                      type: "GENERAL_STORE_TOGGLE_NAVMENU",
                    });
                    props.callbackSearching(false);
                  } else {
                    GeneralStore.dispatch({
                      payload: {},
                      type: "GENERAL_STORE_TOGGLE_NAVMENU",
                    });
                    props.callbackSearching(true);
                  }
                }
              }}
              theme="white-ghost"
              className="intro-buttons-bot"
              hoverBGSize="hidden"
            />
          )}
        </div>
      )}

      {errorMsg && <Message text={errorMsg} theme="alert" />}

      {doesNotHavePlan && !isLoading && !canCreatePlan && (
        <div className="intro-button-error">
          <div className="intro-button-error-redline"></div>
          <p className="intro-button-error-text">
            Please ask your manager to create goals for you to get started.
          </p>
        </div>
      )}
    </main>
  );
};

function parsePlansList(gsPlans: TytoData.PPF.Plans.Plan[]) {
  const matchingPlans = gsPlans.filter(
    (plan) =>
      plan.aboutID === SessionHandling.getUserIDOfActiveSession() &&
      plan.activeStatus === "ocENABLED"
  );
  const managedPlans = gsPlans.filter(
    (plan) =>
      plan.permission.gsMemberID !== 0 &&
      plan.aboutID !== SessionHandling.getUserIDOfActiveSession() &&
      plan.activeStatus === "ocENABLED"
  );

  return {
    matchingPlans,
    managedPlans,
  };
}

export default Intro;
