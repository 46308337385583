import * as React from "react";

import { IconProps } from "../typings";

const AngleDown = ({ className, color, gradient, size }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      height={size}
      width={size}
      fill={color}
    >
      {Array.isArray(gradient) && (
        <defs>
          <linearGradient id="AngleDownGradient1" x1="0" x2="1" y1="0" y2="1">
            {gradient.map((grad) => (
              <stop offset={`${grad.offsetPercent}%`} stopColor={grad.color} />
            ))}
          </linearGradient>
        </defs>
      )}
      <path
        fill={Array.isArray(gradient) ? "url(#AngleDownGradient1)" : ""}
        d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"
      />
    </svg>
  );
};

export default AngleDown;
