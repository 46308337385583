/*
 * Component Description
 */
import * as React from "react";
import { Icon } from "components/common/";
import cx from "classnames";
import * as _ from "lodash";

interface Props {
  goalSteps: TytoData.PPF.Plan.Goals.GoalItem[];
  updateGoalSteps: (step: TytoData.PPF.Plan.Goals.GoalItem[]) => void;
  isMobile: boolean;
}

const GoalWeight = (props: Props) => {
  const BUTTON_WIDTH = props.isMobile ? 14 : 25;
  const pointerIDRef = React.useRef<number | null>(null);
  const getWeightedGoals = () => {
    var filteredStep = props.goalSteps.filter(
      (step) => step.status !== "ocDISABLED"
    );
    var uncompleteSteps = filteredStep.filter(
      (step) => step.status === "ocNOTSTARTED"
    );
    var numberOf0WeightSteps = filteredStep.filter(
      (step) => step.itemWeight < 1
    );
    var numberOfNone0WeightSteps = filteredStep.filter(
      (step) => step.itemWeight > 0
    );

    var countMax = 0;
    if (numberOf0WeightSteps.length === filteredStep.length) {
      var distribution = Math.floor(100 / filteredStep.length);
      numberOf0WeightSteps.forEach((step, curIdx) => {
        if (props.goalSteps.length - 1 !== curIdx) {
          countMax += distribution;
          return (step.itemWeight = distribution);
        } else {
          return (step.itemWeight = 100 - countMax);
        }
      });
    } else if (!!numberOf0WeightSteps) {
      var neededWeight = numberOf0WeightSteps.length;
      var availbleWeight = 0;
      uncompleteSteps.forEach(
        (step) => (availbleWeight += step.itemWeight - 1)
      );
      if (availbleWeight < 1) {
        numberOfNone0WeightSteps.forEach((step) => {
          if (step.itemWeight - 1 > 0 && neededWeight > 0) {
            step.itemWeight -= 1;
            neededWeight -= 1;
          }
        });
      } else {
        while (neededWeight > 0) {
          numberOfNone0WeightSteps.forEach((step) => {
            if (
              step.itemWeight - 1 > 0 &&
              neededWeight > 0 &&
              step.status !== "ocCOMPLETE"
            ) {
              step.itemWeight -= 1;
              neededWeight -= 1;
            }
          });
        }
        numberOf0WeightSteps.forEach((step) => {
          step.itemWeight = 1;
        });
      }
    }
    var finalSteps = numberOf0WeightSteps.concat(numberOfNone0WeightSteps);
    var finalWeight = 0;
    finalSteps.forEach((step) => {
      finalWeight += step.itemWeight;
    });

    if (!finalSteps?.length) {
      finalWeight = 0;
    } else {
      if (finalWeight < 100) {
        finalSteps[finalSteps.length - 1].itemWeight += 100 - finalWeight;
      } else if (finalWeight > 100) {
        var getRidOf = finalWeight - 100;
        finalSteps.forEach((step) => {
          if (getRidOf > 0) {
            if (step.itemWeight - getRidOf > 0) {
              step.itemWeight = step.itemWeight - getRidOf;
            } else {
              getRidOf -= step.itemWeight - 1;
              step.itemWeight = 1;
            }
          }
        });
      }
    }
    return finalSteps.sort((a, b) => a.seq - b.seq);
  };
  const weightContainerRef = React.useRef<null | HTMLElement>(null);
  const [goalStepsWeighted, updateGoalStepsWeighted] =
    React.useState(getWeightedGoals);
  const [copyOfSteps, updateCopyOfSteps] = React.useState<
    TytoData.PPF.Plan.Goals.GoalItem[]
  >(JSON.parse(JSON.stringify(goalStepsWeighted)));
  const [percentComplete, updatePercentComplete] = React.useState(() => {
    if (!!copyOfSteps) {
      var tempArr = copyOfSteps.filter(
        (goalStep) => goalStep.status === "ocCOMPLETE"
      );
      var weight = 0;
      tempArr.forEach((step) => {
        weight += step.itemWeight;
      });
      return weight;
    } else {
      return -1;
    }
  });

  const [startingXPosition, updateStartingXPosition] = React.useState(0);

  const [leftStep, updateLeftStep] = React.useState(-2);

  const [rightStep, updateRightStep] = React.useState(-1);

  const [updating, updateUpdating] = React.useState(false);

  const handleMouseDown = () => {
    updateCopyOfSteps(JSON.parse(JSON.stringify(goalStepsWeighted)));
    document.addEventListener(`pointerup`, handleMouseUp);
    document.addEventListener(`pointermove`, throttledHandleMouseMove);
  };

  const handleMouseUp = (event: PointerEvent) => {
    if (event.pointerId !== pointerIDRef.current) {
      return;
    }
    updateUpdating(false);
    console.log("in herer");
    document.removeEventListener(`pointermove`, throttledHandleMouseMove);
    document.removeEventListener(`pointerup`, handleMouseUp);
    props.updateGoalSteps(copyOfSteps);
    pointerIDRef.current = null;
  };

  const handleMouseMove = (event: PointerEvent) => {
    if (event.pointerId !== pointerIDRef.current) {
      return;
    }
    var diffrence = event.pageX - startingXPosition;
    const containerWidth =
      weightContainerRef.current?.getBoundingClientRect?.().width;

    if (!containerWidth) {
      return;
    }

    var accountingForButtons = (copyOfSteps.length - 1) * BUTTON_WIDTH;
    var percentChange = Math.floor(
      diffrence / ((containerWidth - accountingForButtons) / 100)
    );

    if (copyOfSteps && goalStepsWeighted && updating) {
      const mutatedCopy = [...copyOfSteps];

      if (percentChange < 0) {
        if (
          goalStepsWeighted[leftStep].itemWeight - Math.abs(percentChange) >
          0
        ) {
          mutatedCopy[leftStep].itemWeight =
            goalStepsWeighted[leftStep].itemWeight - Math.abs(percentChange);
          mutatedCopy[rightStep].itemWeight =
            goalStepsWeighted[rightStep].itemWeight + Math.abs(percentChange);
        } else {
          mutatedCopy[leftStep].itemWeight = 1;
          mutatedCopy[rightStep].itemWeight =
            goalStepsWeighted[rightStep].itemWeight +
            (goalStepsWeighted[leftStep].itemWeight - 1);
        }
      } else if (percentChange > 0) {
        if (goalStepsWeighted[rightStep].itemWeight - percentChange > 0) {
          mutatedCopy[rightStep].itemWeight =
            goalStepsWeighted[rightStep].itemWeight - percentChange;
          mutatedCopy[leftStep].itemWeight =
            goalStepsWeighted[leftStep].itemWeight + percentChange;
        } else {
          mutatedCopy[rightStep].itemWeight = 1;
          mutatedCopy[leftStep].itemWeight =
            goalStepsWeighted[leftStep].itemWeight +
            (goalStepsWeighted[rightStep].itemWeight - 1);
        }
      }

      if (percentChange !== 0) {
        updateCopyOfSteps(mutatedCopy);
      }
    }
  };
  const throttledHandleMouseMove = _.throttle(handleMouseMove, 100, {
    trailing: true,
    leading: true,
  });

  React.useEffect(() => {
    updateGoalStepsWeighted(getWeightedGoals);
  }, [props.goalSteps]);

  React.useEffect(() => {
    updateCopyOfSteps(JSON.parse(JSON.stringify(goalStepsWeighted)));
  }, [goalStepsWeighted]);

  React.useEffect(() => {
    updatePercentComplete(() => {
      if (!!copyOfSteps) {
        var tempArr = copyOfSteps.filter(
          (goalStep) => goalStep.status === "ocCOMPLETE"
        );
        var weight = 0;
        tempArr.forEach((step) => {
          weight += step.itemWeight;
        });
        return weight;
      } else {
        return -1;
      }
    });
  }, [copyOfSteps]);

  React.useEffect(() => {
    if (startingXPosition !== 0) {
      handleMouseDown();
    }
  }, [startingXPosition]);
  return (
    <section className="cg-editgoal-goal-step-weight" ref={weightContainerRef}>
      <div className="cg-editgoal-goal-step-weight-percent">
        <h1 className="cg-editgoal-goal-step-weight-percent-value title-font">
          {percentComplete}%
        </h1>
        <p className="cg-editgoal-goal-step-weight-percent-text title-font2">
          complete
        </p>
      </div>
      <div className="cg-editgoal-goal-step-weight-items title-font2">
        {copyOfSteps.length === goalStepsWeighted.length &&
          copyOfSteps?.map((step, curIdx) => {
            if (step.status !== "ocDISABLED") {
              return (
                <React.Fragment key={step.gsGoalItemKey ?? curIdx}>
                  <div
                    id={`cg-editgoal-goal-step-weight-item-${curIdx}`}
                    className={cx(
                      "cg-editgoal-goal-step-weight-item",
                      step.status === "ocCOMPLETE" ? "complete" : ""
                    )}
                    style={{
                      width: `${
                        updating
                          ? step.itemWeight
                          : goalStepsWeighted[curIdx].itemWeight ===
                            step.itemWeight
                          ? step.itemWeight
                          : goalStepsWeighted[curIdx].itemWeight
                      }%`,
                      minWidth: props.isMobile ? "12px" : "22px",
                    }}
                  >
                    <div className="cg-editgoal-goal-step-weight-item-inner">
                      {updating
                        ? step.itemWeight
                        : goalStepsWeighted[curIdx].itemWeight ===
                          step.itemWeight
                        ? step.itemWeight
                        : goalStepsWeighted[curIdx].itemWeight}
                      %
                    </div>
                  </div>
                  {curIdx + 1 !== copyOfSteps.length && (
                    <Icon
                      id={`cg-editgoal-goal-step-weight-button-${curIdx}`}
                      // className="cg-editgoal-goal-step-weight-button"
                      buttonProps={{
                        className: "cg-editgoal-goal-step-weight-button",
                      }}
                      size={BUTTON_WIDTH}
                      icon="dual-drag-arrow"
                      isButton={true}
                      onPointerDown={(event) => {
                        if (!pointerIDRef.current) {
                          updateUpdating(true);
                          updateLeftStep(curIdx);
                          updateRightStep(curIdx + 1);
                          updateStartingXPosition(event.clientX);
                          pointerIDRef.current = event.pointerId;
                        }
                      }}
                    />
                  )}
                </React.Fragment>
              );
            }
          })}
      </div>
    </section>
  );
};

export default GoalWeight;
