/*
 * Component Description
 */
import * as React from "react";

import { IconProps } from "../typings";

const Plus = ({ className, color, size, style }: IconProps) => {
  return (
    <svg
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 42 42"
      height={size}
      width={size}
      fill={color}
    >
    <path fillRule="evenodd" clipRule="evenodd" d="M24.3597 17.64C21.8187 15.099 17.5224 15.1953 14.7697 17.9498C12.3127 20.4068 10.4192 22.2985 7.96392 24.7555C5.20942 27.5083 5.11317 31.8045 7.65417 34.3455C10.1952 36.8865 14.4914 36.7903 17.2442 34.0358C18.2907 32.9893 20.6479 30.6338 20.6479 30.6338C21.1589 30.121 21.1589 29.2898 20.6479 28.777C20.1352 28.2643 19.3039 28.2643 18.7912 28.777L15.3874 32.1808C13.6987 33.8695 11.0702 34.048 9.51092 32.4888C7.95167 30.9295 8.13017 28.301 9.81892 26.6123L16.6247 19.8065C18.3152 18.116 20.9437 17.9375 22.5029 19.4968C23.0157 20.0078 23.8469 20.0078 24.3597 19.4968C24.8724 18.984 24.8724 18.1528 24.3597 17.64V17.64Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.554 24.4458C18.7842 25.6761 20.4537 26.3691 22.1932 26.3691C23.9345 26.3691 25.604 25.6761 26.8342 24.4458C29.4347 21.8453 31.659 19.6228 34.2595 17.0223C35.4897 15.7903 36.181 14.1208 36.181 12.3813C36.181 10.6401 35.4897 8.9706 34.2595 7.74035C33.0292 6.5101 31.3597 5.81885 29.6185 5.81885C27.879 5.81885 26.2095 6.5101 24.9775 7.74035C23.8557 8.86385 22.5712 10.1483 21.2657 11.4538C20.753 11.9666 20.753 12.7978 21.2657 13.3106C21.7767 13.8216 22.6097 13.8216 23.1207 13.3106L26.8342 9.5971C27.5727 8.8586 28.5737 8.44385 29.6185 8.44385C30.6632 8.44385 31.6642 8.8586 32.4027 9.5971C33.1412 10.3356 33.556 11.3366 33.556 12.3813C33.556 13.4261 33.1412 14.4271 32.4027 15.1656L24.9775 22.5908C24.2407 23.3276 23.238 23.7441 22.1932 23.7441C21.1502 23.7441 20.1475 23.3276 19.409 22.5908C18.898 22.0781 18.0667 22.0781 17.554 22.5908C17.0412 23.1018 17.0412 23.9331 17.554 24.4458V24.4458Z" />
    </svg>
  );
};

export default Plus;


