import { AccountSession } from "./AccountSession";
import { AssetEncoding } from "./Asset.Encoding";
import { Block } from "./Block";
import { BlockEnrollment } from "./BlockEnrollment";
import { BlockPrerequisites } from "./Block.Prerequisites";
import { CatalogCurriculumPublication } from "./CatalogCurriculumPublication";
import { CurriculumPubCatalogItemsTrending } from "./CurriculumPubCatalogItemsTrending";
import { SearchLessonContent } from "./CatalogCurriculumPublication.SearchLessonContent";
import { ConfigurationClient } from "./Configuration.Client";
import { DevPlanEnrollment } from "./DevPlanEnrollment";
import { Domain } from "./Domain";
import { DomainImage } from "./Domain.Image";
import { DISCProfileInteractive } from "./DiscProfile.Interactive";
import { DISCProfileInteractiveMockup } from "./DiscProfile.Interactive.Mockup";
import { DISCProfileTeam } from "./DiscProfile.Team";
import { DISCProfileMini } from "./DiscProfile.Mini";
import { DISCProfiles } from "./DiscProfiles";
import { DISCProfilesEmailViewReady } from "./DiscProfiles.EmailViewReady";
import { DISCProfilesMini } from "./DiscProfilesMini";
import { DomainBilling } from "./DomainBilling";
import { DomainBillings } from "./DomainBillings";
import { DomainInvitationEmail } from "./DomainInvitationEmail";
import { DomainInvitationEmailTemplate } from "./DomainInvitationEmail.Template";
import { DomainMessage } from "./Domain.Message";
import { DomainMessages } from "./Domain.Messages";
import { EmailQueueStatus } from "./EmailQueueStatus";
import { EmailQueueStatusUser } from "./EmailQueueStatus.User";
import { EnrollmentVerificationRequest } from "./Enrollment.VerificationRequest";
import { Answer } from "./Exam.Take.Answer";
import { Complete } from "./Exam.Take.Complete";
import { EvaluateResponse } from "./Exam.Take.Evaluate.Response";
import { FunctionOps } from "./FunctionOps";
import { GSChangelog } from "./GSChangelog";
import { GSGoal } from "./GSGoal";
import { GSGoalItem } from "./GSGoal.item";
import { GSGoalProfileImage } from "./GSGoal.ProfileImage";
import { GSGoals } from "./GSGoals";
import { GSMeasureLog } from "./GSMeasure.Log";
import { GSPPFDashboard } from "./GS.PPFDashboard";
import { GSPPFDashboardSummary } from "./GS.PPFDashboard.summary";
import { GSPlans } from "./GSPlans";
import { GSPlan } from "./GSPlan";
import { GSPlanNotices } from "./GSPlan.Notices";
import { GSPlanNotice } from "./GSPlan.Notice";
import {
  GSPlanNoticeComment,
  GSPlanNoticeCommentLike,
  GSPlanNoticeCommentUnlike,
} from "./GSPlan.Notice.Comment";
import { GSPlanNoticeMember } from "./GSPlan.Notice.Member";
import { GSMemberClone } from "./GSMember.Clone";
import { GSMember } from "./GSMember";
import { GSMembers } from "./GSMembers";
import { Question } from "./Exam.Take.Question";
import { TrainingInvite } from "./Exam.Take.TrainingInvite";
import { TrainingInvites } from "./Exam.Take.TrainingInvites";
import { LaunchEnrollment } from "./Launch.Enrollment";
import { Lesson } from "./Lesson";
import { LoginAuthenticate } from "./LoginAuthenticate";
import { LoginAuthenticate4 } from "./Login.Authenticate4";
import { LoginRecover } from "./Login.Recover";
import { LoginRecoverTeamTools } from "./Login.Recover.TeamTools";
import { LoginResetPassword } from "./Login.ResetPassword";
import { LoginResetPasswordValidate } from "./Login.ResetPassword.Validate";
import { Logout } from "./Session.Logout";
import { Menu } from "./Menu";
import { Person } from "./Person";
import { PersonMyPassword } from "./Person.MyPassword";
import { PersonPassword } from "./Person.Password";
import { PlanEnroll } from "./Plan.Enroll";
import { PPFTemplateImages } from "./PPF.TemplateImages";
import { PrerequisiteEnrollments } from "./PrerequisiteEnrollments";
import { ProfilePhoto } from "./Person.ProfilePhoto";
import { PersonAdvanced } from "./PersonAdvanced";
import { PersonNotices } from "./Person.Notices";
import { SaveForLesson } from "./SaveForLesson";
import { Tasks } from "./Tasks";
import { TaskStructure } from "./Task.Structure";
import { TaskVerificationRequest } from "./Task.VerificationRequest";
import { Team } from "./Team";
import { Teamboard } from "./Teamboard";
import { TeamsByFunction } from "./TeamsByFunction";
import { TeamMembershipPerson } from "./TeamMembership.Person";
import { TeamToolsConfig } from "./TeamTools.Config";
import { TeamToolsEmailLogin } from "./TeamTools.EmailLogin";
import { TeamToolsInvite } from "./TeamToolsInvite";
import { TeamToolsInviteEmail } from "./TeamToolsInvite.Email";
import { TeamToolsInviteTempSession } from "./TeamToolsInvite.tempSession";
import { Timezones } from "./TimeZone";
import { Training } from "./Training";
import { Upload } from "./Upload";
import { UserAlertCounts } from "./UserAlertCounts";

// * Simple abstraction so it makes more sense as a DEV;
// * All the PPF endpoints are "gsPlan", as in "Goal Sharing Plan"
// * Where "Goal Sharing" was the original name for "PPF: Personal, Professional, Financial Goals".
// * So the endpoints are faithfully in the "GS" namespace, as well as aliased under "PPF"
const GOAL_SHARING_ENDPOINTS = {
  Dashboard: GSPPFDashboard,
  DashboardSummary: GSPPFDashboardSummary,
  Goal: {
    ...GSGoal,
    Item: GSGoalItem,
    ProfileImage: GSGoalProfileImage,
  },
  Goals: {
    ...GSGoals,
  },
  Measure: {
    Log: GSMeasureLog,
  },
  Member: {
    ...GSMember,
    Clone: GSMemberClone,
  },
  Members: {
    ...GSMembers,
  },
  Plans: {
    ...GSPlans,
  },
  Plan: {
    ...GSPlan,
    Notices: GSPlanNotices,
    Notice: {
      ...GSPlanNotice,
      Comment: {
        ...GSPlanNoticeComment,
        Like: GSPlanNoticeCommentLike,
        Unlike: GSPlanNoticeCommentUnlike,
      },
      Member: GSPlanNoticeMember,
    },
  },
  TemplateImages: PPFTemplateImages,
};

const API = {
  AccountSession,
  Asset: {
    Encoding: AssetEncoding,
  },
  Block: {
    ...Block,
    Enrollment: BlockEnrollment,
    Prerequisites: BlockPrerequisites,
  },
  CatalogCurriculumPublication: {
    ...CatalogCurriculumPublication,
    SearchLessonContent,
  },
  CurriculumPubCatalogItemsTrending,
  Configuration: {
    Client: ConfigurationClient,
  },
  DevPlan: {
    Enrollment: DevPlanEnrollment,
  },
  DISCProfiles: {
    ...DISCProfiles,
    EmailViewReady: DISCProfilesEmailViewReady,
  },
  DISCProfile: {
    Interactive: DISCProfileInteractive,
    InteractiveMockup: DISCProfileInteractiveMockup,
    Mini: DISCProfileMini,
    Team: DISCProfileTeam,
  },
  DISCProfilesMini,
  DomainBilling,
  DomainBillings,
  Domain: {
    ...Domain,
    Image: DomainImage,
    Message: DomainMessage,
    Messages: DomainMessages,
  },
  DomainInvitationEmail: {
    ...DomainInvitationEmail,
    Template: DomainInvitationEmailTemplate,
  },
  EmailQueueStatus: {
    ...EmailQueueStatus,
    User: EmailQueueStatusUser,
  },
  Enrollment: {
    VerificationRequest: EnrollmentVerificationRequest,
  },
  Exam: {
    Take: {
      Answer,
      Complete,
      Evaluate: {
        Reponse: EvaluateResponse,
      },
      Question,
      TrainingInvite,
      TrainingInvites,
    },
  },
  GSChangelog,
  GS: GOAL_SHARING_ENDPOINTS,
  LaunchEnrollment,
  Lesson,
  LoginAuthenticate,
  // LoginRecover,
  Login: {
    Authenticate4: LoginAuthenticate4,
    ResetPassword: {
      ...LoginResetPassword,
      Validate: LoginResetPasswordValidate,
    },
    Recover: {
      ...LoginRecover,
      TeamTools: LoginRecoverTeamTools,
    },
  },
  Menu,
  FunctionOps,
  Person: {
    ...Person,
    MyPassword: PersonMyPassword,
    Notices: PersonNotices,
    Password: PersonPassword,
    ProfilePhoto,
  },
  PersonAdvanced,
  Plan: {
    Enroll: PlanEnroll,
  },
  PPF: GOAL_SHARING_ENDPOINTS,
  PrerequisiteEnrollments,
  SaveForLesson,
  Session: {
    Logout,
  },
  Task: {
    Structure: TaskStructure,
    VerificationRequest: TaskVerificationRequest,
  },
  Tasks,
  Team,
  Teamboard,
  TeamsByFunction,
  TeamMembership: {
    Person: TeamMembershipPerson,
  },
  TeamTools: {
    Config: TeamToolsConfig,
    EmailLogin: TeamToolsEmailLogin,
  },
  TeamToolsInvite: {
    ...TeamToolsInvite,
    Email: TeamToolsInviteEmail,
    TempSession: TeamToolsInviteTempSession,
  },
  Timezones,
  Training,
  Upload,
  UserAlertCounts,
};

export default API;
