/*
 * Component Description
 */
import * as React from "react";

import { StoreContext as GeneralStoreContext } from "data/stores/GeneralStore";
import { MenuToggleButton } from "components/common/";

import "./NewGoalHeader.scss";

interface Props {
  plan?: TytoData.PPF.Plan.Plan;
}

export const NewGoalHeader = (props: Props) => {
  const GeneralStore = React.useContext(GeneralStoreContext);

  const isMobile = !!GeneralStore.state?.isMobile ?? false;

  return (
    <nav className="plan-newgoal-header">
      <div className="plan-newgoal-header-left">
        {isMobile && (
          <>
            <MenuToggleButton />
            <h3 className="plan-newgoal-header-subtitle">
              Hello {props.plan?.about?.elementName ?? "There"}
            </h3>
          </>
        )}
        <h2 className="plan-newgoal-header-title title-font">
          What Type of Goal
        </h2>
      </div>

      <div className="plan-newgoal-header-right">
        {/* <UserThumb
  
          /> */}
      </div>
    </nav>
  );
};
export default NewGoalHeader;
