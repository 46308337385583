import {
  ReactPlugin,
  useTrackEvent,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";

// * Only real purpose of this is to enforce that the eventType passed to useTrackEvent
// * is one our standarized events for our finite list of event Types.
export function useNormalizedTrackEvent(
  appInsights: ReactPlugin,
  eventType: Tracking.InteractionEventType,
  eventData: any,
  skipFirstRun?: boolean
) {
  const eventTracker = useTrackEvent(
    appInsights,
    eventType,
    eventData,
    skipFirstRun
  );

  return eventTracker;
}

export function useNormalizedTrackMetric(
  appInsights: ReactPlugin,
  componentName: Tracking.InteractionEventType
) {
  const eventTracker = useTrackMetric(appInsights, componentName);

  return eventTracker;
}
