import * as React from "react";

import { IconProps } from "../typings";

const CheckBox = ({
  className,
  color,
  gradient,
  gradientKey,
  size,
}: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="1 0 23 23"
      height={size + 4}
      width={size + 4}
      fill={color}
    >
      {Array.isArray(gradient) && (
        <defs>
          <linearGradient id={gradientKey} x1="0" x2="1" y1="0" y2="1">
            {gradient.map((grad) => (
              <stop
                key={`${grad.offsetPercent}-${grad.color}`}
                offset={`${grad.offsetPercent}%`}
                stopColor={grad.color}
              />
            ))}
          </linearGradient>
        </defs>
      )}

      <path
        d="M20 11.83C19.7348 11.83 19.4804 11.9354 19.2929 12.1229C19.1054 12.3104 19 12.5648 19 12.83V18.4C19 18.5591 18.9368 18.7117 18.8243 18.8243C18.7117 18.9368 18.5591 19 18.4 19H5.6C5.44087 19 5.28826 18.9368 5.17574 18.8243C5.06321 18.7117 5 18.5591 5 18.4V5.6C5 5.44087 5.06321 5.28826 5.17574 5.17574C5.28826 5.06321 5.44087 5 5.6 5H15.17C15.4352 5 15.6896 4.89464 15.8771 4.70711C16.0646 4.51957 16.17 4.26522 16.17 4C16.17 3.73478 16.0646 3.48043 15.8771 3.29289C15.6896 3.10536 15.4352 3 15.17 3H5.6C4.91125 3.00263 4.25146 3.27741 3.76443 3.76443C3.27741 4.25146 3.00263 4.91125 3 5.6V18.4C3.00263 19.0888 3.27741 19.7485 3.76443 20.2356C4.25146 20.7226 4.91125 20.9974 5.6 21H18.4C19.0888 20.9974 19.7485 20.7226 20.2356 20.2356C20.7226 19.7485 20.9974 19.0888 21 18.4V12.83C21 12.5648 20.8946 12.3104 20.7071 12.1229C20.5196 11.9354 20.2652 11.83 20 11.83Z"
        fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""}
      />
      <path
        d="M10.7195 10.9998C10.5318 10.8311 10.2873 10.7395 10.0349 10.7435C9.78259 10.7475 9.54109 10.8468 9.35888 11.0214C9.17666 11.196 9.06721 11.4331 9.05249 11.685C9.03776 11.937 9.11884 12.1852 9.27947 12.3798L11.4995 14.7098C11.5924 14.8073 11.7041 14.885 11.8278 14.9383C11.9516 14.9916 12.0848 15.0193 12.2195 15.0198C12.3535 15.0206 12.4862 14.9944 12.6099 14.9429C12.7336 14.8914 12.8457 14.8155 12.9395 14.7198L19.7195 7.71982C19.8114 7.62526 19.8838 7.51353 19.9325 7.391C19.9813 7.26847 20.0054 7.13753 20.0036 7.00567C20.0017 6.87382 19.9739 6.74361 19.9217 6.6225C19.8695 6.50139 19.794 6.39174 19.6995 6.29982C19.6049 6.20789 19.4932 6.13549 19.3707 6.08674C19.2481 6.038 19.1172 6.01387 18.9853 6.01572C18.8535 6.01758 18.7233 6.04539 18.6022 6.09757C18.481 6.14974 18.3714 6.22526 18.2795 6.31982L12.2295 12.5798L10.7195 10.9998Z"
        fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""}
      />
    </svg>
  );
};

export default CheckBox;
