import * as React from "react";

import { IconProps } from "../typings";

import "./balloon.scss";

const Balloon = ({ className, color, size }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      height={size}
      width={size}
      fill={color}
    >
        <path className="cc-icon-balloon-main" d="M378.88,133.12c0,81.92-81.92,163.84-122.88,163.84s-122.88-81.92-122.88-163.84
            c0-67.85,55.03-122.88,122.88-122.88S378.88,65.27,378.88,133.12"/>
        <g>
            <path className="cc-icon-balloon-border" d="M266.24,51.2c0,5.652-4.588,10.24-10.24,10.24c-39.526,0-71.68,32.154-71.68,71.68
                c0,5.652-4.588,10.24-10.24,10.24s-10.24-4.588-10.24-10.24c0-50.811,41.349-92.16,92.16-92.16
                C261.652,40.96,266.24,45.548,266.24,51.2 M256,286.72c-35.41,0-112.64-77.107-112.64-153.6c0-62.095,50.545-112.64,112.64-112.64
                s112.64,50.545,112.64,112.64C368.64,209.613,291.41,286.72,256,286.72 M389.12,133.12C389.12,59.72,329.4,0,256,0
                S122.88,59.72,122.88,133.12c0,75.059,64.532,151.122,112.763,169.718l-7.373,7.352c-2.908,2.929-3.789,7.352-2.212,11.162
                c1.577,3.83,5.325,6.328,9.462,6.328h13.66c30.597,72.95,14.459,89.764-7.885,112.558c-13.681,13.988-29.204,29.839-36.209,59.146
                c-1.331,5.489,2.068,11.018,7.578,12.329c0.799,0.205,1.597,0.287,2.376,0.287c4.649,0,8.847-3.154,9.953-7.864
                c5.714-23.777,18.534-36.905,30.945-49.582c27.955-28.549,44.38-53.494,15.401-126.874h5.14c4.137,0,7.885-2.499,9.462-6.328
                c1.577-3.809,0.696-8.233-2.212-11.162l-7.373-7.352C324.588,284.242,389.12,208.179,389.12,133.12"/>
            <path className="cc-icon-balloon-overlay" d="M387.133,110.981c-0.819-4.936-5.1-8.54-10.097-8.54
                c-5.571,0.717-9.318,3.666-10.117,8.643C355.471,183.419,285.512,245.76,256,245.76c-29.532,0-99.492-62.341-110.92-134.676
                c-0.799-4.956-5.038-8.622-10.097-8.643h-0.02c-5.018,0-9.298,3.604-10.117,8.54c-1.331,7.946-1.966,15.176-1.966,22.139
                c0,86.344,85.402,174.08,133.12,174.08s133.12-87.736,133.12-174.08C389.12,126.157,388.465,118.927,387.133,110.981"/>
        </g>
    </svg>
  );
};

export default Balloon;
