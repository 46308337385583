import * as React from "react";
import cx from "classnames";
import Select from "react-select";

import { useTeamsByFunction } from "data/network/hooks/";
import "./style.scss";

export interface Props {
  className?: String;
  classNamePrefix?: string;
  labels: string[];
  values: string[];
  styles?: any;
  placeHolder?: String;
  passBack: (value: any) => void;
  isSearchable?: boolean;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  useStandardStyling?: boolean;
}

const ReactSelect = (props: Props) => {
  const [options, updateOptions] = React.useState(() => {
    if (props.labels.length === props.values.length) {
      props.labels?.map((label, value) => ({
        label: label,
        value: props.values[value],
      }));
    } else {
      return [{ label: "Error", value: "Error" }];
    }
  });

  React.useEffect(() => {
    updateOptions(
      props.labels?.map((label, value) => ({
        label: label,
        value: props.values[value],
      }))
    );
  }, [props.labels, props.values]);

  const fallbackPrefixClass = props.useStandardStyling
    ? "standard-react-select"
    : undefined;

  return (
    <Select
      className={cx(
        "cc-react-select",
        props.useStandardStyling && "standard-react-select",
        props.className
      )}
      classNamePrefix={props.classNamePrefix ?? fallbackPrefixClass}
      options={options}
      styles={props.styles}
      placeholder={props.placeHolder}
      onChange={props.passBack}
      isSearchable={props.isSearchable}
      onMenuOpen={props.onMenuOpen}
      onMenuClose={props.onMenuClose}
    />
  );
};

export default ReactSelect;

interface TeamPickerProps {
  className?: String;
  styles?: any;
  placeHolder?: String;
  passBack: (value: number) => void;
}

export const TeamPick = ({
  className,
  styles,
  placeHolder,
  passBack,
}: TeamPickerProps) => {
  var options;

  const { data: teamsData, isLoading: isLoadingTeamsData } = useTeamsByFunction(
    {
      extraOpts: {
        functionName: "Goal Sharing",
        operation: "ocVIEW",
      },
      isEnabled: true,
      onError: () => {},
      onSuccess: (data) => {},
    }
  );

  options = teamsData?.teams.map((team) => ({
    label: team.name,
    value: team.teamID,
  }));

  return (
    <Select
      className={cx("cc-react-select", className)}
      options={options}
      styles={styles}
      placeholder={placeHolder}
      onChange={updateTeamID}
    />
  );

  function updateTeamID(data: { label: string; value: number } | null) {
    if (data !== null && data.value !== null) {
      passBack(data.value);
    } else {
      console.log("Error");
    }
  }
};
