import * as React from "react";
import { BarChart as CCChart, Button } from "components/common/";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
} from "chart.js";
import cx from "classnames";

import "../style.scss";

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement);

interface Props {
  summaryData?: TytoData.PPF.Dashboard.Summary;
  startDate: Date;
  endDate: Date;
  screenWidth: number;
}

const barOptions = {
  responsive: true,
  scales: {
    x: {
      grid: {
        color: "transparent",
      },
    },
    y: {
      borderColor: "#ffffff",
      min: 0,
      ticks: {
        stepSize: 1,
      },
      grid: {
        borderColor: "rgba(255, 255, 255, 0.1)",
        color: "rgba(255, 255, 255, 0.1)",
      },
    },
  },
};

const StatisticsDisplay = (props: Props) => {
  const dateDif = Math.floor(
    (props.endDate.getTime() - props.startDate.getTime()) /
      (1000 * 60 * 60 * 24)
  );

  const actionColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--action")
    .replace(" ", "");

  const greyStroke = getComputedStyle(document.documentElement)
    .getPropertyValue("--grey-light-bg")
    .replace(" ", "");

  const monthDif =
    props.endDate.getMonth() -
    props.startDate.getMonth() +
    12 * (props.endDate.getFullYear() - props.startDate.getFullYear());

  var barLabels: string[] = new Array();
  var createdData: number[] = new Array();
  var measureData: number[] = new Array();

  const [activeButton, updateActiveButton] = React.useState("both");

  if (dateDif <= 31) {
    for (var i = 0; i <= dateDif; i++) {
      var milsec = props.startDate.getTime();
      var newDate = new Date();
      newDate.setTime(milsec);
      newDate.setDate(newDate.getDate() + i);
      var dateData = props.summaryData?.dateSeries?.[i];

      createdData.push(dateData?.countDistinctAboutGoalsCreated ?? 0);
      measureData.push(dateData?.countDistinctAboutGoalsUpdated ?? 0);
      barLabels.push(newDate.getDate().toString());
    }
  } else if (monthDif < 12) {
    for (var i = 0; i <= monthDif; i++) {
      var milsec = props.startDate.getTime();
      var newDate = new Date();
      newDate.setTime(milsec);
      newDate.setMonth(newDate.getMonth() + i);
      var cCount = 0;
      var mCount = 0;
      var matchingDates = props.summaryData?.dateSeries?.filter(
        (date) => newDate.getMonth() === new Date(date.histDate).getMonth()
      );
      matchingDates?.forEach(
        (item) => (
          (cCount += item.countDistinctAboutGoalsCreated),
          (mCount += item.countDistinctAboutGoalsUpdated)
        )
      );
      createdData.push(cCount);
      measureData.push(mCount);
      barLabels.push(switchToStringMonth(newDate.getMonth()));
    }
  } else {
    var index = 0;
    for (
      var i = props.startDate.getFullYear();
      i <= props.endDate.getFullYear();
      i++
    ) {
      var milsec = props.startDate.getTime();
      var newDate = new Date();
      newDate.setTime(milsec);
      newDate.setFullYear(newDate.getFullYear() + index);
      var cCount = 0;
      var mCount = 0;
      var matchingDates = props.summaryData?.dateSeries?.filter(
        (date) =>
          newDate.getFullYear() === new Date(date.histDate).getFullYear()
      );
      matchingDates?.forEach(
        (item) => (
          (cCount += item.countDistinctAboutGoalsCreated),
          (mCount += item.countDistinctAboutGoalsUpdated)
        )
      );
      createdData.push(cCount);
      measureData.push(mCount);
      barLabels.push(newDate.getFullYear().toString());
      index++;
    }
  }

  const [barData, updateBarData] = React.useState([createdData, measureData]);
  const [barColors, updateBarColors] = React.useState([
    actionColor,
    greyStroke,
  ]);
  const [legendLabels, updateLegendLabels] = React.useState([
    "Goals Created",
    "Goals Updated",
  ]);

  return (
    <div className="statistics-display-cont">
      <div
        className={cx(
          "statistics-display-pie-graphs",
          activeButton === "both" || props.screenWidth > 800
            ? "both-doughnut"
            : ""
        )}
      >
        {activeButton === "both" && props.screenWidth < 801 && (
          <>
            <div className="statistics-display-pie-graph-goalsCreated">
              {createSVGGraph(
                151,
                69,
                actionColor,
                10,
                props.summaryData?.countPersons ?? 0,
                props.summaryData?.countDistinctAboutGoalsCreated ?? 0,
                "have created a goal"
              )}
              <div className="statistics-display-pie-graph-text">
                <h1>
                  {props.summaryData?.countDistinctAboutGoalsCreated ?? 0}/
                  {props.summaryData?.countPersons ?? 0}
                </h1>
                <p>Users</p>
              </div>
            </div>
            <div className="statistics-display-pie-graph-line"></div>
            <div className="statistics-display-pie-activity">
              {createSVGGraph(
                151,
                69,
                greyStroke,
                10,
                props.summaryData?.countPersons ?? 0,
                props.summaryData?.countDistinctAboutGoalsUpdated ?? 0,
                "have updated a goal"
              )}
              <div className="statistics-display-pie-graph-text">
                <h1>
                  {props.summaryData?.countDistinctAboutGoalsUpdated ?? 0}/
                  {props.summaryData?.countPersons ?? 0}
                </h1>
                <p>Users</p>
              </div>
            </div>
          </>
        )}
        {activeButton === "goalsCreated" && props.screenWidth < 801 && (
          <div className="statistics-display-pie-graph-goalsCreated solo-doughnut">
            <div>
              {createSVGGraph(
                213,
                101,
                actionColor,
                10,
                props.summaryData?.countPersons ?? 0,
                props.summaryData?.countDistinctAboutGoalsCreated ?? 0,
                "have created a goal"
              )}
            </div>
            <div className="statistics-display-pie-graph-text">
              <div>
                <h1>
                  {props.summaryData?.countDistinctAboutGoalsCreated ?? 0}/
                  {props.summaryData?.countPersons ?? 0}
                </h1>
                <p>Users</p>
              </div>
            </div>
          </div>
        )}
        {activeButton === "goalActivity" && props.screenWidth < 801 && (
          <div className="statistics-display-pie-activity solo-doughnut">
            <div className="statistics-display-pie-graph-text">
              <div>
                <h1>
                  {props.summaryData?.countDistinctAboutGoalsUpdated ?? 0}/
                  {props.summaryData?.countPersons ?? 0}
                </h1>
                <p>Users</p>
              </div>
            </div>
            <div>
              {createSVGGraph(
                213,
                101,
                greyStroke,
                10,
                props.summaryData?.countPersons ?? 0,
                props.summaryData?.countDistinctAboutGoalsUpdated ?? 0,
                "have updated a goal"
              )}
            </div>
          </div>
        )}
        {props.screenWidth > 800 && (
          <>
            <div
              className={cx(
                "statistics-display-pie-graph-goalsCreated",
                activeButton === "goalsCreated" ? "solo-doughnut-desktop" : "",
                activeButton === "goalActivity" ? "blur-filter" : ""
              )}
            >
              {createSVGGraph(
                activeButton === "goalsCreated" ? 274 : 213,
                activeButton === "goalsCreated" ? 131 : 101,
                actionColor,
                10,
                props.summaryData?.countPersons ?? 0,
                props.summaryData?.countDistinctAboutGoalsCreated ?? 0,
                "have created a goal"
              )}
              <div className="statistics-display-pie-graph-text">
                <h1>
                  {props.summaryData?.countDistinctAboutGoalsCreated ?? 0}/
                  {props.summaryData?.countPersons ?? 0}
                </h1>
                <p>Users</p>
              </div>
            </div>
            <div className="statistics-display-pie-graph-line"></div>
            <div
              className={cx(
                "statistics-display-pie-activity",
                activeButton === "goalActivity" ? "solo-doughnut-desktop" : "",
                activeButton === "goalsCreated" ? "blur-filter" : ""
              )}
            >
              {activeButton === "goalActivity" && (
                <div className="statistics-display-pie-graph-text">
                  <h1>
                    {props.summaryData?.countDistinctAboutGoalsUpdated ?? 0}/
                    {props.summaryData?.countPersons ?? 0}
                  </h1>
                  <p>Users</p>
                </div>
              )}
              {createSVGGraph(
                activeButton === "goalActivity" ? 274 : 213,
                activeButton === "goalActivity" ? 131 : 101,
                greyStroke,
                10,
                props.summaryData?.countPersons ?? 0,
                props.summaryData?.countDistinctAboutGoalsUpdated ?? 0,
                "have updated a goal"
              )}
              {activeButton !== "goalActivity" && (
                <div className="statistics-display-pie-graph-text">
                  <h1>
                    {props.summaryData?.countDistinctAboutGoalsUpdated ?? 0}/
                    {props.summaryData?.countPersons ?? 0}
                  </h1>
                  <p>Users</p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div className="statistics-display-button-line"></div>
      <div className="statistics-graph-selection">
        <Button
          theme={activeButton === "goalsCreated" ? "white" : "white-ghost"}
          value="Goals Created"
          style={{ marginRight: "6px" }}
          onClick={() => {
            updateBarData([createdData]);
            updateBarColors([actionColor]);
            updateLegendLabels(["Goals Created"]);
            updateActiveButton("goalsCreated");
          }}
        />
        <Button
          theme={activeButton === "both" ? "white" : "white-ghost"}
          value="Both"
          style={{ marginRight: "6px" }}
          onClick={() => {
            updateBarData([createdData, measureData]);
            updateBarColors([actionColor, greyStroke]);
            updateLegendLabels(["Goals Created", "Goals Updated"]);
            updateActiveButton("both");
          }}
        />
        <Button
          theme={activeButton === "goalActivity" ? "white" : "white-ghost"}
          value="Goal Activity"
          style={{ marginRight: "6px" }}
          onClick={() => {
            updateBarData([measureData]);
            updateBarColors([greyStroke]);
            updateLegendLabels(["Goals Updated"]);
            updateActiveButton("goalActivity");
          }}
        />
      </div>
      <CCChart
        labels={barLabels}
        data={barData}
        options={barOptions}
        colors={barColors}
        legendLabels={legendLabels}
        borderRadius={50}
      />
    </div>
  );

  function switchToStringMonth(number: number) {
    switch (number) {
      case 0:
        return "Jan";
      case 1:
        return "Feb";
      case 2:
        return "Mar";
      case 3:
        return "Apr";
      case 4:
        return "May";
      case 5:
        return "Jun";
      case 6:
        return "Jul";
      case 7:
        return "Aug";
      case 8:
        return "Sep";
      case 9:
        return "Oct";
      case 10:
        return "Nov";
      case 11:
        return "Dec";
      default:
        return "Error";
    }
  }
};

function createSVGGraph(
  size: number,
  radius: number,
  strokeColor: string,
  strokeWidth: number,
  total: number,
  complete: number,
  innerText: string
) {
  const percentComplete = complete / total;
  const percentUncomplete = 1 - percentComplete;
  const circumference = 2 * Math.PI * radius;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      className="donut"
    >
      <circle
        className="donut-hole"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="transparent"
      ></circle>
      <circle
        className="donut-ring"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="transparent"
        stroke="#474747"
        strokeWidth={strokeWidth}
      ></circle>
      {total !== 0 && complete !== 0 && (
        <circle
          className="donut-segment"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="transparent"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeDasharray={`${percentComplete * circumference} ${
            percentUncomplete * circumference
          }`}
          strokeDashoffset={circumference / 4}
          strokeLinecap="round"
        ></circle>
      )}
      <g className="donut-text-cont">
        <text y="50%" transform="translate(0, 2)">
          <tspan
            x="50%"
            textAnchor="middle"
            className="donut-percent"
            fontSize={`${size * 0.23841059602}px`}
          >
            {Math.round(percentComplete * 100)}%
          </tspan>
        </text>
        <text y="60%" transform="translate(0, 2)">
          <tspan
            x="50%"
            textAnchor="middle"
            className="donut-text"
            fontSize={`${size * 0.059602649}px`}
          >
            {innerText}
          </tspan>
        </text>
      </g>
    </svg>
  );
}
export default StatisticsDisplay;
