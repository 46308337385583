/*
 * Component Description
 * Searches plans and displays the plan object
 */

import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";
import { Redirect } from "react-router-dom";

import {
  Link,
  Message,
  TextButton,
  SearchInput,
  TextHighlighter,
  Timestamp,
  UserThumbByID,
} from "components/common/";
import { usePPFPlans } from "data/network/hooks/";
import { createPPFPlan } from "data/network/special-calls/";
import { INTERFACE_PATHS, SUB_INTERFACE_PATHS } from "data/constants/";
// import { StoreContext as GeneralStoreContext } from "data/stores/GeneralStore";

import "./style.scss";

const APRIL_FIRST = new Date("04/01/2022");
// const ONE_HOUR_AGO = new Date(
//   +NOW - timeInMS({ timeQuantity: 1, timeType: "hours" })
// );

function filterPlans({
  plans,
  searchTerm,
}: {
  plans?: TytoData.PPF.Plans.Plan[];
  searchTerm: string;
}) {
  if (!plans) {
    return [];
  } else if (!searchTerm) {
    return plans;
  }

  const searchTermEscaped = _.escapeRegExp(searchTerm);

  return plans.filter((plan) => {
    const regExp = new RegExp(searchTermEscaped, "i");

    return regExp.test(plan?.about?.elementName ?? "");
  });
}

function getPlansUserIsMemberOf(plans?: TytoData.PPF.Plans.Plan[]) {
  if (!plans?.length) {
    return [];
  }

  return plans.filter((plan) => !!plan?.permission?.gsMemberID);
}

interface Props {
  className?: string;
  isInMenu?: boolean;
  GeneralStore: StoreAPI.GeneralStoreProps;
  updateIsSearching?: (showSearching?: boolean) => void;
}

const SearchMyPlans = (props: Props) => {
  const [redirectURL, updateRedirectURL] = React.useState("");
  const [creatingPlan, updateCreatingPlan] = React.useState(false);
  const [isPending, startTansition] = React.useTransition();
  const [searchTerm, updateSearchTerm] = React.useState("");
  const [usersPlans, updateUsersPlans] = React.useState<
    TytoData.PPF.Plans.Plan[] | null
  >(null);
  const [searchResults, updateSearchResults] = React.useState<
    TytoData.PPF.Plans.Plan[]
  >([]);

  const plansQuery = usePPFPlans({
    onSuccess: (plansResp) => {
      const { gsPlans } = plansResp ?? {};

      const myPlans = getPlansUserIsMemberOf(gsPlans);

      updateUsersPlans(myPlans);
    },
  });

  const onSearchTermChange = (newVal: string) => {
    updateSearchTerm(newVal);

    startTansition(() => {
      if (!newVal) {
        updateSearchResults([]);
      } else {
        updateSearchResults(
          filterPlans({ searchTerm: newVal, plans: usersPlans ?? [] })
        );
      }
    });
  };

  React.useEffect(() => {
    onSearchTermChange(searchTerm);
  }, [plansQuery?.eagerData]);

  //   const plansByUserID = React.useMemo(() => {
  //     return _.keyBy(plans, "aboutID");
  //   }, [plans]);

  React.useEffect(() => {
    if (redirectURL && props.isInMenu) {
      window.location.href = redirectURL;
      props.updateIsSearching?.(false);
    }
  }, [redirectURL]);

  if (redirectURL && !props.isInMenu) {
    return <Redirect to={redirectURL} />;
  }

  const noData = !usersPlans && plansQuery.isLoading;

  const searchResultsSmart = searchTerm ? searchResults : usersPlans;

  return (
    <div
      className={cx(
        "cm-menu-contents-wrapper cm-menu-main-search-wrapper",
        creatingPlan && "creating-plan"
      )}
    >
      <section className="cm-menu-top-cont-search">
        <form
          className="cm-menu-top-search-top"
          onSubmit={(e: any) => {
            if (creatingPlan) {
              return;
            }

            updateSearchTerm(`${searchTerm}`);
            e.preventDefault?.();
          }}
        >
          <SearchInput
            autoFocus={true}
            containerClassName="cm-menu-search-input-wrapper"
            className="cm-menu-search-input cm-menu-mypeople-search-input title-font"
            disabled={creatingPlan || noData}
            placeholder="Search Your People"
            name="Search"
            onChange={onSearchTermChange}
            // onChange={(newVal) => updateSearchTerm(newVal)}
            value={searchTerm}
          />
          {props.updateIsSearching && (
            <TextButton
              className="cm-menu-search-cancel"
              value="Cancel"
              onClick={() => props.updateIsSearching?.(false)}
            />
          )}
        </form>
      </section>

      <section className="cm-menu-inner-cont cm-menu-search-details">
        {noData ? (
          <Message text="Loading People and Plans..." />
        ) : (
          <>
            {!!searchTerm || searchResultsSmart ? (
              <>
                {!!searchResultsSmart?.length ? (
                  <ul className="cm-menu-search-wrapper-off">
                    {searchResultsSmart.map((plan, curIdx: number) => (
                      <SingleResult
                        idx={curIdx}
                        creating={creatingPlan}
                        GeneralStore={props.GeneralStore}
                        plan={plan}
                        key={plan.gsPlanID}
                        searchTerm={searchTerm}
                        updateCreating={updateCreatingPlan}
                        updateRedirectURL={updateRedirectURL}
                        updateIsSearching={props.updateIsSearching}
                      />
                    ))}
                  </ul>
                ) : (
                  <p className="cm-menu-search-instruction">
                    No people found
                    {!!searchTerm && ` matching "${searchTerm ?? ""}"`}
                  </p>
                )}
              </>
            ) : (
              <div className="cm-menu-search-instruction">
                You are not a Member of any Plans.
              </div>
            )}
          </>
        )}
      </section>
    </div>
  );
};

interface SingleResultProps {
  idx: number;
  plan?: TytoData.PPF.Plans.Plan;
  GeneralStore: StoreAPI.GeneralStoreProps;
  creating?: boolean;
  searchTerm: string;
  updateCreating: (isCreating: boolean) => void;
  updateRedirectURL: (path: string) => void;
  updateIsSearching?: (showSearching?: boolean) => void;
}

const SingleResult = (
  {
    GeneralStore,
    idx,
    creating,
    plan,
    searchTerm,
    updateIsSearching,
  }: SingleResultProps,
  props: Props
) => {
  const isMobile = !!GeneralStore.state?.isMobile;
  const [userPlanPath] = React.useState(() => {
    return plan?.gsPlanID
      ? `${INTERFACE_PATHS.PLAN}/${plan?.gsPlanID}/${SUB_INTERFACE_PATHS.PLAN.GOALS}`
      : "";
  });
  const [formattedLastChangedDate] = React.useState(() => {
    if (!plan?.lastChangeDate) {
      return "";
    }

    const asDate = new Date(plan.lastChangeDate);

    return new Intl.DateTimeFormat("en-US")?.format?.(asDate) ?? "";
  });

  if (!plan) {
    return null;
  }

  const manageAccessMsg = getManageAccessMsg(plan);

  return (
    <Link
      className="cm-menu-search-result-link"
      disabled={creating || !userPlanPath}
      type="link"
      href={`${userPlanPath}`}
      value={plan?.about?.elementName ?? ""}
    >
      <li
        className="cm-menu-search-card"
        key={`${plan?.gsPlanID || idx}`}
        onClick={() => {
          updateIsSearching?.(false);
        }}
      >
        <UserThumbByID
          className="cm-menu-search-thumbnail"
          size={isMobile ? 50 : 40}
          userID={plan.aboutID ?? 0}
          userName={plan?.about?.elementName ?? ""}
        />

        <div className="cm-menu-search-card-right">
          <div className="cm-menu-search-card-right-inner-cont">
            <p className="cm-menu-search-link-user">
              <TextHighlighter
                highlightClassName=""
                searchTerm={searchTerm}
                text={plan?.about?.elementName ?? ""}
                type="hightlight"
              />
            </p>

            {!!plan && !!plan?.permission?.goalAdd && (
              <Link
                className="cm-menu-search-link-create"
                href={`${INTERFACE_PATHS.PLAN}/${plan.gsPlanID ?? 0}/${
                  SUB_INTERFACE_PATHS.PLAN.NEW_GOAL
                }`}
                onClickCapture={(e) => {
                  e.stopPropagation?.();
                }}
                disabled={creating}
                value={!isMobile ? "Create Goals" : "Create"}
              />
            )}
          </div>
          {manageAccessMsg && (
            <span className="cm-menu-search-privileges">{manageAccessMsg}</span>
          )}
          {manageAccessMsg && formattedLastChangedDate && (
            <span className="cm-menu-search-lastchanged-date">
              Last updated {formattedLastChangedDate}
            </span>
          )}
        </div>
      </li>
    </Link>
  );
};

function getManageAccessMsg(plan: any) {
  let per = plan?.permission ?? {};
  if (
    per.planChange === true &&
    per.goalAdd === true &&
    per.goalDelete === true &&
    per.memberChange === true
  ) {
    return "You have access";
  }
  if (per.planChange === false) {
    return "Managed by someone else";
  }

  return "";
}

export default SearchMyPlans;
