/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { Redirect } from "react-router-dom";

import {
  INTERFACE_PATHS,
  SUB_INTERFACE_PATHS,
  INTERFACE_SEARCH_PARAMS,
} from "data/constants/";
import { StoreContext as GeneralStoreContext } from "data/stores/GeneralStore";
import { usePPFParentGoalClarifier } from "data/helpers/Hooks";
import { useGoalMutation } from "data/network/hooks/";
import API from "data/network/tyto/";
import * as RQ_CACHE_HELPERS from "data/helpers/react-query-cache";

import { BackNav } from "components/meta/sub-navs";
import { Message, Button } from "components/common/";
import { EditGoal } from "components/goal/";

import "./style.scss";

interface Props extends PlanPage.PlanData {
  targetGoalID?: number;
  isMemberOfPlan: boolean;
  isLoading: boolean;
}

const EditGoalWrapper = (props: Props) => {
  const [childGoal, updateChildGoal] = React.useState(() => {
    return pullChildGoalFromGoalsList({
      targetGoalID: props.targetGoalID,
      planGoals: props.planGoals,
    });
  });
  const [parentGoal, updateParentGoal] = React.useState(() => {
    return getParentGoal({
      parentGoalID: childGoal?.gsParentGoalID,
      planGoals: props.planGoals,
    });
  });

  React.useEffect(() => {
    const newChildGoal = pullChildGoalFromGoalsList({
      targetGoalID: props.targetGoalID,
      planGoals: props.planGoals,
    });

    updateChildGoal(newChildGoal);
    updateParentGoal(
      getParentGoal({
        parentGoalID: newChildGoal?.gsParentGoalID,
        planGoals: props.planGoals,
      })
    );
  }, [props.targetGoalID, props.planGoals]);

  const theme = usePPFParentGoalClarifier(parentGoal);
  const [redirectURL, updateRedirectURL] = React.useState<string | null>(null);

  if (redirectURL) {
    return <Redirect to={redirectURL} />;
  }

  return (
    <article className="plan-editgoal-wrapper">
      {!!props.isMemberOfPlan ? (
        <>
          {!childGoal || !props.plan ? (
            <Message text="Loading..." />
          ) : (
            <PlanEditGoal
              childGoal={childGoal}
              plan={props.plan}
              parentGoal={parentGoal}
              goalID={childGoal?.gsGoalID}
              theme={theme ?? "personal"}
              parentGoals={props.planGoals}
              planGoalsQuery={props.planGoalsQuery}
            />
          )}
        </>
      ) : (
        <div className="plan-edit-blocker-wrapper">
          {!props.isLoading && (
            <>
              <h2>You cannot edit a goal when you are not a manager.</h2>
              <Button
                className="plan-edit-blocker-btn"
                type="button"
                value="Become a Manager"
                theme={"action"}
                onClick={() => {
                  updateRedirectURL(
                    `${INTERFACE_PATHS.PLAN}/${props.plan?.gsPlanID}/${SUB_INTERFACE_PATHS.PLAN.PERMISSIONS}`
                  );
                }}
              />
            </>
          )}
        </div>
      )}
    </article>
  );
};

function pullChildGoalFromGoalsList({
  planGoals,
  targetGoalID,
}: {
  planGoals?: TytoData.PPF.Plan.Goals.ParentGoal[];
  targetGoalID?: number;
}) {
  if (!planGoals?.length || !targetGoalID) {
    return undefined;
  }

  const allChildGoals = _.flatten(
    planGoals.map((parentGoal) => parentGoal.childGoals)
  );

  return allChildGoals.find(({ gsGoalID }) => gsGoalID === targetGoalID);
}

function getParentGoal({
  planGoals,
  parentGoalID,
}: {
  planGoals?: TytoData.PPF.Plan.Goals.ParentGoal[];
  parentGoalID?: number;
}) {
  if (!planGoals?.length || !parentGoalID) {
    return undefined;
  }

  return (planGoals ?? []).find(({ gsGoalID }) => gsGoalID === parentGoalID);
}

// interface GoalPutData {
//   goalText: string;
//   goalDesc: string;
//   parentGoalID: number;
//   goalEndDate: string;
//   newSteps?: TytoData.PPF.Plan.Goals.GoalItem[];
//   updatedExistingSteps?: TytoData.PPF.Plan.Goals.GoalItem[];
// }

interface PlanEditGoalProps extends Pick<PlanPage.PlanData, "planGoalsQuery"> {
  plan: TytoData.PPF.Plan.Plan;
  childGoal: TytoData.PPF.Plan.Goals.ChildGoal;
  goalID: number;
  theme: keyof typeof TytoData.PPFParentGoalName;
  parentGoal?: TytoData.PPF.Plan.Goals.ParentGoal;
  parentGoals?: TytoData.PPF.Plan.Goals.ParentGoal[];
}

const PlanEditGoal = (props: PlanEditGoalProps) => {
  const GeneralStore = React.useContext(GeneralStoreContext);

  const [uploadingImage, updateUploadingImage] = React.useState(false);
  const [redirectURL, updateRedirectURL] = React.useState("");

  const goalMutation = useGoalMutation({
    childGoal: props.childGoal,
    onError: () => {},
    onSuccess: async (payload) => {
      const planID = props.plan.gsPlanID;

      const { newItems, updatedItems } = payload ?? {};
      console.log("NEWITEMS: ", newItems, " UPDATEDITEMS: ", updatedItems);
      debugger;

      try {
        // * If steps were supplied, iterate over list and create each step
        // * using newly created GoalID from successful Goal 'POST' request
        if (newItems?.length) {
          const newGoalItemsResps = await Promise.allSettled(
            newItems.map(({ description, status, seq, itemWeight }) =>
              API.PPF.Goal.Item.post({
                gsGoalID: props.childGoal.gsGoalID,
                description,
                itemWeight,
                seq,
                status: status ?? "ocNOTSTARTED",
              })
            )
          );

          if (newGoalItemsResps.length) {
            await Promise.allSettled(
              newGoalItemsResps.map((settledGoalItemPostResps, curIdx) => {
                if (settledGoalItemPostResps.status !== "fulfilled") {
                  return Promise.resolve(0);
                }

                return API.PPF.Goal.Item.put({
                  gsGoalItemKey: settledGoalItemPostResps?.value?.gsGoalItemKey,
                  itemWeight: newItems[curIdx]?.itemWeight ?? 0,
                });
              })
            );
          }
        }

        // * If updates for Existing Goal Items, iterate over and make 'PUT'
        // * request for each item
        if (updatedItems?.length) {
          console.log("updatedItems: ", updatedItems);
          await Promise.all(
            updatedItems.map((existingStep) =>
              API.PPF.Goal.Item.put(
                _.pick(existingStep, [
                  "description",
                  "gsGoalItemKey",
                  "itemWeight",
                  "seq",
                  "status",
                ])
              )
            )
          );
        }

        if (planID) {
          // RQ_CACHE_HELPERS.invalidateGoalsCache(planID);

          await props.planGoalsQuery.refetch();

          updateRedirectURL(
            `${INTERFACE_PATHS.PLAN}/${planID}${
              props.childGoal?.gsGoalID
                ? `/${SUB_INTERFACE_PATHS.PLAN.GOALS}/${
                    props.childGoal?.gsGoalID
                  }?${INTERFACE_SEARCH_PARAMS.PLAN.GOAL_STATUS_FILTER}=${
                    props.childGoal.targetStatus === "ocCOMPLETE"
                      ? "completed"
                      : "ocNOTSTARTED"
                  }`
                : ""
            }`
          );
        }
      } catch (err) {
        if (planID) {
          RQ_CACHE_HELPERS.invalidateGoalsCache(planID);

          updateRedirectURL(
            `${INTERFACE_PATHS.PLAN}/${planID}${
              props.childGoal?.gsGoalID
                ? `/${SUB_INTERFACE_PATHS.PLAN.GOALS}/${
                    props.childGoal?.gsGoalID
                  }?${INTERFACE_SEARCH_PARAMS.PLAN.GOAL_STATUS_FILTER}=${
                    props.childGoal.targetStatus === "ocCOMPLETE"
                      ? "completed"
                      : "ocNOTSTARTED"
                  }`
                : ""
            }`
          );
        }
      }
    },
  });

  const onCancel = () => {
    const planID = props.plan.gsPlanID;
    if (planID) {
      RQ_CACHE_HELPERS.invalidateGoalsCache(planID);

      updateRedirectURL(
        `${INTERFACE_PATHS.PLAN}/${planID}${
          props.childGoal?.gsGoalID
            ? `/${SUB_INTERFACE_PATHS.PLAN.GOALS}/${
                props.childGoal?.gsGoalID
              }?${INTERFACE_SEARCH_PARAMS.PLAN.GOAL_STATUS_FILTER}=${
                props.childGoal.targetStatus === "ocCOMPLETE"
                  ? "completed"
                  : "ocNOTSTARTED"
              }`
            : ""
        }`
      );
    }
  };

  if (redirectURL) {
    return <Redirect to={redirectURL} />;
  }

  return (
    <article className="plan-editgoal-wrapper">
      {/* <BackNav
        onBack={() => {
          updateRedirectURL(
            `${INTERFACE_PATHS.PLAN}/${props.childGoal.gsPlanID}/${
              SUB_INTERFACE_PATHS.PLAN.GOALS
            }/${props.childGoal.gsGoalID}?${
              INTERFACE_SEARCH_PARAMS.PLAN.GOAL_STATUS_FILTER
            }=${
              props.childGoal.targetStatus === "ocCOMPLETE"
                ? "completed"
                : "ocNOTSTARTED"
            }`
          );
        }}
        title="Edit Goal"
      /> */}

      <EditGoal
        creating={uploadingImage || goalMutation.isLoading}
        formType="edit"
        goalTitle={props.childGoal.name ?? "Goal"}
        goalDesc={props.childGoal.impact ?? ""}
        parentGoal={props.parentGoal}
        goalTargetStatus={props.childGoal.targetStatus}
        onCancel={onCancel}
        goalDuration={props.childGoal.durationYears}
        goalCreatedDate={props.childGoal.createdDate}
        legacySteps={props.childGoal.items}
        onSave={(goalData) => {
          if (goalMutation.isLoading) {
            return;
          }

          goalMutation.mutate({
            durationYears: goalData.goalDuration,
            targetStatus: goalData.targetStatus,
            gsParentGoalID: goalData.gsParentGoalID,
            impact: goalData.goalDesc,
            items: goalData.items,
            gsGoalName: goalData.goalTitle,
            newItems: goalData.newGoalSteps,
            profileImageID: goalData.profileImageID,
            templateImageKey: goalData.newTemplateImageKey,
            updatedItems: goalData.updatedExistingGoalSteps,
            uploadGUID: goalData.uploadGUID,
            targetDateFinal: goalData.targetDateFinal,
            plan: props.plan,
          });
        }}
        goalEndDate={
          props.childGoal.targetDateFinal
            ? props.childGoal.targetDateFinal
            : props.childGoal.targets?.slice?.(-1)?.[0]?.targetDate
        } // * A lot of Question Marks...
        plan={props.plan}
        profileImage={props.childGoal.profileImageAsset}
        steps={props.childGoal.gsGoalItems}
        templateImageKey={props.childGoal.profileImageKey}
        parentGoalType={props.theme}
        updateCreating={updateUploadingImage}
        parentGoals={props.parentGoals}
      />
    </article>
  );
};

export default EditGoalWrapper;
