/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import _ from "lodash";
import { Redirect } from "react-router-dom";

import {
  INTERFACE_PATHS,
  SUB_INTERFACE_PATHS,
  INTERFACE_SEARCH_PARAMS,
  GOAL_IMAGE_RATIOS,
} from "data/constants";
import {
  Icon,
  Link,
  MenuButton,
  ProgressBar,
  TextButton,
  ReactSelect,
  DatePicker,
  CheckBox,
  Message,
} from "components/common/";
import API from "data/network/tyto/";
import { getEncoding, makePathURLQualified } from "data/helpers/";
import { StoreContext as GeneralStoreContext } from "data/stores/GeneralStore";
import { useGoalNotices, usePPFTemplateImageURL } from "data/helpers/Hooks";
import {
  useGoalMemberAddMutation,
  useGoalMutation,
  useGoalDeleteMutation,
} from "data/network/hooks/";
import { runPermissionCheck } from "data/helpers/react-query-cache";
import { launchPhotoSwipe } from "data/helpers/photo-swipe";
import { PhotoSwipeHTML } from "components/meta";
import {
  startFireworks,
  // fireConfettiInRandomDirection,
} from "data/utils/funfetti";
import {
  getGoalImageURL,
  getStatusOptions,
  getFriendlyStatus,
} from "../../utils/";

import { GoalItems } from "components/goal/";

import NoticeThreadsWrapper, {
  DEFAULT_NOTICES_ARR,
} from "./NoticeThreadWrapper";

// import NoticeThreads from "../notice-threads/";

import "./style.scss";
import ChildGoalsList from "interfaces/plan/subcomponents/child-goals-list";
import GoalWeight from "./GoalWeight";

const PS_GOAL_IMAGE_ID = "photoswipe-goal-image";

export interface Props {
  animation?: GoalStories.AnimationName;
  domID?: string;
  plan: TytoData.PPF.Plan.Plan;
  childGoal: TytoData.PPF.Plan.Goals.ChildGoal;
  newMessageInputRef: React.MutableRefObject<HTMLInputElement | null>;
  newStepInputRef: React.MutableRefObject<HTMLInputElement | null>;
  planNotices?: TytoData.Notices.Notice[];
  permissions: TytoData.PPF.Plan.MemberPermissionData;
  goToNextGoal: () => void;
  theme?: keyof typeof TytoData.PPFParentGoalName;
  statusFilter?: string;
  planChangelog?: Array<TytoData.GoalChangeLogItem>;
  isMemberOfPlan: boolean;
  isMobile: boolean;
}

const CurrentGoal = ({
  animation,
  childGoal,
  domID,
  newMessageInputRef,
  newStepInputRef,
  planNotices,
  permissions,
  plan,
  theme,
  statusFilter,
  planChangelog,
  isMemberOfPlan,
}: Props) => {
  const GeneralStore = React.useContext(GeneralStoreContext);
  //   const notices = useGoalNotices({
  //     noticeBoard: goalNotices,
  //     goalID: childGoal.gsGoalID,
  //   });
  const [photoSwipeID, updatePhotoSwipeID] = React.useState(() => {
    return makePhotoSwipeGoalImageID(childGoal);
  });
  const [redirectURL, updateRedirectURL] = React.useState("");
  const [errorMsg, updateErrorMsg] = React.useState("");
  const [goalYear, updateGoalYear] = React.useState(() => {
    if (childGoal.durationYears > 0) {
      return childGoal.durationYears;
    }

    const savedDate =
      childGoal.targets?.[(childGoal.targets?.length ?? 0) - 1]?.targetDate;

    var finalDate = (
      savedDate ? new Date(savedDate) : new Date()
    ).getFullYear();
    var startDate = new Date(childGoal.createdDate).getFullYear();
    return finalDate - startDate;
  });
  const [dateForDatePicker, updateDateForDatePicker] = React.useState(() => {
    return (
      childGoal.targets?.[(childGoal.targets?.length ?? 0) - 1]?.targetDate ??
      new Date().toISOString()
    );
  });
  const [commentHistoryDisplaying, updateCommentHistoryDisplaying] =
    React.useState(false);
  const isMobile = !!(GeneralStore.state?.isMobile ?? false);

  const goalID = _.get(childGoal, "gsGoalID", 0);
  const planID = _.get(plan, "gsPlanID", 0);

  const goalDeleteMutation = useGoalDeleteMutation({
    gsGoalID: goalID,
    planID: planID,
    onError: (newErrorMsg) => {
      updateErrorMsg(newErrorMsg);
    },
    onSuccess: () => {},
  });

  const stepsContainerRef = React.useRef<null | HTMLDivElement>(null);

  const stepContainerHeight =
    stepsContainerRef.current?.getBoundingClientRect?.().height ?? 0;
  const goalMutation = useGoalMutation({
    childGoal: childGoal,
    onError: () => {},
    onSuccess: (payload) => {
      if (
        payload?.targetStatus === "ocCOMPLETE" &&
        payload.targetStatus !== childGoal.targetStatus
      ) {
        startFireworks({
          fireworksDisplayDuration: 3_500,
          timeBetweenEachFireworkInMS: 300,
        });
      }
    },
  });

  const [goalImageURL, updateGoalImageURL] = React.useState(() => {
    return getGoalImageURL(childGoal);
  });

  const [filteredSteps, updateFilteredSteps] = React.useState(() => {
    var filtered = childGoal.gsGoalItems.filter(
      (step) => step.status !== "ocDISABLED"
    );
    return filtered.sort((a, b) => a.seq - b.seq);
  });

  React.useEffect(() => {
    updateGoalImageURL(getGoalImageURL(childGoal));
  }, [childGoal.profileImageAsset]);

  const { templateSquareImageURL } = usePPFTemplateImageURL(
    childGoal.profileImageKey
  );

  React.useEffect(() => {
    const safeRead =
      childGoal.targets?.[(childGoal.targets?.length ?? 0) - 1]?.targetDate;
    const safeDate = safeRead ?? new Date().toISOString();

    updateDateForDatePicker(safeDate as any);

    // updateDateForDatePicker(
    //   childGoal.targets[childGoal.targets.length - 1].targetDate
    // );
    var filtered = childGoal.gsGoalItems.filter(
      (step) => step.status !== "ocDISABLED"
    );
    updateFilteredSteps(filtered.sort((a, b) => a.seq - b.seq));
    if (childGoal.durationYears > 0) {
      updateGoalYear(childGoal.durationYears);
    } else {
      const safeRead =
        childGoal.targets?.[(childGoal.targets?.length ?? 0) - 1]?.targetDate;

      var finalDate = (
        safeRead ? new Date(safeRead) : new Date()
      ).getFullYear();
      var startDate = new Date(childGoal.createdDate).getFullYear();
      updateGoalYear(finalDate - startDate);
    }

    updatePhotoSwipeID(makePhotoSwipeGoalImageID(childGoal));
  }, [childGoal]);

  const [goalName, lastWordOfGoalName] = React.useMemo(() => {
    const wordsAsArr = (childGoal.name ?? "Goal").split(" ");

    const lastWord = wordsAsArr.slice(-1)[0];
    const start = (
      wordsAsArr.length < 2 ? [] : wordsAsArr.slice(0, wordsAsArr.length - 1)
    ).join(" ");

    return [start ?? "", lastWord ?? ""];
  }, [childGoal.name]);

  const goalNotices = useGoalNotices({
    noticeBoard: planNotices ?? DEFAULT_NOTICES_ARR,
    goalID: childGoal.gsGoalID,
  });

  const messagesCount = React.useMemo(() => {
    return goalNotices.reduce((newCount, notice) => {
      return newCount + 1 + (notice.comments?.length ?? 0);
    }, 0);
  }, [goalNotices]);

  const bgSrcURL = templateSquareImageURL || goalImageURL;

  function startGoalDelete(gsGoalID: number) {
    try {
      const confirmed = window.confirm(
        `Are you sure you want to delete this goal. This action is not reversible.`
      );

      if (confirmed) {
        goalDeleteMutation.mutate({ gsGoalID });
        updateRedirectURL(`${INTERFACE_PATHS.HOME}`);
      } else {
        updateRedirectURL(
          //WIP Redirect goes to correct interface but does not load the data again. - routing home gives correct result
          // `${INTERFACE_PATHS.PLAN}/${plan.gsPlanID}/${SUB_INTERFACE_PATHS.PLAN.GOALS}/${childGoal.gsGoalID}`
          `${INTERFACE_PATHS.HOME}`
        );
      }
    } catch (err) {
      const errorMsg = _.get(err, "msg", "Error Occurred.");
      updateErrorMsg(errorMsg);
    }
  }

  if (redirectURL) {
    return <Redirect to={redirectURL} />;
  }

  return (
    <div
      id={domID}
      className={cx("cg-goalstories-currentgoal-cont", animation)}
    >
      <section className="cg-goalstories-currentgoal-image-and-details">
        <div className="cg-goalstories-currentgoal-details">
          {/* <section className="cg-goalstories-currentgoal-type-year">
            <div className="cg-goalstories-currentgoal-type">
              {theme ? theme[0].toUpperCase() + theme.substring(1) : "error"}
            </div>
            <div className="cg-goalstories-currentgoal-year">
              {goalYear} year{goalYear > 1 ? "s" : ""}
            </div>
          </section> */}
          <div className="cg-goalstories-currentgoal-title-cont">
            <h2 className="cg-goalstories-currentgoal-title">
              {goalName ?? ""}{" "}
              <span style={{ display: "inline-block" }}>
                {lastWordOfGoalName ?? "Goal"}{" "}
                {!!isMemberOfPlan ? (
                  <MenuButton
                    className="cg-goalstories-currentgoal-ctx-menu"
                    menuItems={[
                      {
                        as: "link",
                        label: "Edit goal",
                        href: `${INTERFACE_PATHS.PLAN}/${plan.gsPlanID}/${SUB_INTERFACE_PATHS.PLAN.EDIT_GOAL}/${childGoal.gsGoalID}`,
                      },
                      {
                        as: "link",
                        label: statusFilter
                          ? "View Active Goals"
                          : "View Completed Goals",
                        href: statusFilter
                          ? `${INTERFACE_PATHS.PLAN}/${plan.gsPlanID}/${SUB_INTERFACE_PATHS.PLAN.GOALS}`
                          : `${INTERFACE_PATHS.PLAN}/${plan.gsPlanID}/${SUB_INTERFACE_PATHS.PLAN.GOALS}?${INTERFACE_SEARCH_PARAMS.PLAN.GOAL_STATUS_FILTER}=completed`,
                      },
                      {
                        as: "link",
                        label: "View Permissions",
                        href: `${INTERFACE_PATHS.PLAN}/${plan.gsPlanID}/${SUB_INTERFACE_PATHS.PLAN.PERMISSIONS}/${childGoal.gsGoalID}`,
                      },
                      {
                        as: "link",
                        label: "Change Photo",
                        href: `${INTERFACE_PATHS.PLAN}/${plan.gsPlanID}/${SUB_INTERFACE_PATHS.PLAN.EDIT_GOAL}/${childGoal.gsGoalID}`,
                      },
                      {
                        as: "link",
                        label: "Delete Goal",
                        onSelect: () => {
                          startGoalDelete(goalID);
                        },
                      },
                      {
                        as: "link",
                        label: "Help",
                        href: `${INTERFACE_PATHS.HELP}`,
                      },
                    ]}
                  >
                    <Icon
                      className="cg-goalstories-currentgoal-title-btn"
                      size={isMobile ? 22 : 34}
                      icon="elipsis-v"
                    />
                  </MenuButton>
                ) : null}
              </span>
            </h2>
          </div>

          {childGoal?.impact && (
            <p className="cg-goalstories-currentgoal-desc">
              {childGoal.impact}
            </p>
          )}
          {childGoal.durationYears === -1 && (
            <div className="cg-goalstories-currentgoal-noduration-msg-cont">
              <span className="cg-goalstories-currentgoal-noduration-msg">Please edit this goal and set a Goal Duration</span>
            </div>
          )}
          <div className="cg-goalstories-currentgoal-details-extra-details-dueDate">
            <span className="cg-goalstories-currentgoal-details-extra-details-label">
              Due Date{" "}
            </span>
            {isMemberOfPlan ? (
              <DatePicker
                className="cg-goalstories-currentgoal-details-extra-details-dueDate-picker"
                date={dateForDatePicker}
                disabled={true}
                type="date"
                onChange={(date) => {
                  if (date) {
                    goalMutation.mutate({
                      targetDateFinal: date?.toISOString(),
                    });
                    updateDateForDatePicker(date?.toISOString());
                  }
                }}
              />
            ) : (
              (childGoal.targets?.[(childGoal.targets?.length ?? 0) - 1]
                ?.targetDate
                ? new Date(
                    childGoal.targets?.[
                      (childGoal.targets?.length ?? 0) - 1
                    ]?.targetDate
                  )
                : new Date()
              )
                .toLocaleString()
                .split(",")[0]
            )}
          </div>
          <div className="cg-goalstories-currentgoal-details-extra-details">
            <div className="cg-goalstories-currentgoal-details-extra-details-status">
              <span className="cg-goalstories-currentgoal-details-extra-details-label">
                This goal is{" "}
              </span>
              {isMemberOfPlan ? (
                <ReactSelect
                  placeHolder={getFriendlyStatus(childGoal.targetStatus)}
                  labels={getStatusOptions()}
                  key={childGoal.gsGoalID}
                  values={["ocATRISK", "ocNOTSTARTED", "ocCOMPLETE"]}
                  passBack={(value) => {
                    switch (value.value) {
                      case "ocATRISK":
                        goalMutation.mutate({ targetStatus: "ocATRISK" });
                        break;
                      case "ocNOTSTARTED":
                        goalMutation.mutate({ targetStatus: "ocNOTSTARTED" });
                        break;
                      case "ocCOMPLETE":
                        goalMutation.mutate({ targetStatus: "ocCOMPLETE" });
                        break;
                    }
                  }}
                  className="cg-goalstories-currentgoal-imgsteps-details-toggles-cont-selector details-goal-status"
                  classNamePrefix="details-goal-status"
                  isSearchable={false}
                />
              ) : (
                getFriendlyStatus(childGoal.targetStatus)
              )}
            </div>
            {!isMobile && (
              <div className="cg-goalstories-currentgoal-details-extra-details-vertical-bar"></div>
            )}
            <div className="cg-goalstories-currentgoal-details-extra-details-created">
              <span className="cg-goalstories-currentgoal-details-extra-details-label" />
              created {getTimeCreatedAgo(childGoal.createdDate)} ago
            </div>
          </div>
        </div>
        <div
          className={cx(
            "cg-goalstories-currentgoal-image-cont",
            !bgSrcURL && "no-image"
          )}
          // style={{ backgroundImage: bgSrcURL ? `url(${bgSrcURL})` : "" }}
        >
          {bgSrcURL ? (
            <img
              role="button"
              onClick={(e) => {
                let img: Data.Asset | Data.GenericImage | undefined =
                  childGoal.profileImageAsset;

                if (!img && childGoal.profileImageKey) {
                  img = {
                    src: bgSrcURL,
                    height: GOAL_IMAGE_RATIOS.PORTRAIT.height,
                    width: GOAL_IMAGE_RATIOS.PORTRAIT.width,
                    title: childGoal.profileImageKey,
                    _type: "generic",
                  } as Data.GenericImage;
                }

                launchPhotoSwipe({
                  idx: 0,
                  ref: e.currentTarget,
                  pswpUniqueID: photoSwipeID,
                  assets: img ? [img] : [],
                  targetEncodingType: "ocDEFAULT",
                });
              }}
              className="cg-goalstories-currentgoal-image"
              src={bgSrcURL}
            />
          ) : (
            <div className="cg-goalstories-currentgoal-image no-image" />
          )}
        </div>

        <PhotoSwipeHTML
          className="cg-goalstories-currentgoal-image-photoswipe"
          pswpUniqueID={photoSwipeID}
        />
      </section>
      <GoalWeight gsGoalID={childGoal.gsGoalID} goalSteps={filteredSteps} />
      <section className="cg-goalstories-currentgoal-steps-chat">
        <div
          className="cg-goalstories-currentgoal-steps-cont"
          ref={stepsContainerRef}
        >
          <h1 className="cg-goalstories-currentgoal-steps-title">TASKS</h1>
          {childGoal.items && (
            <div className="cg-goalstories-currentgoal-legacy-steps">
              <div>
                <h3 className="cg-goalstories-currentgoal-legacy-steps-title">Steps (deprecated)</h3>
              </div>
              <p className="cg-goalstories-currentgoal-legacy-steps-msg">Please edit this goal to move the legacy "steps to achieve" into tasks.</p>
              <div className="cg-goalstories-currentgoal-legacy-steps-renderbox" dangerouslySetInnerHTML={{ __html: childGoal.items }}></div>
            </div>
          )}
          {filteredSteps.length > 0 ? (
            <ul className="cg-goalstories-currentgoal-steps">
              {filteredSteps.map((step) => {
                return (
                  <li
                    className="cg-goalstories-currentgoal-step"
                    key={step.gsGoalItemKey}
                  >
                    <CheckBox
                      className="cg-goalstories-currentgoal-step-checkbox"
                      label={step.description}
                      isChecked={step.status === "ocCOMPLETE"}
                      onChange={(newCheckedValue) => {
                        API.PPF.Goal.Item.put({
                          gsGoalItemKey: step.gsGoalItemKey,
                          status: newCheckedValue
                            ? "ocCOMPLETE"
                            : "ocNOTSTARTED",
                        });
                        const tempSteps = [...filteredSteps];
                        tempSteps.forEach((innerstep) => {
                          if (innerstep.gsGoalItemKey === step.gsGoalItemKey) {
                            innerstep.status = newCheckedValue
                              ? "ocCOMPLETE"
                              : "ocNOTSTARTED";
                          }
                        });
                        updateFilteredSteps(tempSteps);
                      }}
                      size={20}
                      theme={theme}
                      disabled={!isMemberOfPlan}
                    />
                    <p className="cg-goalstories-currentgoal-step-desc">
                      {step.description}
                    </p>
                  </li>
                );
              })}
            </ul>
          ) : (
            <Message
              className="cg-goalstories-currentgoal-no-steps-msg"
              text="No Tasks"
            />
          )}
        </div>
        {commentHistoryDisplaying ? (
          <div
            className="cg-goalstories-currentgoal-chat-cont cg-goalstories-currentgoal-chat-cont-opened"
            style={{
              maxHeight: `${
                stepContainerHeight > 500 ? stepContainerHeight : 500
              }px`,
            }}
          >
            <NoticeThreadsWrapper
              childGoal={childGoal}
              isMemberOfPlan={isMemberOfPlan}
              isMobile={isMobile}
              newMessageInputRef={newMessageInputRef}
              permissions={permissions}
              planNotices={planNotices ?? []}
              planChangelog={planChangelog ?? []}
              plan={plan}
              maxHeight={
                stepContainerHeight > 500 - 152
                  ? stepContainerHeight
                  : 500 - 152
              }
              passbackOpenStatus={(open) => {
                updateCommentHistoryDisplaying(open);
              }}
              //152 reperesents the height of the other elements in the notice thread
            />
          </div>
        ) : (
          <div className="cg-goalstories-currentgoal-chat-cont cg-goalstories-currentgoal-chat-cont-unopened">
            {isMobile ? (
              <Icon
                buttonProps={{
                  className: cx(
                    "chat-textedit-modaled-msg-icon",
                    !messagesCount && "no-comments"
                  ),
                  ...({ ["data-msgcount"]: messagesCount } as any),
                }}
                isButton={true}
                icon="message"
                size={34}
                onClick={() => {
                  updateCommentHistoryDisplaying(true);
                }}
              />
            ) : (
              <>
                <p className="cg-goalstories-currentgoal-chat-cont-unopened-text">
                  View Goal Comments & History
                </p>
                <Icon
                  className="cg-goalstories-currentgoal-chat-cont-unopened-button-icon"
                  buttonProps={{
                    className:
                      "cg-goalstories-currentgoal-chat-cont-unopened-button",
                  }}
                  icon="angle-up"
                  size={isMobile ? 20 : 28}
                  isButton={true}
                  onClick={() => {
                    updateCommentHistoryDisplaying(true);
                  }}
                />
              </>
            )}
          </div>
        )}
      </section>
    </div>
  );
};

function calcCompleteItemsCountData(
  childGoal: TytoData.PPF.Plan.Goals.ChildGoal
) {
  if (!childGoal?.gsGoalItems?.length) {
    return {
      completedCount: 0,
      totalCount: 1,
      percentComplete: 0,
    };
  }

  const completedCount = childGoal.gsGoalItems.reduce(
    (completedCount, goalItem) => {
      return completedCount + (goalItem?.status === "ocCOMPLETE" ? 1 : 0);
    },
    0
  );

  return {
    completedCount,
    totalCount: childGoal.gsGoalItems.length,
    percentComplete: Math.floor(
      (completedCount / (childGoal.gsGoalItems.length || 1)) * 100
    ),
  };
}

interface GoalProgressDataProps {
  isMobile?: boolean;
  childGoal: TytoData.PPF.Plan.Goals.ChildGoal;
  newStepInputRef: React.MutableRefObject<HTMLInputElement | null>;
  permissions: TytoData.PPF.Plan.MemberPermissionData;
  theme?: keyof typeof TytoData.PPFParentGoalName;
  isMemberOfPlan: boolean;
}

export const GoalProgressData = (props: GoalProgressDataProps) => {
  const [listShowing, updateListShowing] = React.useState<
    "items" | "status" | null
  >(null);
  const [itemsData, updateItemsData] = React.useState(() => {
    return calcCompleteItemsCountData(props.childGoal);
  });

  const goalMutation = useGoalMutation({
    childGoal: props.childGoal,
    onError: () => {},
    onSuccess: () => {},
  });

  React.useEffect(() => {
    updateItemsData(calcCompleteItemsCountData(props.childGoal));
  }, [props.childGoal.gsGoalItems]);

  return (
    <>
      <div className="cg-goalstories-currentgoal-imgsteps-details-cont">
        {props.isMobile && (
          <div className="cg-goalstories-currentgoal-imgsteps-details-toggles-cont">
            <Link
              type="button"
              onClick={() => {
                if (listShowing === "items") {
                  updateListShowing(null);
                } else {
                  updateListShowing("items");
                }
              }}
              value={`${itemsData.percentComplete}% Complete`}
            >
              {itemsData.percentComplete}% Complete{" "}
              <Icon
                icon={listShowing === "items" ? "angle-up" : "angle-down"}
                size={14}
              />
            </Link>

            <Link
              type="button"
              onClick={() => {
                if (listShowing === "status") {
                  updateListShowing(null);
                } else {
                  updateListShowing("status");
                }
              }}
              value={`${props.childGoal.targetStatus}`}
            >
              <GoalStatus
                key={props.childGoal.gsGoalID}
                goalStatus={props.childGoal.targetStatus}
              />
              <Icon
                icon={listShowing === "status" ? "angle-up" : "angle-down"}
                size={14}
              />
            </Link>
          </div>
        )}

        {!props.isMobile && (
          <div className="cg-goalstories-currentgoal-imgsteps-details-toggles-cont">
            <span>{itemsData.percentComplete}% Complete</span>
            {props.isMemberOfPlan ? (
              <ReactSelect
                placeHolder={getFriendlyStatus(props.childGoal.targetStatus)}
                labels={getStatusOptions()}
                values={["ocATRISK", "ocNOTSTARTED", "ocCOMPLETE"]}
                passBack={(value) => {
                  switch (value.value) {
                    case "ocATRISK":
                      goalMutation.mutate({ targetStatus: "ocATRISK" });
                      break;
                    case "ocNOTSTARTED":
                      goalMutation.mutate({ targetStatus: "ocNOTSTARTED" });
                      break;
                    case "ocCOMPLETE":
                      goalMutation.mutate({ targetStatus: "ocCOMPLETE" });
                      break;
                  }
                }}
                className="cg-goalstories-currentgoal-imgsteps-details-toggles-cont-selector"
                classNamePrefix="details-goal-status"
                isSearchable={false}
                styles={{
                  option: (style: CSSStyleDeclaration) => ({
                    ...style,
                    backgroundColor: "transparent",
                    color: "var(--font-color)",
                  }),
                  control: (style: CSSStyleDeclaration) => ({
                    ...style,
                    backgroundColor: "transparent",
                    color: "var(--font-color)",
                    borderColor: "transparent ",
                    width: "160px",
                    height: "24px",
                    textAlign: "right",
                    // boxShadow: "0 0 0 1px var(--focus-outline-color)",
                    "&:focus": {
                      borderColor: "var(--focus-outline-color)",
                    },
                    "&:hover": {
                      borderColor: "var(--focus-outline-color)",
                    },
                  }),
                  singleValue: (style: CSSStyleDeclaration) => ({
                    ...style,
                    color: "var(--font-color)",
                    overflow: "visable",
                    textAlign: "right",
                  }),
                  menuList: (style: CSSStyleDeclaration) => ({
                    ...style,
                  }),
                  menu: (style: CSSStyleDeclaration) => ({
                    ...style,
                    backgroundColor: "var(--bg-color)",
                    color: "var(--font-color)",
                    paddingTop: "0px",
                    marginTop: "0px",
                    height: "fit-content",
                    width: "160px",
                    border: "none",
                    textAlign: "right",
                  }),
                  placeholder: (style: CSSStyleDeclaration) => ({
                    ...style,
                    color: "var(--font-color)",
                  }),
                  dropdownIndicator: (style: CSSStyleDeclaration) => ({
                    ...style,
                    transform: listShowing === "status" ? "rotate(180deg)" : "",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }),
                  indicatorSeparator: () => ({
                    width: "0px",
                  }),
                  valueContainer: (style: CSSStyleDeclaration) => ({
                    ...style,
                    paddingRight: "0px",
                  }),
                }}
                onMenuOpen={() => {
                  updateListShowing("status");
                }}
                onMenuClose={() => {
                  updateListShowing(null);
                }}
              />
            ) : (
              getFriendlyStatus(props.childGoal.targetStatus)
            )}
          </div>
        )}

        {!props.isMobile && (
          <ProgressBar
            className="cg-goalstories-currentgoal-imgsteps-details-progbar"
            theme={props.theme}
            progressCount={itemsData.completedCount}
            totalCount={itemsData.totalCount || 1}
            progressDecimal={itemsData.percentComplete / 100}
            height={3}
          />
        )}

        {(listShowing === "items" || !props.isMobile) && (
          <div className="">
            <GoalItems
              isMemberOfPlan={props.isMemberOfPlan}
              canAdd={true} //true
              childGoal={props.childGoal}
              newStepInputRef={props.newStepInputRef}
              parentGoalType={props.theme}
            />
          </div>
        )}

        {listShowing === "status" && props.isMobile && (
          <div className="">
            {props.isMemberOfPlan ? (
              <ul className="cg-goalstories-currentgoal-status-options">
                <li>
                  <TextButton
                    className=""
                    disabled={goalMutation.isLoading}
                    onClick={() => {
                      runPermissionCheck({
                        planID: props.childGoal.gsPlanID,
                        onCancel: () => {
                          console.log("Cancelled Request");
                        },
                        onError: (errorMsg) => {
                          console.log("ERROR: ", errorMsg);
                          window.alert(`FAILED: ${errorMsg}`);
                        },
                        onSuccess: () => {
                          goalMutation.mutate({ targetStatus: "ocATRISK" });
                        },
                      });
                    }}
                    value="At Risk"
                  >
                    <GoalStatus goalStatus={"ocATRISK"} />
                  </TextButton>
                </li>

                <li>
                  <TextButton
                    className=""
                    disabled={goalMutation.isLoading}
                    onClick={() => {
                      goalMutation.mutate({ targetStatus: "ocNOTSTARTED" });
                    }}
                    value="On Track"
                  >
                    <GoalStatus goalStatus={"ocNOTSTARTED"} />
                  </TextButton>
                </li>

                <li>
                  <TextButton
                    className=""
                    disabled={goalMutation.isLoading}
                    onClick={() => {
                      goalMutation.mutate({ targetStatus: "ocCOMPLETE" });
                    }}
                    value="Completed"
                  >
                    <GoalStatus goalStatus={"ocCOMPLETE"} />
                  </TextButton>
                </li>
              </ul>
            ) : null}
          </div>
        )}
      </div>

      {props.isMobile && !!itemsData.completedCount && (
        <ProgressBar
          className="cg-goalstories-currentgoal-imgsteps-details-progbar"
          theme={props.theme}
          progressCount={itemsData.completedCount}
          totalCount={itemsData.totalCount}
          progressDecimal={itemsData.percentComplete / 100}
          height={3}
        />
      )}
    </>
  );
};

function getTimeCreatedAgo(goalCreatedDate: string) {
  const createdDate = new Date(goalCreatedDate);
  const currentDate = new Date();
  var difference = 0;
  if (currentDate.getFullYear() !== createdDate.getFullYear()) {
    difference = currentDate.getFullYear() - createdDate.getFullYear();
    return `${difference} year${difference > 1 ? "s" : ""}`;
  } else if (currentDate.getMonth() !== createdDate.getMonth()) {
    difference = currentDate.getMonth() - createdDate.getMonth();
    return `${difference} month${difference > 1 ? "s" : ""}`;
  } else if (currentDate.getDate() !== createdDate.getDate()) {
    difference = currentDate.getDate() - createdDate.getDate();
    return `${difference} day${difference > 1 ? "s" : ""}`;
  } else if (currentDate.getHours() !== createdDate.getHours()) {
    difference = currentDate.getHours() - createdDate.getHours();
    return `${difference} hour${difference > 1 ? "s" : ""}`;
  } else if (currentDate.getMinutes() !== createdDate.getMinutes()) {
    difference = currentDate.getMinutes() - createdDate.getMinutes();
    return `${difference} minute${difference > 1 ? "s" : ""}`;
  } else {
    difference = currentDate.getSeconds() - createdDate.getSeconds();
    return `${difference > 0 ? difference : 1} second${
      difference > 1 ? "s" : ""
    }`;
  }
}

function makePhotoSwipeGoalImageID(childGoal: Props["childGoal"]) {
  return `${PS_GOAL_IMAGE_ID}-${childGoal.gsGoalID ?? 0}`;
}

interface GoalStatusProps {
  goalStatus: TytoData.PPF.Plan.Plan["targetStatus"];
}

const GoalStatus = (props: GoalStatusProps) => {
  const [friendlyStatus, updateFriendlyStatus] = React.useState(() => {
    return getFriendlyStatus(props.goalStatus);
  });

  React.useEffect(() => {
    updateFriendlyStatus(getFriendlyStatus(props.goalStatus));
  }, [props.goalStatus]);

  return <span className="">{friendlyStatus}</span>;
};

export default CurrentGoal;
