import { ENDPOINT_GS_CHANGELOG } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_GS_CHANGELOG;

export const GSChangelog = {
  get(params: Endpoints.Tyto.gsChangelog.GetParameters, callOpts?: CallOpts) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.gsChangelog.Get>;
  },
};
