import {
  REACT_QUERY_KEYS,
  PPF_ROLES_BY_NAME,
  INTERFACE_PATHS,
  SUB_INTERFACE_PATHS,
} from "data/constants/";
import _ from "lodash";

import {
  DEFAULT_PPF_MEMBER_ADD_DATA,
  ENDPOINT_GS_TEMPLATE_IMAGES,
} from "data/constants/";
import { SessionHandling } from "data/storage";
import * as QueryCacheHelpers from "data/helpers/react-query-cache";
import { pullMessageFromError } from "data/helpers/";

import { queryClient } from "..";
import API from "../tyto";

/**
 * Retrieves Config object from Server, giving preference to previously loaded data
 * @param {Boolean | Undefined} avoidCache - Whether it should try to use cached data if such is available
 * @returns Configuration Client Object of Undefined
 */
export async function getClientConfig(avoidCache?: boolean) {
  try {
    if (!avoidCache) {
      const cachedConfig: TytoData.Configuration.Client | undefined =
        queryClient.getQueryData(REACT_QUERY_KEYS.CLIENT_CONFIG, {
          stale: false,
        });

      if (cachedConfig) {
        return cachedConfig;
      }
    }

    const config = await API.Configuration.Client.get({});

    queryClient.setQueryData(REACT_QUERY_KEYS.CLIENT_CONFIG, config);

    return config;
  } catch (err) {
    // TODO: Handle error
  }
}

/**
 * Retrieve URL that should be used for Uploading a File
 * but use Cached Data if available
 */
export async function getPPFTemplateImagesURL(avoidCache?: boolean) {
  try {
    // * Note: This was expected to be retrieved from another config file, but such is currently not the case
    return `${ENDPOINT_GS_TEMPLATE_IMAGES}`;
    // const clientConfig = await getClientConfig(avoidCache);

    // @ts-ignore
    // return clientConfig?.gsPPFImagesURL ?? `${URL_ORIGIN}/-p/gsPPFimages.json`;
  } catch (err) {
    // TODO: Handle error better?
    return undefined;
  }
}

/**
 * Retrieve URL that should be used for Uploading a File
 * but use Cached Data if available
 */
export async function getUploadURL(avoidCache?: boolean) {
  try {
    const clientConfig = await getClientConfig(avoidCache);

    return clientConfig?.uploadServices?.[0];
  } catch (err) {
    // TODO: Handle error better?
    return undefined;
  }
}

export async function uploadFiles(
  files?: Array<File | Blob>
): Promise<{ errorMsg?: string; uploadResp?: TytoData.Upload }> {
  try {
    if (!files?.length) {
      throw Error("No Files were supplied. Cannot upload nothing.");
    }

    const uploadURL = await getUploadURL();

    if (!uploadURL) {
      const isPlural = (files?.length ?? 2) > 1;

      throw Error(
        `Cannot determine where to upload File${isPlural ? "s" : ""}`
      );
    }

    const uploadResp = await API.Upload.post({ endpointURL: uploadURL, files });

    return {
      uploadResp,
    };
  } catch (err: any) {
    // * NOTE: actual types for 'err' are: (Endpoints.ResponseError | Error | string)

    let errorMsg = typeof err === "string" ? err : undefined;

    if (!errorMsg) {
      if ((err as Error)?.message) {
        errorMsg = (err as Error).message;
      } else if (
        (err as Endpoints.ResponseError)?.msg ||
        (err as Endpoints.ResponseError)?.technical
      ) {
        errorMsg =
          (err as Endpoints.ResponseError)?.msg ??
          (err as Endpoints.ResponseError)?.technical;
      }
    }

    return {
      errorMsg: `${errorMsg}`,
    };
  }
}

export async function getPPFTemplateImages() {
  // try {
  const getURL = await getPPFTemplateImagesURL();

  if (!getURL) {
    throw Error(`Cannot determine PPFTemplateImages .json file location.`);
  }

  const imagesJSONResp = await API.PPF.TemplateImages.get({
    endpointURL: getURL,
  });

  const data =
    typeof imagesJSONResp === "string"
      ? (JSON.parse(
          imagesJSONResp
        ) as Endpoints.Responses.PPF.TemplateImages.Get)
      : imagesJSONResp;

  return data;
  // } catch (err: any) {
  //   // * NOTE: actual types for 'err' are: (Endpoints.ResponseError | Error | string)

  //   let errorMsg = typeof err === "string" ? err : undefined;

  //   if (!errorMsg) {
  //     if ((err as Error)?.message) {
  //       errorMsg = (err as Error).message;
  //     } else if (
  //       (err as Endpoints.ResponseError)?.msg ||
  //       (err as Endpoints.ResponseError)?.technical
  //     ) {
  //       errorMsg =
  //         (err as Endpoints.ResponseError)?.msg ??
  //         (err as Endpoints.ResponseError)?.technical;
  //     }
  //   }

  //   return {
  //     errorMsg: `${errorMsg}`,
  //   };
  // }
}

export async function updatePlanPermsBeforeProceeding({
  memberID,
  planID,
  scopeData,
  onCancel,
  onError,
  onSuccess,
}: {
  memberID: number;
  planID: number;
  scopeData?: Omit<
    Endpoints.Tyto.GS.Member.PostParameters,
    "memberID" | "gsPlanID"
  >;
  onCancel: () => void;
  onError: (errorMSg: any) => void;
  onSuccess: () => void;
}) {
  try {
    const confirmed = window.confirm(
      `In order to make this action you must first become a member of this plan. But proceeding, you will be added as a Member of this plan.`
    );

    if (confirmed) {
      const memberAddedResp = await API.PPF.Member.post({
        gsPlanID: planID,
        memberID,
        ...DEFAULT_PPF_MEMBER_ADD_DATA,
        ...(scopeData ?? {}),
      });

      if (memberAddedResp?.memberID) {
        QueryCacheHelpers.invalidateMembersCache(planID);
      }

      onSuccess();
    } else {
      onCancel();
    }
  } catch (err) {
    onError(pullMessageFromError(err));
  }
}

// * CREATE PPF PLAN STUFF.....
const DEFAULT_PARENT_GOAL_NAMES = ["Personal", "Professional", "Financial"];

const DEFAULT_PLAN_DATA = {
  gsPlanType: "ocPPF",
  isLocked: false,
  description1: "",
  aboutType: "ocPERSON",
};

const DEFAULT_GOAL_DATA: {
  goalType: keyof typeof TytoData.GoalType;
  targetStatus: keyof typeof TytoData.GoalStatus;
  weight: number;
} = {
  goalType: "ocCOMPOSITE",
  targetStatus: "ocNOTSTARTED",
  weight: 1,
};

export async function createPPFPlan({
  personID,
  personName,
  startDate,
  teamRootID,
  onError,
  onSuccess,
}: {
  // person: TytoData.AdvancedPerson;
  personID: number;
  personName: string;
  teamRootID: number;
  startDate: Date;
  onError: (errorMsg: string) => void;
  onSuccess: (gsPlanID: number, redirectURL: string) => void;
}) {
  if (!startDate || (startDate.getFullYear?.() ?? NaN) === NaN) {
    onError("Bad Start Date. Could not trigger Plan creation.");
  }

  try {
    const startYear = startDate.getFullYear();
    const startDateFormatted = startDate.toISOString();
    const endDate = new Date(startDate);
    endDate.setFullYear(startDate.getFullYear() + 100);
    const endDateFormatted = endDate.toISOString();

    const planName = `${startYear}, ${personName}`;
    const nowAsString = new Date().toISOString();

    const planResp = await API.GS.Plan.post({
      ...DEFAULT_PLAN_DATA,
      gsPlanName: planName,
      gsPlanDesc: planName,
      description1: nowAsString,
      teamRoot: teamRootID,
      aboutID: personID,
      startTime: startDateFormatted,
      endTime: endDateFormatted,
    });

    if (personID !== SessionHandling.getPropertyFromActiveSession("userID")) {
      await API.GS.Member.post({
        // ...DEFAULT_PPF_MEMBER_ADD_DATA,
        ...PPF_ROLES_BY_NAME.MANAGER.permissions,
        gsPlanID: planResp.gsPlanID,
        memberID: personID,
      });
    }

    // * NOTE: WIP
    await Promise.all(
      DEFAULT_PARENT_GOAL_NAMES.map((goalName) =>
        API.GS.Goal.post({
          ...DEFAULT_GOAL_DATA,
          targetDateFinal: endDateFormatted,
          gsPlanID: planResp.gsPlanID,
          gsGoalDesc: `${goalName}`,
          gsGoalName: `${goalName}`,
        })
      )
    );

    const plansResp = await API.PPF.Plans.get({});
    QueryCacheHelpers.setRQPlansData(plansResp);

    onSuccess(
      planResp.gsPlanID,
      `${INTERFACE_PATHS.PLAN}/${planResp.gsPlanID ?? 0}/${
        SUB_INTERFACE_PATHS.PLAN.NEW_GOAL
      }`
    );
  } catch (err) {
    onError(pullMessageFromError(err));
  }
}
