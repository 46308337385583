/*
 * Store for Toast State - Stores message type string and a text message to be read on any interface
 */
import * as React from "react";
import * as _ from "lodash";

let StoreContext: React.Context<StoreAPI.ToastStoreProps> = React.createContext(
  {}
);

let initialState: StoreAPI.ToastStoreState = {
  // messages: message[];
  message: {
    toastMessage: "",
    toastMessageType: "",
  },
};

// Reduces kind of state then returns it.. ready to go into Provider
let reducer = (
  state: StoreAPI.ToastStoreState,
  action: StoreAPI.StoreAction<StoreAPI.ToastStoreActionType>
) => {
  console.log(action);
  switch (action.type) {
    case "TOAST_STORE_MESSAGE":
      //TODO might need a rework
      // const messageObj = action.payload.message;

      return {
        ...state,
        message: {
          toastMessage: action.payload.message.toastMessage,
          toastMessageType: action.payload.message.toastMessageType,
        },
        // message: action.payload.message,
        // messagesList: [messageObj, ...messageObj],
      };
    default:
      return state;
  }
};
function StoreContextProvider(props: any) {
  // [A]
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };

  // [B]
  return (
    <StoreContext.Provider value={value}>
      {props.children}
    </StoreContext.Provider>
  );
}

let ToastContextConsumer = StoreContext.Consumer;

// [C]
// Provider is sending state out.
// ToastContextConsumer is setting up ToastContext with createContext ref Consumer
export { StoreContext, StoreContextProvider, ToastContextConsumer };
