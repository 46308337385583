import * as React from "react";
import { TextButton, UserThumb } from "../../../components/common/";

import "./ExistingSessions.scss";

interface Props {
  authResults: TytoData.AuthResult[];
  onCancel: () => void;
  onChoose: (authResult: TytoData.AuthResult) => void;
}

const MultipleSessionsFound = (props: Props) => {
  return (
    <section className="login-existing-sessions-cont">
      <ul className="login-existing-sessions-list">
        {props.authResults.map((authResult) => (
          <li className="login-existing-sessions-list-item">
            <button
              className="login-existing-sessions-list-item-button"
              onClick={() => {
                props.onChoose(authResult);
              }}
            >
              <UserThumb
                className="login-existing-sessions-list-item-userthumb"
                userName={authResult.authSession.userName}
                size={22}
              />

              <span className="login-existing-sessions-list-item-username">
                {authResult.authSession.userName}
                {!!authResult.authSession.domainID && (
                  <span className="login-existing-sessions-list-item-domainid font-size-tiny">
                    {authResult.authSession.domainID}
                  </span>
                )}
              </span>
            </button>
          </li>
        ))}
      </ul>

      <TextButton
        className="login-existing-sessions-showothers font-size-small"
        onClick={props.onCancel}
        value="Cancel"
      />
    </section>
  );
};

export default MultipleSessionsFound;
