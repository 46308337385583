/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import API from "data/network/tyto/";
import { Menu, Nav } from "components/meta/";
import { GenericNav } from "components/meta/sub-navs/";

import PlanCreation from "./PlanCreation";

import "./style.scss";

function parseUserID(userID?: string | number) {
  if (!userID) {
    return undefined;
  }

  const parsedID = parseInt(`${userID}`, 10);

  if (!parsedID || parsedID === NaN) {
    return undefined;
  }

  return parsedID;
}

interface Props extends SITE.GeneralStoreInjectedProps {
  userID?: string;
  subInterface?: PlanPage.Subinterface;
}

const PlanCreationWrapper = (props: Props) => {
  const [userID, updateUserID] = React.useState(parseUserID(props.userID));
  const [menuHasBeenOpen, updateMenuHasBeenOpen] = React.useState(
    _.get(props, "GeneralStore.state.showMenu", false)
  );

  const storeMenuOpenValue = _.get(props, "GeneralStore.state.showMenu", false);

  // * If config client has not previously loaded, do so now
  React.useEffect(() => {
    const uploadURL = props.GeneralStore.state?.uploadURL;

    if (!uploadURL) {
      API.Configuration.Client.get({})
        .then((resp) => {
          props.GeneralStore.dispatch?.({
            payload: {
              configurationClient: resp,
            },
            type: "GENERAL_STORE_CONFIGURATION_CLIENT_LOADED",
          });
        })
        .catch((err) => {
          // TODO: Handle error
        });
    }
  }, []);

  // * Trigger flag so that turning menu off animates back left
  React.useEffect(() => {
    if (!menuHasBeenOpen && storeMenuOpenValue) {
      updateMenuHasBeenOpen(true);
    }
  }, [storeMenuOpenValue, menuHasBeenOpen]);

  const menuIsOpen = storeMenuOpenValue;
  const canCreatePlan = true; // TODO: Implement actual logic to determine if such is true...

  return (
    <div className={cx("interface-wrapper", menuIsOpen && "menu-open")}>
      {/* <Nav className="profile-nav" /> */}
      <Menu className="profile-menu" />

      <div
        className={cx(
          "interface-main-cont interface-createplan",
          menuHasBeenOpen && "animate-back"
        )}
      >
        <GenericNav></GenericNav>
        {canCreatePlan ? (
          <PlanCreation
            isMobile={_.get(props, "GeneralStore.state.isMobile", false)}
            GeneralStore={props.GeneralStore}
            userID={userID}
          />
        ) : (
          <div>You do not have permission to Create a PPF Plan.</div>
        )}
      </div>
    </div>
  );
};

export default PlanCreationWrapper;
