/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Nav, Menu } from "components/meta/";

import Home from "./Home";

import "./style.scss";

interface Props extends SITE.GeneralStoreInjectedProps {}

const HomeWrapper = (props: Props) => {
  const bgVideoElement = React.useRef<HTMLVideoElement | null>(null);
  const [showSearch, updateShowSearch] = React.useState(false);
  const [searchTerm, updateSearchTerm] = React.useState("");
  const [menuHasBeenOpen, updateMenuHasBeenOpen] = React.useState(
    _.get(props, "GeneralStore.state.showMenu", false)
  );

  const storeMenuOpenValue = _.get(props, "GeneralStore.state.showMenu", false);

  // * Trigger flag so that turning menu off animates back left
  React.useEffect(() => {
    if (!menuHasBeenOpen && storeMenuOpenValue) {
      updateMenuHasBeenOpen(true);
    }
  }, [storeMenuOpenValue, menuHasBeenOpen]);

  const menuIsOpen = storeMenuOpenValue;

  return (
    <div className={cx("interface-wrapper", menuIsOpen && "menu-open")}>
      <Nav className="home-nav" />
      <Menu className="home-menu" />

      <main
        className={cx(
          "interface-main-cont interface-home",
          menuHasBeenOpen && "animate-back"
        )}
      >
        <Home
          isMobile={_.get(props, "GeneralStore.state.isMobile", false)}
          showSearch={showSearch}
          toggleShowSearch={updateShowSearch}
        />
      </main>
    </div>
  );
};

export default HomeWrapper;
