/*
 * Wraps the top level Interface Router with PageVisibility State so such can be monitored at a high level
 */
import * as React from "react";

import Router from "./";

interface Props {}

interface State {
  isOnline: boolean;
  pageVisibility: "prerender" | "hidden" | "visible";
}

export default class RouterWrapper extends React.Component<Props, State> {
  wsServerConnection?: WebSocket;

  constructor(props: Props) {
    super(props);

    this.state = {
      pageVisibility: document.visibilityState,
      isOnline: !!window.navigator.onLine,
    };

    this._updateOnlineStatus = this._updateOnlineStatus.bind(this);
    this._updateVisibilityState = this._updateVisibilityState.bind(this);
  }

  componentDidMount() {
    document.addEventListener("visibilitychange", this._updateVisibilityState);
    document.addEventListener("online", this._updateOnlineStatus);
    document.addEventListener("offline", this._updateOnlineStatus);
  }

  componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this._updateVisibilityState
    );
    document.removeEventListener("online", this._updateOnlineStatus);
    document.removeEventListener("offline", this._updateOnlineStatus);
  }

  _updateOnlineStatus() {
    this.setState({
      isOnline: !!window.navigator.onLine,
    });
  }

  _updateVisibilityState() {
    if (document.visibilityState !== this.state.pageVisibility) {
      this.setState({
        pageVisibility: document.visibilityState,
      });
    }
  }

  render() {
    return (
      <Router
        isOnline={this.state.isOnline}
        visibilityState={this.state.pageVisibility}
      />
    );
  }
}
