/*
 * Component Description
 */
import * as React from "react";

import { IconProps } from "../typings";

const CheckCircle = ({ className, color, size, style }: IconProps) => {
  return (
    <svg
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 17"
      height={size}
      width={size}
      fill={color}
    >
      <path d="M8.49996 0C3.81311 0 0 3.81302 0 8.49996C0 13.1869 3.81311 17 8.49996 17C13.1868 17 16.9999 13.1869 16.9999 8.49996C16.9999 3.81302 13.1869 0 8.49996 0ZM13.3808 7.06424L8.04069 12.4043C7.81363 12.6314 7.5118 12.7564 7.19072 12.7564C6.86964 12.7564 6.56781 12.6314 6.34075 12.4043L3.61913 9.68271C3.39207 9.45565 3.26701 9.15382 3.26701 8.83274C3.26701 8.51157 3.39207 8.20974 3.61913 7.98268C3.8461 7.75562 4.14793 7.63056 4.4691 7.63056C4.79018 7.63056 5.0921 7.75562 5.31907 7.98277L7.19063 9.85425L11.6807 5.36421C11.9077 5.13715 12.2096 5.01218 12.5306 5.01218C12.8517 5.01218 13.1535 5.13715 13.3806 5.36421C13.8494 5.83306 13.8494 6.59557 13.3808 7.06424Z" />
    </svg>
  );
};

export default CheckCircle;
