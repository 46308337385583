import * as React from "react";

import { IconProps } from "../typings";

const TriangleArrowRight = ({ className, color, size }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      height={size}
      width={size}
      fill={color}
    >
        <g clipPath="url(#clip0_1215_28)">
        <path d="M17.6043 8.0133L4.42805 0.467452C2.51212 -0.629812 0.958984 0.270569 0.958984 2.47838V17.5216C0.958984 19.7294 2.51212 20.6298 4.42805 19.5325L17.6043 11.9869C19.5202 10.8896 19.5202 9.1106 17.6043 8.0133Z"/>
        </g>
        <defs>
        <clipPath id="clip0_1215_28">
        <rect width={"20"} height={"20"} />
        </clipPath>
        </defs>
    </svg>
  );
};

export default TriangleArrowRight;
