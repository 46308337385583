/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import TytoCalls from "data/network/tyto/";

import { Props as ProfileWrapperProps } from "../Profile";
import Certifications from "./certifications/";
import Information from "./information/";

import "./ProfileTabs.scss";
import { StoreContext as ToastStoreContext } from "../../../data/stores/ToastStore";

export interface Props extends ProfileWrapperProps {
  AppStore: StoreAPI.AppStoreProps;
  GeneralStore: StoreAPI.GeneralStoreProps;
  personData: TytoData.Person;
  subInterface?: ProfilePage.Subinterface;
}

export function determineIfChangesExist(
  dataItems: ProfilePage.PersonDataItemsProps["dataItems"]
) {
  return dataItems.some(
    (dataItem) => dataItem.localValue !== dataItem.storedValue
  );
}

export function findAllChanges(
  dataItems: ProfilePage.PersonDataItemsProps["dataItems"]
) {
  return dataItems.filter(
    (dataItem) => dataItem.localValue !== dataItem.storedValue
  );
}

function createDataItem(
  personData: TytoData.Person,
  key: keyof TytoData.Person,
  friendlyLabel: string
): ProfilePage.PersonDataItem<any> {
  return {
    key,
    friendlyLabel,
    storedValue: personData[key],
    localValue: personData[key],
  };
}

function updateDataItem(
  personData: ProfilePage.PersonDataItem<any>,
  newValue: any
) {
  return {
    ...personData,
    localValue: newValue,
  };
}

function updateDataItemStoredValue(
  personData: ProfilePage.PersonDataItem<any>,
  newValue: any
) {
  return {
    ...personData,
    storedValue: newValue,
  };
}

const ProfileTabs = (props: Props) => {
  const ToastStore = React.useContext(ToastStoreContext);

  const [isSaving, updateIsSaving] = React.useState(false);
  const [errorMsg, updateErrorMsg] = React.useState("");
  const [firstName, updateFirstName] = React.useState(
    createDataItem(props.personData, "givenName", "First Name")
  );
  const [lastName, updateLastName] = React.useState(
    createDataItem(props.personData, "familyName", "Last Name")
  );
  const [email, updateEmail] = React.useState(
    createDataItem(props.personData, "email", "Email Address")
  );
  const [title, updateTitle] = React.useState(
    createDataItem(props.personData, "jobTitle", "Title")
  );
  const [company, updateCompany] = React.useState(
    createDataItem(props.personData, "company", "Company")
  );
  const [city, updateCity] = React.useState(
    createDataItem(props.personData, "city", "City")
  );
  const [state, updateState] = React.useState(
    createDataItem(props.personData, "state", "State")
  );
  const [postalCode, updatePostalCode] = React.useState(
    createDataItem(props.personData, "postalCode", "Postal Code")
  );
  const [timezone, updateTimezone] = React.useState(
    createDataItem(props.personData, "timeZoneNameGeneral", "Time Zone")
  );

  React.useEffect(() => {
    updateFirstName(
      updateDataItemStoredValue(firstName, props.personData.givenName)
    );
    updateLastName(
      updateDataItemStoredValue(lastName, props.personData.familyName)
    );
    updateEmail(updateDataItemStoredValue(email, props.personData.email));
    updateTitle(updateDataItemStoredValue(title, props.personData.jobTitle));
    updateCompany(updateDataItemStoredValue(company, props.personData.company));
    updateCity(updateDataItemStoredValue(city, props.personData.city));
    updateState(updateDataItemStoredValue(state, props.personData.state));
    updatePostalCode(
      updateDataItemStoredValue(postalCode, props.personData.postalCode)
    );
    updateTimezone(
      updateDataItemStoredValue(timezone, props.personData.timeZoneNameGeneral)
    );
  }, [props.personData]);

  const dataItems: ProfilePage.PersonDataItemsProps["dataItems"] =
    React.useMemo(() => {
      return [
        firstName,
        lastName,
        email,
        title,
        company,
        city,
        state,
        postalCode,
        timezone,
      ];
    }, [
      firstName,
      lastName,
      email,
      title,
      company,
      city,
      state,
      postalCode,
      timezone,
    ]);

  return (
    <div className="profile-tab-wrapper">
      <SubinterfaceRouter
        {...props}
        dataItems={dataItems}
        updateValue={(
          dataItem: ProfilePage.PersonDataItem<any>,
          newValue: any
        ) => {
          const updatedDataItem = updateDataItem(dataItem, newValue);

          switch (dataItem.key) {
            case "givenName":
              updateFirstName(updatedDataItem);
              return;
            case "familyName":
              updateLastName(updatedDataItem);
              return;
            case "email":
              updateEmail(updatedDataItem);
              return;
            case "jobTitle":
              updateTitle(updatedDataItem);
              return;
            case "company":
              updateCompany(updatedDataItem);
              return;
            case "city":
              updateCity(updatedDataItem);
              return;
            case "state":
              updateState(updatedDataItem);
              return;
            case "postalCode":
              updatePostalCode(updatedDataItem);
              return;
            case "timeZoneNameGeneral":
              updateTimezone(updatedDataItem);
              return;
            default:
              return;
          }
        }}
        isSaving={isSaving}
        errorMsg={errorMsg}
        saveChanges={() => {
          const allChanges = findAllChanges(dataItems);
          const changesFormatted = (allChanges || []).reduce(
            (accum: { [x: string]: any }, curItem) => {
              accum[curItem.key] = curItem.localValue;

              return accum;
            },
            {}
          );

          // * Should never be true
          if (!allChanges?.length) {
            updateErrorMsg("No Changes found.");
            return;
          }

          // * Say we are saving, and clear any previous errorMsg
          updateIsSaving(true);
          if (errorMsg) {
            updateErrorMsg("");
          }

          saveUpdates({
            personID: props.personData.personID,
            updates: changesFormatted,
            onError: (errorMsg) => {
              updateErrorMsg(`${errorMsg}`);
              updateIsSaving(false);
              ToastStore.dispatch?.({
                payload: {
                  message: {
                    toastMessage: errorMsg,
                    toastMessageType: "error",
                  },
                },
                type: "TOAST_STORE_MESSAGE",
              });
            },
            onSuccess: (freshPersonData) => {
              props.AppStore.dispatch?.({
                payload: {
                  personData: freshPersonData,
                },
                type: "APP_STORE_PERSONAL_DATA_LOADED",
              });
              //
              ToastStore.dispatch?.({
                payload: {
                  message: {
                    toastMessage: "Successfully Saved",
                    toastMessageType: "success",
                  },
                },
                type: "TOAST_STORE_MESSAGE",
              });
              updateIsSaving(false);
            },
          });
        }}
      />
    </div>
  );
};

async function saveUpdates({
  updates,
  personID,
  onError,
  onSuccess,
}: {
  personID: number;
  updates: Partial<Endpoints.Tyto.Person.GetParameters>;
  onError: (errorMsg: string) => void;
  onSuccess: (userData: TytoData.Person) => void;
}) {
  try {
    await TytoCalls.Person.put({
      ...updates,
      personID,
    });

    const { person } = await TytoCalls.Person.get({ personID });

    onSuccess(person);
  } catch (err) {
    const errorMsg = _.get(
      err,
      "msg",
      "Error occurred. Could not save updates."
    );
    onError(errorMsg);
  }
}

type SubinterfaceRouter = Props & ProfilePage.PersonDataItemsProps;

const SubinterfaceRouter = (props: SubinterfaceRouter) => {
  switch (props.subInterface) {
    case "my-certifications":
      return <Certifications {...props} />;
    case "information":
    default:
      return <Information {...props} />;
  }
};

export default ProfileTabs;
