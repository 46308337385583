/*
 * Component Description
 */
import * as React from "react";
import _ from "lodash";
// import { UseQueryResult } from "react-query";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import {
  INTERFACE_SEARCH_PARAMS,
  PLAN_CHANGE_LOG_SRC_TYPES,
  SUB_INTERFACE_PATHS,
} from "data/constants/";
import {
  usePPFPlan,
  usePPFPlanGoals,
  usePPFPlanMembers,
  usePPFPlanNoticeBoard,
  usePersonProfilePhoto,
  useGSChangelog,
} from "data/network/hooks/";

import { StandardNav } from "components/meta/";
import Nav from "components/meta/sub-navs/generic-nav";
import { Icon, ReactSelect } from "components/common/";
import {
  getFilterStatusOptions,
  getPlaceholderStatusText,
  getStatusFilter,
} from "components/goal/goal-stories/utils/";

import CreateNewGoal from "./subcomponents/new-goal/";
import EditGoal from "./subcomponents/edit-goal/";
import Personal from "./subcomponents/personal/";
import Professional from "./subcomponents/professional/";
import Financial from "./subcomponents/financial/";
import Overview from "./subcomponents/overview/";
import Goals from "./subcomponents/goals/";
import PermissionsWrapper from "./subcomponents/permissions";

import { ActiveSession } from "../../data/storage/session-data";

import "./style.scss";

interface Props {
  filter?: string;
  isMobile: boolean;
  planID: number;
  targetGoalID?: number;
  GeneralStore: StoreAPI.GeneralStoreProps;
  subInterface?: PlanPage.Subinterface;
  showAll?: boolean;
  isMemberOfPlan?: boolean;
}

const PPFPlan = (props: Props) => {
  const navHistory = useHistory();
  const locationData = useLocation();

  const planQuery = usePPFPlan({ planID: props.planID });
  const planGoalsQuery = usePPFPlanGoals({ planID: props.planID });
  const planMembersQuery = usePPFPlanMembers({ planID: props.planID });
  const planNoticeBoardQuery = usePPFPlanNoticeBoard({ planID: props.planID });
  const planChangelogQuery = useGSChangelog({ gsPlanID: props.planID });
  // const profilePhoto = usePersonProfilePhoto({
  //   personID: planQuery?.data?.gsPlan?.aboutID ?? 0,
  //   isEnabled: !!planQuery?.data?.gsPlan?.aboutID,
  // });
  const plan =
    planQuery.data?.gsPlan ?? planQuery.storedValueQuery?.data?.gsPlan;
  const planGoals =
    planGoalsQuery.data?.gsGoals ??
    planGoalsQuery.storedValueQuery?.data?.gsGoals;
  const planNotices =
    planNoticeBoardQuery.data?.notices ??
    planNoticeBoardQuery.storedValueQuery?.data?.notices;
  const planChangeData = planChangelogQuery.eagerData;

  const changeLog = React.useMemo(() => {
    return planChangeData?.changeLog?.filter?.(
      (change) => change.src === PLAN_CHANGE_LOG_SRC_TYPES.GOAL_CHANGE
    );
  }, [planChangeData]);

  // Checks if User is a member of the plan
  // Gets passed down to limit interface for users that do not have membership access
  const mainUserID = ActiveSession.userID();
  const isMemberOfPlan = React.useMemo(() => {
    return !!planMembersQuery.eagerData?.gsMembers?.find?.(
      (number) => number.memberID === mainUserID
    );
  }, [planMembersQuery.eagerData]);

  const isGoalsPage = props.subInterface === "goals";

  const showGoalsGridButton = isGoalsPage && !props.showAll;
  // const centerButtons = showGoalsGridButton
  // ? [
  //     {
  //       href: `/plan/${plan?.gsPlanID ?? 0}/goals/all${locationData.search ?? ""}`,
  //       onClick: _.noop,
  //       value: "View All",
  //     },
  //   ]
  // : undefined;

  return (
    <main className="interface-ppfplan centered-content-wrapper ppfplan-extra-nav-for-stories interface-ppfplan-mobile-adjustments">
      {props.isMobile ? (
        <Nav className="ppfplan-main-nav-mobile">
          {showGoalsGridButton && (
            <Icon
              icon="grid"
              isButton={true}
              buttonProps={{
                className: "ppfplan-main-nav-grib-btn",
              }}
              onClick={() => {
                navHistory.push(
                  `/plan/${plan?.gsPlanID ?? 0}/goals/all${
                    locationData.search ?? ""
                  }`
                );
              }}
              size={18}
            />
          )}
          {props.showAll && (
            <ReactSelect
              key={props.filter}
              className="interface-ppfplan-grid-status-filter"
              placeHolder={getPlaceholderStatusText(props.filter ?? "")}
              labels={["All Goals", "Completed Goals", "In Progress Goals"]}
              values={getFilterStatusOptions()}
              passBack={(newFilterData) => {
                const newFilterValue = getStatusFilter(
                  _.get(newFilterData, "value", "")
                );

                if (newFilterValue === getStatusFilter(props.filter ?? "")) {
                  return;
                }

                let newURL = `/plan/${props.planID ?? 0}/goals/all`;

                if (newFilterValue !== "incomplete") {
                  newURL = `${newURL}?${INTERFACE_SEARCH_PARAMS.PLAN.GOAL_STATUS_FILTER}=${newFilterValue}`;
                }

                navHistory.push(newURL);
              }}
              useStandardStyling={true}
            />
          )}
        </Nav>
      ) : (
        <StandardNav
          className="ppfplan-main-nav"
          // centerButtons={centerButtons}
        >
          {showGoalsGridButton && (
            <Icon
              icon="grid"
              isButton={true}
              buttonProps={{
                className: "ppfplan-main-nav-grib-btn",
              }}
              onClick={() => {
                navHistory.push(`/plan/${plan?.gsPlanID ?? 0}/goals/all`);
              }}
              title="Show Grid of Goals"
              size={25}
            />
          )}

          {props.showAll && (
            <ReactSelect
              key={props.filter}
              placeHolder={getPlaceholderStatusText(props.filter ?? "")}
              labels={["All Goals", "Completed Goals", "In Progress Goals"]}
              values={getFilterStatusOptions()}
              passBack={(newFilterData) => {
                const newFilterValue = getStatusFilter(
                  _.get(newFilterData, "value", "")
                );

                if (newFilterValue === getStatusFilter(props.filter ?? "")) {
                  return;
                }

                let newURL = `/plan/${props.planID ?? 0}/goals/all`;

                if (newFilterValue !== "incomplete") {
                  newURL = `${newURL}?${INTERFACE_SEARCH_PARAMS.PLAN.GOAL_STATUS_FILTER}=${newFilterValue}`;
                }

                navHistory.push(newURL);
              }}
              useStandardStyling={true}
            />
          )}
        </StandardNav>
      )}
      {/* {props.subInterface === SUB_INTERFACE_PATHS.PLAN.GOALS && (
        <Icon
          icon="angle-left"
          size={props.isMobile ? 25 : 45}
          className="ppfplan-extra-nav-for-stories-icon-left"
          isButton={true}
          onClick={() => updateNavNumber(navNumber - 1)}
        />
      )} */}
      <PlanSubTabRouter
        {...props}
        plan={plan}
        isMemberOfPlan={isMemberOfPlan}
        isLoading={planMembersQuery.isLoading}
        planGoals={planGoals}
        planMembers={planMembersQuery.eagerData?.gsMembers}
        planNotices={planNotices}
        planChangelog={changeLog}
        planGoalsQuery={planGoalsQuery}
        showNavButtons={props.subInterface === SUB_INTERFACE_PATHS.PLAN.GOALS}
      />
      {/* {props.subInterface === SUB_INTERFACE_PATHS.PLAN.GOALS && (
        <Icon
          icon="angle-right"
          className="ppfplan-extra-nav-for-stories-icon-right"
          size={props.isMobile ? 25 : 45}
          isButton={true}
          onClick={() => updateNavNumber(navNumber + 1)}
        />
      )} */}
    </main>
  );
};

interface PlanSubTabRouterProps extends Props, PlanPage.PlanData {
  isMemberOfPlan: boolean;
  isLoading: boolean;
  showNavButtons: boolean;
}

const PlanSubTabRouter = (props: PlanSubTabRouterProps) => {
  switch (props.subInterface) {
    case SUB_INTERFACE_PATHS.PLAN.NEW_GOAL:
      return <CreateNewGoal {...props} isMemberOfPlan={props.isMemberOfPlan} />;
    case SUB_INTERFACE_PATHS.PLAN.PERSONAL:
      return <Personal {...props} />;
    case SUB_INTERFACE_PATHS.PLAN.PROFESSIONAL:
      return <Professional {...props} />;
    case SUB_INTERFACE_PATHS.PLAN.FINANCIAL:
      return <Financial {...props} />;
    case SUB_INTERFACE_PATHS.PLAN.GOALS:
      return <Goals {...props} isMemberOfPlan={props.isMemberOfPlan} />;
    case SUB_INTERFACE_PATHS.PLAN.EDIT_GOAL:
      return <EditGoal {...props} isMemberOfPlan={props.isMemberOfPlan} />;
    case SUB_INTERFACE_PATHS.PLAN.PERMISSIONS:
      return <PermissionsWrapper {...props} />;
    case SUB_INTERFACE_PATHS.PLAN.COMPLETED_GOALS:
    case SUB_INTERFACE_PATHS.PLAN.OVERVIEW:
    default:
      return <Overview {...props} />;
  }
};

export default PPFPlan;
