/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import Icon from "../icon/";

import "./style.scss";

export type ButtonTheme =
  | keyof typeof SITE.Theme
  | keyof typeof SITE.PPFNames
  | `${keyof typeof SITE.Theme | keyof typeof SITE.PPFNames | "white"}-ghost`
  | "blank"
  | "transparent"
  | "white"
  | "dark-grey";
export interface Props {
  animateWaiting?: boolean;
  autofocus?: boolean;
  className?: string;
  children?: any;
  disabled?: boolean;
  isFake?: boolean;
  hoverBGSize?: "large" | "default" | "hidden";
  onClick: () => void;
  style?: React.CSSProperties;
  theme: ButtonTheme;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  value: string;
  waiting?: boolean;
}

const Button = ({
  animateWaiting = true,
  autofocus,
  children,
  className,
  disabled,
  hoverBGSize = "default",
  isFake,
  onClick,
  style,
  theme,
  type,
  value,
  waiting,
}: Props) => {
  return (
    <button
      autoFocus={autofocus}
      className={cx(
        "cc-btn",
        hoverBGSize === "large" && "larger-hover",
        theme,
        className
      )}
      disabled={disabled || waiting}
      style={style}
      onClick={isFake ? undefined : onClick}
      type={type}
    >
      {animateWaiting && waiting ? (
        <Icon className="cc-btn-waiting-icon" icon="elipsis-h" size={16} />
      ) : (
        <>
          {hoverBGSize !== "hidden" && (
            <div className="cc-btn-hover-bg" aria-disabled={true} />
          )}
          {children ? children : value}
        </>
      )}
    </button>
  );
};

export default Button;
