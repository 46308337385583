/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import { ReactSelect } from "components/common/";

const MINUTE = 1000 * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const MONTH = DAY * 30.5; // * Sort of...
const YEAR = DAY * 365;

interface FriendlyRelativeTime {
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
}

type TimeQuantityName = "year" | "month" | "day" | "hour" | "minute";

interface Props {
  className?: string;
  children?: any;
  startCompareDate?: string | Date;
  date: string | Date;
  debug?: boolean;
  show1900?: boolean;
  style?: React.CSSProperties;
  terse?: boolean;
  titleClarificationText?: string;
  nowCallBack?: (isNow: boolean) => void;
  updateable?: boolean;
  updateDateCallBack?: (newDate: Date) => void;
}

const Timestamp = (props: Props) => {
  const [parsedTimeStampMsg, updateParsedTimeStampMsg] = React.useState(() => {
    return parseTimeStamp(props) ?? "";
  });

  React.useEffect(() => {
    updateParsedTimeStampMsg(parseTimeStamp(props) ?? "");
  }, [props.date]);

  if (parsedTimeStampMsg === "Just Now" && props.nowCallBack) {
    props.nowCallBack(true);
  }

  if (props.updateable) {
    return (
      <ReactSelect
        labels={["1yrs", "3yrs", "5yrs"]}
        values={["1", "3", "5"]}
        styles={{
          option: (style: CSSStyleDeclaration) => ({
            ...style,
            backgroundColor: "#29303C",
            borderTop: "1px solid #535353",
            color: "var(--white)",
          }),
          control: (style: CSSStyleDeclaration) => ({
            ...style,
            backgroundColor: "#29303C",
            color: "var(--white)",
            borderColor: "transparent ",
          }),
          menu: (style: CSSStyleDeclaration) => ({
            ...style,
            backgroundColor: "#29303C",
            color: "var(--white)",
          }),
          input: (style: CSSStyleDeclaration) => ({
            ...style,
            color: "var(--white)",
          }),
          singleValue: (style: CSSStyleDeclaration) => ({
            ...style,
            color: "var(--white)",
          }),
          placeholder: (style: CSSStyleDeclaration) => ({
            ...style,
            color: "var(--white)",
          }),
        }}
        placeHolder={parsedTimeStampMsg}
        passBack={(value) => {
          if (props.updateDateCallBack) {
            var date = new Date();
            switch (value.value) {
              case "1":
                date.setFullYear(new Date().getFullYear() + 1);
                props.updateDateCallBack(date);
                break;
              case "3":
                date.setFullYear(new Date().getFullYear() + 3);
                props.updateDateCallBack(date);
                break;
              case "5":
                date.setFullYear(new Date().getFullYear() + 5);
                props.updateDateCallBack(date);
                break;
            }
          }
        }}
      />
    );
  }
  return (
    <span
      className={cx("cc-timestamp", props.className)}
      title={
        props.titleClarificationText
          ? props.titleClarificationText + " " + getReadableDate(props.date)
          : getReadableDate(props.date)
      }
      style={props.style}
    >
      {parsedTimeStampMsg}
      {(parsedTimeStampMsg && props.children) ?? null}
    </span>
  );
};

function parseTimeStamp({
  date,
  debug,
  startCompareDate,
  terse = true,
}: Props) {
  const asDate = new Date(date);

  if (+asDate === NaN || +asDate < 0) {
    return "";
  }

  const compareDate = startCompareDate
    ? +new Date(startCompareDate)
    : undefined;

  if (debug) {
    debugger;
  }

  const helpfulObject = compareDate
    ? getTimeDiffItems(compareDate, +asDate)
    : getTimeDiffItems(+asDate, Date.now());

  return getFriendlyString(helpfulObject, terse);
}

function getFriendlyString(
  { years, months, days, hours, minutes }: FriendlyRelativeTime,
  terse: boolean
) {
  let quantity = years;
  let quantityName: TimeQuantityName = "year";

  const currHoursInDay = new Date().getHours();

  // * Accounts for part of an additional day that ought to
  // * add a day to the count, since we want this in relative terms
  // * example: it is 8:00am and a date of 11:00pm 2 days ago would
  // * otherwise parse as "1 day". We would want it to say "2 days ago".
  if (hours > currHoursInDay) {
    days += 1;
    hours = hours - currHoursInDay;
  }

  if (years) {
    // * Simply here to stop other blocks to run
  } else if (months) {
    quantity = months;
    quantityName = "month";
  } else if (days) {
    quantity = days;
    quantityName = "day";
  } else if (hours) {
    quantity = hours;
    quantityName = "hour";
  } else if (minutes) {
    quantity = minutes;
    quantityName = "minute";
  } else {
    return "Just Now";
  }

  return getTimeQuanitityName({
    quantity,
    quantityName,
    terse,
  });
}

function getPluralString(quantity: number) {
  return quantity > 1 ? "s" : "";
}

function getTimeQuanitityName({
  quantity,
  quantityName,
  terse,
}: {
  quantityName: TimeQuantityName;
  quantity: number;
  terse: boolean;
}) {
  switch (quantityName) {
    case "minute":
      return `${quantity}${terse ? "" : " "}${
        terse ? "min" : quantityName
      }${getPluralString(quantity)}`;
    case "hour":
      return `${quantity}${terse ? "" : " "}${
        terse ? "hr" : quantityName
      }${getPluralString(quantity)}`;
    case "day":
      return `${quantity}${terse ? "" : " "}${
        terse ? "d" : quantityName
      }${getPluralString(quantity)}`;
    case "month":
      return `${quantity}${terse ? "" : " "}${
        terse ? " mo" : quantityName
      }${getPluralString(quantity)}`;
    case "year":
      return `${quantity}${terse ? "" : " "}${
        terse ? "yr" : quantityName
      }${getPluralString(quantity)}`;
  }

  return "";
}

function getTimeDiffItems(
  firstDateMS: number,
  secondDateMS: number
): FriendlyRelativeTime {
  const diff = secondDateMS - firstDateMS;

  let years = 0;
  let months = 0;
  let days = 0;
  let hours = 0;
  let minutes = 0;

  years = diff >= YEAR ? Math.floor(diff / YEAR) : 0;
  const daysOffYearRemainder = diff % YEAR;

  months =
    daysOffYearRemainder >= MONTH
      ? Math.floor(daysOffYearRemainder / MONTH)
      : months;
  const monthsRemainder = daysOffYearRemainder % MONTH;

  days = monthsRemainder >= DAY ? Math.floor(monthsRemainder / DAY) : days;
  const daysRemainder = monthsRemainder % DAY;

  hours = daysRemainder >= HOUR ? Math.floor(daysRemainder / HOUR) : hours;
  const hoursRemainder = daysRemainder % HOUR;

  minutes = hoursRemainder >= MINUTE ? Math.floor(hoursRemainder / MINUTE) : 0;
  const minutesRemainder = hoursRemainder % MINUTE;

  return {
    years,
    months,
    days,
    hours,
    minutes,
  };
}

function getReadableDate(date: Date | string) {
  const actualDate = new Date(date);
  var dateString = "";

  if (actualDate.getMonth() < 9) {
    dateString += "0" + (actualDate.getMonth() + 1) + "/";
  } else {
    dateString += actualDate.getMonth() + 1 + "/";
  }

  if (actualDate.getDate() < 10) {
    dateString += "0" + actualDate.getDate() + "/";
  } else {
    dateString += actualDate.getDate() + "/";
  }

  dateString += actualDate.getFullYear() + " ";

  if (actualDate.getHours() < 12) {
    if (actualDate.getHours() < 10) {
      if (actualDate.getHours() === 0) {
        dateString += 12 + ":";
      } else {
        dateString += "0" + actualDate.getHours() + ":";
      }
    } else {
      dateString += actualDate.getHours() + ":";
    }
    if (actualDate.getMinutes() < 10) {
      dateString += "0" + actualDate.getMinutes() + "am";
    } else {
      dateString += actualDate.getMinutes() + "am";
    }
  } else {
    if (actualDate.getHours() === 12) {
      dateString += actualDate.getHours() + ":";
    } else {
      dateString += actualDate.getHours() - 12 + ":";
    }
    if (actualDate.getMinutes() < 10) {
      dateString += "0" + actualDate.getMinutes() + "pm";
    } else {
      dateString += actualDate.getMinutes() + "pm";
    }
  }
  return dateString;
}

export default Timestamp;
