/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import AgnosticModal from "../agnostic";
import TextEdit from "./subcomponents/TextEdit";

import "./style.scss";

export interface Props extends Modals.CommonModalProps {
  onConfirm: (newText: string) => void;
  startingText: string;
  label: string;
  theme?: keyof typeof SITE.Theme;
}

const TextEditModal = (props: Props) => {
  return (
    <AgnosticModal
      className={cx("textedit-modal-dialog-container", props.className)}
      overlayClassName={props.overlayClassName}
      isOpen={props.isOpen}
      hasCloseBtn={false}
      modalStyle={props.modalStyle}
      onDismiss={props.onDismiss}
      overlayStyle={props.overlayStyle}
    >
      <TextEdit
        closePopup={props.onDismiss}
        onConfirm={props.onConfirm}
        label={props.label}
        startingText={props.startingText}
      />
    </AgnosticModal>
  );
};

export default TextEditModal;
