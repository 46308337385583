/*
 * Component Description
 */
import * as React from "react";
import { TextButton, UserThumb } from "../../../components/common/";

interface Props {
  activeSessionKey?: string;
  allSessions: Data.SessionData[];
  hasMatchingSession: boolean;
  onChoose: (session: Data.SessionData) => void;
}

const ExistingSessions = ({
  activeSessionKey,
  allSessions,
  hasMatchingSession,
  onChoose,
}: Props) => {
  const [showAllSessions, updateShowAllSessions] = React.useState(
    !hasMatchingSession
  );

  if (!allSessions.length) {
    return null;
  }

  const sessionsToShow =
    !showAllSessions && !!activeSessionKey
      ? allSessions.filter((session) => session.sessionKey === activeSessionKey)
      : allSessions;

  return (
    <section className="login-existing-sessions-cont">
      <p className="login-existing-sessions-title">Continue as..</p>
      <ul className="login-existing-sessions-list">
        {sessionsToShow.map((session) => (
          <li
            className="login-existing-sessions-list-item"
            key={session.sessionKey}
          >
            <button
              className="login-existing-sessions-list-item-button"
              onClick={() => onChoose(session)}
            >
              <UserThumb
                className="login-existing-sessions-list-item-userthumb"
                userName={session.userName}
                size={28}
              />

              <span className="login-existing-sessions-list-item-username font-size-normal">
                {session.userName}
              </span>
              {!!session.domainID && (
                <span className="login-existing-sessions-list-item-domainid font-size-tiny">
                  {session.domainID}
                </span>
              )}
            </button>
          </li>
        ))}
      </ul>

      {allSessions.length > 1 && !showAllSessions && (
        <TextButton
          className="login-existing-sessions-showothers font-size-small"
          onClick={() => updateShowAllSessions(true)}
          value="Other Sessions"
        />
      )}

      <p className="login-existing-sessions-title">
        Or Login to a different Account
      </p>
    </section>
  );
};

export default ExistingSessions;
