import * as React from "react";

import { IconProps } from "../typings";

const Grid = ({
  className,
  color,
  size,
}: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 360.49 360.49"
      // viewBox="0 0 320 512"
      height={size}
      width={size}
      fill={color}
    >
        <g>
            <g>
                <g>
                    <path d="M96.653,0H13.061C7.29,0,2.612,4.678,2.612,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592
                        c5.771,0,10.449-4.678,10.449-10.449V10.449C107.102,4.678,102.424,0,96.653,0z"/>
                    <path d="M222.041,0h-83.592C132.678,0,128,4.678,128,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592
                        c5.771,0,10.449-4.678,10.449-10.449V10.449C232.49,4.678,227.812,0,222.041,0z"/>
                    <path d="M96.653,125.388H13.061c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592
                        c5.771,0,10.449-4.678,10.449-10.449v-83.592C107.102,130.066,102.424,125.388,96.653,125.388z"/>
                    <path d="M222.041,125.388h-83.592c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592
                        c5.771,0,10.449-4.678,10.449-10.449v-83.592C232.49,130.066,227.812,125.388,222.041,125.388z"/>
                    <path d="M347.429,0h-83.592c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592
                        c5.771,0,10.449-4.678,10.449-10.449V10.449C357.878,4.678,353.199,0,347.429,0z"/>
                    <path d="M347.429,125.388h-83.592c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592
                        c5.771,0,10.449-4.678,10.449-10.449v-83.592C357.878,130.066,353.199,125.388,347.429,125.388z"/>
                    <path d="M96.653,256H13.061c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592
                        c5.771,0,10.449-4.678,10.449-10.449v-83.592C107.102,260.678,102.424,256,96.653,256z"/>
                    <path d="M222.041,256h-83.592c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592
                        c5.771,0,10.449-4.678,10.449-10.449v-83.592C232.49,260.678,227.812,256,222.041,256z"/>
                    <path d="M347.429,256h-83.592c-5.771,0-10.449,4.678-10.449,10.449v83.592c0,5.771,4.678,10.449,10.449,10.449h83.592
                        c5.771,0,10.449-4.678,10.449-10.449v-83.592C357.878,260.678,353.199,256,347.429,256z"/>
                </g>
            </g>
        </g>
    </svg>
  );
};

export default Grid;

