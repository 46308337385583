/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import { Link as ReactRouterLink } from "react-router-dom";

import Button, { Props as ButtonProps } from "../button/";

import "./style.scss";

type LinkType = "link" | "button";
export interface Props {
  className?: string;
  children?: any;
  disabled?: boolean;
  buttonProps?: ButtonProps;
  href?: string;
  label?: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClickCapture?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  style?: React.CSSProperties;
  target?: "_blank";
  ariaTitle?: string;
  type?: LinkType;
  value: string;
}

const BASE_CLASSNAMES = "cc-link";

const Link = (props: Props) => {
  const type = props.type || "link";

  if (props.href) {
    return (
      <ReactRouterLink
        className={cx(
          BASE_CLASSNAMES,
          props.disabled && "fake-disabled",
          props.className
        )}
        onClick={props.onClick}
        onClickCapture={props.onClickCapture}
        target={props.target}
        to={props.href}
        style={props.style}
        title={props.ariaTitle}
      >
        {type === "button" && props.buttonProps ? (
          <Button {...props.buttonProps} isFake={true} />
        ) : null}

        {type !== "button" ? (
          <>{props.children ? props.children : props.value}</>
        ) : null}
      </ReactRouterLink>
    );
  }

  return (
    <button
      className={cx(BASE_CLASSNAMES, "is-button", props.className)}
      disabled={props.disabled}
      onClick={props.onClick}
      style={props.style}
    >
      {props.children ? props.children : props.value}
    </button>
  );
};

export default Link;
