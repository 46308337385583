/**
 * Render Interactive Goal 'Stories' similar
 * to 'Instagrame Stories'
 */
import * as React from "react";
import _ from "lodash";
import cx from "classnames";
import { useHistory } from "react-router-dom";

import {
  detemerminePPFParentGoal,
  elementIsCurrentlyFocused,
} from "data/helpers/";
import { usePlanPermissions } from "data/network/hooks/";

import { Icon, Link, Message, Button } from "components/common";

import GoalBars from "./subcomponents/goals-bars/";
import CurrentGoal from "./subcomponents/current-goal/";
import GoalNav from "./subcomponents/goal-nav";

import "./style.scss";
import {
  INTERFACE_PATHS,
  PPF_ROLES_BY_NAME,
  SUB_INTERFACE_PATHS,
  INTERFACE_SEARCH_PARAMS,
} from "data/constants";

import { useEventListener } from "data/helpers/Hooks";
import { useGoalMemberAddMutation } from "data/network/hooks/";

import {
  findChildGoal,
  getChildGoalURL,
  getGoalMatchesStatusFilter,
  getNormalizedStatusFilter,
  getTargetGoalsList,
} from "./utils/";
import { useKeyedData } from "./utils/hooks";

import GoalsGrid from "./subcomponents/goals-grid/";

const GRID_DOM_IDS = {
  BECOME_MANAGER: "goal-stories-become-manager",
  DIRECTORY_NAV: "goal-stories-dir",
  BUTTON_LEFT: "goal-stories-btn-left",
  BUTTON_RIGHT: "goal-stories-btn-right",
  WARNING_MSG: "goal-stories-warning-msg",
  CUR_GOAL: "goal-stories-curgoal",
};

interface Props {
  statusFilter?: string;
  plan: TytoData.PPF.Plan.Plan;
  parentGoals: TytoData.PPF.Plan.Goals.ParentGoal[];
  targetGoalID?: number;
  goalNotices?: TytoData.Notices.Notice[];
  goalChangelog?: Array<TytoData.GoalChangeLogItem>;
  isMemberOfPlan: boolean;
  isMobile: boolean;
  showNavButtons: boolean;
  showAll?: boolean;
}

const GoalStories = ({
  goalNotices,
  parentGoals,
  plan,
  statusFilter,
  targetGoalID,
  goalChangelog,
  isMemberOfPlan,
  isMobile,
  showAll: showGoalsGrid,
}: Props) => {
  const navHistory = useHistory();
  const animationNameRef = React.useRef<GoalStories.AnimationName | undefined>(
    "grow-in"
  );
  const newStepInputRef = React.useRef<HTMLInputElement | null>(null);
  const newMessageInputRef = React.useRef<HTMLInputElement | null>(null);
  const { permissions } = usePlanPermissions({ planID: plan.gsPlanID });

  const normalizedStatusFilter = getNormalizedStatusFilter(statusFilter as any);

  const {
    allChildGoals: childGoals,
    completedChildGoals,
    incompleteChildGoals,
    childIndexes,
    keyedChildren,
    keyedParents,
    parentByTheme,
  } = useKeyedData(parentGoals);

  const [curGoalID, updateCurGoalID] = React.useState(() => {
    const targetGoal = targetGoalID ? keyedChildren[targetGoalID] : undefined;

    const matchesStatusFilter = getGoalMatchesStatusFilter({
      childGoal: targetGoal,
      statusFilter: normalizedStatusFilter,
    });

    if (matchesStatusFilter) {
      return targetGoal?.gsGoalID ?? 0;
    }

    const listMatchingFilter = getTargetGoalsList({
      allChildGoals: childGoals,
      completedChildGoals,
      incompleteChildGoals,
      statusFilter: normalizedStatusFilter,
    });

    return listMatchingFilter[0]?.gsGoalID ?? 0;
  });
  const [errorMsg, updateErrorMsg] = React.useState("");

  React.useEffect(() => {
    if (showGoalsGrid) {
      return;
    }

    updateCurGoalID((cGoalID) => {
      return targetGoalID ?? cGoalID;
    });
  }, [targetGoalID, parentGoals, statusFilter]);

  function goLeftOrRight(
    dir: "left" | "right",
    animationNameRef: React.MutableRefObject<
      GoalStories.AnimationName | undefined
    >
  ) {
    const listMatchingFilter = getTargetGoalsList({
      allChildGoals: childGoals,
      completedChildGoals,
      incompleteChildGoals,
      statusFilter: normalizedStatusFilter,
    });

    const newGoalID = findChildGoal({
      childGoals: listMatchingFilter,
      curGoalID,
      dir,
      keyedChildren,
    })?.gsGoalID;

    const url = getChildGoalURL({
      filter: normalizedStatusFilter,
      childGoalID: newGoalID ?? 0,
      keyedChildren,
    });

    if (!url) {
      return;
    }

    animationNameRef.current = dir === "left" ? "from-left" : "from-right";
    navHistory.push(url);
  }

  useEventListener<KeyboardEvent>(
    "keydown",
    (event) => {
      const { key } = event as KeyboardEvent;

      const keyLC = `${key}`.toLocaleLowerCase();

      if (keyLC === "arrowright" || keyLC === "arrowleft") {
        if (newMessageInputRef.current || newStepInputRef.current) {
          if (
            elementIsCurrentlyFocused(newMessageInputRef.current) ||
            elementIsCurrentlyFocused(newStepInputRef.current)
          ) {
            return;
          }
        }

        goLeftOrRight(
          keyLC === "arrowleft" ? "left" : "right",
          animationNameRef
        );
      }
    },
    window
  );

  // * If Status Filter from URL changes, and curGoal doesn't match new statuf filter
  // * Attempt to switch to first goal matching new Status Filter
  React.useEffect(() => {
    const curGoal = keyedChildren[curGoalID];

    if (showGoalsGrid) {
      return;
    }

    // * "My PPF's" - If Cur Goal doesn't match new Status Filter then route to it.
    if (
      !getGoalMatchesStatusFilter({
        childGoal: curGoal,
        statusFilter: normalizedStatusFilter,
      })
    ) {
      const listMatchingFilter = getTargetGoalsList({
        allChildGoals: childGoals,
        completedChildGoals,
        incompleteChildGoals,
        statusFilter: normalizedStatusFilter,
      });

      // * Redirect to first goal matching Filter
      const firstItemGoal = findChildGoal({
        childGoals: listMatchingFilter,
        curGoalID,
        dir: "right",
        keyedChildren,
        targetIdx: 0,
      });

      if (firstItemGoal) {
        const url = getChildGoalURL({
          childGoalID: firstItemGoal?.gsGoalID ?? 0,
          keyedChildren,
          filter: normalizedStatusFilter,
        });

        if (!url) {
          return;
        }

        navHistory.push(url);
      }
    }
  }, [statusFilter, plan.gsPlanID]);

  const currentGoal = keyedChildren[curGoalID];
  const goalType = parentByTheme[currentGoal?.gsParentGoalID];

  const stsAlertInfo = React.useMemo(() => {
    let stsFilter = statusFilter ?? "incomplete";

    if (
      stsFilter === "incomplete" &&
      currentGoal?.targetStatus === "ocCOMPLETE"
    ) {
      return {
        isActually: "Completed",
        filtering: "Incomplete",
      };
    } else if (
      stsFilter === "completed" &&
      currentGoal?.targetStatus !== "ocCOMPLETE"
    ) {
      return {
        isActually: "Incomplete",
        filtering: "Completed",
      };
    }

    return null;
  }, [currentGoal, statusFilter]);

  const addPersonMutation = useGoalMemberAddMutation({
    gsPlanID: plan.gsPlanID,
    onError: (newErrorMsg) => {
      updateErrorMsg(newErrorMsg);
    },
    onSuccess: () => {},
  });

  function addPersonToGSMembersOnGoalStories(memberID: number) {
    const managerPerms = PPF_ROLES_BY_NAME.MANAGER.permissions;
    addPersonMutation.mutate({ memberID, managerPerms });
  }

  const indexesBase = childIndexes[normalizedStatusFilter];
  const indexOfGoalType = indexesBase[goalType];

  const currentGoalsList = getTargetGoalsList({
    allChildGoals: childGoals,
    completedChildGoals,
    incompleteChildGoals,
    statusFilter: normalizedStatusFilter,
  });
  const showLeftRightBtns = !!currentGoalsList?.length;

  if (showGoalsGrid) {
    return (
      <article
        className={cx(
          "cg-goals-stories goalsgrid-display",
          !isMemberOfPlan && plan.permission.goalAdd && "has-manager-msg",
          stsAlertInfo && "has-sts-alert"
        )}
      >
        <GoalsGrid
          childGoals={currentGoalsList}
          parentByTheme={parentByTheme}
        />
      </article>
    );
  }

  return (
    <article
      className={cx(
        "cg-goals-stories",
        !isMemberOfPlan && plan.permission.goalAdd && "has-manager-msg",
        stsAlertInfo && "has-sts-alert"
      )}
    >
      {!isMemberOfPlan && plan.permission.goalAdd ? (
        <div id={GRID_DOM_IDS.BECOME_MANAGER} className="cg-manager-wrapper">
          <Button
            className="cg-manager-add-btn"
            type="button"
            value="Become a Manager"
            theme={"white-ghost"}
            onClick={() => {
              addPersonToGSMembersOnGoalStories(plan.permission.memberID);
            }}
          />
        </div>
      ) : null}

      <GoalNav
        domID={GRID_DOM_IDS.DIRECTORY_NAV}
        curGoalLength={currentGoal?.durationYears ?? 1}
        onGoalTypeSelect={(newGoalType) => {
          const localBase = indexesBase[newGoalType];
          let startingIdx = localBase?.start ?? -1;

          if (startingIdx < 0) {
            if ((localBase?.start ?? -1) > 0) {
              startingIdx = localBase.start;
            }
            // childIndexes["all"][goalType]?.start ??
            // 0
          }

          const listMatchingFilter = getTargetGoalsList({
            allChildGoals: childGoals,
            completedChildGoals,
            incompleteChildGoals,
            statusFilter: normalizedStatusFilter,
          });

          const newCurGoal = findChildGoal({
            childGoals: listMatchingFilter,
            curGoalID,
            dir: "right",
            keyedChildren,
            targetIdx: startingIdx,
          });

          const url = getChildGoalURL({
            childGoalID: newCurGoal?.gsGoalID ?? 0,
            keyedChildren,
            filter: normalizedStatusFilter,
          });

          if (!url) {
            return;
          }

          animationNameRef.current = "grow-in";

          navHistory.push(url);
        }}
        onGoalDurationSelect={(duration: 1 | 3 | 5) => {
          let startingIdx = indexOfGoalType?.[duration] ?? -1;

          if (startingIdx < 0) {
            if ((indexOfGoalType?.start ?? -1) > 0) {
              startingIdx = indexOfGoalType.start;
            }
            // childIndexes["all"][goalType]?.start ??
            // 0
          }

          const listMatchingFilter = getTargetGoalsList({
            allChildGoals: childGoals,
            completedChildGoals,
            incompleteChildGoals,
            statusFilter: normalizedStatusFilter,
          });

          const goalID = findChildGoal({
            childGoals: listMatchingFilter,
            curGoalID,
            dir: "right",
            keyedChildren,
            targetIdx: startingIdx,
          })?.gsGoalID;

          const url = getChildGoalURL({
            childGoalID: goalID ?? 0,
            keyedChildren,
            filter: normalizedStatusFilter,
          });

          if (!url) {
            return;
          }
          animationNameRef.current = "grow-in";
          navHistory.push(url);
        }}
        selectedGoalType={goalType}
        hasPersonalGoals={!!indexesBase["personal"].count}
        hasProfessionGoals={!!indexesBase["professional"].count}
        hasFinancialGoals={!!indexesBase["financial"].count}
        has1YearGoals={indexOfGoalType?.[1] >= 0}
        has3YearGoals={indexOfGoalType?.[3] >= 0}
        has5YearGoals={indexOfGoalType?.[5] >= 0}
      />

      {stsAlertInfo && (
        <section
          id={GRID_DOM_IDS.WARNING_MSG}
          className="cg-goals-stories-warning-cont"
        >
          <div className="cg-goals-stories-warning-icon-cont">
            <Icon
              className="cg-goals-stories-warning-icon"
              size={24}
              icon="warning"
            />
          </div>

          <div className="cg-goals-stories-warning-msg-cont">
            <p className="cg-goals-stories-warning-msg">
              You are filtering to show {stsAlertInfo.filtering} Goals, but this
              Goal is {stsAlertInfo.isActually}.
            </p>
          </div>
        </section>
      )}

      <div className="cg-goals-stories-btn-cont" id={GRID_DOM_IDS.BUTTON_LEFT}>
        {showLeftRightBtns && (
          <Icon
            buttonProps={{
              className: "cg-goals-stories-left-btn",
            }}
            icon="angle-left"
            size={isMobile ? 25 : 45}
            className="cg-goals-stories-btn-icon"
            isButton={true}
            onClick={() => {
              goLeftOrRight("left", animationNameRef);
            }}
          />
        )}
      </div>

      <div className="cg-goals-stories-btn-cont" id={GRID_DOM_IDS.BUTTON_RIGHT}>
        {showLeftRightBtns && (
          <Icon
            buttonProps={{
              className: "cg-goals-stories-right-btn",
            }}
            icon="angle-right"
            size={isMobile ? 25 : 45}
            className="cg-goals-stories-btn-icon"
            isButton={true}
            onClick={() => {
              goLeftOrRight("right", animationNameRef);
            }}
          />
        )}
      </div>

      {currentGoal ? (
        <CurrentGoal
          animation={animationNameRef.current}
          key={currentGoal?.gsGoalID}
          childGoal={currentGoal}
          domID={GRID_DOM_IDS.CUR_GOAL}
          isMemberOfPlan={isMemberOfPlan}
          isMobile={isMobile}
          newMessageInputRef={newMessageInputRef}
          newStepInputRef={newStepInputRef}
          planNotices={goalNotices}
          planChangelog={goalChangelog}
          goToNextGoal={() => {
            // TODO
          }}
          statusFilter={statusFilter}
          plan={plan}
          theme={goalType}
          permissions={permissions}
        />
      ) : (
        <section
          id={GRID_DOM_IDS.CUR_GOAL}
          className="cg-goals-stories-notfound-section"
        >
          {!!parentGoals && !!curGoalID && (
            <Message
              className="cg-goals-stories-notfound-section-title"
              text="There are no goals to display with current filter"
            />
          )}
          {!!parentGoals && !curGoalID && permissions.goalAdd && (
            <>
              <Message
                className="cg-goals-stories-notfound-section-title"
                text="There are no goals to display with current filter"
              />

              {isMemberOfPlan && (
                <Link
                  buttonProps={{
                    className: "cg-goals-stories-notfound-section-btn",
                    theme: "action",
                    value: "Create Goal",
                    onClick: _.noop,
                  }}
                  className="cg-goals-stories-notfound-section-btn-link"
                  href={`${INTERFACE_PATHS.PLAN}/${plan.gsPlanID}/${SUB_INTERFACE_PATHS.PLAN.NEW_GOAL}`}
                  value="Create Goal"
                  type="button"
                />
              )}
            </>
          )}
        </section>
      )}
    </article>
  );
};

export default GoalStories;
