import confetti, { Options } from "canvas-confetti";
import { userPrefersReducedMotion } from "data/helpers";

/**
 * Docs can be found here: https://www.kirilv.com/canvas-confetti/
 * Much of this code is ripped from the docs/examples
 */

const DEFAULT_OPTS = {
  particleCount: 100,
  spread: 160,
  origin: { y: 0.6 },
};

// * Straight ripped from documentation
function randomInRange(min = 50, max = 100) {
  return Math.random() * (max - min) + min;
}

export function fireConfetti(opts?: Options) {
  const options = opts ?? DEFAULT_OPTS;

  confetti(options);
}

// * RANDOM DIRECTION CONFETTI ==================================
export function fireConfettiInRandomDirection(opts: {
  quantity?: number;
  options?: Options;
}) {
  if (userPrefersReducedMotion()) {
    return;
  }

  const qty = opts?.quantity ?? 1;

  const arrayOfLength = Array.from({ length: Math.max(qty, 1) });

  arrayOfLength.forEach(() => {
    confetti({
      angle: randomInRange(55, 125),
      spread: randomInRange(50, 70),
      particleCount: randomInRange(50, 100),
      origin: { y: 0.6 },
      ...(opts?.options ?? {}),
    });
  });
}

// * REALISTIC CONFETTI =========================================
// * Some default settings specific to 'Realistic' confetti launch
const REALISTIC_FIRE_PARTICAL_COUNT = 200;
const REALISTIC_FIRE_DEFAULTS = {
  origin: { y: 0.7 },
};

function fire(particleRatio = 0.2, opts: Options) {
  confetti(
    Object.assign({}, REALISTIC_FIRE_DEFAULTS, opts, {
      particleCount: Math.floor(REALISTIC_FIRE_PARTICAL_COUNT * particleRatio),
    })
  );
}

export function fireRealistically(opts?: Options) {
  if (userPrefersReducedMotion()) {
    return;
  }

  const options = opts ?? {};

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
    ...options,
  });
  fire(0.2, {
    spread: 60,
    ...options,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
    ...options,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
    ...options,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
    ...options,
  });
}

// * FIREWORKS CONFETTI =========================================
const FIREWORKS_DEFAULT_DURATION = 15 * 1000;
// const FIREWORKS_DEFAULT_ANIMATION_END = Date.now() + FIREWORKS_DEFAULT_DURATION;
const FIREWORKS_DEFAULT_CONFIG = {
  startVelocity: 30,
  spread: 360,
  ticks: 60,
  zIndex: 1001,
};

let interval: NodeJS.Timeout | undefined;

export function startFireworks(opts?: {
  diminishFireworks?: boolean;
  fireworksDisplayDuration?: number;
  timeBetweenEachFireworkInMS?: number;
}) {
  if (userPrefersReducedMotion()) {
    return;
  }

  const {
    diminishFireworks = true,
    fireworksDisplayDuration = FIREWORKS_DEFAULT_DURATION,
    timeBetweenEachFireworkInMS = 250,
  } = opts ?? {};

  const animationEnd = Date.now() + fireworksDisplayDuration;

  if (interval) {
    clearInterval(interval);
  }

  interval = setInterval(() => {
    const timeLeft = (animationEnd ?? 0) - Date.now();

    if (timeLeft <= 0) {
      if (interval) {
        clearInterval(interval);
      }

      return;
    }

    const particleCount =
      50 *
      ((diminishFireworks ? timeLeft : fireworksDisplayDuration) /
        fireworksDisplayDuration);

    // since particles fall down, start a bit higher than random
    confetti(
      Object.assign({}, FIREWORKS_DEFAULT_CONFIG, {
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
      })
    );
    confetti(
      Object.assign({}, FIREWORKS_DEFAULT_CONFIG, {
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
      })
    );
  }, timeBetweenEachFireworkInMS ?? 250);
}

export function stopFireworks() {
  if (interval) {
    try {
      clearInterval(interval);
    } catch (err) {
      // TODO
    }
  }
}

// @ts-ignore
// window["startFireworks"] = startFireworks;
