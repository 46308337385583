/*
 * Component Description
 */
import * as React from "react";

import { IconProps } from "../typings";

const Exam = ({ className, color, size, style }: IconProps) => {
  return (
    <svg
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 37 45"
      height={size}
      width={size}
      fill={color}
    >
      <path d="M27.5576 32.7832H22.3721C21.4012 32.7832 20.6143 33.5702 20.6143 34.541C20.6143 35.5119 21.4012 36.2988 22.3721 36.2988H27.5576C28.5285 36.2988 29.3154 35.5119 29.3154 34.541C29.3154 33.5702 28.5285 32.7832 27.5576 32.7832Z" />
      <path d="M27.5576 22.1484H22.3721C21.4012 22.1484 20.6143 22.9354 20.6143 23.9062C20.6143 24.8771 21.4012 25.6641 22.3721 25.6641H27.5576C28.5285 25.6641 29.3154 24.8771 29.3154 23.9062C29.3154 22.9354 28.5285 22.1484 27.5576 22.1484Z" />
      <path d="M34.5791 30.9375C35.55 30.9375 36.3369 30.1505 36.3369 29.1797V7.03125C36.3369 3.15422 33.1827 0 29.3057 0H7.69434C3.8173 0 0.663086 3.15422 0.663086 7.03125V37.9688C0.663086 41.8458 3.8173 45 7.69434 45H29.3056C33.1827 45 36.3368 41.8458 36.3369 37.9688C36.3369 36.9979 35.55 36.2109 34.5791 36.2109C33.6083 36.2109 32.8213 36.9979 32.8213 37.9688C32.8213 39.9073 31.2442 41.4844 29.3057 41.4844H7.69434C5.75582 41.4844 4.17871 39.9073 4.17871 37.9688V7.03125C4.17871 5.09273 5.75582 3.51562 7.69434 3.51562H29.3057C31.2442 3.51562 32.8213 5.09273 32.8213 7.03125V29.1797C32.8213 30.1505 33.6083 30.9375 34.5791 30.9375Z" />
      <path d="M18.4819 6.99449C17.7336 6.37626 16.6255 6.48156 16.0072 7.23003L12.2099 11.8261L10.7115 10.2874C10.0343 9.59192 8.92137 9.57706 8.2258 10.2543C7.53032 10.9316 7.51547 12.0445 8.19276 12.7401L10.0784 14.6766C10.0939 14.6926 10.1097 14.7082 10.1259 14.7236C10.6957 15.266 11.4562 15.5676 12.2385 15.5676C12.3057 15.5674 12.3733 15.5652 12.4408 15.5607C13.2885 15.5041 14.0801 15.0939 14.6151 14.4344L18.7174 9.46922C19.3358 8.72075 19.2303 7.6128 18.4819 6.99449Z" />
      <path d="M18.4818 28.429C17.7335 27.8108 16.6255 27.9162 16.0072 28.6646L12.2088 33.2619L10.7081 31.7292C10.0288 31.0356 8.9159 31.024 8.22227 31.7032C7.52864 32.3824 7.51704 33.4954 8.19617 34.189L10.0818 36.1146C10.0962 36.1293 10.1109 36.1438 10.1258 36.158C10.6956 36.7004 11.4562 37.0021 12.2385 37.0021C12.3058 37.002 12.3733 36.9998 12.4408 36.9953C13.2884 36.9388 14.08 36.5285 14.6151 35.8691L18.7174 30.9038C19.3358 30.1553 19.2303 29.0473 18.4818 28.429Z" />
      <path d="M27.5576 11.3379H22.3721C21.4012 11.3379 20.6143 12.1249 20.6143 13.0957C20.6143 14.0665 21.4012 14.8535 22.3721 14.8535H27.5576C28.5285 14.8535 29.3154 14.0665 29.3154 13.0957C29.3154 12.1249 28.5285 11.3379 27.5576 11.3379Z" />
      <path d="M18.4818 17.7943C17.7335 17.176 16.6255 17.2815 16.0072 18.0298L12.2088 22.6271L10.7081 21.0945C10.0288 20.4009 8.9159 20.3893 8.22227 21.0685C7.52864 21.7477 7.51704 22.8607 8.19617 23.5543L10.0818 25.4799C10.0962 25.4946 10.1109 25.5091 10.1258 25.5233C10.6956 26.0657 11.4562 26.3674 12.2385 26.3674C12.3058 26.3672 12.3733 26.365 12.4408 26.3606C13.2884 26.304 14.08 25.8938 14.6151 25.2343L18.7174 20.269C19.3358 19.5206 19.2303 18.4126 18.4818 17.7943Z" />
    </svg>
  );
};

export default Exam;
