import { IconProps } from "../typings";

const Camera = ({ className, color, size }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 36"
      height={size}
      width={size}
      fill={color}
    >
      {/* {Array.isArray(gradient) && (
        <defs>
          <linearGradient id={gradientKey} x1="0" x2="1" y1="0" y2="1">
            {gradient.map((grad) => (
              <stop
                key={`${grad.offsetPercent}-${grad.color}`}
                offset={`${grad.offsetPercent}%`}
                stopColor={grad.color}
              />
            ))}
          </linearGradient>
        </defs>
      )} */}
        {/* fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""} */}
        <path d="M45 30C45 30.7956 44.6839 31.5587 44.1213 32.1213C43.5587 32.6839 42.7957 33 42 33H6C5.20435 33 4.44129 32.6839 3.87868 32.1213C3.31607 31.5587 3 30.7956 3 30V12C3 11.2044 3.31607 10.4413 3.87868 9.87868C4.44129 9.31607 5.20435 9 6 9H9.516C11.9017 8.9987 14.1893 8.05021 15.876 6.363L18.366 3.879C18.927 3.31782 19.6875 3.00176 20.481 3H27.513C28.3086 3.00017 29.0715 3.31635 29.634 3.879L32.118 6.363C32.9539 7.19914 33.9463 7.8624 35.0386 8.31486C36.1309 8.76733 37.3017 9.00014 38.484 9H42C42.7957 9 43.5587 9.31607 44.1213 9.87868C44.6839 10.4413 45 11.2044 45 12V30ZM6 6C4.4087 6 2.88258 6.63214 1.75736 7.75736C0.632141 8.88258 0 10.4087 0 12L0 30C0 31.5913 0.632141 33.1174 1.75736 34.2426C2.88258 35.3679 4.4087 36 6 36H42C43.5913 36 45.1174 35.3679 46.2426 34.2426C47.3679 33.1174 48 31.5913 48 30V12C48 10.4087 47.3679 8.88258 46.2426 7.75736C45.1174 6.63214 43.5913 6 42 6H38.484C36.8928 5.99966 35.367 5.3673 34.242 4.242L31.758 1.758C30.633 0.632704 29.1072 0.000339824 27.516 0H20.484C18.8928 0.000339824 17.367 0.632704 16.242 1.758L13.758 4.242C12.633 5.3673 11.1072 5.99966 9.516 6H6Z"/>
        <path d="M24 27C22.0109 27 20.1032 26.2098 18.6967 24.8033C17.2902 23.3968 16.5 21.4891 16.5 19.5C16.5 17.5109 17.2902 15.6032 18.6967 14.1967C20.1032 12.7902 22.0109 12 24 12C25.9891 12 27.8968 12.7902 29.3033 14.1967C30.7098 15.6032 31.5 17.5109 31.5 19.5C31.5 21.4891 30.7098 23.3968 29.3033 24.8033C27.8968 26.2098 25.9891 27 24 27ZM24 30C26.7848 30 29.4555 28.8938 31.4246 26.9246C33.3938 24.9555 34.5 22.2848 34.5 19.5C34.5 16.7152 33.3938 14.0445 31.4246 12.0754C29.4555 10.1062 26.7848 9 24 9C21.2152 9 18.5445 10.1062 16.5754 12.0754C14.6062 14.0445 13.5 16.7152 13.5 19.5C13.5 22.2848 14.6062 24.9555 16.5754 26.9246C18.5445 28.8938 21.2152 30 24 30ZM9 13.5C9 13.8978 8.84196 14.2794 8.56066 14.5607C8.27936 14.842 7.89782 15 7.5 15C7.10218 15 6.72064 14.842 6.43934 14.5607C6.15804 14.2794 6 13.8978 6 13.5C6 13.1022 6.15804 12.7206 6.43934 12.4393C6.72064 12.158 7.10218 12 7.5 12C7.89782 12 8.27936 12.158 8.56066 12.4393C8.84196 12.7206 9 13.1022 9 13.5Z"/>
    </svg>
  );
};

export default Camera;
