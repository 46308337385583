/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { INTERFACE_PATHS, PPF_LOGO_FULL, SITE_THEMES } from "data/constants/";
import { getPublicImagePath, logoutUser } from "data/helpers/";
import { SessionHandling } from "data/storage/";

import {
  Button,
  Image,
  Link,
  SessionUserThumb,
  Icon,
} from "components/common/";

const themeOptions = [
  {
    label: "Dark",
    value: SITE_THEMES.dark,
  },
  {
    label: "Light",
    value: SITE_THEMES.light,
  },
  {
    label: "Default (OS Setting)",
    value: SITE_THEMES.system,
  },
];

interface Props extends CMMenu.MenuWrapperProps {
  children?: any;
}

const GenericMenuContent = ({
  buildNumber,
  children,
  GeneralStore,
  hasParentSite,
  siteTheme,
  version,
  ...props
}: Props) => {
  const [userName, updateUserName] = React.useState(() => {
    return SessionHandling.getPropertyFromActiveSession("userName") ?? "";
  });
  const menuIsShowing = _.get(GeneralStore, "state.showMenu", false);
  const isMobile = _.get(GeneralStore, "state.isMobile", false);

  if (!menuIsShowing) {
    return null;
  }

  return (
    <aside className={cx("cm-menu", props.className)}>
      {menuIsShowing && isMobile && (
        <button
          className="cm-menu-black-overlay"
          onClick={() => {
            if (GeneralStore.dispatch) {
              GeneralStore.dispatch({
                payload: {},
                type: "GENERAL_STORE_TOGGLE_NAVMENU",
              });
            }
          }}
        />
      )}
      <div className="cm-menu-contents-wrapper">
        <section className="cm-menu-top-cont">
          <Link
            className={cx("cm-menu-logo-anchor-cont", false && "searching")}
            href={INTERFACE_PATHS.HOME}
            value="Domain Logo Home Link"
            ariaTitle="Home Page Link"
            // onClick={trackHomeImageLinkClick}
          >
            <Image
              alt="Tryyb Logo"
              className="cm-menu-logo"
              src={getPublicImagePath(PPF_LOGO_FULL)}
            />
          </Link>

          <div className="cm-menu-user-info-wrapper-row">
            <Link
              className={cx("cm-menu-user-info-profile")}
              href={INTERFACE_PATHS.PROFILE}
              value="Profile"
              ariaTitle="Profile Link"
            >
              <div className="cm-menu-user-info-cont">
                <div className="cm-menu-user-info-userthumb-cont">
                  <SessionUserThumb
                    className="cm-menu-user-info-userthumb"
                    size={48}
                    userName=""
                  />
                </div>

                <div className="cm-menu-user-info-userdata-cont">
                  <h3 className="cm-menu-user-info-userdata-username">
                    {userName}
                  </h3>
                </div>
              </div>
            </Link>
            <Icon
              icon={
                hasParentSite && false
                  ? (`angle-${
                      GeneralStore.state?.showMenu ? "left" : "right"
                    }` as "angle-left" | "angle-right")
                  : `${GeneralStore.state?.showMenu ? "close" : "bars"}`
              }
              buttonProps={{
                className: "cm-nav-toggle-btn-sidebar",
                name: "Menu Toggle Button",
              }}
              isButton={true}
              onClick={() => {
                if (GeneralStore.dispatch) {
                  GeneralStore.dispatch({
                    payload: {},
                    type: "GENERAL_STORE_TOGGLE_NAVMENU",
                  });
                }
              }}
              size={isMobile ? 20 : 25}
            />
          </div>
        </section>

        <section className="cm-menu-inner-cont">{children}</section>

        <footer className="cm-menu-footer">
          <div className="cm-menu-footer-logout-cont">
            <Button
              type="button"
              onClick={() => {
                logoutUser({
                  AppStore: props.AppStore,
                  onSuccess: () => {},
                  onError: () => {},
                  withPrompt: true,
                });
              }}
              value="Sign Out"
              theme="action"
            />
          </div>

          <span className="cm-menu-build text-color">
            Version: <span className="cm-menu-build-num">{version}</span>
          </span>
          {buildNumber && (
            <span className="cm-menu-build text-color">
              Build: <span className="cm-menu-build-num">{buildNumber}</span>
            </span>
          )}

          {/* <TextButton
            className="cm-menu-footer-sitetheme-toggle text-color"
            onClick={() => {
              let newSiteTheme = "dark";

              if (siteTheme === "dark") {
                newSiteTheme = "light";
              }

              if (GeneralStore.dispatch) {
                GeneralStore.dispatch({
                  payload: {
                    siteTheme: newSiteTheme,
                  },
                  type: "GENERAL_STORE_UPDATE_SITE_THEME",
                });
              }
            }}
            value={
              siteTheme === "dark"
                ? "Switch to Light Mode"
                : "Switch to Dark Mode (default)"
            }
          /> */}

          {/* <Select
            className="cm-menu-contents-theme-options"
            onChange={(newVal) => {
              if (GeneralStore.dispatch) {
                GeneralStore.dispatch({
                  payload: {
                    siteTheme: `${newVal}`,
                  },
                  type: "GENERAL_STORE_UPDATE_SITE_THEME",
                });
              }
            }}
            options={themeOptions}
            value={siteTheme}
          /> */}
        </footer>
      </div>
    </aside>
  );
};

export default GenericMenuContent;
