import * as React from "react";

import { IconProps } from "../typings";

const ArrowAltFromTop = ({
  className,
  color,
  gradient,
  gradientKey,
  size,
}: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      height={size}
      width={size}
      fill={color}
    >
      {Array.isArray(gradient) && (
        <defs>
          <linearGradient id={gradientKey} x1="0" x2="1" y1="0" y2="1">
            {gradient.map((grad) => (
              <stop
                key={`${grad.offsetPercent}-${grad.color}`}
                offset={`${grad.offsetPercent}%`}
                stopColor={grad.color}
              />
            ))}
          </linearGradient>
        </defs>
      )}
      <path
        fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""}
        d="M12 32h360c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H12C5.4 64 0 58.6 0 52v-8c0-6.6 5.4-12 12-12zm218.9 96h-77.7c-8.8 0-16 7.2-16 16v144H43.3c-7.1 0-10.7 8.6-5.7 13.6l143.1 143.5c6.3 6.3 16.4 6.3 22.7 0l143.1-143.5c5-5 1.5-13.6-5.7-13.6h-93.9V144c0-8.8-7.2-16-16-16m0-32c26.5 0 48 21.5 48 48v112h61.9c35.6 0 53.4 43.1 28.3 68.2L226 467.8c-18.8 18.8-49.2 18.8-68 0L14.9 324.2C-10.2 299 7.8 256 43.3 256h61.9V144c0-26.5 21.5-48 48-48h77.7z"
      />
    </svg>
  );
};

export default ArrowAltFromTop;
