/*
 * Component Description
 * Search Input is a wrapper that adds icon and clear icon to input
 */
import * as React from "react";
import cx from "classnames";
import { Input, Icon } from "components/common/";
import { Props as CCInputProps } from "../input/";

import "../input/style.scss";
import "./style.scss";

type SearchInputType = "input";

interface Props extends CCInputProps {
  // autoFocus?: boolean;
  // containerClassName?: string;
  // className?: string;
  // disabled?: boolean;
  // id?: string;
  // maxLength?: number;
  // name: string;
  // onChange: (newVal: string) => void;
  // onEnter?: () => void;
  // readOnly?: boolean;
  // rows?: number;
  // placeholder?: string;
  // showCount?: boolean;
  // style?: React.CSSProperties;
  // type?: SearchInputType;
  // value: string;
  // onSearchClear?: () => void;
}

// const DEFAULT_INPUT_TYPE = "input";

const SearchInput = ({ className, containerClassName, ...props }: Props) => {
  return (
    <div className={cx("cc-search-input-wrapper", containerClassName)}>
      <Icon
        className="cc-search-input-eyeglass"
        isButton={false}
        icon="search"
        size={19}
      />

      <Input {...props} className={cx("cc-search-input", className)} />

      {props.value && (<Icon
        className="cc-search-input-clear-icon"
        buttonProps={{
          className: "cc-search-input-clear-icon-btn btn-touch-reaction",
        }}
        isButton={true}
        onClick={() => props.onChange("")}
        icon="close"
        size={24}
      />)}
    </div>
  );
};

export default SearchInput;
