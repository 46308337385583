import { ENDPOINT_GS_PLANS } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_GS_PLANS;

export const GSPlans = {
  get(params: Endpoints.Tyto.GS.Plans.GetParameters, callOpts?: CallOpts) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Plans.Get>;
  },
};
