import { ENDPOINT_GS_PPF_DASHBOARD_SUMMARY } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_GS_PPF_DASHBOARD_SUMMARY;

export const GSPPFDashboardSummary = {
  get(
    params: Endpoints.Tyto.GS.PPFDashboard.summary.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.PPFDashboard.summary.Get>;
  },
};
