/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import ImageGrid from "./subcomponents/image-grid/";
import ImageCropper from "./subcomponents/image-cropper/";

interface Props extends Modals.CommonProps, Modals.ImagePickerProps {}

const ImagePickerModal = (props: Props) => {
  const [imageFromSystemSelect, updateImageFromSystemSelect] =
    React.useState<File | null>(props.initialFile ?? null);
  // const [selectedTemplateImage, updateSelectedTemplateImage] =
  //   React.useState<Data.PPFTemplateImage | null>(null);

  return (
    <div className={cx("imagepicker-modal-inner-cont", props.className)}>
      {imageFromSystemSelect ? (
        <ImageCropper
          imageFile={imageFromSystemSelect}
          theme={props.theme}
          onImageSelect={props.onImageSelect}
        />
      ) : (
        <ImageGrid
          onTemplateImageSelect={props.onTemplateImageSelect}
          onSystemImageSelect={updateImageFromSystemSelect}
          theme={props.theme}
        />
      )}
    </div>
  );
};

export default ImagePickerModal;
