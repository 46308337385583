/**
 * This File is intended as Single Source of Truth for CRUD operations against
 * the Client-Side running instance of ReactQuery. It can be considered a reusable
 * Library for both Updating and Invalidating Specific Caches within ReactQuery
 *
 * It is *NOT* intended as a place to do any of the following:
 * - Make Network Requests
 * - Update/Manipulate the DOM
 * - Dispatch Events to a 'Store' (data/Stores/*)
 */
import _ from "lodash";

import { REACT_QUERY_KEYS } from "data/constants/";
import { SessionHandling } from "data/storage/";
import { queryClient } from "data/network/";

import { updatePlanPermsBeforeProceeding } from "data/network/special-calls/";

// * ====================================
// * RQ READ/WRITE METHODS (START) ======
export function getRQPlanData(planID: number) {
  const plan = queryClient.getQueryData([REACT_QUERY_KEYS.PLAN, planID]);

  return (plan as Endpoints.Responses.GS.Plan.Get | undefined)?.gsPlan;
}

export function setRQPlansData(plansResp: Endpoints.Responses.GS.Plans.Get) {
  queryClient.setQueryData(
    [REACT_QUERY_KEYS.PLANS],
    _.omit(plansResp, ["session", "error", "links"])
  );

  return true;
}

interface PlanDataSet extends Partial<TytoData.PPF.Plan.Plan> {
  gsPlanID: number;
}

export function setRQPlanData(planData: PlanDataSet) {
  if (!planData.gsPlanID) {
    return false;
  }

  const plan = getRQPlanData(planData.gsPlanID);

  if (!plan) {
    return false;
  }

  queryClient.setQueryData([REACT_QUERY_KEYS.PLAN, planData.gsPlanID], {
    ...plan,
    ..._.omit(planData, "gsPlanID"),
  });

  return true;
}

export function getRQPlanMembersData(planID: number) {
  const planMembers = queryClient.getQueryData([
    REACT_QUERY_KEYS.PLAN_MEMBERS,
    planID,
  ]);

  return (planMembers as Endpoints.Responses.GS.Members.Get | undefined)
    ?.gsMembers;
}

export function setRQPlanMembersData(
  planID: number,
  members: TytoData.PPF.Plan.Member[]
) {
  queryClient.setQueryData([REACT_QUERY_KEYS.PLAN_MEMBERS, planID], {
    gsMembers: members,
  });

  return true;
}

export function getRQGoalsData(planID: number) {
  const cachedGoals = queryClient.getQueryData([
    REACT_QUERY_KEYS.PLAN_GOALS,
    planID,
  ]);

  return (cachedGoals as Endpoints.Responses.GS.Goals.Get | undefined)?.gsGoals;
}

export function setRQGoalsData(
  planID: number,
  parentGoals: TytoData.PPF.Plan.Goals.ParentGoal[]
) {
  if (!planID || !parentGoals) {
    return false;
  }

  queryClient.setQueryData([REACT_QUERY_KEYS.PLAN_GOALS, planID], {
    gsGoals: parentGoals,
  });

  return true;
}

export function getRQNoticesData(planID: number) {
  const cachedNotices = queryClient.getQueryData([
    REACT_QUERY_KEYS.PLAN_NOTICEBOARD,
    planID,
  ]);

  return (cachedNotices as Endpoints.Responses.GS.Plan.Notices.Get | undefined)
    ?.notices;
}

export function setRQNoticesData(
  planID: number,
  notices: TytoData.Notices.Notice[]
) {
  if (!planID || !notices) {
    return false;
  }

  queryClient.setQueryData([REACT_QUERY_KEYS.PLAN_NOTICEBOARD, planID], {
    notices,
  });

  return true;
}
// * RQ READ/WRITE METHODS (END) ========
// * ====================================
// *
// *
// * ====================================
// * UPDATE METHODS (START) =============
/**
 * Update data around a 'childGoal' inside the Goals ReactQuery Cache
 * @param {ChildGoal Object} goal - ChildGoal you want to update
 * @returns {Boolean} - Inidicating whether it successfully found and updated the Goal in the RQ Cache
 */
export function updateChildGoalData({
  goal,
  data,
}: {
  goal: TytoData.PPF.Plan.Goals.ChildGoal;
  data: Partial<
    Omit<
      TytoData.PPF.Plan.Goals.ChildGoal,
      "gsGoalID" | "gsPlanID" | "parentGoalID"
    >
  >;
}) {
  const {
    gsPlanID: planID,
    gsParentGoalID: parentGoalID,
    gsGoalID: goalID,
  } = goal ?? {};

  if (!planID) {
    return false;
  }

  // * Get local copy of current 'Goals' data from queryClient
  const curQueryData = getRQGoalsData(planID);

  if (!curQueryData) {
    return false;
  }

  // * Iterate through list of 'parentGoals' to find parent of target Goal
  // ! IMPORTANT ! This approach does *not* account for concept of having childGoals inside other childGoals
  // ! AKA having goals deeper than 2 total levels (ParentGoals > ChildGoals)
  const updatedData = curQueryData.map((parentGoal) => {
    if (parentGoalID && parentGoalID === parentGoal.gsGoalID) {
      const childGoals = parentGoal.childGoals ?? [];

      const updatedChildGoals = childGoals.map((childGoal) => {
        if (childGoal.gsGoalID === goalID) {
          return {
            ...childGoal,
            ...data,
            modifiedDate: new Date().toISOString(),
          };
        }

        return childGoal;
      });

      return {
        ...parentGoal,
        childGoals: updatedChildGoals,
      };
    }

    return parentGoal;
  });

  return setRQGoalsData(planID, updatedData);
}

/**
 * Update data around a 'parentGoal' inside the Goals ReactQuery Cache
 * @param {ChildGoal Object} goal - ParentGoal you want to update
 * @returns {Boolean} - Inidicating whether it successfully found and updated Goal in the RQ Cache
 */
export function updateParentGoalData({
  goal,
  data,
}: {
  goal: TytoData.PPF.Plan.Goals.ChildGoal;
  data: Partial<
    Omit<
      TytoData.PPF.Plan.Goals.ParentGoal,
      "gsGoalID" | "gsPlanID" | "parentGoalID"
    >
  >;
}) {
  const {
    gsPlanID: planID,
    gsParentGoalID: parentGoalID,
    gsGoalID: goalID,
  } = goal ?? {};

  if (!planID) {
    return false;
  }

  // * Get local copy of current 'Goals' data from queryClient
  const curQueryData = getRQGoalsData(planID);

  if (!curQueryData) {
    return false;
  }

  // * Iterate through list of 'parentGoals' to find parent of target Goal
  // ! IMPORTANT ! This approach does *not* account for concept of having childGoals inside other childGoals
  // ! AKA having goals deeper than 2 total levels (ParentGoals > ChildGoals)
  const updatedData = curQueryData.map((parentGoal) => {
    if (parentGoal.gsGoalID === goalID) {
      return {
        ...parentGoal,
        ...data,
      };
    }

    return parentGoal;
  });

  return setRQGoalsData(planID, updatedData);
}

/**
 * Removes child goal from parents list and updates ReactQuery Cache
 * @param {Number} childGoalID  - ChildGoal you want to remove
 * @returns {Boolean} - Inidicating whether it successfully found and updated Goal in the RQ Cache
 */
export function removeChildGoal(gsGoalID: number, planID: number) {
  if (!planID) {
    return false;
  }

  // * Get local copy of current 'Goals' data from queryClient
  let parentGoalsForPlan = getRQGoalsData(planID);

  if (!parentGoalsForPlan) {
    return false;
  }

  //Try to find the matching child goal in each parents list
  const childGoal = parentGoalsForPlan.reduce(
    (
      matchingChildGoal: TytoData.PPF.Plan.Goals.ChildGoal | null,
      parentGoal
    ) => {
      //If there is no matching child yet try to find the childgoal inside the current parent goals childgoals list
      if (!matchingChildGoal) {
        const matchingChild = (parentGoal.childGoals ?? []).find(
          (childGoal) => childGoal.gsGoalID === gsGoalID
        );
        //If you find it update it
        if (matchingChild) {
          return matchingChild;
        }
      }

      return matchingChildGoal;
    },
    null
  );

  // If we find it then we remove it.
  const { gsParentGoalID: parentGoalID } = childGoal ?? {};

  // * Iterate through list of 'parentGoals' to find parent of target Goal
  // Save updated list
  const updatedData = parentGoalsForPlan.map((parentGoal) => {
    if (parentGoal.gsGoalID === parentGoalID) {
      const childGoals = (parentGoal.childGoals ?? []).filter(
        (childGoal) => childGoal.gsGoalID !== gsGoalID
      );
      return {
        ...parentGoal,
        childGoals,
      };
    }

    return parentGoal;
  });

  return setRQGoalsData(planID, updatedData);
}

/**
 * Update the 'gsGoalItems' for a Goal, either updating existing items, folding in new ones, or both
 * @param {TytoData.PPF.Plan.Goals.ChildGoal} goal - The 'childGoal' whose 'gsGoalItems' Array is being updated
 * @param {TytoData.PPF.Plan.Goals.GoalItem[]} existingItemUpdates - Items that already exist Server side, that have been updated
 * @param {TytoData.PPF.Plan.Goals.GoalItem[]} newItems - Items that were just created and are desired to be folded into the Array
 * @returns
 */
export function updateChildGoalItems({
  existingItemUpdates,
  newItems,
  goal,
}: {
  existingItemUpdates?: TytoData.PPF.Plan.Goals.GoalItem[];
  newItems?: TytoData.PPF.Plan.Goals.GoalItem[];
  goal: TytoData.PPF.Plan.Goals.ChildGoal;
}) {
  const {
    gsPlanID: planID,
    gsParentGoalID: parentGoalID,
    gsGoalID: goalID,
  } = goal ?? {};

  if (!planID) {
    return false;
  }

  // * Get local copy of current 'Goals' data from queryClient
  const curQueryData = getRQGoalsData(planID);

  if (!curQueryData) {
    return;
  }

  const existingItemsKeyed = _.keyBy(existingItemUpdates, "gsGoalItemKey");

  const mutatedGoalsData = curQueryData.map((curParentGoal) => {
    // * If ParentGoal matches target Goals ParentID, mutate, otherwise just return parentGoal untouched
    if (parentGoalID && curParentGoal.gsGoalID === parentGoalID) {
      // * Create new 'childGoals' Array for matching ParentGoal
      const mutatedChildGoals = (curParentGoal?.childGoals ?? []).map(
        (curGoal) => {
          // * If childGoal matches targetChild Goal, mutate it, otherwise return 'childGoal' untouched
          if (curGoal.gsGoalID === goal.gsGoalID) {
            let existingItemsCopy = [...(goal.gsGoalItems ?? [])];

            // * If there are existing items whose data is updated, do that
            if (existingItemUpdates?.length) {
              existingItemsCopy = existingItemsCopy.map((item) => {
                if (existingItemsKeyed[item.gsGoalItemKey]) {
                  return {
                    ...item,
                    ...existingItemsKeyed[item.gsGoalItemKey],
                  };
                }

                return item;
              });
            }

            // * If there are new items, fold them into list at the end
            if (newItems?.length) {
              existingItemsCopy = [...existingItemsCopy, ...newItems];
            }

            // * Return 'childGoal' with mutated/updated 'gsGoalItems' Array
            return {
              ...curGoal,
              gsGoalItems: existingItemsCopy,
            };
          }

          // * Untouched, non-matching 'childGoal'
          return curGoal;
        }
      );

      return {
        ...curParentGoal,
        childGoals: mutatedChildGoals,
      };
    }

    return curParentGoal;
  });

  return setRQGoalsData(planID, mutatedGoalsData);
}

/**
 * Simple Wrapper around 'updateCommentData' for Toggling 'Liked' value of a Comment
 * @param {Number} commentID - ID of Comment
 * @param {Boolean} isNowLiked - Whether the comment is now Liked or no longer liked
 * @param {number} noticeID - ID of Notice the Comment belongs to
 * @param {Number} planID - ID of plan the notice Thread belongs
 * @returns
 */
export function updateCommentLike({
  commentID,
  isNowLiked,
  noticeID,
  planID,
}: {
  commentID: number;
  isNowLiked: boolean;
  noticeID: number;
  planID: number;
}) {
  return updateCommentData({
    comment: {
      noticeID,
      commentID,
    },
    planID,
    data: {
      ratingMemberLiked: isNowLiked ? 1 : 0,
    },
  });
}

/**
 * Update the cached Data for a Comment
 * @param {Object} comment - The full comment object (before or after current update)
 * @param {Number} planID - Which plan this Comment's Notice Thread belongs to
 * @param {Object} data - properties pertaining to the comment that were updated
 * @returns
 */
export function updateCommentData({
  comment,
  planID,
  data,
}: {
  comment: Partial<TytoData.Notices.Comment>;
  planID: number;
  data: Partial<TytoData.Notices.Comment>;
  // "commentText" | "activeStatus" | "ratingSumLiked" | "ratingMemberLiked"
}) {
  const { noticeID, commentID } = comment ?? {};

  if (!noticeID || !planID) {
    return false;
  }

  const curQueryData = getRQNoticesData(planID);

  if (!curQueryData) {
    return false;
  }

  const updatedNotices = curQueryData.map((curNotice) => {
    if (curNotice.noticeID === noticeID) {
      const updatedComments = (curNotice.comments ?? []).map((curComment) => {
        if (curComment.commentID === commentID) {
          return {
            ...curComment,
            ...data,
          };
        }

        return curComment;
      });

      return {
        ...curNotice,
        comments: updatedComments,
      };
    }

    return curNotice;
  });

  return setRQNoticesData(planID, updatedNotices);
}

/**
 * Update the cached Data for a Notice
 * @param {Object} notice - The full notice object (before or after current update)
 * @param {Object} data - properties pertaining to the Notice that were updated
 * @returns
 */
export function updateNoticeData({
  notice,
  data,
}: {
  notice: TytoData.Notices.Notice;
  data: Pick<
    TytoData.Notices.Notice,
    | "aboutID"
    | "aboutType"
    | "comments"
    | "isNew"
    | "sortDate"
    | "lastCommentDate"
    | "activeStatus"
  >;
}) {
  const { noticeID, toElementID, toElementType } = notice ?? {};

  if (!noticeID || !toElementID || toElementType !== "ocGSPLAN") {
    return false;
  }

  const curQueryData = getRQNoticesData(toElementID);

  if (!curQueryData) {
    return false;
  }

  const updatedNotices = curQueryData.map((curNotice) => {
    if (curNotice.noticeID === noticeID) {
      return {
        ...curNotice,
        ..._.omit(data, ["commentID", "noticeID"]),
      };
    }

    return curNotice;
  });

  return setRQNoticesData(toElementID, updatedNotices);
}

export function updatePlanMembers({
  planID,
  members,
}: {
  planID: number;
  members: TytoData.PPF.Plan.Member[];
}) {
  const planMembersQuery = getRQPlanMembersData(planID);

  if (!planMembersQuery) {
    return;
  }

  const updatedPlanMembersData = _.uniqBy(
    [...(members ?? []), ...(planMembersQuery ?? [])],
    "gsMemberID"
  );

  return setRQPlanMembersData(planID, updatedPlanMembersData);
}
// * UPDATE METHODS (END) ===============
// * ====================================
// *
// *
// * ====================================
// * INVALDATION METHODS (START) ========
export function invalidatePersonNotices({
  top = 50,
  userID,
}: {
  top?: number;
  userID: number;
}) {
  if (!userID) {
    return false;
  }

  queryClient.invalidateQueries([
    REACT_QUERY_KEYS.PERSON_NOTICES,
    top,
    userID ?? null,
  ]);

  return true;
}

/**
 * Invalidate the ReactQuery Client Cache for a specific Plan's Goals
 * @param {Number} planID - ID of plan whose goals you wish to invalidate
 * @returns {Boolean} - untrustworthy boolean of whether it thinks it succeeded
 */
export function invalidateGoalsCache(planID: number) {
  debugger;
  if (!planID) {
    return false;
  }

  queryClient.invalidateQueries([REACT_QUERY_KEYS.PLAN_GOALS, planID]);

  return true;
}

/**
 * Invalidate the ReactQuery Client Cache for a specific Plan's Members
 * @param {Number} planID - ID of plan whose goals you wish to invalidate
 * @returns {Boolean} - untrustworthy boolean of whether it thinks it succeeded
 */
export function invalidateMembersCache(planID: number) {
  if (!planID) {
    return false;
  }

  queryClient.invalidateQueries([REACT_QUERY_KEYS.PLAN_MEMBERS, planID]);

  return true;
}

/**
 * Invalidate the ReactQuery Client Cache for a specific Plan's NoticeBoards
 * @param {Number} planID - ID of plan whose goals you wish to invalidate
 * @returns {Boolean} - untrustworthy boolean of whether it thinks it succeeded
 */
export function invalidateNoticeBoardsCache(planID: number) {
  if (!planID) {
    return false;
  }

  queryClient.invalidateQueries([REACT_QUERY_KEYS.PLAN_NOTICEBOARD, planID]);

  return true;
}

/**
 * Invalidate the ReactQuery Client Cache for a specific Plan's GET Data
 * @param {Number} planID - ID of plan whose goals you wish to invalidate
 * @returns {Boolean} - untrustworthy boolean of whether it thinks it succeeded
 */
export function invalidatePlanCache(planID: number) {
  if (!planID) {
    return false;
  }

  queryClient.invalidateQueries([REACT_QUERY_KEYS.PLAN, planID]);

  return true;
}

/**
 * Invalidate the ReactQuery Client Cache of Generic Plans (Plural) GET Data
 * @param {Number} planID - ID of plan whose goals you wish to invalidate
 * @returns {Void} - Does not return anything
 */
export function invalidatePlansCache() {
  queryClient.invalidateQueries(REACT_QUERY_KEYS.PLANS);
}

// * INVALDATION METHODS (END) ==========
// * ====================================
// *
// *
// * ====================================
// * PERMISSION CHECKS (START) ==========
export function getPlanPermissions(planID: number) {
  return getRQPlanData(planID)?.permission;
}

export function hasExplicitPermission(planID: number) {
  let hasImplicitPermission = true;

  const permissionsActiveStatus =
    getRQPlanData(planID)?.permission?.activeStatus;

  switch (permissionsActiveStatus) {
    case "ocENABLED":
      return true;
    case "ocDISABLED":
    case "ocIMPLICIT":
    default:
      return false;
  }
}

export function runPermissionCheck({
  memberID,
  planID,
  targetPermissionCheck = "goalChange",
  onCancel,
  onError,
  onSuccess,
}: {
  memberID?: number;
  planID: number;
  targetPermissionCheck?: keyof Omit<
    TytoData.PPF.Plan.Member,
    | "createdByID"
    | "createdDate"
    | "modifiedByID"
    | "modifiedDate"
    | "gsMemberID"
    | "memberID"
    | "gsPlanID"
    | "activeStatus"
  >;
  onCancel: () => void;
  onError: (errorMsg: string) => void;
  onSuccess: () => void;
}) {
  const memID =
    memberID ?? SessionHandling.getPropertyFromActiveSession("userID") ?? 0;

  const hasPermission = hasExplicitPermission(planID);

  if (hasPermission) {
    onSuccess();
    return;
  }

  const membersList = getRQPlanMembersData(planID);
  const memberInMembersList =
    membersList?.find?.((member) => member.memberID === memID) ?? undefined;

  if (hasPermission || memberInMembersList?.[targetPermissionCheck]) {
    onSuccess();
  } else {
    updatePlanPermsBeforeProceeding({
      memberID: memID,
      planID,
      onSuccess,
      onError,
      onCancel,
    });
    // onError();
  }
}
// * PERMISSION CHECKS (END) ============
// * ====================================
