import * as React from "react";

import { DEFAULT_PARENT_CHILD_INDEX_VALUES, getKeyedData } from "./";

export const useKeyedData = (
  parentGoals: TytoData.PPF.Plan.Goals.ParentGoal[]
) => {
  const computationCount = React.useRef(1);

  const keyedData = React.useMemo(() => {
    console.log("getKeyedData called. Count: ", computationCount.current);
    computationCount.current += 1;

    return getKeyedData(parentGoals);
  }, [parentGoals]);

  // const [keyedData, updateKeyedData] = React.useState(() => {
  //   return getKeyedData(parentGoals);
  // });

  // React.useEffect(() => {
  //   updateKeyedData(getKeyedData(parentGoals));
  // }, [parentGoals]);

  return keyedData;
};
