/*
 * Alias for CC/Link that is a button
 */
import * as React from "react";
import Link, { Props as LinkProps } from "../link/";

export interface Props extends LinkProps {}

const TextButton = (props: Props) => {
  return <Link {...props} />;
};

export default TextButton;
