/*
 * Top Level interface Routing for whole Site.
 * Additionally, it manages rerouting to /Login is session is bad
 */
import * as React from "react";
import _ from "lodash";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  useParams,
  useLocation,
  Switch,
  useHistory,
} from "react-router-dom";

// import Storage from "../data/storage/index";
import { StoreContext as GeneralStoreContext } from "../data/stores/GeneralStore";
import { StoreContext as AppStoreContext } from "../data/stores/AppStore";
import {
  INTERFACE_PATHS,
  INTERFACE_SEARCH_PARAMS,
  HAS_LOCAL_STORAGE,
  SUB_INTERFACE_PATHS,
} from "../data/constants/";
import { handleInvalidSession } from "../data/helpers/";
import { onSessionDataChange } from "./helpers";
import { useTraining } from "../data/network/hooks/";

import CreatePlan from "interfaces/create-plan/";
import FourZeroFour from "./special-interfaces/404";
import Library from "../interfaces/library";
import Home from "../interfaces/home";
import Introduction from "interfaces/introduction/";
import Login from "../interfaces/login";
import LoginToTargetSession from "./special-interfaces/LoginToTargetSession";
import Profile from "../interfaces/profile/";
import PPFPlans from "interfaces/plans";
import PPFPlan from "interfaces/plan";
import Statistics from "interfaces/statistics/";

import { ActiveSession } from "data/storage/session-data";
import "./styles.scss";
interface Props {
  isOnline: boolean;
  visibilityState: "prerender" | "hidden" | "visible";
}

/*
    interface GeneralStoreInjectedProps {
    GeneralStoreState: StoreAPI.GeneralStoreState;
    GeneralStoreDispatch: StoreAPI.GeneralStoreDispatch;
  }
*/

const Routing = (props: Props) => {
  let GeneralStore = React.useContext(GeneralStoreContext);
  let AppStore = React.useContext(AppStoreContext);

  const [curSessionData, updateCurSessionData] = React.useState(
    _.get(AppStore, "state.sessionData")
  );
  const [isInitalLoad, updateIsInitialLoad] = React.useState(true);

  const stateSessionData = _.get(AppStore, "state.sessionData");

  // * Watch for changes to curSessionData
  React.useEffect(() => {
    const stateSessionData = _.get(AppStore, "state.sessionData");
    // debugger;

    if (
      stateSessionData &&
      (isInitalLoad ||
        _.get(curSessionData, "sessionKey") !==
          _.get(stateSessionData, "sessionKey"))
    ) {
      if (_.get(stateSessionData, "sessionKey")) {
        updateCurSessionData(stateSessionData);
      }

      onSessionDataChange({
        AppStore,
        sessionData: stateSessionData,
      });
    }

    if (isInitalLoad) {
      updateIsInitialLoad(false);
    }
  }, [stateSessionData]);

  React.useEffect(() => {
    if (props.isOnline !== _.get(GeneralStore, "state.isOnline")) {
      if (GeneralStore.dispatch) {
        GeneralStore.dispatch({
          payload: {},
          type: "GENERAL_STORE_ONLINE_STATUS_CHANGE",
        });
      }
    }
  }, [props.isOnline]);

  //* Impersonation Stopper
  const isImpersonating = ActiveSession.isImpersonationSession();
  if (isImpersonating && !ActiveSession.isMocaworksDevImpersonating()) {
    return (
      <div className="impersonation-wrapper">
        Impersonation is not allowed in PPF Goals.
      </div>
    );
  }

  return (
    <Router>
      <Switch>
        <Route path={INTERFACE_PATHS.LOGIN_WITH_KEY}>
          <LoginToTargetSession GeneralStore={GeneralStore} />
        </Route>

        <Route path={INTERFACE_PATHS.LOGIN}>
          <Login GeneralStore={GeneralStore} />
        </Route>

        <Route path="/">
          <SessionWrapper AppStoreState={AppStore.state}>
            <AuthenticatedRoutes
              AppStore={AppStore}
              GeneralStore={GeneralStore}
            />
          </SessionWrapper>
        </Route>

        <Route path={INTERFACE_PATHS.NO_STORAGE}>
          <FourZeroFour
            title={
              HAS_LOCAL_STORAGE
                ? "Data Storage present."
                : "Storage Unavailable"
            }
            content={
              HAS_LOCAL_STORAGE
                ? "Data Storage does seem to be present for Mastery to use, but failed in some critical manner."
                : `Likely due to Browser Settings, Mastery is unable to locally set data.
              This is critical functionality for the Application. The inability to store data locally makes the Application non-functional.`
            }
            linkData={{
              href: `${
                GeneralStore.state?.initialLoadDestination ??
                INTERFACE_PATHS.HOME
              }`,
              value: `Open in New Tab ${
                !HAS_LOCAL_STORAGE ? "(avoid security issue)" : ""
              }`,
              target: "_blank",
            }}
          />
        </Route>

        <Route path="*"></Route>
      </Switch>
    </Router>
  );
};

interface LocalWrapperProps {
  GeneralStore: StoreAPI.GeneralStoreProps;
}

const ProfileLocalWrapper = ({ GeneralStore }: LocalWrapperProps) => {
  const { planID, subInterface } = useParams<{
    subInterface?: ProfilePage.Subinterface;
    planID: string;
  }>();

  return <Profile subInterface={subInterface} GeneralStore={GeneralStore} />;
};

const CreatePlanLocalWrapper = ({ GeneralStore }: LocalWrapperProps) => {
  const { userID } = useParams<{
    userID?: string; // * Psych! It's actually a number but TypeScript complains *facepalm*
  }>();

  return <CreatePlan userID={userID} GeneralStore={GeneralStore} />;
};

const PPFPlanLocalWrapper = ({ GeneralStore }: LocalWrapperProps) => {
  const { planID, goalID, subInterface } = useParams<{
    subInterface?: PlanPage.Subinterface;
    goalID?: string;
    planID: string; // * Psych! It's actually a number but TypeScript complains *facepalm*
  }>();

  const subInterfaceTypeSafe = React.useMemo(() => {
    return subInterface
      ? (`${subInterface}`.toLocaleLowerCase() as PlanPage.Subinterface)
      : subInterface;
  }, [subInterface]);

  let location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const filter =
    searchParams.get(INTERFACE_SEARCH_PARAMS.PLAN.GOAL_STATUS_FILTER) ||
    undefined;

  return (
    <PPFPlan
      filter={filter}
      planID={planID}
      goalID={goalID}
      subInterface={subInterfaceTypeSafe}
      GeneralStore={GeneralStore}
    />
  );
};

function createFriendlyNameFromPathname(pathname: string) {
  const [_, interfaceName = "", subinterfaceName = ""] = `${pathname}`.split(
    "/"
  );

  switch (`/${interfaceName}`) {
    case INTERFACE_PATHS.PLAN:
      switch (subinterfaceName) {
        case SUB_INTERFACE_PATHS.PLAN.PERSONAL:
          return "Personal (PPF Plan)";
        case SUB_INTERFACE_PATHS.PLAN.PROFESSIONAL:
          return "Professional (PPF Plan)";
        case SUB_INTERFACE_PATHS.PLAN.FINANCIAL:
          return "Financial (PPF Plan)";
        default:
          return "PPF Plan";
      }

      return "PPF Plan";
    case INTERFACE_PATHS.PLANS:
      return "PPF Plans";
    case INTERFACE_PATHS.TEAMBOARD:
      return "Teamboard";
    case INTERFACE_PATHS.PROFILE:
      const profileIdentifier = "(Profile)";

      switch (subinterfaceName) {
        case SUB_INTERFACE_PATHS.PROFILE.INFORMATION:
          return `Information ${profileIdentifier}`;
        case SUB_INTERFACE_PATHS.PROFILE.MY_CERTIFICATIONS:
          return `My Complete Courses ${profileIdentifier}`;
        default:
          return "Profile";
      }
    case INTERFACE_PATHS.HOME:
    default:
      return "Home";
  }
}

interface LocationData {
  hash: string;
  key: string; // * window.History key
  pathname: ""; // * RelPath; window.location.pathname
  search: ""; // * window.location.search
  state?: any; // * Object, defined if history.push() method was used and state was supplied during invocation
}

function createLocationState(loc: LocationData) {
  // * [1] - Create friendly text based on pathname
  const friendlyText = createFriendlyNameFromPathname(loc.pathname);

  // * [2] - Fold in friendly text to state, or create state if such does not exist
  const newState = {
    friendlyText,
    ...(loc.state || {}),
  };

  // * [3] - Ping GeneralStore to store such information
  return newState;
}

interface HistoryScribeProps {
  GeneralStore?: StoreAPI.GeneralStoreProps;
  children?: any;
}

const HistoryScribe = (props: HistoryScribeProps) => {
  let loc = useLocation();

  React.useEffect(() => {
    console.log("HistoryScribe noticed location change: ", loc);

    const safeHistoryState = createLocationState(loc as any);

    props.GeneralStore?.dispatch?.({
      payload: {
        historyItem: {
          ...loc,
          state: safeHistoryState,
        },
      },
      type: "GENERAL_STORE_UPDATE_HISTORY",
    });

    // props.GeneralStore?.dispatch?.({
    //   payload: {
    //     showMenu:
    //       props.GeneralStore?.state?.isMobile === false &&
    //       /^\/(course|plan)\//i.test(loc.pathname),
    //   },
    //   type: "GENERAL_STORE_TOGGLE_NAVMENU",
    // });
  }, [loc]);

  return props.children || null;
};

interface SessionWrapperProps {
  AppStoreState?: StoreAPI.AppStoreState;
  children?: any;
}

const SessionWrapper = ({ AppStoreState, children }: SessionWrapperProps) => {
  const hasChecked = _.get(AppStoreState, "hasCheckedForStoredSession", false);
  const sessionData = _.get(AppStoreState, "sessionData");

  const hasValidSession = !hasChecked || sessionData;

  if (!hasValidSession) {
    return (
      <Redirect
        to={`${INTERFACE_PATHS.LOGIN}?redirect=${window.location.pathname}`}
      />
    );
  }
  // React.useEffect(() => {
  //   if (!hasValidSession) {
  //     window.location.href = `${INTERFACE_PATHS.LOGIN}?redirect=${window.location.pathname}`;
  //   }
  // }, [hasValidSession]);

  return children || null;
};

// * AUTHENTICATED ROUTES WRAPPER ============
interface AuthenticatedRoutesProps {
  AppStore: StoreAPI.AppStoreProps;
  GeneralStore: StoreAPI.GeneralStoreProps;
}

const AuthenticatedRoutes = ({
  AppStore,
  GeneralStore,
}: AuthenticatedRoutesProps) => {
  return (
    <Switch>
      <Route exact path={INTERFACE_PATHS.HOME}>
        <HistoryScribe GeneralStore={GeneralStore}>
          <Home GeneralStore={GeneralStore} />
        </HistoryScribe>
      </Route>

      <Route exact path="/home">
        <HistoryScribe GeneralStore={GeneralStore}>
          <Home GeneralStore={GeneralStore} />
        </HistoryScribe>
      </Route>

      <Route path={`${INTERFACE_PATHS.INTRODUCTION}`}>
        <HistoryScribe GeneralStore={GeneralStore}>
          <Introduction GeneralStore={GeneralStore} />
        </HistoryScribe>
      </Route>

      <Route path={`${INTERFACE_PATHS.HELP}`}>
        <HistoryScribe GeneralStore={GeneralStore}>
          <Library GeneralStore={GeneralStore} />
        </HistoryScribe>
      </Route>

      <Route path={INTERFACE_PATHS.PROFILE}>
        <HistoryScribe GeneralStore={GeneralStore}>
          <Profile GeneralStore={GeneralStore} />
        </HistoryScribe>
      </Route>

      <Route exact path={`${INTERFACE_PATHS.PROFILE}/:subInterface`}>
        <HistoryScribe GeneralStore={GeneralStore}>
          <ProfileLocalWrapper GeneralStore={GeneralStore} />
        </HistoryScribe>
      </Route>

      <Route exact path={INTERFACE_PATHS.PLANS}>
        <HistoryScribe GeneralStore={GeneralStore}>
          <PPFPlans GeneralStore={GeneralStore} />
        </HistoryScribe>
      </Route>

      <Route exact path={INTERFACE_PATHS.CREATE_PLAN}>
        <HistoryScribe GeneralStore={GeneralStore}>
          <CreatePlanLocalWrapper GeneralStore={GeneralStore} />
        </HistoryScribe>
      </Route>

      <Route path={`${INTERFACE_PATHS.CREATE_PLAN}/:userID`}>
        <HistoryScribe GeneralStore={GeneralStore}>
          <CreatePlanLocalWrapper GeneralStore={GeneralStore} />
        </HistoryScribe>
      </Route>

      <Route exact path={INTERFACE_PATHS.PLAN}>
        <HistoryScribe GeneralStore={GeneralStore}>
          <PPFPlanLocalWrapper GeneralStore={GeneralStore} />
        </HistoryScribe>
      </Route>

      <Route path={`${INTERFACE_PATHS.PLAN}/:planID/:subInterface/:goalID`}>
        <HistoryScribe GeneralStore={GeneralStore}>
          <PPFPlanLocalWrapper GeneralStore={GeneralStore} />
        </HistoryScribe>
      </Route>

      <Route path={`${INTERFACE_PATHS.PLAN}/:planID/:subInterface`}>
        <HistoryScribe GeneralStore={GeneralStore}>
          <PPFPlanLocalWrapper GeneralStore={GeneralStore} />
        </HistoryScribe>
      </Route>

      <Route exact path={`${INTERFACE_PATHS.PLAN}/:planID`}>
        <HistoryScribe GeneralStore={GeneralStore}>
          <PPFPlanLocalWrapper GeneralStore={GeneralStore} />
        </HistoryScribe>
      </Route>

      <Route path={`${INTERFACE_PATHS.STATISTICS}`}>
        <HistoryScribe GeneralStore={GeneralStore}>
          <Statistics GeneralStore={GeneralStore} />
        </HistoryScribe>
      </Route>

      <Route path="*">
        <HistoryScribe GeneralStore={GeneralStore}>
          <FourZeroFour />
        </HistoryScribe>
      </Route>
    </Switch>
  );
};

export default Routing;
