import { ENDPOINT_GS_MEASURE_LOG } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_GS_MEASURE_LOG;

export const GSMeasureLog = {
  get(
    params: Endpoints.Tyto.GS.Measure.Log.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Measure.Log.Get>;
  },
  post(
    params: Endpoints.Tyto.GS.Measure.Log.PostParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "post",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Measure.Log.Post>;
  },
};
