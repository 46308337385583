import { ENDPOINT_GS_TEMPLATE_IMAGES } from "data/constants/";
import { callWrapper, CallOpts } from "./utils";

// import { SessionData } from "../../typings";
interface GetParams extends Endpoints.Tyto.PPF.TemplateImages.GetParameters {
  endpointURL: string;
}

export const PPFTemplateImages = {
  get(params: GetParams, callOpts?: CallOpts) {
    // if (!params.endpointURL) {
    //   return new Promise((res, rej) => {
    //     rej("Upload destination not found.");
    //   });
    // }

    const { endpointURL = ENDPOINT_GS_TEMPLATE_IMAGES, ...rest } = params || {};

    return callWrapper("get", endpointURL || "", rest || {}, {
      ...(callOpts || {}),
    }) as Promise<Endpoints.Responses.PPF.TemplateImages.Get>;
  },
};
