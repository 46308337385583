/*
 * Component Description //
 * Viewing Permissions and then searching then allowing manager to add and remove
 */

import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";
import { Redirect } from "react-router-dom";

import {
  Icon,
  Link,
  SearchInput,
  UserThumbByID,
  Message,
} from "components/common/";
import {
  INTERFACE_PATHS,
  SUB_INTERFACE_PATHS,
  PPF_ROLES_BY_NAME,
} from "data/constants/";
import { StoreContext as GeneralStoreContext } from "data/stores/GeneralStore";
import { BackNav } from "components/meta/sub-navs";
import { useAdvancedPersonSearch } from "data/network/hooks/";
import { useGoalMemberAddMutation } from "data/network/hooks/";
import { useGoalMembersDeleteMutation } from "data/network/hooks/";

import "./style.scss";

interface Props extends PlanPage.PlanData {
  GeneralStore: StoreAPI.GeneralStoreProps;
  isMobile?: boolean;
  targetGoalID?: number;
  aboutID?: number;
}

const PermissionWrapper = (props: Props) => {
  const [redirectURL, updateRedirectURL] = React.useState("");
  const [addUser, updateAddUser] = React.useState(false);

  let aboutID = _.get(props.plan, "aboutID", 0);
  let planName = _.get(props.plan?.about, "elementName", "");
  let userName = _.get(props.plan?.about, "name", "");

  if (redirectURL) {
    return <Redirect to={redirectURL} />;
  }
  return (
    <div className="perm-flex-wrapper">
      <div className="perm-top-user-info-cont">
        <span className="perm-top-back-nav hide-in-desktop">
          <BackNav
            onBack={() => {
              //targetGoalID gives back context to go to same story
              updateRedirectURL(
                `${INTERFACE_PATHS.PLAN}/${props.planID}/${SUB_INTERFACE_PATHS.PLAN.GOALS}/${props.targetGoalID}`
              );
            }}
            title=""
          />
        </span>
        <UserThumbByID
          className="perm-thumbnail"
          size={props.isMobile ? 50 : 40}
          userID={aboutID}
          userName={planName ?? ""}
        />
        <div className="perm-plan-name"> {planName}</div>

        <div className="perm-top-back-nav hide-in-mobile">
          <BackNav
            onBack={() => {
              //targetGoalID gives back context to go to same story
              updateRedirectURL(
                `${INTERFACE_PATHS.PLAN}/${props.planID}/${SUB_INTERFACE_PATHS.PLAN.GOALS}/${props.targetGoalID}`
              );
            }}
            title=""
          />
        </div>
      </div>

      <article>
        <div className="perm-description-text">
          User's Who have access to {userName} PPF's
        </div>

        <div className="perm-members">
          <PlanMembers {...props} />
        </div>

        <div className="perm-add-user-cont">
          {addUser ? (
            <PermissionAddUser {...props} />
          ) : (
            <>
              <Icon
                className="perm-eyeglass"
                isButton={true}
                icon="search"
                size={19}
              />
              <div
                className="perm-add-user-text"
                onClick={() => {
                  updateAddUser(true);
                }}
              >
                Add User
              </div>
            </>
          )}
        </div>
      </article>
    </div>
  );
};

const PlanMembers = (props: Props) => {
  //Filtering out the member that the plan belongs to
  // const planMembersArray2 = props.planMembers;
  let userPlanBelongsTo = props.plan?.aboutID;
  const planMembersArray = props.planMembers?.filter(
    (members) => members.memberID != userPlanBelongsTo
  );

  return (
    <section className="perm-inner-cont perm-members-section">
      <h2 className="title-font font-size-medium">
        {planMembersArray ? (
          <>
            {!!planMembersArray?.length ? (
              <ul className="">
                {planMembersArray.map((member) => (
                  <SingleMember member={member} key={member.memberID} />
                ))}
              </ul>
            ) : (
              <p className=""></p>
            )}
          </>
        ) : (
          <div className="">No Members for this plan.</div>
        )}
      </h2>
    </section>
  );
};

interface SingleMemberProps {
  member: TytoData.PPF.Plan.Member;
}

const SingleMember = ({ member }: SingleMemberProps, props: Props) => {
  const [errorMsg, updateErrorMsg] = React.useState("");
  const GeneralStore = React.useContext(GeneralStoreContext);
  const isMobile = !!GeneralStore.state?.isMobile;
  const gsMemberID = _.get(member, "gsMemberID", 0);
  const gsPlanID = _.get(member, "gsPlanID", 0);

  const memberMutation = useGoalMembersDeleteMutation({
    gsMemberID: gsMemberID,
    gsPlanID: gsPlanID,
    onError: (newErrorMsg) => {
      updateErrorMsg(newErrorMsg);
    },
    onSuccess: () => {},
  });

  function removePersonFromGSMembers(gsMemberID: number) {
    memberMutation.mutate({ gsMemberID });
  }

  function getManageAccessPermissionMsg({ member }: SingleMemberProps) {
    let per = member;
    if (
      per.planChange === true &&
      per.goalAdd === true &&
      per.goalDelete === true &&
      per.memberChange === true
    ) {
      return "Manager";
    }
    if (
      per.planChange === false &&
      per.planDelete === false &&
      per.planView === true
    ) {
      return "Contributer";
    }

    return "";
  }
  const manageAccessPerm = getManageAccessPermissionMsg({ member });

  return (
    <ul className="perm-card-wrapper">
      <li className="perm-member-card" key={`${member?.memberID}`}>
        <UserThumbByID
          className=""
          size={isMobile ? 48 : 40}
          userID={member.memberID}
          userName={member.memberElement.elementName ?? ""}
        />
        <div className="flex-details-add">
          <div className="member-flex-column">
            <Link
              className="perm-user-details"
              // href={`${INTERFACE_PATHS.PLAN}/${person.userID}`}
              value={member.memberElement.elementName ?? "Unnamed Plan"}
            />
            {manageAccessPerm && (
              <span className="perm-has-access">{manageAccessPerm}</span>
            )}
          </div>

          {errorMsg && <p className="perm-error">{errorMsg}</p>}
          <Link
            className="perm-remove-link"
            value="Remove"
            onClick={() => {
              removePersonFromGSMembers(gsMemberID);
            }}
          />
        </div>
      </li>
    </ul>
  );
};

function filterPeople({
  people,
  searchTerm,
}: {
  people?: TytoData.AdvancedPerson[];
  searchTerm: string;
}) {
  if (!people) {
    return [];
  } else if (!searchTerm) {
    return people;
  }

  const searchTermEscaped = _.escapeRegExp(searchTerm);

  return people.filter((person) => {
    const regExp = new RegExp(searchTermEscaped, "i");

    return regExp.test(person.givenName + " " + person.familyName ?? "");
  });
}

const DEFAULT_ARR: any[] = [];

const PermissionAddUser = (props: Props) => {
  const [errorMsg, updateErrorMsg] = React.useState("");
  const [searchTerm, updateSearchTerm] = React.useState("");
  const [searchResults, updateSearchResults] = React.useState<
    TytoData.AdvancedPerson[]
  >([]);
  let membersArray = props.planMembers;

  const timeoutKey = React.useRef<number | null>(null);

  const searchQuery = useAdvancedPersonSearch({
    searchTerm,
    isEnabled: !!searchTerm,
    extraOpts: {
      functionName: "Team Membership",
      operation: "ocVIEW",
    },
  });
  const people = searchQuery?.data?.ret?.people ?? DEFAULT_ARR;
  // console.log("=== people-search", people);

  React.useEffect(() => {
    if (timeoutKey.current) {
      clearTimeout(timeoutKey.current);

      timeoutKey.current = null;
    }
    if (!searchTerm) {
      updateSearchResults([]);
    } else {
      timeoutKey.current = window.setTimeout(() => {
        updateSearchResults(filterPeople({ searchTerm, people }));
      }, 600);
    }
  }, [searchTerm, people]);
  // console.log("==== searchResults", searchResults);

  return (
    <div className="perm-user-search-wrapper">
      <section className="perm-user-search-section">
        <form
          className=""
          onSubmit={(e: any) => {
            updateSearchTerm(`${searchTerm}`);
            e.preventDefault?.();
          }}
        >
          <h2>
            {errorMsg && <p className="perm-error-search-user">{errorMsg}</p>}
            <SearchInput
              autoFocus={true}
              className="title-font font-size-medium"
              placeholder="Add User"
              name="Add User"
              onChange={(newVal: any) => updateSearchTerm(newVal)}
              value={searchTerm}
            />
          </h2>
        </form>
      </section>

      <section className="perm-inner-cont perm-add-user-section">
        <h2 className="title-font font-size-medium">
          {searchResults ? (
            <>
              {!!searchResults?.length ? (
                <ul className="">
                  {searchResults.map((person) => (
                    <PermissionAddUserPerson
                      person={person}
                      key={person.userID}
                      membersArray={membersArray}
                    />
                  ))}
                </ul>
              ) : (
                <>
                  {searchQuery?.isFetching ? <Message text="Loading..." /> : ""}
                </>
              )}
            </>
          ) : (
            <div className="">Search for a person.</div>
          )}
        </h2>
      </section>
    </div>
  );
};

interface PermissionAddUserPersonProps {
  person: TytoData.AdvancedPerson;
  membersArray?: TytoData.PPF.Plan.Member[];
}

const PermissionAddUserPerson = (
  { person, membersArray }: PermissionAddUserPersonProps,
  props: Props
) => {
  const [errorMsg, updateErrorMsg] = React.useState("");
  const GeneralStore = React.useContext(GeneralStoreContext);
  const isMobile = !!GeneralStore.state?.isMobile;

  const userID = _.get(person, "userID", 0);
  const memberID = userID;
  const gsPlanID = _.get(membersArray, "[0].gsPlanID", 0);

  const addPersonMutation = useGoalMemberAddMutation({
    gsPlanID: gsPlanID,
    onError: (newErrorMsg) => {
      updateErrorMsg(newErrorMsg);
    },
    onSuccess: () => {},
  });

  function addPersonToGSMembers(memberID: number) {
    const managerPerms = PPF_ROLES_BY_NAME.MANAGER.permissions;
    addPersonMutation.mutate({ memberID, managerPerms });
  }

  let checkAccess = managerCheck(userID, membersArray);
  return (
    <li className="perm-add-user-search-card" key={`${person?.userID}`}>
      <UserThumbByID
        className=""
        size={isMobile ? 48 : 40}
        userID={person.userID}
        userName={person.givenName ?? ""}
      />
      <div className={checkAccess ? "flex-details" : "flex-details-add"}>
        <Link
          className="perm-user-details"
          // href={`${INTERFACE_PATHS.PLAN}/${person.userID}`}
          value={person.givenName + " " + person.familyName ?? "Unnamed Plan"}
        />

        {errorMsg && <p className="perm-error">{errorMsg}</p>}

        {checkAccess ? (
          <div>
            <span className="perm-has-access">Already has Access</span>
          </div>
        ) : (
          <Link
            className="perm-add-link"
            value="Add"
            onClick={() => {
              addPersonToGSMembers(memberID);
            }}
          />
        )}
      </div>
    </li>
  );
};

function managerCheck(userID: number, membersArray: any) {
  //Checking if searched user is found in the managed members array to manipulate dom
  const found = membersArray.find((member: any) => member.memberID === userID);
  if (!!found) {
    return true;
  } else {
    return false;
  }
}

export default PermissionWrapper;
