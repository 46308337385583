import { ENDPOINT_GS_PLAN_NOTICE_MEMBER } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_GS_PLAN_NOTICE_MEMBER;

export const GSPlanNoticeMember = {
  delete(params: Endpoints.Tyto.GS.Plan.Notice.Member.DeleteParameters, callOpts?: CallOpts) {
    return callWrapper(
      "delete",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Plan.Notice.Member.Delete>;
  },
  post(params: Endpoints.Tyto.GS.Plan.Notice.Member.PostParameters, callOpts?: CallOpts) {
    return callWrapper(
      "post",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Plan.Notice.Member.Post>;
  },
};