import * as PS from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";

import { getEncoding, makePathURLQualified } from "../";

export interface PSImage {
  h: number;
  w: number;
  src: string;
  title: string;
  isLastItem: boolean;
  photoNumber: number;
}

interface PSHtml {
  html: string;
}

interface SetStatusData {
  setStatus: number;
  title: string;
  desc: string;
}

export const DEFAULT_PS_PHOTO_WIDTH = 1200;
export const DEFAULT_PS_PHOTO_HEIGHT = 800;
const DEFAULT_PS_OVERRIDE_OPTS = {
  shareEl: false,
  showHideOpacity: true,
};

function getMaxDimensions() {
  return {
    maxWidth: window.innerWidth ?? DEFAULT_PS_PHOTO_WIDTH,
    maxHeight: window.innerHeight ?? DEFAULT_PS_PHOTO_HEIGHT,
  };
}

export function getPSWPUniqueId(pswpUniqueID?: string) {
  return `#pswp${pswpUniqueID || ""}`;
}

export function getPSWPElement(pswpUniqueID?: string) {
  const pswpElement = document.getElementById(
    getPSWPUniqueId(pswpUniqueID)
  ) as HTMLDivElement;

  return pswpElement;
}

export function launchPhotoSwipe({
  idx,
  ref,
  assets,
  onChange,
  pswpUniqueID,
  targetEncodingType = "ocDEFAULT",
}: {
  idx: number;
  ref: HTMLElement;
  assets: Array<Data.Asset | Data.GenericImage>;
  onChange?: (data: {
    photoNumber: number;
    isFirstItem: boolean;
    isLastItem: boolean;
    slide: PSImage;
  }) => void;
  pswpUniqueID?: string;
  targetEncodingType?: keyof typeof TytoData.EncodingType;
}) {
  const pswpElement = getPSWPElement(pswpUniqueID);

  if (!pswpElement || !assets.length) {
    console.log(
      `Returned PhotoSwipe early because either PhotoSwipe Element was not found (${
        !pswpElement ? "true" : "false"
      }), or no Assets were supplied (${
        !assets || !assets.length ? "true" : "false"
      }).`
    );
    return;
  }

  const { maxHeight, maxWidth } = getMaxDimensions();

  const assetsFormatted = assets.reduce(
    (accum: PSImage[], asset, curIdx, assetsArr) => {
      if ("_type" in asset) {
        accum.push({
          h: Math.min(maxHeight, asset.height),
          w: Math.min(maxWidth, asset.width),
          // h: Math.min(DEFAULT_PS_PHOTO_HEIGHT, enc.height),
          // w: Math.min(DEFAULT_PS_PHOTO_WIDTH, enc.width),
          src: asset.src,
          title: `${asset.title ?? ""}`,
          isLastItem: curIdx + 1 === assetsArr.length,
          photoNumber: curIdx + 1,
        });
      } else {
        const enc = getEncoding(asset.encodings, targetEncodingType);

        if (!!enc) {
          accum.push({
            h: Math.min(maxHeight, enc.height),
            w: Math.min(maxWidth, enc.width),
            // h: Math.min(DEFAULT_PS_PHOTO_HEIGHT, enc.height),
            // w: Math.min(DEFAULT_PS_PHOTO_WIDTH, enc.width),
            src: makePathURLQualified(enc.pathURL),
            title: `${asset.assetDesc || asset.assetName}`,
            isLastItem: curIdx + 1 === assetsArr.length,
            photoNumber: curIdx + 1,
          });
        }
      }

      return accum;
    },
    []
  );

  if (!assetsFormatted.length) {
    console.log(
      "Returned PhotoSwipe early because no valid encodings were found from list."
    );
    return;
  }

  const options = {
    ...DEFAULT_PS_OVERRIDE_OPTS,
    index: idx,
    getThumbBoundsFn: () => {
      // get window scroll Y
      const pageYScroll =
        window.pageYOffset || document.documentElement.scrollTop;
      // optionally get horizontal scroll

      // get position of element relative to viewport
      const { left, top, width, height } = ref.getBoundingClientRect();
      return {
        x: left,
        y: top + pageYScroll,
        w: width,
        h: height,
      };
    },
  };

  const gallery = new PS.default(
    pswpElement,
    PhotoSwipeUI_Default,
    assetsFormatted,
    options
  );

  gallery.init();

  if (onChange) {
    gallery.listen("afterChange", () => {
      const curItem = gallery.currItem;

      const { isLastItem, photoNumber } = curItem as PSImage;

      onChange({
        isFirstItem: photoNumber === 1,
        isLastItem,
        photoNumber,
        slide: curItem as PSImage,
      });
    });
  }
}

export function launchPhotoSwipeForDocument({
  idx,
  ref,
  assetsAlreadyFormatted,
  pswpUniqueID,
  photoswipeOpts = {},
}: {
  idx: number;
  ref: HTMLElement;
  assetsAlreadyFormatted: Array<PSHtml | PSImage>;
  pswpUniqueID?: string;
  photoswipeOpts?: { [x: string]: any };
}) {
  const pswpElement = getPSWPElement(pswpUniqueID);

  if (!pswpElement || !assetsAlreadyFormatted.length) {
    console.log(
      `Returned PhotoSwipe early because either PhotoSwipe Element was not found (${
        !pswpElement ? "true" : "false"
      }), or no Assets were supplied (${
        !assetsAlreadyFormatted || !assetsAlreadyFormatted.length
          ? "true"
          : "false"
      }).`
    );
    return;
  }

  const options = {
    ...DEFAULT_PS_OVERRIDE_OPTS,
    index: idx,
    getThumbBoundsFn: () => {
      // get window scroll Y
      const pageYScroll =
        window.pageYOffset || document.documentElement.scrollTop;
      // optionally get horizontal scroll

      // get position of element relative to viewport
      const { left, top, width } = ref.getBoundingClientRect();
      return {
        x: left,
        y: top + pageYScroll,
        w: width,
        // h: height,
      };
    },
    ...photoswipeOpts,
  };

  const gallery = new PS.default(
    pswpElement,
    PhotoSwipeUI_Default,
    assetsAlreadyFormatted,
    options
  );

  gallery.init();
}
