/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import "./style.scss";

export interface Props {
  checked: boolean;
  id: string;
  className?: string;
  onChange: (checked: boolean) => void;
}

const ToggleSwitch = ({ checked, id, className, onChange }: Props) => {
  return (
    <div className={cx("toggle-switch", className)}>
      <input
        type="checkbox"
        className="checkbox"
        checked={checked}
        name={id}
        id={id}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
      />
      <label className="label" htmlFor={id}>
        <span className="inner" />
        <span className="switch" />
      </label>
    </div>
  );
};

export default ToggleSwitch;
