import * as React from 'react';

import "./style.scss";

interface Props extends PlanPage.PlanData {
};

const DisabledMsg = (props: Props) => {
    if (props.plan?.activeStatus !== "ocDISABLED") {
        return null;
    }

    return (
      <header className='plan-disabled-msg-cont'>
          <p className='plan-disabled-msg title-font'>
            This plan has been DISABLED
          </p>
      </header>
  )
}

export default DisabledMsg;