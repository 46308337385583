/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { TextButton } from "../";

import "./style.scss";
import { PropertiesPlugin } from "@microsoft/applicationinsights-web";

const ERROR_DATA_ATT = "data-error";

interface Props {
  className?: string;
  date: string | number | Date;
  onChange: (
    newDate: Date | null,
    e?: React.ChangeEvent<HTMLInputElement>
  ) => void;
  style?: React.CSSProperties;
  type: "datetime-local" | "date";
  disabled?: boolean;
}

function dateAsLocalTime(date: Date, includeTime?: boolean) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  let yearAsStr = `${year}`;

  if (yearAsStr.length < 4) {
    yearAsStr = `${"0".repeat(4 - yearAsStr.length)}${yearAsStr}`;
  }

  return `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
}

function parseDateFromInput(dateString: string) {
  const [year, month, date] = `${dateString}`.split("-");

  console.log({
    year,
    month,
    date,
  });

  return new Date(`${month}/${date}/${year}`);
}

const DatePicker = (props: Props) => {
  // * Simple SafeGuard to make sure only a Valid Date String is passed to <input /> in Render
  const formattedPropsDate = React.useMemo(() => {
    const safeDate = new Date(props.date);

    // * If something was wrong and created an Invalid Date, getFullYear will return NaN instead of a year
    if (safeDate.getFullYear() === NaN) {
      const tempDate = new Date();

      return {
        isNull: false,
        date: tempDate,
        dateString: dateAsLocalTime(tempDate),
      };
    }

    return {
      isNull: safeDate.getFullYear() < 1901 && safeDate.getFullYear() >= 1899,
      date: safeDate,
      dateString: dateAsLocalTime(safeDate),
    };
  }, [props.date]);

  if (formattedPropsDate.isNull) {
    return (
      <TextButton
        onClick={() => {
          props.onChange?.(new Date());
        }}
        value="Choose Date"
      />
    );
  }

  return (
    <input
      disabled={props.disabled}
      className={cx("cc-datepicker", props.className)}
      onChange={(e) => {
        console.log("date input event: ", e);
        // if ((e.target.valueAsDate?.getFullYear?.() ?? 0) < 1899) {
        //   return;
        // }
        props.onChange?.(parseDateFromInput(e.target.value), e);
      }}
      style={props.style}
      type={props.type}
      value={formattedPropsDate.dateString}
    />
  );
};

interface UnControlledDatePickerProps {}

export const UncontrolledDatePicker = ({
  date: dateFromProps,
  onChange,
  ...rest
}: Props) => {
  const ref = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (!ref.current || !dateFromProps) {
      ref.current?.setAttribute?.(ERROR_DATA_ATT, "true");
      return;
    }

    ref.current.value = dateAsLocalTime(new Date(dateFromProps));
    ref.current?.setAttribute(ERROR_DATA_ATT, "false");
  }, []);

  React.useEffect(() => {
    if (!ref.current || !dateFromProps) {
      ref.current?.setAttribute?.(ERROR_DATA_ATT, "false");
      return;
    }

    ref.current.value = dateAsLocalTime(new Date(dateFromProps));
    ref.current?.setAttribute(ERROR_DATA_ATT, "false");
  }, [dateFromProps]);

  const onDateChange = (
    newDate: Date | null,
    e?: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!newDate) {
      return;
    }

    if (!e) {
      onChange(newDate);
      return;
    }

    if ((e.target.valueAsDate?.getFullYear() ?? 0) >= 1899) {
      onChange(newDate);

      ref.current?.setAttribute(ERROR_DATA_ATT, "false");
    } else {
      ref.current?.setAttribute(ERROR_DATA_ATT, "true");
    }
  };

  return (
    <input
      disabled={rest.disabled}
      ref={ref}
      className={cx("cc-datepicker", rest.className)}
      onChange={(e) => {
        onDateChange?.(parseDateFromInput(e.target.value), e);
      }}
      style={rest.style}
      type={rest.type}
      // value={formattedPropsDate.dateString}
    />
  );
};

export default DatePicker;
