/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Button, Icon } from "components/common/";
import { Props as ButtonProps } from "components/common/button/";

import "./style.scss";

interface Props {
  actionBtn?: Partial<ButtonProps>;
  cancelBtnProps?: Partial<ButtonProps>;
  className?: string;
  children?: any;
  onBack?: () => void;
  style?: React.CSSProperties;
  title?: string;
}

const BackNav = (props: Props) => {
  return (
    <nav className={cx("cm-subnavs-back-nav")} style={props.style}>
      <div className="cm-subnavs-back-nav-side-cont-left">
        <Icon
          isButton={true}
          buttonProps={{
            type: "button",
          }}
          onClick={props.onBack}
          icon="angle-left"
          size={18}
        />
      </div>

      <div className="cm-subnavs-back-nav-cont-center">
        {props.children ?? (
          <h3 className="cm-subnavs-back-nav-cont-center-title title-font">
            {props.title ?? ""}
          </h3>
        )}
      </div>

      <div className="cm-subnavs-back-nav-side-cont-right">
        <Button
          className="cm-subnavs-back-nav-side-right-btn"
          onClick={props.onBack ?? _.noop}
          hoverBGSize="hidden"
          theme="action-ghost"
          type="button"
          value="Back"
          {...(props.cancelBtnProps ?? {})}
        />

        {props.actionBtn && (
          <Button
            {...props.actionBtn}
            className={cx(
              props.className,
              "cm-subnavs-back-nav-side-right-btn"
            )}
            onClick={props.actionBtn.onClick ?? _.noop}
            theme={props.actionBtn.theme ?? "action"}
            value={props.actionBtn.value ?? "Save"}
          />
        )}
      </div>
    </nav>
  );
};

export default BackNav;
