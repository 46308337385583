import * as React from "react";

import { IconProps } from "../typings";

const DualDragArrow = ({ className, color, size, style }: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 26 22"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.67811 0.5625V10.3512H4.18754L6.48343 8.0464L5.2287 6.79167L0.779297 11.2411L5.2287 15.6905L6.48343 14.4357L4.18754 12.131H9.67811V21.9196H11.4579V0.5625H9.67811ZM21.2466 6.79167L19.9918 8.0464L22.2877 10.3512H16.7972V0.5625H15.0174V21.9196H16.7972V12.131H22.2877L19.9918 14.4357L21.2466 15.6905L25.696 11.2411L21.2466 6.79167Z" />
    </svg>
  );
};

export default DualDragArrow;
