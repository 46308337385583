/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { INTERFACE_PATHS, SUB_INTERFACE_PATHS } from "data/constants";
import {
  detemerminePPFParentGoal,
  getEncoding,
  makePathURLQualified,
} from "data/helpers/";
import { Link } from "components/common/";

import "./style.scss";

interface Props extends PlanPage.PlanData {
  parentGoal: TytoData.PPF.Plan.Goals.ParentGoal;
}

const ChildGoalsList = (props: Props) => {
  const [theme, updateTheme] = React.useState(() => {
    return detemerminePPFParentGoal(props.parentGoal);
  });

  React.useEffect(() => {
    updateTheme(detemerminePPFParentGoal(props.parentGoal));
  }, [props.parentGoal]);

  if (!props.parentGoal?.childGoals?.length) {
    return null;
  }

  return (
    <section className={cx("plan-parentgoal-list", theme)}>
      {props.parentGoal.childGoals.map((childGoal) => (
        <ChildGoal
          planID={props.planID}
          key={childGoal.gsGoalID}
          childGoal={childGoal}
          parentGoal={props.parentGoal}
        />
      ))}
    </section>
  );
};

function getCompletedItemsCount(childGoal: TytoData.PPF.Plan.Goals.ChildGoal) {
  return (childGoal.gsGoalItems ?? []).reduce((completedCount, goalItem) => {
    return completedCount + (goalItem.status === "ocCOMPLETE" ? 1 : 0);
  }, 0);
}

function getGoalImageURL(childGoal: TytoData.PPF.Plan.Goals.ChildGoal) {
  if (!childGoal.profileImageAsset?.encodings?.length) {
    return "";
  }

  const encoding = getEncoding(
    childGoal.profileImageAsset.encodings,
    "ocDEFAULT"
  );

  if (!encoding) {
    return "";
  }

  return makePathURLQualified(encoding.pathURL);
}

interface ChildGoalProps {
  planID: number;
  parentGoal: TytoData.PPF.Plan.Goals.ParentGoal;
  childGoal: TytoData.PPF.Plan.Goals.ChildGoal;
  theme?: keyof typeof TytoData.PPFParentGoalName;
}

const ChildGoal = ({ childGoal, planID }: ChildGoalProps) => {
  const [completedCount, updateCompletedCount] = React.useState(() => {
    return getCompletedItemsCount(childGoal);
  });
  const [href, updateHRef] = React.useState(
    `${INTERFACE_PATHS.PLAN}/${planID}/${SUB_INTERFACE_PATHS.PLAN.GOALS}/${childGoal.gsGoalID}`
  );
  const [goalImageURL, updateGoalImageURL] = React.useState(() => {
    return getGoalImageURL(childGoal);
  });

  React.useEffect(() => {
    updateHRef(
      `${INTERFACE_PATHS.PLAN}/${planID}/${SUB_INTERFACE_PATHS.PLAN.GOALS}/${childGoal.gsGoalID}`
    );
  }, [childGoal]);

  React.useEffect(() => {
    updateGoalImageURL(getGoalImageURL(childGoal));
  }, [childGoal.profileImageAsset]);

  React.useEffect(() => {
    updateCompletedCount(getCompletedItemsCount(childGoal));
  }, [childGoal]);

  return (
    <Link
      className="plan-childgoal-card-link-wrapper button-generic"
      href={`${href}`}
      value={"View Child Goal"}
    >
      <div
        className="plan-childgoal-card"
        style={{
          backgroundImage: goalImageURL ? `url(${goalImageURL})` : undefined,
        }}
      >
        <h3 className="plan-childgoal-name title-font animated-underline">
          {childGoal.name}
        </h3>
        {
          !!childGoal?.gsGoalItems?.length ? (
            <p className="plan-childgoal-summary">
              {completedCount} of {childGoal.gsGoalItems.length} Step
              {childGoal.gsGoalItems.length === 1 ? "" : "s"} Completed
            </p>
          ) : null
          // <p className="plan-childgoal-summary">Contains no Steps</p>
        }
      </div>
    </Link>
  );
};

export default ChildGoalsList;
