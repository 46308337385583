import { ENDPOINT_GS_GOALS } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_GS_GOALS;

export const GSGoals = {
  get(params: Endpoints.Tyto.GS.Goals.GetParameters, callOpts?: CallOpts) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Goals.Get>;
  },
};
