import { ENDPOINT_GS_GOAL } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_GS_GOAL;

export const GSGoal = {
  delete(params: Endpoints.Tyto.GS.Goal.DeleteParameters, callOpts?: CallOpts) {
    return callWrapper(
      "delete",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Goal.Delete>;
  },
  post(params: Endpoints.Tyto.GS.Goal.PostParameters, callOpts?: CallOpts) {
    return callWrapper(
      "post",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Goal.Post>;
  },
  put(params: Endpoints.Tyto.GS.Goal.PutParameters, callOpts?: CallOpts) {
    return callWrapper(
      "put",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Goal.Put>;
  },
};
