/*
 * Component Description
 */
import * as React from "react";

// import TytoCalls from "../../../data/network/tyto/";
// import { queryClient } from "data/network/";
import * as Storage from "data/storage/";
import { makeRelPathAbsolute } from "data/helpers/";
import { Icon, UserThumb } from "components/common/";
import { ProfileImageModal } from "components/modals/";

import "./UserImage.scss";

function getActiveUserThumbAsset(profileData: TytoData.Person) {
  const { profileImageAssets, profileImageID } = profileData;

  return (profileImageAssets || []).find(
    (imageAsset) => imageAsset.assetID === profileImageID
  );
}

function getFormattedThumbnailPathURL() {
  const thumbnailPathURL =
    Storage.SessionHandling.getActiveSession()?.profileThumbPath;

  if (!thumbnailPathURL) {
    return undefined;
  }

  return makeRelPathAbsolute(thumbnailPathURL, true);
}

interface Props {
  AppStore: StoreAPI.AppStoreProps;
  isMobile: boolean;
  personData: TytoData.Person;
  uploadURL?: string;
  userName: string;
}

const UserImage = ({
  AppStore,
  isMobile,
  personData,
  uploadURL,
  userName,
}: Props) => {
  const [showProfileImageModal, updateShowProfileImageModal] =
    React.useState(false);
  const [profileImageAsset, updateProfileImageAsset] = React.useState(
    getActiveUserThumbAsset(personData)
  );
  const [sessionThumbnailRelPath] = React.useState(
    getFormattedThumbnailPathURL()
  );

  React.useEffect(() => {
    updateProfileImageAsset(getActiveUserThumbAsset(personData));
  }, [personData]);

  // const { profileImageAssets, profileImageID } = personData;

  return (
    <>
      <div className="profile-header-userthumb-wrapper">
        <UserThumb
          className="profile-header-userthumb"
          userName={userName}
          asset={profileImageAsset}
          size={isMobile ? 140 : 225}
          userThumbPathURL={sessionThumbnailRelPath}
        />

        <button
          className="profile-header-userthumb-upload-btn"
          onClick={() => updateShowProfileImageModal(true)}
        >
          <Icon
            className="profile-header-userthumb-upload-btn-icon"
            icon="download"
            size={17}
          />
        </button>
      </div>

      <ProfileImageModal
        isOpen={showProfileImageModal}
        onImageUpload={() => {
          if (AppStore.dispatch) {
            // TytoCalls.Person.get({ personID: personData.personID }).then(resp => {
            //   AppStore.dispatch?.({
            //     payload: {
            //       personData: resp.person
            //     },
            //     type: "APP_STORE_PERSONAL_DATA_LOADED"
            //   });
            // });
            // TODO: Invalidate usePerson query
            // queryClient.invalidateQueries()
          }
        }}
        onDismiss={() => updateShowProfileImageModal(false)}
        tryybProfile={personData}
        memberID={personData.personID}
        uploadURL={uploadURL || ""}
      />
    </>
  );
};

export default UserImage;
