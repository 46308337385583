/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import "./style.scss";

interface Props {
  className?: string;
  text: string;
  children?: any;
  style?: React.CSSProperties;
  textClassName?: string;
  theme?: keyof typeof SITE.Theme;
  fillBackground?: boolean;
}

const Message = (props: Props) => {
  return (
    <div
      className={cx(
        "cc-message-cont font-color fade",
        props.theme,
        props.className
      )}
      style={props.style}
    >
      <p className={cx("cc-message", props.textClassName)}>
        {props.children ?? props.text}
      </p>
    </div>
  );
};

export default Message;
