/*
 * Component Description
 * Searches plans and displays the plan object
 */

import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";
import { Redirect } from "react-router-dom";

import {
  Link,
  Message,
  TextButton,
  TextHighlighter,
  SearchInput,
  UserThumbByID,
} from "components/common/";
import { usePPFPlans, usePPFPlanDashboard } from "data/network/hooks/";
import { createPPFPlan } from "data/network/special-calls/";
import { INTERFACE_PATHS, SUB_INTERFACE_PATHS } from "data/constants/";
import { timeInMS } from "data/helpers/";
import { SessionHandling } from "data/storage/";

import MyPeopleSearch from "./MyPeopleSearch";

import "./style.scss";

// * Veeeery arbitrary date so that ReactQuery correctly cache's response.
// * Without this, it would create a new date each time and have a different
// * Key each time, resulting in network request each time.
const APRIL_FIRST = new Date("04/01/2022");

function filterPlans({
  people,
  searchTerm,
}: {
  people?: TytoData.PPF.Dashboard.Person[];
  searchTerm: string;
}) {
  if (!people) {
    return [];
  } else if (!searchTerm) {
    return people;
  }

  const searchTermEscaped = _.escapeRegExp(searchTerm);

  return people.filter((person) => {
    const regExp = new RegExp(searchTermEscaped, "i");

    return regExp.test(person.personName ?? "");
  });
}

interface Props {
  className?: string;
  isInMenu?: boolean;
  GeneralStore: StoreAPI.GeneralStoreProps;
  updateIsSearching?: (showSearching?: boolean) => void;
}

const SearchPlans = (props: Props) => {
  const [redirectURL, updateRedirectURL] = React.useState("");
  const [creatingPlan, updateCreatingPlan] = React.useState(false);
  const [isPending, startTansition] = React.useTransition();
  const [searchTerm, updateSearchTerm] = React.useState("");
  const [searchResults, updateSearchResults] = React.useState<
    TytoData.PPF.Dashboard.Person[]
  >([]);
  const timeoutKey = React.useRef<number | null>(null);

  const { eagerData: plansEagerData, isLoading: isLoadingPlans } = usePPFPlans(
    {}
  );
  const { eagerData: dashboardEagerData, isLoading: isLoadingDashboard } =
    usePPFPlanDashboard({
      isCascade: true,
      startDate: APRIL_FIRST,
      endDate: APRIL_FIRST,
      teamID: SessionHandling.getPropertyFromActiveSession("domainID") ?? 0,
      onDownloadProgress: (progEvent) => {
        console.log("ProgEvent: ", progEvent);
      },
    });

  const plans = plansEagerData?.gsPlans;
  const allPersons = dashboardEagerData?.dashboard?.allPersons;

  const onSearchTermChange = (newVal: string) => {
    updateSearchTerm(newVal);

    startTansition(() => {
      if (!newVal) {
        updateSearchResults([]);
      } else {
        updateSearchResults(
          filterPlans({ searchTerm: newVal, people: allPersons })
        );
      }
    });
  };

  React.useEffect(() => {
    onSearchTermChange(searchTerm);
  }, [allPersons]);

  const plansByUserID = React.useMemo(() => {
    return _.keyBy(plans, "aboutID");
  }, [plans]);

  React.useEffect(() => {
    if (redirectURL && props.isInMenu) {
      window.location.href = redirectURL;
      props.updateIsSearching?.(false);
    }
  }, [redirectURL]);

  if (redirectURL && !props.isInMenu) {
    return <Redirect to={redirectURL} />;
  }

  const noData =
    (!plansEagerData && isLoadingPlans) ||
    (!dashboardEagerData && isLoadingDashboard);

  return (
    <div
      className={cx(
        "cm-menu-contents-wrapper cm-menu-main-search-wrapper",
        creatingPlan && "creating-plan"
      )}
    >
      <section className="cm-menu-top-cont-search">
        <form
          className="cm-menu-top-search-top"
          onSubmit={(e: any) => {
            if (creatingPlan) {
              return;
            }

            updateSearchTerm(`${searchTerm}`);
            e.preventDefault?.();
          }}
        >
          <SearchInput
            autoFocus={true}
            containerClassName="cm-menu-search-input-wrapper"
            className="cm-menu-search-input title-font font-size-medium"
            disabled={creatingPlan || noData}
            placeholder="Search Users"
            name="Search"
            onChange={onSearchTermChange}
            // onChange={(newVal) => updateSearchTerm(newVal)}
            value={searchTerm}
          />
          {props.updateIsSearching && (
            <TextButton
              className="cm-menu-search-cancel"
              value="Cancel"
              onClick={() => props.updateIsSearching?.(false)}
            />
          )}
        </form>
      </section>

      <section className="cm-menu-inner-cont cm-menu-search-details">
        {noData ? (
          <Message text="Loading People ..." />
        ) : (
          <>
            {searchTerm ? (
              <>
                {!!searchResults?.length ? (
                  <ul className="cm-menu-search-wrapper-off">
                    {searchResults.map((person, curIdx: number) => (
                      <SingleResult
                        idx={curIdx}
                        creating={creatingPlan}
                        GeneralStore={props.GeneralStore}
                        person={person}
                        plan={plansByUserID[person.personID]}
                        key={person.personID}
                        searchTerm={searchTerm}
                        updateCreating={updateCreatingPlan}
                        updateRedirectURL={updateRedirectURL}
                        updateIsSearching={props.updateIsSearching}
                      />
                    ))}
                  </ul>
                ) : (
                  <p className="cm-menu-search-instruction">No users found.</p>
                )}
              </>
            ) : (
              <div className="cm-menu-search-instruction">
                Search for a user.
              </div>
            )}
          </>
        )}
      </section>
    </div>
  );
};

interface SingleResultProps {
  idx: number;
  person: TytoData.PPF.Dashboard.Person;
  plan?: TytoData.PPF.Plans.Plan;
  GeneralStore: StoreAPI.GeneralStoreProps;
  creating?: boolean;
  searchTerm: string;
  updateCreating: (isCreating: boolean) => void;
  updateRedirectURL: (path: string) => void;
  updateIsSearching?: (showSearching?: boolean) => void;
}

const SingleResult = (
  {
    GeneralStore,
    idx,
    creating,
    person,
    plan,
    searchTerm,
    updateCreating,
    updateRedirectURL,
    updateIsSearching,
  }: SingleResultProps,
  props: Props
) => {
  const [errorMsg, updateErrorMsg] = React.useState("");
  const isMobile = !!GeneralStore.state?.isMobile;
  const [userPlanPath, updateUserPlanPath] = React.useState(() => {
    return plan?.gsPlanID
      ? `${INTERFACE_PATHS.PLAN}/${plan?.gsPlanID}/${SUB_INTERFACE_PATHS.PLAN.GOALS}`
      : "";
  });

  if (!person) {
    return null;
  }

  const manageAccessMsg = getManageAccessMsg(plan, person);
  return (
    <>
      {/* <hr />
      <h1>
        {!!plan ? `${plan?.gsPlanID}` : "noPlan"}
        {!!person.hasPlanAdd ? " - hasPlanAdd" : " - NOhasPlanAdd"}
      </h1> */}
      {/* 
      // Buttons have no plans. to allow onclick create
      // Links have plans to route you to goals 
      */}
      <Link
        className={cx(
          "cm-menu-search-result-link",
          !person.hasPlanAdd && !plan && "fake-disabled"
          //* !plan && "fake-disabled",
        )}
        disabled={creating}
        type={!!plan ? "link" : "button"}
        onClick={
          //Button - If they don't have a plan and you can add a plan ask first
          !plan && !!person.hasPlanAdd
            ? (e) => {
                try {
                  const confirmed =
                    person.personID ===
                    SessionHandling.getUserIDOfActiveSession()
                      ? true
                      : window.confirm(
                          `This use does not have any PPF Goals. Would you like to start creating them?`
                        );

                  if (confirmed) {
                    // debugger;
                    updateCreating(true);
                    updateErrorMsg("");

                    createPPFPlan({
                      personID: person.personID,
                      personName: person.personName,
                      startDate: new Date(),
                      teamRootID: person.primaryElementID,
                      onError: (newErrorMsg) => {
                        updateErrorMsg(`${newErrorMsg}`);
                        updateCreating(false);
                      },
                      onSuccess: (planID, redirectPath) => {
                        updateRedirectURL(redirectPath);
                        updateCreating(false);
                      },
                    });
                    e.preventDefault?.();
                  }
                } catch (err) {
                  const errorMsg = _.get(err, "msg", "Error Occurred.");
                  updateErrorMsg(errorMsg);
                }
              }
            : () => {
                updateIsSearching?.(false);
              }
        }
        href={!plan ? undefined : `${userPlanPath}`}
        value={person.personName ?? ""}
      >
        <li className="cm-menu-search-card" key={`${plan?.gsPlanID || idx}`}>
          <UserThumbByID
            className="cm-menu-search-thumbnail"
            size={isMobile ? 50 : 40}
            userID={person.personID}
            userName={person.personName ?? ""}
          />

          <div className="cm-menu-search-card-right">
            <div className="cm-menu-search-card-right-inner-cont">
              <p className="cm-menu-search-link-user">
                <TextHighlighter
                  highlightClassName=""
                  searchTerm={searchTerm}
                  text={person.personName ?? ""}
                  type="hightlight"
                />
              </p>
              {/* // If they have a plan and you have goaladd then show create button */}
              {!!plan && !!plan?.permission?.goalAdd && (
                <Link
                  className="cm-menu-search-link-create"
                  href={`${INTERFACE_PATHS.PLAN}/${plan.gsPlanID ?? 0}/${
                    SUB_INTERFACE_PATHS.PLAN.NEW_GOAL
                  }`}
                  onClickCapture={(e) => {
                    e.stopPropagation?.();
                  }}
                  disabled={creating}
                  value={!isMobile ? "Create Goals" : "Create"}
                />
              )}

              {/* {!plan && !person.hasPlanAdd && (
                <p className="cm-menu-search-link-create">
                  {!isMobile ? "View" : ""}
                </p>
              )} */}
            </div>
            {manageAccessMsg && (
              <span className="cm-menu-search-privileges">
                {manageAccessMsg}
              </span>
            )}
          </div>

          {errorMsg && (
            <p className="cm-menu-search-result-error-msg">{errorMsg}</p>
          )}
        </li>
      </Link>
    </>
  );
};

function getManageAccessMsg(plan: any, person: any) {
  let per = plan?.permission ?? {};
  //PLAN TRUE -  You have management permission
  if (
    per.planChange === true &&
    per.goalAdd === true &&
    per.goalDelete === true &&
    per.memberChange === true
  ) {
    return "You have access";
  }
  // You do not have management permission
  if (per.planChange === false) {
    return "Managed by someone else";
  }

  // PLAN FALSE - Person result does not have a plan
  if (!plan) {
    //You do not have permission to make them one.
    if (!person.hasPlanAdd) {
      return "No active goals - No permission to manage";
    }
    return "No active goals";
  }
  return "";
}

interface RouterProps extends Props {
  searchType: SearchMenuWrapperProps["searchType"];
}

export const SearchPlansRouter = ({ searchType, ...props }: RouterProps) => {
  switch (searchType) {
    case "my-people":
      return (
        <MyPeopleSearch
          className={props.className}
          isInMenu={true}
          GeneralStore={props.GeneralStore}
          updateIsSearching={props.updateIsSearching}
        />
      );
    case "all-people":
    default:
      return (
        <SearchPlans
          className={props.className}
          isInMenu={true}
          GeneralStore={props.GeneralStore}
          updateIsSearching={props.updateIsSearching}
        />
      );
  }
};

interface SearchMenuWrapperProps extends CMMenu.MenuWrapperProps {
  GeneralStore: StoreAPI.GeneralStoreProps;
}

const SearchMenuWrapper = (props: SearchMenuWrapperProps) => {
  return (
    <aside className={cx("cm-menu", "full-screen", props.className)}>
      <SearchPlansRouter
        searchType={props.searchType}
        className={props.className}
        isInMenu={true}
        GeneralStore={props.GeneralStore}
        updateIsSearching={props.updateIsSearching}
      />
    </aside>
  );
};

export default SearchMenuWrapper;
