import * as React from "react";

import { IconProps } from "../typings";

const Bell = ({ className, color, size }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
      height={size}
      width={size}
      fill={color}
    >
        <path d="M22.5001 0C10.0935 0 0 10.0934 0 22.4999C0 34.9063 10.0935 44.9997 22.5001 44.9997C34.9066 44.9997 45 34.9063 45 22.4999C45 10.0934 34.9066 0 22.5001 0ZM22.5001 41.3112C12.1273 41.3112 3.6885 32.8727 3.6885 22.4999C3.6885 12.1272 12.1273 3.6885 22.5001 3.6885C32.8728 3.6885 41.3115 12.1272 41.3115 22.4999C41.3115 32.8727 32.8728 41.3112 22.5001 41.3112Z" />
        <path d="M32.2335 13.8642L18.8743 27.2235L12.7665 21.1156C12.0462 20.3955 10.8786 20.3955 10.1583 21.1156C9.43813 21.8359 9.43813 23.0035 10.1583 23.7238L17.5702 31.1358C17.9304 31.4958 18.4024 31.676 18.8743 31.676C19.3463 31.676 19.8183 31.4958 20.1785 31.1358L34.8417 16.4725C35.5619 15.7523 35.5619 14.5846 34.8417 13.8643C34.1214 13.144 32.9536 13.144 32.2335 13.8642Z" />
    </svg>
  );
};

export default Bell;
