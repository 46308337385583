import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";

import { BROWSER_HISTORY, IS_LOCAL_HOST } from "data/constants/";

const reactPlugin = new ReactPlugin();

const clickPluginInstance = new ClickAnalyticsPlugin();
// Click Analytics configuration
const clickPluginConfig = {
  autoCapture: true,
  urlCollectQuery: true,
};

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: `${
      process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY ?? ""
    }`,
    connectionString: `${
      process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING ?? ""
    }`,
    /* ...Other Configuration Options... */
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {
        history: BROWSER_HISTORY,
      },
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
    disableTelemetry: IS_LOCAL_HOST,
  },
});
appInsights.loadAppInsights();
// appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

export { appInsights, reactPlugin };
