/*
 * Component Description
 */
import * as React from 'react';
import cx from 'classnames';

import { StoreContext as GeneralStoreContext } from 'data/stores/GeneralStore';
import Icon from '../icon';
import * as _ from 'lodash';

import './style.scss';

interface Props {
  className?: string;
}

const MenuToggleButton = (props: Props) => {
  const GeneralStore = React.useContext(GeneralStoreContext);

  const isMobile = GeneralStore?.state?.isMobile;
  const menuIsOpen = GeneralStore?.state?.showMenu ?? false;

  return (
    <Icon
      icon="bars"
      buttonProps={{
        className: cx('cc-menu-toggle-btn', props.className),
        name: 'Menu Toggle Button',
      }}
      isButton={true}
      onClick={() => {
        if (GeneralStore.dispatch) {
          GeneralStore.dispatch({
            payload: {},
            type: 'GENERAL_STORE_TOGGLE_NAVMENU',
          });
          //TODO NAV needs to close search as well () => updateIsSearching(false)
          //creates weird bug that launches search nav to right side in desktop
        }
      }}
      size={isMobile ? 20 : 25}
    />
  );
};

export default MenuToggleButton;
