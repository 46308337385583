/*
 * Primary Store for things like Logged in User Information, Primary Team information, etc..
 */
import * as React from "react";
import * as _ from "lodash";
// import { n } from "react-router-dom";

import { INTERFACE_PATHS } from "../constants/";
import { appInsights } from "../tracking/";
import { SessionHandling } from "../storage/";
import {
  addStylesheetForSession,
  removeDomainStyleSheet,
} from "data/helpers/meta-utils";

const {
  getActiveSession,
  getAllSessions,
  getSafeToExposeSessionKey,
  setActiveSession,
  storeSessionData,
} = SessionHandling;

export interface HistoryItem {
  url: string;
  uri: string;
}

function sessionDataFromStorage() {
  // * This is attempting to find and set route on AppStore setup...
  // * But if the path is session-check we don't want to do that...
  // * We don't want the App redirecting and want it to stay on /session-check
  const regExp = new RegExp(
    _.escapeRegExp(INTERFACE_PATHS.LOGIN_WITH_KEY),
    "i"
  );
  const isSessionCheckRoute = regExp.test(window.location.pathname);

  let activeSession =
    (isSessionCheckRoute ? undefined : getActiveSession()) ?? undefined;

  if (!activeSession && !isSessionCheckRoute) {
    const allStoredSessions = getAllSessions();

    if (allStoredSessions.length === 1 && !!allStoredSessions[0]) {
      activeSession = allStoredSessions[0];

      const activeSessKey = _.get(activeSession, "sessionKey");

      if (activeSessKey) {
        setActiveSession(activeSessKey);
      }
    }
  }

  const sessionKey = _.get(activeSession, "sessionKey");
  const loggedInUserID = _.get(activeSession, "userID", 0);

  if (activeSession?.domainID) {
    addStylesheetForSession(_.noop, activeSession.domainID);
  }

  return {
    // hasCheckedForStoredSession: !!activeSession,
    hasCheckedForStoredSession: true,
    loggedInUserID,
    sessionData: activeSession,
    sessionKey,
  };
}

const PERSONAL_DATA_RESET = {
  discMini: {},
  personInfo: undefined,
};

const SESSION_DATA_RESET = {
  sessionData: undefined,
};

let StoreContext: React.Context<StoreAPI.AppStoreProps> = React.createContext(
  {}
);

let initialState: StoreAPI.AppStoreState = {
  discMini: {},
  discMiniNullProfiles: {},
  coursesParentMap: new Map(),
  taskStructuresLoaded: new Set(),
  //   hasCheckedForStoredSession: false,
  ...sessionDataFromStorage(),
};

export let DISPATCH:
  | React.Dispatch<StoreAPI.StoreAction<StoreAPI.AppStoreActionType>>
  | undefined;

let reducer = (
  state: StoreAPI.AppStoreState,
  action: StoreAPI.StoreAction<StoreAPI.AppStoreActionType>
) => {
  console.log(action);

  switch (action.type) {
    // | "APP_STORE_CLEAR_ACTIVE_SESSION"
    case "APP_STORE_DISC_MINI_LOADED":
      if (action.payload && action.payload.discMini) {
        const discMini = action.payload.discMini as Data.DISCProfileMini;

        const isValidProfile =
          discMini.styleKey3 &&
          discMini.styleName3 &&
          (!!discMini.d3 || !!discMini.i3 || !!discMini.s3 || !!discMini.c3);

        if (isValidProfile) {
          return {
            ...state,
            discMini: {
              ...(state.discMini || {}),
              [discMini.personID]: discMini,
            },
          };
        } else {
          return {
            ...state,
            discMiniNullProfiles: {
              ...(state.discMiniNullProfiles || {}),
              [discMini.personID]: discMini,
            },
          };
        }
      }

      return state;
    case "APP_STORE_PERSONAL_DATA_LOADED":
      if (action.payload) {
        const userID = _.get(action, "payload.personData.personID", 0);

        if (userID && userID === _.get(state, "sessionData.userID", 0)) {
          return {
            ...state,
            personInfo: action.payload.personData,
          };
        }
      }

      return state;
    case "LOGOUT_USER":
      const currentSessionData = state.sessionData;

      if (currentSessionData) {
        removeDomainStyleSheet(currentSessionData.domainID);
        // const successfullyCleared = clearActiveSession();
        return {
          ...state,
          ...PERSONAL_DATA_RESET,
          ...SESSION_DATA_RESET,
        };
      }

      return state;
    case "APP_STORE_UPDATE_COURSES_PARENT_LIST":
      const courseID: number | undefined = action.payload?.courseID;
      const parentsMap: StoreAPI.CourseParentsList | undefined =
        action.payload?.parentsMap;

      if (courseID && parentsMap) {
        const curParentsMap = new Map([...Array.from(state.coursesParentMap)]);

        if (action.payload?.override) {
          curParentsMap.set(`${courseID}`, parentsMap);
        } else {
          const curCourseParentsMap = curParentsMap.get(`${courseID}`);

          curParentsMap.set(`${courseID}`, {
            ...(curCourseParentsMap || {}),
            ...parentsMap,
          });
        }

        return {
          ...state,
          coursesParentMap: curParentsMap,
          // course
        };
      }

      return state;
    case "APP_STORE_UPDATE_COURSES_PARENT_LIST_MULTIPLE":
      if (Array.isArray(action.payload?.parentsListMap)) {
        const newItems = action.payload.parentsListMap as [
          string,
          StoreAPI.CourseParentsList
        ][];
        const newTaskStructuresList = newItems.reduce(
          (accum, [courseID, parentsListMap]) => {
            if (parentsListMap.planTaskIDs?.length) {
              parentsListMap.planTaskIDs.forEach((parentPlanID) => {
                if (parentPlanID) {
                  accum.add(parentPlanID);
                }
              });
            }

            return accum;
          },
          new Set() as Set<number>
        );

        if (action.payload?.override) {
          const newParentsMap = new Map([...newItems]);

          return {
            ...state,
            coursesParentMap: newParentsMap,
            taskStructuresLoaded: newTaskStructuresList,
          };
        } else {
          const newParentsMap = new Map([
            ...Array.from(state.coursesParentMap),
            ...newItems,
          ]);

          return {
            ...state,
            coursesParentMap: newParentsMap,
            taskStructuresLoaded: new Set([
              ...Array.from(state.taskStructuresLoaded || []),
              ...Array.from(newTaskStructuresList),
            ]),
          };
        }
      }

      return state;
    case "USER_LOGGED_IN":
      if (!action.payload) {
        return state;
      }

      const sessionData: Data.SessionData = _.get(
        action,
        "payload.session",
        {}
      );
      const loggedInUserID = _.get(sessionData, "userID", 0);
      const sessionKey = _.get(sessionData, "sessionKey", "");

      addStylesheetForSession(_.noop, sessionData?.domainID);

      // TODO: Handle sending and alrady existing session previously pulled from sessionData
      const wasSuccessfullyStored = storeSessionData(sessionData);

      if (!wasSuccessfullyStored) {
        return state;
      }

      setActiveSession(sessionKey);

      if (action.callback) {
        action.callback({});
      }

      // * Update AppInsights userData for better understanding of data
      appInsights?.setAuthenticatedUserContext?.(
        `${sessionData?.userID ?? ""}`,
        `${sessionData?.domainID ?? ""}`
      );

      const differentUser =
        !!state.loggedInUserID && state.loggedInUserID !== loggedInUserID;

      // * Clears Personal Data if new user
      const personalDataReset = differentUser
        ? {
            ...PERSONAL_DATA_RESET,
          }
        : {};

      return {
        ...state,
        ...personalDataReset,
        hasCheckedForStoredSession: true,
        sessionData,
        loggedInUserID,
      };
    default:
      return state;
  }
};

function StoreContextProvider(props: any) {
  // [A]
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };

  // console.log("Setting DISPATCH_REF");
  DISPATCH = dispatch;

  // [B]
  return (
    <StoreContext.Provider value={value}>
      {props.children}
    </StoreContext.Provider>
  );
}

let StoreContextConsumer = StoreContext.Consumer;

// [C]
export { StoreContext, StoreContextProvider, StoreContextConsumer };
