/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import pkgJson from "../../../../package.json";
import { StoreContext } from "../../../data/stores/GeneralStore";
import { StoreContext as AppStoreContext } from "../../../data/stores/AppStore";
import * as Storage from "../../../data/storage/";
import { SITE_THEMES } from "../../../data/constants/";
// import * as MetaVariables from "../../../data/constants/meta";

import PlanMenu from "./subcomponents/plan/";
import DefaultMenu from "./subcomponents/default/";
import SearchMenu from "./subcomponents/searching/";
import GenericMenuContent from "./GenericMenuContent";

import "./style.scss";

// export interface Props {
//   blockID?: number;
//   className?: string;
//   taskID?: number;
//   menuType?: keyof typeof CMMenu.MenuType;
//   version: string;
// }

const Menu = (props: CMMenu.MenuProps) => {
  let AppStore = React.useContext(AppStoreContext);
  let GeneralStore = React.useContext(StoreContext);

  const menuIsShowing = _.get(GeneralStore, "state.showMenu", false);
  // const isMobile = _.get(GeneralStore, "state.isMobile", false);
  const [searchType, updateSearchType] =
    React.useState<CMMenu.SearchType>("my-people");
  const [isSearching, updateIsSearching] = React.useState(!!props.searching);
  const [userID] = React.useState(() => {
    return Storage.SessionHandling.getUserIDOfActiveSession();
  });
  const [versionString] = React.useState(
    `${pkgJson?.version ?? ""}` || `${process.env.BUILD_NUM || ""}`
  );
  const [jenkinsBuildNumber] = React.useState(
    `${process.env.REACT_APP_BUILD_NUM || ""}`
    // `${(MetaVariables as any)?.BUILD_NUM || ""}`
  );

  React.useEffect(() => {
    updateIsSearching(!!props.searching);
  }, [props.searching]);

  React.useEffect(() => {
    if (props.searchType) {
      updateSearchType(props.searchType);
    }
  }, [props.searchType]);

  const hasParentSite = !!GeneralStore.state?.hasParentSite;

  React.useEffect(() => {
    if (!menuIsShowing && isSearching) {
      updateIsSearching(false);
    }
  }, [menuIsShowing]);

  // if (!menuIsShowing) {
  //   return null;
  // }

  const siteTheme: keyof typeof SITE.SiteTheme = _.get(
    GeneralStore,
    "state.siteTheme",
    SITE_THEMES.system
  );

  if (isSearching) {
    return (
      <SearchMenu
        AppStore={AppStore}
        GeneralStore={GeneralStore}
        hasParentSite={hasParentSite}
        menuIsShowing={menuIsShowing}
        userID={userID}
        siteTheme={siteTheme}
        buildNumber={jenkinsBuildNumber}
        version={versionString}
        isSearching={isSearching}
        searchType={searchType}
        updateIsSearching={(newIsSearching) =>
          updateIsSearching(newIsSearching ?? !isSearching)
        }
        updateSearchType={updateSearchType}
        {...props}
      />
    );
  }

  switch (props.menuType) {
    case "plan":
    case "default":
    default:
      return (
        <GenericMenuContent
          AppStore={AppStore}
          GeneralStore={GeneralStore}
          hasParentSite={hasParentSite}
          menuIsShowing={menuIsShowing}
          userID={userID}
          siteTheme={siteTheme}
          buildNumber={jenkinsBuildNumber}
          version={versionString}
          isSearching={isSearching}
          searchType={searchType}
          updateIsSearching={(newIsSearching) =>
            updateIsSearching(newIsSearching ?? !isSearching)
          }
          updateSearchType={updateSearchType}
          {...props}
        >
          <PlanMenu
            hasParentSite={hasParentSite}
            planID={props.planID}
            isSearching={isSearching}
            searchType={searchType}
            updateIsSearching={(newIsSearching) =>
              updateIsSearching(newIsSearching ?? !isSearching)
            }
            updateSearchType={updateSearchType}
          />
        </GenericMenuContent>
      );
    // return (
    //   <GenericMenuContent
    //     AppStore={AppStore}
    //     GeneralStore={GeneralStore}
    //     hasParentSite={hasParentSite}
    //     menuIsShowing={menuIsShowing}
    //     userID={userID}
    //     siteTheme={siteTheme}
    //     buildNumber={jenkinsBuildNumber}
    //     version={versionString}
    //     isSearching={isSearching}
    //     updateIsSearching={(newIsSearching) =>
    //       updateIsSearching(newIsSearching ?? !isSearching)
    //     }
    //     {...props}
    //   >
    //     <DefaultMenu
    //       hasParentSite={hasParentSite}
    //       isSearching={isSearching}
    //       updateIsSearching={(newIsSearching) =>
    //         updateIsSearching(newIsSearching ?? !isSearching)
    //       }
    //     />
    //   </GenericMenuContent>
    // );
  }

  // return (
  //   <
  // );

  // return (
  //   <aside className={cx("cm-menu", props.className)}>
  //     <section className="cm-menu-inner-cont">
  //       {/* <ul className="cm-menu-list">
  //         <li className="cm-menu-item">Fake Item</li>
  //       </ul> */}
  //       <SubMenuRouter {...props} isMobile={isMobile} userID={userID} />
  //     </section>

  //     {menuIsShowing && isMobile && (
  //       <button
  //         className="cm-menu-black-overlay"
  //         onClick={() => {
  //           if (GeneralStore.dispatch) {
  //             GeneralStore.dispatch({
  //               payload: {},
  //               type: "GENERAL_STORE_TOGGLE_NAVMENU",
  //             });
  //           }
  //         }}
  //       />
  //     )}

  //     <footer className="cm-menu-footer">
  //       <span className="cm-menu-build text-color">
  //         Build: <span className="cm-menu-build-num">{version}</span>
  //       </span>

  //       <TextButton
  //         className="cm-menu-footer-sitetheme-toggle text-color"
  //         onClick={() => {
  //           let newSiteTheme = "dark";

  //           if (siteTheme === "dark") {
  //             newSiteTheme = "light";
  //           }

  //           if (GeneralStore.dispatch) {
  //             GeneralStore.dispatch({
  //               payload: {
  //                 siteTheme: newSiteTheme,
  //               },
  //               type: "GENERAL_STORE_UPDATE_SITE_THEME",
  //             });
  //           }
  //         }}
  //         value={
  //           siteTheme === "dark"
  //             ? "Switch to Light Mode"
  //             : "Switch to Dark Mode (default)"
  //         }
  //       />
  //     </footer>
  //   </aside>
  // );
};

// interface SubMenuRouterProps extends Props {
//   isMobile: boolean;
//   userID: number;
// }

// const SubMenuRouter = ({
//   menuType = "default",
//   ...restOfProps
// }: SubMenuRouterProps) => {
//   switch (menuType) {
//     case "training":
//       return <TrainingMenu {...restOfProps} />;
//     case "default":
//     default:
//       return <DefaultMenu />;
//   }
// };

export default Menu;
