/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Menu } from "components/meta/";
import Intro from "./intro";

import "./style.scss";

interface Props extends SITE.GeneralStoreInjectedProps {}

const IntroWrapper = (props: Props) => {
  const [menuHasBeenOpen, updateMenuHasBeenOpen] = React.useState(
    _.get(props, "GeneralStore.state.showMenu", false)
  );
  const storeMenuOpenValue = _.get(props, "GeneralStore.state.showMenu", false);

  const [isSearching, updateIsSearching] = React.useState(false);

  // * Trigger flag so that turning menu off animates back left
  React.useEffect(() => {
    if (!menuHasBeenOpen && storeMenuOpenValue) {
      updateMenuHasBeenOpen(true);
    }
  }, [storeMenuOpenValue, menuHasBeenOpen]);

  const menuIsOpen = storeMenuOpenValue;

  return (
    <div className={cx("interface-wrapper", menuIsOpen && "menu-open")}>
      <Menu className="home-menu" searching={isSearching} />

      <main
        className={cx(
          "interface-main-cont interface-introduction",
          menuHasBeenOpen && "animate-back"
        )}
      >
        <Intro
          GeneralStore={props.GeneralStore}
          callbackSearching={(serch) => {
            updateIsSearching(serch);
          }}
        />
      </main>
    </div>
  );
};

export default IntroWrapper;
