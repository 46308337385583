import { ENDPOINT_GS_PLAN } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_GS_PLAN;

export const GSPlan = {
  delete(params: Endpoints.Tyto.GS.Plan.DeleteParameters, callOpts?: CallOpts) {
    return callWrapper(
      "delete",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Plan.Delete>;
  },
  get(params: Endpoints.Tyto.GS.Plan.GetParameters, callOpts?: CallOpts) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Plan.Get>;
  },
  post(params: Endpoints.Tyto.GS.Plan.PostParameters, callOpts?: CallOpts) {
    return callWrapper(
      "post",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Plan.Post>;
  },
  put(params: Endpoints.Tyto.GS.Plan.PutParameters, callOpts?: CallOpts) {
    return callWrapper(
      "put",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Plan.Put>;
  },
};
