/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";
import { animated, useSpring } from "@react-spring/web";

interface GoalWeightProps {
  gsGoalID: number;
  goalSteps: TytoData.PPF.Plan.Goals.GoalItem[];
}

const GoalWeight = (props: GoalWeightProps) => {
  const idRef = React.useRef<number>(Number(props.gsGoalID));
  const [percentComplete, updatePercentComplete] = React.useState(() => {
    var goalsCompleted = props.goalSteps.filter(
      (step) => step.status === "ocCOMPLETE"
    );
    var pComplete = 0;

    goalsCompleted.forEach((step) => {
      pComplete += step.itemWeight;
    });
    return pComplete;
  });

  const [curVal, api] = useSpring(() => {
    return {
      val: percentComplete,
      immediate: true,
      duration: 380,
    };
  });
  // const weightProps = useSpring({
  //   val: percentComplete,
  //   from: { val: 0 },
  // });

  const [has0ItemWeight, updateHas0ItemWieght] = React.useState(() => {
    const under1 = props.goalSteps.find((step) => step.itemWeight < 1);
    return !!under1;
  });

  React.useEffect(() => {
    var goalsCompleted = props.goalSteps.filter(
      (step) => step.status === "ocCOMPLETE"
    );
    var pComplete = 0;
    goalsCompleted.forEach((step) => {
      pComplete += step.itemWeight;
    });

    api.start({
      val: pComplete,
      immediate: props.gsGoalID !== idRef.current,
      duration: Math.max(380, 380 * (pComplete / 50)),
    });

    if (idRef.current !== props.gsGoalID) {
      idRef.current = props.gsGoalID;
    }

    updatePercentComplete(pComplete);

    updateHas0ItemWieght(!!props.goalSteps.find((step) => step.itemWeight < 1));
  }, [props.goalSteps]);

  return (
    <section className="cg-goalstories-currentgoal-goalWeight-cont">
      <div className="cg-goalstories-currentgoal-goalWeight-percentComplete">
        <h1
          className={cx(
            "cg-goalstories-currentgoal-goalWeight-percentComplete-h1",
            percentComplete >= 100 && "is-complete"
          )}
        >
          <animated.span>
            {curVal.val.to((weightNumber) => weightNumber.toFixed(0))}
          </animated.span>
          %
        </h1>
        <p className="cg-goalstories-currentgoal-goalWeight-percentComplete-p">
          complete
        </p>
      </div>
      {props.goalSteps.length < 1 && (
        <div className="cg-goalstories-currentgoal-goalWeight-noGoals">
          This goal has no Tasks
        </div>
      )}
      {props.goalSteps.length > 0 && (
        <>
          {props.goalSteps.map((step) => {
            return (
              <div
                key={step.gsGoalItemKey}
                className={cx(
                  "cg-goalstories-currentgoal-goalWeight-step",
                  step.status === "ocCOMPLETE" ? "goal-completed" : ""
                )}
                style={{
                  width: `${
                    has0ItemWeight
                      ? 100 / props.goalSteps.length
                      : step.itemWeight
                  }%`,
                }}
              >
                <div
                  className={cx(
                    "cg-editgoal-goal-goalweight-step-inner",
                    step.itemWeight <= 5 && "less-than-5"
                  )}
                  data-itemweight={`${step.itemWeight}%`}
                />
              </div>
            );
          })}
        </>
      )}
    </section>
  );
};

export default GoalWeight;
