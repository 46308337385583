import { ENDPOINT_GS_MEMBERS } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_GS_MEMBERS;

export const GSMembers = {
  get(params: Endpoints.Tyto.GS.Members.GetParameters, callOpts?: CallOpts) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.GS.Members.Get>;
  },
};
