/*
 * Component Description
 */
import * as React from "react";
import { Redirect } from "react-router-dom";

import {
  DEFAULT_PPF_MEMBER_ADD_DATA,
  PPF_MEMBER_ADD_DATA_EVERYTHING_BUT_PLAN_CHANGE,
  INTERFACE_PATHS,
} from "data/constants/";
import { Button, DatePicker, Link, Input, UserThumb } from "components/common/";
import { useAdvancedPersonSearch } from "data/network/hooks/";
import { makeRelPathAbsolute, pullMessageFromError } from "data/helpers/";
import API from "data/network/tyto/";

import "./style.scss";

interface Props {
  isMobile: boolean;
  GeneralStore: StoreAPI.GeneralStoreProps;
  userID?: number;
}

const PlanCreation = (props: Props) => {
  const [selectedPerson, updateSelectedPerson] =
    React.useState<TytoData.AdvancedPerson | null>(null);
  const [creating, updateCreating] = React.useState(false);

  return (
    <main className="interface-plan-creation centered-content-wrapper">
      <h2 className="title-font font-size-title">Create Plan</h2>

      {!selectedPerson ? (
        <PersonSelect updateSelectedPerson={updateSelectedPerson} />
      ) : (
        <CreatePlan
          creating={creating}
          updateCreating={updateCreating}
          person={selectedPerson}
        />
      )}
    </main>
  );
};

interface PersonSelectProps {
  updateSelectedPerson: React.Dispatch<
    React.SetStateAction<TytoData.AdvancedPerson | null>
  >;
}

const PersonSelect = (props: PersonSelectProps) => {
  const [searchTerm, updateSearchTerm] = React.useState("");
  const [searchTermTemp, updateSearchTermTemp] = React.useState("");

  const searchQuery = useAdvancedPersonSearch({
    searchTerm,
    isEnabled: !!searchTerm,
    extraOpts: {
      functionName: "Page GSPPF",
      operation: "ocADD",
    },
  });

  const people = searchQuery?.data?.ret?.people ?? [];

  return (
    <>
      <form
        className="plan-creation-person-form"
        onSubmit={(e) => {
          updateSearchTerm(`${searchTermTemp}`);

          e.preventDefault?.();
        }}
      >
        <Input
          className="plan-creation-person-form-input"
          name="Person Name"
          onChange={(newVal) => updateSearchTermTemp(newVal)}
          value={searchTermTemp}
        />

        <Button
          className="plan-creation-person-form-btn"
          disabled={searchQuery.isFetching}
          onClick={() => {
            // * NOTE: Should be picked up by form 'onSubmit' above
          }}
          theme="action"
          type="submit"
          value="Search"
        />
      </form>

      <ul>
        {!!people?.length ? (
          people.map((person) => (
            <PersonListOption person={person} key={person.userID} {...props} />
          ))
        ) : (
          <>
            {searchQuery?.isFetching ? (
              <li>Loading...</li>
            ) : (
              <li>No Results</li>
            )}
          </>
        )}
      </ul>
    </>
  );
};

function getThumbnailPath(person: PersonListOptionProps["person"]) {
  const { profileImage } = person ?? {};

  if (!profileImage?.thumbnailPath) {
    return undefined;
  }

  return makeRelPathAbsolute(profileImage.thumbnailPath, true);
}

interface PersonListOptionProps extends PersonSelectProps {
  person: TytoData.AdvancedPerson;
}

const PersonListOption = ({
  person,
  updateSelectedPerson,
}: PersonListOptionProps) => {
  const [thumbnailPath, updateThumbnailPath] = React.useState(
    getThumbnailPath(person)
  );

  React.useEffect(() => {
    updateThumbnailPath(getThumbnailPath(person));
  }, [person]);

  return (
    <li className="font-size-medium">
      <Link
        onClick={() => {
          updateSelectedPerson(person);
        }}
        style={{ padding: "6px 0px", display: "flex", alignItems: "center" }}
        type="button"
        value={`${person.givenName ?? ""} ${person.familyName ?? ""}`}
      >
        <UserThumb
          size={28}
          userName={`${person.givenName ?? ""} ${person.familyName ?? ""}`}
          userThumbPathURL={thumbnailPath}
        />

        <p>
          {person.givenName ?? ""} {person.familyName ?? ""}
        </p>
      </Link>
    </li>
  );
};

interface CreatePlanProps {
  creating?: boolean;
  updateCreating: React.Dispatch<React.SetStateAction<boolean>>;
  person: TytoData.AdvancedPerson;
}

const CreatePlan = (props: CreatePlanProps) => {
  const [errorMsg, updateErrorMsg] = React.useState("");
  const [newPlanID, updateNewPlanID] = React.useState(0);
  const [startDate, updateStartDate] = React.useState(new Date());
  const [thumbnailPath, updateThumbnailPath] = React.useState(
    getThumbnailPath(props.person)
  );

  React.useEffect(() => {
    updateThumbnailPath(getThumbnailPath(props.person));
  }, [props.person]);

  if (newPlanID) {
    return <Redirect to={`${INTERFACE_PATHS.PLAN}/${newPlanID}`} />;
  }

  return (
    <form
      onSubmit={(e) => {
        if (props.creating) {
          // * Nothing to do; already creating...
          return;
        }

        props.updateCreating(true);

        startPlanCreation({
          person: props.person,
          startDate,
          onError: (newErrorMsg) => {
            updateErrorMsg(newErrorMsg);
            props.updateCreating(false);
          },
          onSuccess: (planID) => {
            props.updateCreating(false);
            updateNewPlanID(planID);
          },
        });

        e.preventDefault?.();
      }}
    >
      <section>
        <UserThumb
          size={42}
          userName={`${props.person.givenName ?? ""} ${
            props.person.familyName ?? ""
          }`}
          userThumbPathURL={thumbnailPath}
        />

        <p>
          {props.person.givenName ?? ""} {props.person.familyName ?? ""}
        </p>
      </section>

      <p>Start Date</p>
      <DatePicker
        date={startDate}
        onChange={(newDate) => {
          updateStartDate(newDate ?? new Date());
        }}
        type="date"
      />

      {errorMsg && <p>{errorMsg}</p>}

      <Button
        className="plan-creation-create-form-btn"
        disabled={props.creating}
        onClick={() => {}}
        type="submit"
        value={props.creating ? "..." : "Create Plan"}
        theme="action"
      />
    </form>
  );
};

const DEFAULT_PLAN_DATA = {
  // gsPlanName: "",
  // gsPlanDesc: "",
  gsPlanType: "ocPPF",
  isLocked: false,
  description1: "",
  // teamRoot: 0,
  // endTime: "",
  // startTime: "",
  // aboutID: 0,
  aboutType: "ocPERSON",
};

// const DEFAULT_MEMBER_ADD_DATA = {
//   // gsPlanID: 0,
//   // memberID: 0,
//   goalView: true,
//   memberView: true,
//   planView: true,
//   goalAdd: false,
//   goalChange: false,
//   goalDelete: false,
//   measureLogAdd: true,
//   measureLogChange: true,
//   measureLogDelete: true,
//   measureLogView: true,
//   memberAdd: true,
//   memberChange: true,
//   memberDelete: true,
//   noticeStandardAdd: false,
//   noticeStandardChange: false,
//   noticeStandardDelete: false,
//   noticeStandardView: false,
//   planChange: false,
//   planDelete: false,
// };

const DEFAULT_PARENT_GOAL_NAMES = ["Personal", "Professional", "Financial"];

const DEFAULT_GOAL_DATA: {
  goalType: keyof typeof TytoData.GoalType;
  targetStatus: keyof typeof TytoData.GoalStatus;
  weight: number;
} = {
  goalType: "ocCOMPOSITE",
  // gsGoalDesc: "Personal",
  // gsGoalName: "Personal",
  // gsPlanID: 0,
  // targetDateFinal: "2023-04-26T23:03:35.998Z",
  targetStatus: "ocNOTSTARTED",
  weight: 1,
};

async function startPlanCreation({
  person,
  startDate,
  onError,
  onSuccess,
}: {
  person: TytoData.AdvancedPerson;
  startDate: Date;
  onError: (errorMsg: string) => void;
  onSuccess: (gsPlanID: number) => void;
}) {
  if (!startDate || (startDate.getFullYear?.() ?? NaN) === NaN) {
    onError("Bad Start Date. Could not trigger Plan creation.");
  }

  try {
    const startYear = startDate.getFullYear();
    const startDateFormatted = startDate.toISOString();
    const endDate = new Date(startDate);
    endDate.setFullYear(startDate.getFullYear() + 100);
    const endDateFormatted = endDate.toISOString();

    const personName = `${person.givenName ?? ""} ${person.familyName ?? ""}`;

    const planName = `${startYear}, ${personName}`;
    const nowAsString = new Date().toISOString();

    const planResp = await API.GS.Plan.post({
      ...DEFAULT_PLAN_DATA,
      gsPlanName: planName,
      gsPlanDesc: planName,
      description1: nowAsString,
      teamRoot: person.teamRootID,
      aboutID: person.userID,
      startTime: startDateFormatted,
      endTime: endDateFormatted,
    });

    await API.GS.Member.post({
      // ...DEFAULT_PPF_MEMBER_ADD_DATA,
      ...PPF_MEMBER_ADD_DATA_EVERYTHING_BUT_PLAN_CHANGE,
      gsPlanID: planResp.gsPlanID,
      memberID: person.userID,
    });

    // * NOTE: WIP
    const createdGoals = await Promise.all(
      DEFAULT_PARENT_GOAL_NAMES.map((goalName) =>
        API.GS.Goal.post({
          ...DEFAULT_GOAL_DATA,
          targetDateFinal: endDateFormatted,
          gsPlanID: planResp.gsPlanID,
          gsGoalDesc: `${goalName}`,
          gsGoalName: `${goalName}`,
        })
      )
    );

    onSuccess(planResp.gsPlanID);
  } catch (err) {
    onError(pullMessageFromError(err));
  }
}

export default PlanCreation;
