/*
 * Takes in a single Asset Object and renders an Encoding (if possible).
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { getScopedEncoding, getViewAssetPath } from "data/helpers/";
import { useLessonThumbnailOverride } from "data/helpers/Hooks";

import Image from "../image/";
import { Props as VideoProps } from "../video/";
// import Link from "../link/";
import AudioWrapper from "./subcomponents/AudioWrapper";
import URLWrapper, { URLDisplayProps } from "./subcomponents/URLWrapper";
import VideoWrapper from "./subcomponents/VideoWrapper";

import "./style.scss";

export interface Props {
  audioProps?: Partial<Omit<VideoProps, "poster">>;
  className?: string;
  containerClassName?: string;
  asset: TytoData.Asset;
  images?: Data.LessonImage[];
  onClick?: (
    e: React.SyntheticEvent<HTMLElement>,
    childRef?: React.MutableRefObject<HTMLElement | null>
  ) => void;
  urlProps?: URLDisplayProps;
  videoProps?: Partial<VideoProps>;
  useImageForVideoUntilInteraction?: boolean;
  videoPlayIsReplay?: boolean;
  useCaption?: boolean;
  style?: React.CSSProperties;
}

const Asset = (props: Props) => {
  const childRef = React.useRef<HTMLElement | null>(null);
  const [assetType, updateAssetType] = React.useState(
    props.asset?.assetType?.toLocaleLowerCase()
  );

  // React.useEffect(() => {
  //   updateAssetType(props.asset?.assetType?.toLocaleLowerCase());
  // }, [props.asset?.assetType]);

  return (
    <figure
      className={cx("cc-asset-cont", props.containerClassName, assetType)}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e, childRef);
        }
      }}
    >
      <AssetComponentRouter childRef={childRef} {...props} />
    </figure>
  );
};

function getMimeTypeScopeValue(
  assetType: keyof typeof TytoData.AssetType
): "image" | "video" | "text" | undefined {
  switch ((assetType || "").toLocaleLowerCase()) {
    case "ocphoto":
    case "ocimage":
    case "ocpdf":
    case "ocword":
      return "image";
    case "ocvideo":
      return "video";
    case "ocassignment":
    case "ocscorm":
    case "ocurl":
    case "oczip":
    default:
      return undefined;
  }
}

interface AssetRouterProps extends Props {
  childRef: any;
}

const AssetComponentRouter = (props: AssetRouterProps) => {
  const [assetType, updateAssetType] = React.useState<
    keyof typeof TytoData.AssetType
  >(_.get(props, "asset.assetType"));
  const [mimeTypeScope, updateMimeTypeScope] = React.useState(
    getMimeTypeScopeValue(assetType)
  );
  const [encoding, updateEncoding] = React.useState(
    getScopedEncoding({
      encodings: props.asset?.encodings as Data.Encoding[],
      targetEncoding: assetType === "ocPDF" ? "ocTHUMBNAIL" : "ocDEFAULT",
      mimeTypeScope,
    })
  );
  const thumbnailPathURL = useLessonThumbnailOverride(props.images);
  const [encodingPathURL, updateEncodingPathURL] = React.useState(
    assetType === "ocPDF" && thumbnailPathURL
      ? thumbnailPathURL
      : getViewAssetPath({
          encodings: props.asset?.encodings as Data.Encoding[],
          targetEncoding: assetType === "ocPDF" ? "ocTHUMBNAIL" : "ocDEFAULT",
          mimeTypeScope,
          includeSessionKey: true,
        })
  );

  React.useEffect(() => {
    // * [1] - Compute New Values
    const newAssetType = _.get(props, "asset.assetType");
    const newMimeTypeScope =
      assetType === newAssetType
        ? mimeTypeScope
        : getMimeTypeScopeValue(newAssetType);
    const newEncoding = getScopedEncoding({
      encodings: props.asset?.encodings as Data.Encoding[],
      targetEncoding: newAssetType === "ocPDF" ? "ocTHUMBNAIL" : "ocDEFAULT",
      mimeTypeScope: newMimeTypeScope,
    });
    const newEncodingPathURL =
      newAssetType === "ocPDF" && thumbnailPathURL
        ? thumbnailPathURL
        : getViewAssetPath({
            encodings: props.asset?.encodings as Data.Encoding[],
            targetEncoding:
              newAssetType === "ocPDF" ? "ocTHUMBNAIL" : "ocDEFAULT",
            mimeTypeScope: newMimeTypeScope,
            includeSessionKey: true,
          });

    // * [2] - Bulk update values after all have been computed
    updateAssetType(newAssetType);
    updateMimeTypeScope(newMimeTypeScope);
    updateEncoding(newEncoding);
    updateEncodingPathURL(newEncodingPathURL);
  }, [props.asset]);

  switch ((assetType || "").toLocaleLowerCase()) {
    case "ocphoto":
    case "ocimage":
    case "ocpdf":
    case "ocword":
      return (
        <>
          <Image
            alt={`${props.asset.assetDesc}`}
            className={cx("cc-asset cc-asset-image", props.className)}
            src={encodingPathURL}
            innerRef={props.childRef}
            style={{
              ...(props.style || {}),
              aspectRatio:
                encoding?.width && encoding?.height
                  ? `${encoding.width / encoding.height}`
                  : undefined,
            }}
          />

          {props.useCaption && props.asset.assetDesc && (
            <figcaption className="cc-asset-caption image-caption text-color">
              {props.asset.assetDesc}
            </figcaption>
          )}
        </>
      );
    case "ocaudio":
      return <AudioWrapper {...props} />;
    case "ocvideo":
      return <VideoWrapper {...props} assetType={assetType} />;
    case "ocurl":
      return (
        <URLWrapper
          {...props}
          {...(props.urlProps ?? {})}
          encodingPathURL={encodingPathURL}
        />
        // <Link
        //   className={cx("cc-asset cc-asset-url", props.className)}
        //   href={encodingPathURL}
        //   target="_blank"
        //   value={props.asset.assetName}
        //   style={props.style}
        // />
      );
    case "ocspreadsheet":
      if (encodingPathURL) {
        return (
          <>
            <Image
              alt={`${props.asset.assetDesc}`}
              className={cx("cc-asset cc-asset-image", props.className)}
              src={encodingPathURL}
              innerRef={props.childRef}
              style={{
                ...(props.style || {}),
                aspectRatio:
                  encoding?.width && encoding?.height
                    ? `${encoding.width / encoding.height}`
                    : undefined,
              }}
            />

            {props.useCaption && props.asset.assetDesc && (
              <figcaption className="cc-asset-caption image-caption text-color">
                {props.asset.assetDesc}
              </figcaption>
            )}
          </>
        );
      }

      return (
        <div
          className="cc-asset zipped-asset-msg text-color"
          style={props.style}
        >
          <p>Spreadhseet</p>
          <p className="cc-asset-nopreview-msg text-color">(No Preview)</p>
        </div>
      );
    case "oczip":
      return (
        <div
          className="cc-asset zipped-asset-msg text-color"
          style={props.style}
        >
          <p>Zipped Asset</p>
          <p className="cc-asset-nopreview-msg text-color">(No Preview)</p>
        </div>
      );
    default:
      return (
        <p className="cc-asset asset-error-msg" style={props.style}>
          Invalid Asset ({(assetType || "").replace(/^oc/, "")}).
        </p>
      );
  }
};

export default Asset;
